import RatingTable from "@components/rating/RatingTable";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import { useGetPendingRatings } from "@store/actions/ratingActions";
import React from "react";

function RatingsPage() {
  const { loading, error, data } = useGetPendingRatings();

  if (loading) {
    return (
      <Box>
        <CircularProgress />
        <Typography>Loading Pending Ratings</Typography>
      </Box>
    );
  }
  return (
    <div>
      <RatingTable ratings={data?.pendingRatings || []} />
    </div>
  );
}

export default RatingsPage;
