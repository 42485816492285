import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useCreatePackagePage } from "@store/actions/packagePageActions";

const NewPackagePageModal = ({ open, setOpen, onSave }) => {
  const [name, setName] = useState("");
  const [addPackagePage] = useCreatePackagePage();

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    addPackagePage({
      variables: {
        input: { name },
      },
    }).then(({ data }) => {
      onSave(data.createPackagePage);
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <form onSubmit={handleSave}>
        <DialogTitle id="form-dialog-title">New Package page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the package page name, after which you will be taken to
            the package page setup wizard.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Package page Name"
            fullWidth
            value={name}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewPackagePageModal;
