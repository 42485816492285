import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_PACKAGE_PAGE,
  DELETE_PACKAGE_PAGE,
  GET_ALL_PACKAGE_PAGES,
  GET_PACKAGE_PAGE,
  UPDATE_PACKAGE_PAGE,
} from "@operations/packagePageOperations";

/* -------------------------------------------------------------------------- */
/*                                PACKAGE PAGES                               */
/* -------------------------------------------------------------------------- */

export const useGetPackagePage = (id) =>
  useQuery(GET_PACKAGE_PAGE, {
    variables: {
      id,
    },
  });

export const useGetAllPackagePages = () => useQuery(GET_ALL_PACKAGE_PAGES);

export const useCreatePackagePage = () =>
  useMutation(CREATE_PACKAGE_PAGE, {
    update: (cache, { data: { createPackagePage } }) => {
      const data = cache.readQuery({
        query: GET_ALL_PACKAGE_PAGES,
      });
      const packagePages = data ? data.packagePages : [];

      writePackagePagesCache(cache, [...packagePages, createPackagePage]);
    },
  });

export const useUpdatePackagePage = () => useMutation(UPDATE_PACKAGE_PAGE);

export const useDeletePackagePage = () =>
  useMutation(DELETE_PACKAGE_PAGE, {
    update: (cache, { data: { deletePackagePage } }) => {
      const data = cache.readQuery({
        query: GET_ALL_PACKAGE_PAGES,
      });
      const packagePages = data ? data.packagePages : [];

      writePackagePagesCache(
        cache,
        packagePages.filter((lp) => lp.id !== deletePackagePage.id)
      );
    },
  });

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const writePackagePagesCache = (cache, packagePages) => {
  cache.writeQuery({
    query: GET_ALL_PACKAGE_PAGES,
    data: {
      packagePages,
    },
  });
};
