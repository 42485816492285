import { List } from "@material-ui/core";
import React from "react";
import BookingItemDrag from "./BookingItemDrag";

function BookingItemCosts({ booking, quote }) {
  // need booking item costs
  return (
    <List>
      {booking?.items
        ?.filter((item) => item.boat)
        .map((item) => (
          <BookingItemDrag key={item.id} item={item} />
        ))}
    </List>
  );
}

export default BookingItemCosts;
