import { ListItem, ListItemIcon, ListItemText } from "@material-ui/core";
import { useBoatLowestPrice } from "components-library";
import DirectionsBoat from "@material-ui/icons/DirectionsBoat";
import { useDrag } from "react-dnd";

function BookingItemDrag({ item }) {
  const { price: lowestPrice } = useBoatLowestPrice(item?.boat);
  const [{ isDragging }, drag] = useDrag({
    item: { type: "bookingItem", item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.4 : 1;
  return (
    <ListItem ref={drag} variant="outlined" style={{ opacity }}>
      <ListItemIcon>
        <DirectionsBoat />
      </ListItemIcon>
      <ListItemText
        primary={item?.boat?.name}
        secondary={
          item?.boat?.owner
            ? `From $${lowestPrice}`
            : `Boat has no owner, cannot have quote sent, From $${lowestPrice}`
        }
        secondaryTypographyProps={{
          style: {
            whiteSpace: "normal",
          },
        }}
      />
    </ListItem>
  );
}

export default BookingItemDrag;
