import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_MENU,
  UPDATE_MENU,
  GET_ALL_MENUS,
  DELETE_MENU,
  CREATE_TOP_BAR_MENU,
  GET_ALL_TOP_BAR_MENUS,
  UPDATE_TOP_BAR_MENU,
  DELETE_TOP_BAR_MENU,
} from "@operations/menuOperations";

/* -------------------------------------------------------------------------- */
/*                                   Menus                                    */
/* -------------------------------------------------------------------------- */

export const useCreateMenu = () =>
  useMutation(CREATE_MENU, {
    update: (cache, { data: { createMenu } }) => {
      const data = cache.readQuery({
        query: GET_ALL_MENUS,
      });
      cache.writeQuery({
        query: GET_ALL_MENUS,
        data: {
          menus: [...(data.menus || []), createMenu],
        },
      });
    },
  });

export const useUpdateMenu = () => useMutation(UPDATE_MENU);

export const useGetAllMenus = () => useQuery(GET_ALL_MENUS);

export const useDeleteMenu = () =>
  useMutation(DELETE_MENU, {
    update: (cache, { data: { deleteMenu } }) => {
      const data = cache.readQuery({
        query: GET_ALL_MENUS,
      });
      const menus = data ? data.menus : [];
      cache.writeQuery({
        query: GET_ALL_MENUS,
        data: {
          menus: menus.filter((w) => w.id !== deleteMenu.id),
        },
      });
    },
  });

/* -------------------------------------------------------------------------- */
/*                                   Top Bar Menus                            */
/* -------------------------------------------------------------------------- */

export const useCreateTopBarMenu = () =>
  useMutation(CREATE_TOP_BAR_MENU, {
    update: (cache, { data: { createTopBarMenu } }) => {
      const data = cache.readQuery({
        query: GET_ALL_TOP_BAR_MENUS,
      });
      cache.writeQuery({
        query: GET_ALL_TOP_BAR_MENUS,
        data: {
          topBarMenus: [...(data.topBarMenus || []), createTopBarMenu],
        },
      });
    },
  });

export const useUpdateTopBarMenu = () => useMutation(UPDATE_TOP_BAR_MENU);

export const useGetAllTopBarMenus = () => useQuery(GET_ALL_TOP_BAR_MENUS);

export const useDeleteTopBarMenu = () =>
  useMutation(DELETE_TOP_BAR_MENU, {
    update: (cache, { data: { deleteTopBarMenu } }) => {
      const data = cache.readQuery({
        query: GET_ALL_TOP_BAR_MENUS,
      });
      const topBarMenus = data ? data.topBarMenus : [];
      cache.writeQuery({
        query: GET_ALL_TOP_BAR_MENUS,
        data: {
          topBarMenus: topBarMenus.filter((w) => w.id !== deleteTopBarMenu.id),
        },
      });
    },
  });
