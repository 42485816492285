import { useImperativeGetPresignedURL } from "@store/actions/mediaActions";
import React from "react";
import ReactS3Uploader from "react-s3-uploader";

function Uploader({ onFinish, hidden, path, multiple, accept }) {
  const getSignedUrl = useImperativeGetPresignedURL();

  const onGetSignedUrl = async (file, callback) => {
    const { data } = await getSignedUrl({
      input: {
        name: file.name,
        path,
        contentType: file.type,
      },
    });
    callback({ signedUrl: data.presignedUrl });
  };

  return (
    <ReactS3Uploader
      hidden={hidden}
      getSignedUrl={onGetSignedUrl}
      accept={accept}
      multiple={multiple}
      // onProgress={onProgress}
      // onError={onError}
      onFinish={onFinish}
      uploadRequestHeaders={{}}
      contentDisposition="auto"
    />
  );
}

export default Uploader;
