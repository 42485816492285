import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import {
  useCreateLocation,
  useUpdateLocation,
} from "@store/actions/locationActions";

function LocationModal({ open, setOpen, onSave, location, setLocation }) {
  const [addLocation] = useCreateLocation();
  const [updateLocation] = useUpdateLocation();

  const close = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    const input = { name: location.name };

    const fn = location.id
      ? updateLocation({
          variables: {
            id: location.id,
            input,
          },
        })
      : addLocation({
          variables: {
            input,
          },
        });

    fn.then(({ data }) => {
      setLocation({});
      onSave(location.id ? data.updateLocation : data.createLocation);
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      fullWidth
      open={open}
      onClose={close}
      disableBackdropClick={true}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle id="form-dialog-title">New Location</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the name of this location
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Location Name"
            fullWidth
            value={location.name}
            onChange={(e) =>
              setLocation({
                ...location,
                name: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default LocationModal;
