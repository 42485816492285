import Loading from "@components/loading/Loading";
import { useGetQuoteByRef } from "@store/actions/quoteActions";
import { Redirect, useParams } from "react-router-dom";

function QuotePage() {
  const { quoteRef } = useParams();
  const { data, loading } = useGetQuoteByRef(quoteRef);
  if (!quoteRef || loading || !data) {
    return <Loading />;
  }
  return (
    <Redirect
      to={`/bookings/${data?.quoteByRef?.booking?.id}/quotes/${data?.quoteByRef?.id}`}
    />
  );
}

export default QuotePage;
