import React, { createContext, useContext, useMemo, useState } from "react";

export const BoatContext = createContext(null);
export const useBoatContext = () => useContext(BoatContext);

const BoatProvider = ({ children }) => {
  const [boat, setBoat] = useState({});

  const state = useMemo(
    () => ({
      boat,
      setBoat,
    }),
    [boat]
  );

  return <BoatContext.Provider value={state}>{children}</BoatContext.Provider>;
};

export default BoatProvider;
