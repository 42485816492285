import Loading from "@components/loading/Loading";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { useGetAllWharfs } from "@store/actions/wharfActions";
import { useEffect, useState } from "react";
import WharfModal from "./WharfModal";

function WharfSelection({ title, parent, setParent }) {
  const [open, setOpen] = useState(false);
  const [wharf, setWharf] = useState({});
  const [wharfs, setWharfs] = useState([]);
  const { loading, data } = useGetAllWharfs();

  useEffect(() => {
    if (data?.wharfs) {
      setWharfs(
        [...data.wharfs].sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        )
      );
    }
  }, [data]);

  const getParentWharfIndex = (wharf) =>
    (parent.wharfs || []).map((f) => f.id).indexOf(wharf.id);

  const isChecked = (wharf) => {
    return getParentWharfIndex(wharf) >= 0;
  };

  const onSave = (wharf) => {
    handleChange(wharf);
    setOpen(false);
  };

  const handleChange = (wharf) => {
    const idx = getParentWharfIndex(wharf);
    let wharfs = [...(parent.wharfs || [])];

    if (idx === -1) {
      wharfs.push(wharf);
    } else {
      wharfs.splice(idx, 1);
    }
    setParent({
      ...parent,
      wharfs,
    });
  };

  if (loading) return <Loading cover={true} />;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="baseline">
            <Typography variant="subtitle1">{title}</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Add new wharf
            </Button>{" "}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {wharfs.map((w) => (
              <Grid key={w.id} item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isChecked(w)}
                      onChange={() => handleChange(w)}
                      name={w.id}
                    />
                  }
                  label={w.name}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <WharfModal
        open={open}
        setOpen={setOpen}
        onSave={onSave}
        wharf={wharf}
        setWharf={setWharf}
      />
    </>
  );
}

export default WharfSelection;
