import Uploader from "@components/media/Uploader";
import { Button, Divider, Grid } from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { useBoatContext } from "@providers/BoatProvider";
import {
  useCreateMedia,
  useDeleteMedia,
  useGetAllMedia,
} from "@store/actions/mediaActions";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { generateUploadedPath } from "utils/helpers";
import MediaSelectModal from "@components/media/MediaSelectModal";
import { useCallback, useState } from "react";
import { useEffect } from "react";
import { ImageGrid, useBoatOrderedImages } from "components-library";
import BoatAssetsDropzone from "./BoatAssetsDropzone";
import { useDebounce } from "react-use";

export default function BoatAssets({ updateBoat }) {
  const { boat, setBoat } = useBoatContext();
  const [addMedia] = useCreateMedia();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showNotification } = useNotificationContext();
  const [deleteMedia, { loading: deleteMediaLoading }] = useDeleteMedia();
  const [open, setOpen] = useState(false);
  const { data: mediaData } = useGetAllMedia();
  const [selectedMedia, setSelectedMedia] = useState([]);
  const { images: boatImages } = useBoatOrderedImages(boat);

  useEffect(() => {
    setSelectedMedia(boat?.medias);
  }, []);

  useDebounce(
    () => {
      onSelect(selectedMedia);
    },
    selectedMedia,
    500
  );

  const onSelect = async (medias) => {
    const updatedBoat = {
      ...boat,
      medias,
    };
    setBoat(updatedBoat);
    await updateBoat(updatedBoat);
  };

  const onFinish = async (result, file) => {
    const source = generateUploadedPath(result.signedUrl.split("?")[0]);
    // save a media
    const { data: res } = await addMedia({
      variables: {
        input: {
          name: file.name,
          source,
        },
      },
    });
    setSelectedMedia((selectedMedia) => [...selectedMedia, res.createMedia]);
  };

  const deleteAsset = (asset) => {
    openConfirmation({
      title: `Delete ${asset.name}`,
      body: `Deleting ${asset.name} can not be undone, are you sure you want to continue?`,
      loading: deleteMediaLoading,
      callback: () => {
        deleteMedia({
          variables: {
            id: asset.id,
          },
        }).then(() => {
          const updatedBoat = {
            ...boat,
            medias: boat.medias.filter((m) => m.id !== asset.id),
            mediaOrder: boat.mediaOrder.filter((id) => id !== asset.id),
          };
          setBoat(updatedBoat);
          updateBoat(updatedBoat);
          closeConfirmation();
          showNotification(`${asset.name} Deleted!`);
        });
      },
    });
  };

  const setMediaOrder = useCallback(
    (fromIndex, toIndex) => {
      const fromID = boat.mediaOrder[fromIndex];
      let clonedOrder = boat.mediaOrder.slice();
      clonedOrder.splice(fromIndex, 1);
      clonedOrder.splice(toIndex, 0, fromID);
      setBoat({
        ...boat,
        mediaOrder: clonedOrder,
      });
    },
    [boat.mediaOrder]
  );

  return (
    <div>
      <Grid container spacing={2}>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            component="label"
            startIcon={<CloudUpload />}
          >
            Upload Boat Image
            <Uploader
              onFinish={onFinish}
              hidden={true}
              path={boat.id}
              accept="image/*"
            />
          </Button>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            variant="contained"
            component="label"
            onClick={() => setOpen(true)}
          >
            Select media
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Divider />
        </Grid>

        <Grid item xs={12}>
          <BoatAssetsDropzone
            assets={boatImages}
            onDeleteAsset={deleteAsset}
            setOrder={setMediaOrder}
          />
        </Grid>
      </Grid>
      <MediaSelectModal
        open={open}
        setOpen={setOpen}
        media={mediaData?.medias || []}
        selected={selectedMedia}
        setSelected={setSelectedMedia}
        multiple={true}
        Preview={() => <ImageGrid images={boatImages} />}
      />
    </div>
  );
}
