import { createTheme } from "@material-ui/core";
import { LoadTheme } from "theme";

const loadTheme = async () => {
  const loadedTheme = await LoadTheme();
  const Config = loadedTheme.conf.default;

  const theme = createTheme({
    spacing: Config.spacing || 8,
    palette: {
      primary: {
        main: Config.primary || "#7a5195",
      },
      secondary: {
        main: Config.secondary || "#003f5c",
      },
      // warning: {
      //   main: Config.warning || "#ff8a8f",
      // },
      // info: {
      //   main: Config.info || "#5a7aff",
      // },
      // error: {
      //   main: Config.error || "#cc3439",
      // },
      // success: {
      //   main: Config.success || "#52c41a",
      // },
      background: {
        default: Config.portalBackground || "#f6f9fb",
      },
      text: {
        primary: Config.text.primary,
        secondary: Config.text.secondary,
      },
    },
    shape: {
      borderRadius: 6,
    },
    custom: {},
    typography: {
      fontFamily: [
        "Raleway",
        "HelveticaNeue",
        "Helvetica Neue",
        "Helvetica",
        "Arial",
        "sans-serif",
      ].join(","),
      subtitle1: {
        fontSize: "1.1rem",
        lineHeight: 1.5,
      },

      h1: {
        fontWeight: 400,
      },
      h2: {
        fontWeight: 400,
      },
      h3: {
        fontWeight: 400,
      },
      h4: {
        fontWeight: 400,
      },
      h5: {
        fontWeight: 400,
      },
      h6: {
        fontWeight: 400,
      },
    },
  });

  theme.custom = {
    ...theme.custom,
    sidebar: {
      background: Config.sidebarBackground || theme.palette.common.white,
    },
    drawerWidth: 260,
    transition: {
      transition: "all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)",
    },
    container: {
      paddingRight: "15px",
      paddingLeft: "15px",
      marginRight: "auto",
      marginLeft: "auto",
    },
  };

  theme.mixins = {
    ...theme.mixins,
    truncate: {
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  };

  theme.overrides = {
    ...theme.overrides,
    MuiCssBaseline: {
      "@global": {
        a: {
          textDecoration: "none",
          color: theme.palette.primary.main,
        },
      },
    },
    MuiDialog: {
      root: {
        backdropFilter: "blur(2px)",
      },
      paper: {
        border: `4px solid ${theme.palette.primary.main}`,
        borderRadius: 10,
      },
    },
    MuiDialogTitle: {
      root: {
        background: theme.palette.primary.main,
        color: "white",
        marginBottom: theme.spacing(2),
      },
    },
    MuiDialogActions: {
      root: {
        borderTop: `1px solid ${theme.palette.divider}`,
      },
    },

    // MuiTab: {
    //   root: {
    //     minWidth: "120px!important",
    //   },
    // },
  };
  return theme;
};

export default loadTheme;
