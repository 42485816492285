import BookingOptionDrop from "@components/booking/BookingOptionDrop";
import WysiwygEditor from "@components/WysiwygEditor/WysiwygEditor";
import Loading from "@components/loading/Loading";
import {
  Button,
  Drawer,
  FormControlLabel,
  Grid,
  Switch,
  Tooltip,
  Typography,
} from "@material-ui/core";
import {
  useGetBooking,
  useGetBookingOption,
  useSendBookingOption,
  useUpdateBookingOption,
} from "@store/actions/bookingActions";
import { useEffect, useLayoutEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useNotificationContext } from "@providers/NotificationProvider";
import BookingOptionItems from "@components/booking/BookingOptionItems";
import { baseURL } from "index";
import MessageModal from "@components/message/MessageModal";
import moment from "moment";
import { useBookingContext } from "@providers/BookingProvider";
import { useUnmount } from "react-use";
import { useLayoutContext } from "@providers/LayoutProvider";
import { friendlyDateTime } from "utils/date";
import { useGetSiteMeta } from "@store/actions/siteMetaActions";

export const BookingOptionPage = () => {
  const { bookingId, bookingOptionId } = useParams();
  const { data: bookingData } = useGetBooking(bookingId);
  const { data: meta } = useGetSiteMeta();
  const { data, loading } = useGetBookingOption(bookingOptionId);
  const [updateBookingOption] = useUpdateBookingOption();
  const [sendBookingOption] = useSendBookingOption(bookingOptionId);
  const { option, setOption, chatOpen } = useBookingContext();
  const { showNotification } = useNotificationContext();
  const [open, setOpen] = useState(false);
  const { setContentRight } = useLayoutContext();
  const [msg, setMsg] = useState("");
  const [sendSms, setSendSms] = useState(true);

  useLayoutEffect(() => {
    if (!chatOpen) {
      setTimeout(() => {
        setContentRight(420);
      }, 1);
    }

    return () => {
      setContentRight("0");
    };
  }, [chatOpen]);

  useEffect(() => {
    setOption(data?.bookingOption);
  }, [data]);

  useEffect(() => {
    if (!meta) {
      return;
    }
    setMsg(`Thank you for your enquiry with the ${
      meta.siteMeta.siteName || "Boat Hire"
    }.
Below are some options for your cruise experience on ${friendlyDateTime(
      bookingData.booking.cruiseDate
    )}.\n
Please review the options and let us know if any of these work for you.`);
  }, [bookingData, meta]);

  useUnmount(() => {
    setOption({});
  });

  const setMessage = (message) => setOption({ ...option, message });

  const saveAndSend = async (message) => {
    await save();
    await sendBookingOption({
      variables: {
        id: option.id,
        message,
        sendSms,
      },
    });
    setOpen(false);
    showNotification("Option Sent");
  };

  const save = async () => {
    const input = {
      message: option.message,
    };
    const items = option.items.map((item) => ({
      price: item.price,
      hours: item.hours,
      item: item.bookingItem.id ? { id: item.bookingItem.id } : null,
      boat: item.bookingItem.boat ? { id: item.bookingItem.boat.id } : null,
    }));
    await updateBookingOption({
      variables: {
        id: option.id,
        input,
        items,
      },
    });

    showNotification("Option Saved");
    return;
  };

  if (loading || !option) return <Loading />;
  return (
    <div>
      <Grid container>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom>
                Booking option -{" "}
                <Tooltip title="Click link to preview booking option, note the url must contain admin=true or the option will be marked as seen by the customer">
                  <a
                    href={`${baseURL}/options/?ref=${option?.reference}&admin=true`}
                    target="_blank"
                  >
                    {baseURL}/options/?ref={option?.reference}&admin=true
                  </a>
                </Tooltip>
              </Typography>
              <Typography variant="body1">
                Drag Booking items from the list on the right to add options to
                send
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <BookingOptionDrop
                booking={bookingData?.booking}
                option={option}
                setOption={setOption}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <Grid item xs={12}>
              <Typography variant="body1" gutterBottom>
                This message will be appended to the bottom of the page
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <WysiwygEditor msg={option?.message} setMsg={setMessage} />
            </Grid>
            <Grid item xs={12}>
              <Grid
                container
                justify="flex-end"
                alignItems="center"
                spacing={3}
              >
                {option?.sentOn && (
                  <Grid item>
                    <Typography variant="body1">
                      Last sent on {moment(option.sentOn).format("lll")}
                    </Typography>
                  </Grid>
                )}
                {option?.seenOn && (
                  <Grid item>
                    <Typography variant="body1">
                      Last seen on {moment(option.seenOn).format("lll")}
                    </Typography>
                  </Grid>
                )}
                <Grid item>
                  <Button variant="contained" color="primary" onClick={save}>
                    Save Only
                  </Button>
                </Grid>
                <Grid item>
                  <FormControlLabel
                    size="small"
                    labelPlacement="start"
                    control={
                      <Switch
                        size="small"
                        checked={sendSms}
                        onChange={(_, checked) => setSendSms(checked)}
                        color="primary"
                      />
                    }
                    label={<Typography variant="body2">Send SMS</Typography>}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen(true)}
                  >
                    Save & Send Option
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Drawer variant="permanent" anchor="right">
        <BookingOptionItems booking={bookingData?.booking} option={option} />
      </Drawer>
      <MessageModal
        open={open}
        setOpen={setOpen}
        onSave={saveAndSend}
        initialMsg={msg}
        title="Send a personalised message to the Customer"
      />
    </div>
  );
};
