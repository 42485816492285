import { gql } from "@apollo/client";

export const GET_PRESIGNED_URL = gql`
  query PresignedUrl($input: FileInput!) {
    presignedUrl(input: $input)
  }
`;

export const GET_MEDIA = gql`
  query Media($id: UUID!) {
    media(id: $id) {
      id
      name
      type
      mediaFiles {
        name
        path
        type
      }
    }
  }
`;
export const GET_ALL_MEDIA = gql`
  query GetAllMedia {
    medias {
      id
      name
      type
      createdAt
      mediaFiles {
        name
        path
        type
      }
    }
  }
`;

export const CREATE_MEDIA = gql`
  mutation CreateMedia($input: MediaInput!) {
    createMedia(input: $input) {
      id
      name
      type
      mediaFiles {
        name
        path
        type
      }
    }
  }
`;

export const DELETE_MEDIA = gql`
  mutation DeleteMedia($id: UUID!) {
    deleteMedia(id: $id) {
      id
    }
  }
`;
