import CardTable from "@components/cardTable/CardTable";
import JsonDisplayer from "@components/jsonDisplayer/JsonDisplayer";
import Loading from "@components/loading/Loading";
import { useGetAllPayouts } from "@store/actions/quoteActions";
import {
  Tab,
  Tabs,
  Box,
  Typography,
  Button,
  TextField,
} from "@material-ui/core";
import { useMemo, useState } from "react";
import { useMarkPayoutAsPaid } from "@store/actions/quoteActions";
import moment from "moment";
import { baseURL } from "index";
import { friendlyDateTime } from "utils/date";

const IDCol = (col, row) => {
  return (
    <Box>
      <Typography variant="body2">Payout ID : {col}</Typography>
      <Typography variant="body2">
        Record created at : {friendlyDateTime(row.createdAt)}
      </Typography>
      <Typography variant="body2">
        Quote REF :{" "}
        <a
          href={`${baseURL}/quote/?ref=${row.quote?.reference}&admin=true`}
          target="_blank"
        >
          {row.quote?.reference}
        </a>
      </Typography>
      <Typography variant="body2">
        Booking REF : {row.quote?.booking?.reference}
      </Typography>
      <Typography variant="body2">
        Lead : {row.quote?.booking?.lead.email}
      </Typography>
    </Box>
  );
};

const OwnerCol = (col, row) => {
  return (
    <Box>
      <Typography variant="body2">
        {col.firstName} {col.email}
      </Typography>
      <Typography variant="body2" style={{ fontWeight: 700 }}>
        Boat: {row.quoteItem?.bookingItem?.boat?.name}
      </Typography>
      <Typography variant="body2">
        Cruise Date: {moment(row.quote?.booking?.cruiseDate).format("lll")}
      </Typography>
      <Typography variant="body2">({col.email})</Typography>
    </Box>
  );
};
const AmountCol = (col, row) => {
  const isNotExchange = row.amount === row.exchangeAmount;
  const total = (row.quote.items || []).reduce((p, v) => {
    const costsTotal = (v.costs || []).reduce((agg, val) => {
      return agg + val.cost;
    }, 0);
    return p + costsTotal + v.baseCost;
  }, 0);
  return (
    <Box sx={{ minWidth: 180 }}>
      <Typography variant="body2">
        Paid {isNotExchange ? "" : "in USD"}: $
        {(row.internalTransaction?.total / 100).toFixed(2)}
      </Typography>
      <Typography variant="body2">
        + Fees {isNotExchange ? "" : "in USD"} : $
        {(row.internalTransaction?.paymentFees / 100).toFixed(2)}
      </Typography>
      <Typography variant="body2">
        Our Cut {isNotExchange ? "" : "in USD"} : $
        {(row.internalTransaction?.ourCut / 100).toFixed(2)}
      </Typography>

      {isNotExchange || (
        <Typography variant="body2">
          To Owner {isNotExchange ? "" : "in USD"} : $
          {(row.amount / 100).toFixed(2)}
        </Typography>
      )}
      <Typography variant="body2" style={{ fontWeight: 700 }}>
        To Owner {row.owner?.currency} : $
        {(row.exchangeAmount / 100).toFixed(2)}
      </Typography>
      <Typography variant="body2" style={{ fontWeight: 700 }}>
        Total Quote : ${total.toFixed(2)}
      </Typography>
    </Box>
  );
};

const ErrorMsgCol = (col) => {
  const json = JSON.parse(col);
  return json?.code;
};

const StatusMsgCol = (markPayoutAsPaid) => (col, row) => {
  const total = (row.quote.items || []).reduce((p, v) => {
    const costsTotal = (v.costs || []).reduce((agg, val) => {
      return agg + val.cost;
    }, 0);
    return p + costsTotal + v.baseCost;
  }, 0);
  const markAsPaid = async () => {
    await markPayoutAsPaid({
      variables: {
        id: row.id,
      },
    });
  };
  return (
    <Box>
      <Typography variant="body2" style={{ fontWeight: 700 }} gutterBottom>
        {row.bookingFullyPaid
          ? "This Boooking is Fully Paid"
          : "This Boooking is Not Fully Paid"}
      </Typography>
      {row.internalTransaction?.total / 100 !== total && (
        <Typography variant="body2" gutterBottom style={{ fontWeight: 700 }}>
          Deposit
        </Typography>
      )}
      <Typography variant="body2" gutterBottom>
        Status : {row.status}
      </Typography>
      {row.errorMsg && (
        <Typography variant="body2">
          {JSON.parse(row.errorMsg)?.code}
        </Typography>
      )}
      {row.status === "manualEmailSent" && (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={markAsPaid}
        >
          Mark As Paid
        </Button>
      )}
    </Box>
  );
};

const InternalTransactionCol = (col) => {
  const { __typename, ...rest } = col;
  return <JsonDisplayer>{rest}</JsonDisplayer>;
};

function TabPanel({ children, value, index }) {
  return <div>{value === index && <>{children}</>}</div>;
}

function PayoutsPage() {
  const { data, loading } = useGetAllPayouts();
  const [tab, setTab] = useState(0);
  const [search, setSearch] = useState("");
  const [markPayoutAsPaid] = useMarkPayoutAsPaid();

  const payouts = useMemo(
    () =>
      (data?.payouts ? [...data?.payouts] : []).sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      ),
    [data]
  );

  const manualPayouts = useMemo(() => {
    const arr = payouts
      .filter((p) => ["manualEmailSent"].includes(p.status))
      .filter(
        (p) =>
          (search || "").length < 3 ||
          p.id.toLowerCase().includes(search.toLowerCase())
      );
    return arr || [];
  }, [payouts, search]);

  const manualPaid = useMemo(() => {
    const arr = payouts
      .filter((p) => ["manualPaid"].includes(p.status))
      .filter(
        (p) =>
          (search || "").length < 3 ||
          p.id.toLowerCase().includes(search.toLowerCase())
      );
    return arr || [];
  }, [payouts, search]);

  const manualError = useMemo(() => {
    const arr = payouts
      .filter((p) => ["manualEmailError"].includes(p.status))
      .filter(
        (p) =>
          (search || "").length < 3 ||
          p.id.toLowerCase().includes(search.toLowerCase())
      );
    return arr || [];
  }, [payouts, search]);

  const stripePayouts = useMemo(() => {
    return payouts.filter((p) => ["sent", "recieved"].includes(p.status));
  }, [payouts]);

  const stripePayoutsError = useMemo(() => {
    return payouts.filter((p) => ["error"].includes(p.status));
  }, [payouts]);

  const stripePayoutsPending = useMemo(() => {
    return payouts.filter((p) => ["ready"].includes(p.status));
  }, [payouts]);

  if (loading) {
    return <Loading />;
  }

  return (
    <>
      <Tabs value={tab} onChange={(_, v) => setTab(v)}>
        <Tab label="Manual To Pay" />
        <Tab label="Manual Error" />
        <Tab label="Manual Paid" />
        <Tab label="Stripe Transfered" />
        <Tab label="Stripe Pending" />
        <Tab label="Stripe Error" />
      </Tabs>
      <Box mt={5}>
        <TabPanel value={tab} index={0}>
          <Box mb={3} sx={{ width: 300 }}>
            <TextField
              label="Search By ID"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <CardTable
            tableHead={["id", "amount", "owner", "status"]}
            tableData={manualPayouts}
            customColumns={[
              {
                name: "id",
                component: IDCol,
              },
              {
                name: "owner",
                component: OwnerCol,
              },
              {
                name: "amount",
                component: AmountCol,
              },
              {
                name: "status",
                component: StatusMsgCol(markPayoutAsPaid),
              },
            ]}
          />
        </TabPanel>

        <TabPanel value={tab} index={1}>
          <Box mb={3} sx={{ width: 300 }}>
            <TextField
              label="Search By ID"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <CardTable
            tableHead={["id", "amount", "owner", "status"]}
            tableData={manualError}
            customColumns={[
              {
                name: "id",
                component: IDCol,
              },
              {
                name: "owner",
                component: OwnerCol,
              },
              {
                name: "amount",
                component: AmountCol,
              },
              {
                name: "status",
                component: StatusMsgCol(markPayoutAsPaid),
              },
            ]}
          />
        </TabPanel>
        <TabPanel value={tab} index={2}>
          <Box mb={3} sx={{ width: 300 }}>
            <TextField
              label="Search By ID"
              variant="outlined"
              size="small"
              fullWidth
              onChange={(e) => setSearch(e.target.value)}
            />
          </Box>
          <CardTable
            tableHead={["id", "amount", "owner", "status"]}
            tableData={manualPaid}
            customColumns={[
              {
                name: "id",
                component: IDCol,
              },
              {
                name: "owner",
                component: OwnerCol,
              },
              {
                name: "amount",
                component: AmountCol,
              },
              {
                name: "status",
                component: StatusMsgCol(markPayoutAsPaid),
              },
            ]}
          />
        </TabPanel>
        <TabPanel value={tab} index={3}>
          <CardTable
            tableHead={[
              "id",
              "amount",
              "transferGroup",
              "owner",
              "internalTransaction",
              "status",
              "errorMsg",
              "attempts",
            ]}
            tableData={stripePayouts}
            customColumns={[
              {
                name: "id",
                component: IDCol,
              },
              {
                name: "owner",
                component: OwnerCol,
              },
              {
                name: "internalTransaction",
                component: InternalTransactionCol,
              },
              {
                name: "errorMsg",
                component: ErrorMsgCol,
              },
            ]}
          />
        </TabPanel>
        <TabPanel value={tab} index={4}>
          <CardTable
            tableHead={[
              "id",
              "amount",
              "transferGroup",
              "owner",
              "internalTransaction",
              "status",
              "errorMsg",
              "attempts",
            ]}
            tableData={stripePayoutsPending}
            customColumns={[
              {
                name: "id",
                component: IDCol,
              },
              {
                name: "owner",
                component: OwnerCol,
              },
              {
                name: "internalTransaction",
                component: InternalTransactionCol,
              },
              {
                name: "errorMsg",
                component: ErrorMsgCol,
              },
            ]}
          />
        </TabPanel>
        <TabPanel value={tab} index={5}>
          <CardTable
            tableHead={[
              "id",
              "amount",
              "transferGroup",
              "owner",
              "internalTransaction",
              "status",
              "errorMsg",
              "attempts",
            ]}
            tableData={stripePayoutsError}
            customColumns={[
              {
                name: "id",
                component: IDCol,
              },
              {
                name: "owner",
                component: OwnerCol,
              },
              {
                name: "internalTransaction",
                component: InternalTransactionCol,
              },
              {
                name: "errorMsg",
                component: ErrorMsgCol,
              },
            ]}
          />
        </TabPanel>
      </Box>
    </>
  );
}

export default PayoutsPage;
