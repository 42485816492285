import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useCreateMenu } from "@store/actions/menuActions";
import { useEffect, useState } from "react";

function NewMenuItemModal({ open, setOpen, onSave, menus, createMenu }) {
  const [name, setName] = useState("");
  const [path, setPath] = useState("");
  const [addMenu] = useCreateMenu();

  useEffect(() => {
    if (!open) {
      setName("");
      setPath("");
    }
  }, [open]);

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    const highestOrder = (menus || []).reduce((highest, obj) => {
      return obj.order > highest ? obj.order : highest;
    }, 0);
    createMenu({
      variables: {
        input: { name, path, order: highestOrder + 1 },
      },
    }).then((r) => {
      onSave(r.data.createMenu);
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <form onSubmit={handleSave}>
        <DialogTitle id="form-dialog-title">New Feature</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the features name.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Path"
            fullWidth
            value={path}
            onChange={(e) => setPath(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default NewMenuItemModal;
