import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_LOCATION,
  DELETE_LOCATION,
  GET_ALL_LOCATIONS,
  GET_LOCATION,
  UPDATE_LOCATION,
} from "@operations/locationOperations";

/* -------------------------------------------------------------------------- */
/*                                LOCATION                                    */
/* -------------------------------------------------------------------------- */

export const useGetLocation = (id) =>
  useQuery(GET_LOCATION, {
    variables: {
      id,
    },
  });

export const useGetAllLocations = () => useQuery(GET_ALL_LOCATIONS);

export const useCreateLocation = () =>
  useMutation(CREATE_LOCATION, {
    update: (cache, { data: { createLocation } }) => {
      const data = cache.readQuery({
        query: GET_ALL_LOCATIONS,
      });
      const locations = data ? data.locations : [];

      writeLocationsCache(cache, [...locations, createLocation]);
    },
  });

export const useUpdateLocation = () => useMutation(UPDATE_LOCATION);

export const useDeleteLocation = () =>
  useMutation(DELETE_LOCATION, {
    update: (cache, { data: { deleteLocation } }) => {
      const data = cache.readQuery({
        query: GET_ALL_LOCATIONS,
      });
      const locations = data ? data.locations : [];

      writeLocationsCache(
        cache,
        locations.filter((lp) => lp.id !== deleteLocation.id)
      );
    },
  });

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const writeLocationsCache = (cache, locations) => {
  cache.writeQuery({
    query: GET_ALL_LOCATIONS,
    data: {
      locations,
    },
  });
};
