import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_BOAT_SECTION,
  CREATE_HTML_BLOCK,
  CREATE_THUMB_SECTION,
  DELETE_BOAT_SECTION,
  DELETE_HTML_BLOCK,
  DELETE_Thumb_SECTION,
  GET_ALL_BOAT_SECTIONS,
  GET_ALL_HTML_BLOCKS,
  GET_ALL_THUMB_SECTIONS,
  GET_BOAT_SECTION,
  GET_HTML_BLOCK,
  UPDATE_BOAT_SECTION,
  UPDATE_HTML_BLOCK,
  UPDATE_THUMB_SECTION,
} from "@store/operations/blockOperations";

export const useGetBoatSection = (id) =>
  useQuery(GET_BOAT_SECTION, { variables: { id } });

export const useGetAllBoatSections = () => useQuery(GET_ALL_BOAT_SECTIONS);

export const useCreateBoatSection = () =>
  useMutation(CREATE_BOAT_SECTION, {
    update: (cache, { data: { createBoatSection } }) => {
      const data = cache.readQuery({ query: GET_ALL_BOAT_SECTIONS });
      cache.writeQuery({
        query: GET_ALL_BOAT_SECTIONS,
        data: {
          boatSections: [...data.boatSections, createBoatSection],
        },
      });
    },
  });

export const useUpdateBoatSection = () => useMutation(UPDATE_BOAT_SECTION);

export const useDeleteBoatSection = () =>
  useMutation(DELETE_BOAT_SECTION, {
    update: (cache, { data: { deleteBoatSection } }) => {
      const { boatSections } = cache.readQuery({
        query: GET_ALL_BOAT_SECTIONS,
      });
      cache.writeQuery({
        query: GET_ALL_BOAT_SECTIONS,
        data: {
          boatSections: boatSections.filter(
            (b) => b.id !== deleteBoatSection.id
          ),
        },
      });
    },
  });

export const useGetAllThumbSections = () => useQuery(GET_ALL_THUMB_SECTIONS);

export const useCreateThumbSection = () =>
  useMutation(CREATE_THUMB_SECTION, {
    update: (cache, { data: { createThumbLinkSection } }) => {
      const data = cache.readQuery({ query: GET_ALL_THUMB_SECTIONS });
      cache.writeQuery({
        query: GET_ALL_THUMB_SECTIONS,
        data: {
          thumbLinkSections: [
            ...data.thumbLinkSections,
            createThumbLinkSection,
          ],
        },
      });
    },
  });

export const useUpdateThumbSection = () => useMutation(UPDATE_THUMB_SECTION);

export const useDeleteThumbSection = () =>
  useMutation(DELETE_Thumb_SECTION, {
    update: (cache, { data: { deleteThumbLinkSection } }) => {
      const { thumbLinkSections } = cache.readQuery({
        query: GET_ALL_THUMB_SECTIONS,
      });
      cache.writeQuery({
        query: GET_ALL_THUMB_SECTIONS,
        data: {
          thumbLinkSections: thumbLinkSections.filter(
            (b) => b.id !== deleteThumbLinkSection.id
          ),
        },
      });
    },
  });

export const useGetCodeBlock = (id) =>
  useQuery(GET_HTML_BLOCK, { variables: { id } });

export const useGetAllCodeBlocks = () => useQuery(GET_ALL_HTML_BLOCKS);

export const useCreateCodeBlock = () =>
  useMutation(CREATE_HTML_BLOCK, {
    update: (cache, { data: { createCodeBlock } }) => {
      const data = cache.readQuery({ query: GET_ALL_HTML_BLOCKS });
      cache.writeQuery({
        query: GET_ALL_HTML_BLOCKS,
        data: {
          codeBlocks: [...data.codeBlocks, createCodeBlock],
        },
      });
    },
  });

export const useUpdateCodeBlock = () => useMutation(UPDATE_HTML_BLOCK);

export const useDeleteCodeBlock = () =>
  useMutation(DELETE_HTML_BLOCK, {
    update: (cache, { data: { deleteCodeBlock } }) => {
      const { codeBlocks } = cache.readQuery({
        query: GET_ALL_HTML_BLOCKS,
      });
      cache.writeQuery({
        query: GET_ALL_HTML_BLOCKS,
        data: {
          codeBlocks: codeBlocks.filter((b) => b.id !== deleteCodeBlock.id),
        },
      });
    },
  });
