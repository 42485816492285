import { useCallback, useEffect, useState } from "react";
import { Editor } from "slate";
import FormatBoldIcon from "@material-ui/icons/FormatBold";
import FormatItalicIcon from "@material-ui/icons/FormatItalic";
import FormatUnderlinedIcon from "@material-ui/icons/FormatUnderlined";
import { useSlate } from "slate-react";

import Divider from "@material-ui/core/Divider";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import {
  insertImage,
  insertVideo,
  isBlockActive,
  isImageUrl,
  isMarkActive,
  isLinkActive,
  toggleBlock,
  toggleMark,
  insertLink,
  unwrapLink,
  insertGrid,
  toggleStyle,
} from "./SlateEditorUtils";
import {
  FormatListNumbered,
  Looks3,
  LooksOne,
  LooksTwo,
  List,
  Image,
  Movie,
  Link,
  LinkOff,
  FormatAlignLeft,
  FormatAlignCenter,
  FormatAlignRight,
  FormatStrikethrough,
} from "@material-ui/icons";
import { Button, ButtonGroup, createStyles, useTheme } from "@material-ui/core";
import MediaSelectModal from "@components/media/MediaSelectModal";
import { useGetAllMedia } from "@store/actions/mediaActions";
import { useUpdateEffect } from "react-use";
import FlexGridPlugin from "./MuiGridPlugin";

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      display: "flex",
      // border: `2px solid ${theme.palette.divider}`,
      flexWrap: "wrap",
    },
    divider: {
      alignSelf: "stretch",
      height: "auto",
      margin: theme.spacing(1, 0.5),
    },
    button: {
      border: "none",
    },
  })
);

const StyledToggleButtonGroup = withStyles((theme) => ({
  grouped: {
    display: "flex",
    flexWrap: "wrap",
    margin: theme.spacing(0.5),
    border: "none",
    padding: theme.spacing(0, 1.5),
    "&:not(:first-child)": {
      borderRadius: theme.shape.borderRadius,
    },
    "&:first-child": {
      borderRadius: theme.shape.borderRadius,
    },
  },
}))(ToggleButtonGroup);

const BlockButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      value={format}
      selected={isBlockActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleBlock(editor, format);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

const MarkButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <ToggleButton
      value={format}
      selected={isMarkActive(editor, format)}
      onMouseDown={(event) => {
        event.preventDefault();
        toggleMark(editor, format);
      }}
    >
      {icon}
    </ToggleButton>
  );
};

const AddGridButton = (cols) => {
  const editor = useSlate();
  return (
    <Button onClick={() => editor.insertFlexGrid(editor, cols)}>
      {cols} Cols
    </Button>
  );
};

const AddLinkButton = () => {
  const editor = useSlate();
  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        event.preventDefault();
        const url = window.prompt("Enter the URL of the link:");
        if (!url) return;
        insertLink(editor, url);
      }}
    >
      <Link />
    </Button>
  );
};

const RemoveLinkButton = () => {
  const editor = useSlate();

  return (
    <Button
      active={isLinkActive(editor)}
      onMouseDown={(event) => {
        if (isLinkActive(editor)) {
          unwrapLink(editor);
        }
      }}
    >
      <LinkOff />
    </Button>
  );
};

const AlignmentButton = ({ format, icon }) => {
  const editor = useSlate();
  const onClickAlignment = useCallback((e) => {
    e.preventDefault();
    toggleStyle(editor, "align", format);
  }, []);

  return (
    <Button onMouseDown={onClickAlignment}>
      <span>{icon}</span>
    </Button>
  );
};

const ColourButton = ({ format, icon, color }) => {
  const editor = useSlate();
  const onClick = useCallback((e) => {
    e.preventDefault();
    toggleStyle(editor, "colour", format);
  }, []);

  return (
    <Button
      onClick={onClick}
      color={color}
      variant="contained"
      size="small"
      style={{
        background: format,
        color: "white",
        borderRadius: 0,
      }}
    >
      <span>{color}</span>
    </Button>
  );
};

export const SlateToolbar = ({ basic }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { data: mediaData } = useGetAllMedia();
  return (
    <Paper elevation={2} className={classes.paper}>
      {!basic && (
        <>
          {AddGridButton(1)}
          {AddGridButton(2)}
          {AddGridButton(3)}
          {AddGridButton(4)}
          <Divider orientation="vertical" className={classes.divider} />
        </>
      )}

      <StyledToggleButtonGroup size="small">
        {MarkButton({ format: "bold", icon: <FormatBoldIcon size={18} /> })}
        {MarkButton({
          format: "italic",
          icon: <FormatItalicIcon size={18} />,
        })}
        {MarkButton({
          format: "underline",
          icon: <FormatUnderlinedIcon size={18} />,
        })}
        {MarkButton({
          format: "strikethrough",
          icon: <FormatStrikethrough size={18} />,
        })}
        {!basic && (
          <>
            <AddLinkButton />
            <RemoveLinkButton />
          </>
        )}
      </StyledToggleButtonGroup>
      <Divider orientation="vertical" className={classes.divider} />
      <StyledToggleButtonGroup size="small" exclusive>
        {BlockButton({ format: "heading-one", icon: <LooksOne size={24} /> })}
        {BlockButton({ format: "heading-two", icon: <LooksTwo size={24} /> })}
        {BlockButton({ format: "heading-three", icon: <Looks3 size={24} /> })}
        {BlockButton({
          format: "numbered-list",
          icon: <FormatListNumbered size={24} />,
        })}
        {BlockButton({
          format: "bulleted-list",
          icon: <List size={34} />,
        })}
      </StyledToggleButtonGroup>
      {!basic && (
        <>
          <Divider orientation="vertical" className={classes.divider} />
          <div className={classes.button}>
            {InsertImageButton({
              icon: <Image size={28} />,
              media: mediaData?.medias || [],
            })}
            {InsertVideoButton({
              icon: <Movie size={28} />,
            })}
          </div>
        </>
      )}

      <AlignmentButton format="left" icon={<FormatAlignLeft size={18} />} />
      <AlignmentButton format="center" icon={<FormatAlignCenter size={18} />} />
      <AlignmentButton format="right" icon={<FormatAlignRight size={18} />} />
      {!basic && (
        <>
          <Divider orientation="vertical" className={classes.divider} />
          <ButtonGroup
            size="small"
            style={{
              height: 32,
              marginTop: 2,
              marginLeft: 16,
              overflow: "hidden",
            }}
          >
            <ColourButton format={theme.palette.text.primary} color={"text"} />
            <ColourButton
              format={theme.palette.text.secondary}
              color={"text 2"}
            />
            <ColourButton
              format={theme.palette.primary.main}
              color={"primary"}
            />
            <ColourButton
              format={theme.palette.secondary.main}
              color={"secondary"}
            />
            <ColourButton
              format={theme.palette.warning.main}
              color={"warning"}
            />
            <ColourButton format={theme.palette.info.main} color={"info"} />
            <ColourButton format={theme.palette.error.main} color={"error"} />
            <ColourButton
              format={theme.palette.success.main}
              color={"success"}
            />
          </ButtonGroup>
        </>
      )}
    </Paper>
  );
};

export const HoveringToolbar = () => {
  const editor = useSlate();
  const [showToolbar, setShowToolbar] = useState(false);
  const { selection } = editor;
  useEffect(() => {
    if (!selection || Editor.string(editor, selection) === "") {
      setShowToolbar(false);
    } else {
      setShowToolbar(true);
    }
  }, [selection]);

  return (
    <div hidden={!showToolbar}>
      <SlateToolbar />
    </div>
  );
};

const InsertImageButton = ({ format, icon, media }) => {
  const editor = useSlate();
  const [open, setOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);

  useUpdateEffect(() => {
    if (open) {
      return;
    }
    selectedMedia.forEach((m) => {
      const url = m.mediaFiles.length ? m.mediaFiles[0].path : "";
      if (url && !isImageUrl(url)) {
        // todo, handle better
        alert("URL is not an image");
        return;
      }
      insertImage(editor, url);
    });
  }, [open, selectedMedia]);

  useUpdateEffect(() => {
    if (!open) {
      setSelectedMedia([]);
    }
  }, [open]);

  const onSelect = (event) => {
    event.preventDefault();
    setOpen(true);
  };

  return (
    <>
      <Button onClick={onSelect}>{icon}</Button>
      <MediaSelectModal
        open={open}
        setOpen={setOpen}
        media={media || []}
        selected={selectedMedia}
        setSelected={setSelectedMedia}
      />
    </>
  );
};

const InsertVideoButton = ({ format, icon }) => {
  const editor = useSlate();
  return (
    <Button
      onClick={(event) => {
        event.preventDefault();
        const url = window.prompt(
          "Enter the URL of the video (youtube or vimeo):"
        );
        // if (url && !isImageUrl(url)) {
        //   alert("URL is not an image");
        //   return;
        // }
        insertVideo(editor, url);
      }}
    >
      {icon}
    </Button>
  );
};
