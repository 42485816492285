import Steps from "@components/Steps";
import { Button, CircularProgress, Grid, Paper } from "@material-ui/core";
import { useContentPageContext } from "providers/ContentPageProvider";
import React, { useCallback, useEffect, useState } from "react";
import TitleIcon from "@material-ui/icons/Title";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import NavigateNext from "@material-ui/icons/NavigateNext";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useUpdateEffect } from "react-use";
import { useUpdateContentPage } from "@store/actions/landingPageActions";
import ContentPageForm from "./ContentPageForm";
import Editor from "@components/editor/Editor";
import ContentPageSEO from "./ContentPageSEO";
import LandingPageContentBlocks from "@components/landing/LandingPageContentBlocks";
import WebIcon from "@material-ui/icons/Web";

function ContentPageWizard() {
  const { contentPage, setContentPage } = useContentPageContext();
  const [updateContentPage, { loading }] = useUpdateContentPage();
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const { showNotification } = useNotificationContext();
  const [valid, setValid] = useState(true);
  const [content, setContent] = useState(null);

  useUpdateEffect(() => {
    setContent(
      contentPage.slateJson ? JSON.parse(contentPage.slateJson) : null
    );
  }, [contentPage.id]);

  useEffect(() => {
    setContentPage({
      ...contentPage,
      slateJson: JSON.stringify(content),
    });
  }, [content]);

  const handleUpdate = useCallback(
    (contentPage) => {
      const {
        name,
        path,
        title,
        seoTitle,
        seoDescription,
        showContact,
        content,
        slateJson,
      } = contentPage;

      let sections = {};
      if (contentPage.sections?.length) {
        sections = contentPage.sections.map((s) => ({
          title: s.title,
          type: s.type,
          link: s.link,
          linkText: s.linkText,
          colWidth: s.colWidth,
          order: s.order,
          section: {
            id:
              s.type === "boatSection"
                ? s.boatSection.id
                : s.type === "codeBlock"
                ? s.codeBlock.id
                : s.thumbLinkSection.id,
          },
        }));
      }
      return updateContentPage({
        variables: {
          id: contentPage.id,
          input: {
            name,
            path,
            title,
            seoTitle,
            seoDescription,
            showContact,
            content,
            slateJson,
            sections,
          },
        },
      });
    },
    [updateContentPage]
  );

  useEffect(() => {
    setSteps([
      {
        label: "Head Section",
        component: (
          <ContentPageForm handleUpdate={handleUpdate} setValid={setValid} />
        ),
        icon: <TitleIcon fontSize="inherit" />,
      },
      {
        label: "Content",
        component: (
          <Editor
            msg={content}
            setMsg={setContent}
            html={contentPage.extra}
            setHtml={(content) => {
              setContentPage({
                ...contentPage,
                content,
              });
            }}
          />
        ),
        icon: <LineStyleIcon fontSize="inherit" />,
      },
      {
        label: "Content Blocks",
        component: (
          <LandingPageContentBlocks
            parent={contentPage}
            setParent={setContentPage}
          />
        ),
        icon: <WebIcon fontSize="inherit" />,
      },
      {
        label: "SEO",
        component: <ContentPageSEO />,
        icon: <HelpOutlineIcon fontSize="inherit" />,
      },
    ]);
  }, [contentPage, setContentPage, handleUpdate]);

  const handleSave = () => {
    handleUpdate(contentPage).then(() => {
      setActiveStep(activeStep + 1);
    });
  };

  const isStepComplete = (i) => {
    return i < activeStep;
  };

  const handleStep = (i) => {
    setActiveStep(i);
  };

  return (
    <>
      <Grid item container spacing={5}>
        <Grid item xs={9}>
          <Paper>
            <Steps
              steps={steps}
              activeStep={activeStep}
              isStepComplete={isStepComplete}
              handleStep={handleStep}
              next={
                <Button
                  disabled={loading || !valid}
                  variant="contained"
                  color="primary"
                  endIcon={
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <NavigateNext />
                    )
                  }
                  onClick={handleSave}
                >
                  Save & next
                </Button>
              }
            />
          </Paper>
        </Grid>
      </Grid>
    </>
  );
}

export default ContentPageWizard;
