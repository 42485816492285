import CommsItemFormBody from "@components/comms/CommsItemFormBody";
import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useCreateOwner, useUpdateOwner } from "@store/actions/boatActions";
import { produce } from "immer";
import React, { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

function OwnerModal({ open, setOpen, owner, setOwner }) {
  const [step, setStep] = useState(1);
  const [rates, setRates] = useState(owner.rates || []);
  const [addOwner] = useCreateOwner();
  const [updateOwner] = useUpdateOwner();
  const { showNotification } = useNotificationContext();

  const close = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setOwner({
      ...owner,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const input = {
      firstName: owner.firstName,
      lastName: owner.lastName,
      email: owner.email,
      phone: owner.phone,
      defaultCommission: owner.defaultCommission,
      iso: owner.iso,
      stripeType: owner.stripeType,
      isManual: owner.isManual,
      businessName: owner.businessName,
      address: owner.address,
      city: owner.city,
      state: owner.state,
      businessID: owner.businessID,
      rates: rates.map((r) => ({
        type: r.type,
        commission: r.commission,
      })),
    };
    const fn = owner.id ? updateOwner : addOwner;
    const res = await fn({
      variables: {
        id: owner.id || null,
        input,
      },
    });
    showNotification(`${owner.firstName} Saved!`);
    setOwner((o) => ({
      ...o,
      id: o.id ? o.id : res.data.createOwner.id,
    }));

    if (step === 1) {
      setStep(2);
      return;
    }
    close();
  };

  useEffect(() => {
    setRates(owner.rates || []);
    return () => {
      if (open) {
        setStep(1);
        setRates([]);
        setOwner({});
      }
    };
  }, [open]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={close}
      disableBackdropClick={true}
    >
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>
          <Grid container justify="space-between">
            <span>Boat Owner</span>
            <ButtonGroup size="small" variant="contained">
              <Button
                variant="contained"
                color={step === 1 ? "secondary" : ""}
                onClick={() => setStep(1)}
              >
                Info
              </Button>
              <Button
                variant="contained"
                color={step === 2 ? "secondary" : ""}
                onClick={() => setStep(2)}
              >
                Business Details
              </Button>
              <Button
                variant="contained"
                color={step === 3 ? "secondary" : ""}
                onClick={() => setStep(3)}
              >
                Rates
              </Button>
            </ButtonGroup>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {step === 1 ? (
            <>
              <DialogContentText>
                Set boat owners details below
              </DialogContentText>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextValidator
                    autoFocus
                    variant="outlined"
                    name="firstName"
                    label="First Name"
                    validators={["required"]}
                    errorMessages={["Owner requires first name"]}
                    fullWidth
                    value={owner.firstName || ""}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    name="lastName"
                    label="Last Name"
                    fullWidth
                    value={owner.lastName || ""}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    variant="outlined"
                    name="email"
                    label="Email"
                    type="tel"
                    validators={["required", "isEmail"]}
                    errorMessages={[
                      "Email is required",
                      "Must be a valid Email Address",
                    ]}
                    fullWidth
                    value={owner.email || ""}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    variant="outlined"
                    name="phone"
                    label="Phone number"
                    fullWidth
                    value={owner.phone || ""}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    variant="outlined"
                    name="defaultCommission"
                    label="Default Commission (percentage)"
                    type="number"
                    fullWidth
                    value={owner.defaultCommission || ""}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>

                <Grid item xs={8}>
                  <TextField
                    disabled={(owner.stripeAccounts || []).length > 0}
                    variant="outlined"
                    name="iso"
                    label="Country Code (ISO)"
                    fullWidth
                    helperText="This by default is the systems default country code, be careful with this as this is the code used when setting up this owners stripe account, must be 2 character iso code, see https://en.wikipedia.org/wiki/ISO_3166-1_alpha-2#Officially_assigned_code_elements for reference"
                    value={owner.iso || ""}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={owner.isManual || false}
                        onChange={() =>
                          setOwner(
                            produce(owner, (draft) => {
                              draft.isManual = !draft.isManual;
                            })
                          )
                        }
                        color="primary"
                      />
                    }
                    label="Set To Manual Payouts"
                  />
                </Grid>
                {!owner.isManual && (
                  <Grid item xs={8}>
                    <TextField
                      disabled={(owner.stripeAccounts || []).length > 0}
                      variant="outlined"
                      name="stripeType"
                      label="Type of stripe business account"
                      fullWidth
                      helperText="This by default is set to a company, be careful with this as this is used when setting up this owners stripe account"
                      value={owner.stripeType || ""}
                      onChange={handleChange}
                      size="small"
                      select
                    >
                      <MenuItem value="company">Company</MenuItem>
                      <MenuItem value="individual">Individual</MenuItem>
                    </TextField>
                  </Grid>
                )}
              </Grid>
            </>
          ) : step === 2 ? (
            <>
              <DialogContentText>
                Set boat owners business details below
              </DialogContentText>
              <Grid container spacing={3}>
                <Grid item xs={6}>
                  <TextValidator
                    autoFocus={step === 2}
                    variant="outlined"
                    name="businessName"
                    label="Business Name"
                    fullWidth
                    value={owner.businessName || ""}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    variant="outlined"
                    name="businessID"
                    label="Business ID e.g ABN or EIN"
                    fullWidth
                    value={owner.businessID || ""}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    variant="outlined"
                    name="address"
                    label="Business Address"
                    fullWidth
                    value={owner.address || ""}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    variant="outlined"
                    name="city"
                    label="Business City"
                    fullWidth
                    value={owner.city || ""}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextValidator
                    variant="outlined"
                    name="state"
                    label="Business State / county"
                    fullWidth
                    value={owner.state || ""}
                    onChange={handleChange}
                    size="small"
                  />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <DialogContentText>
                Set boat owners commisions per item
              </DialogContentText>
              <CommsItemFormBody rates={rates} setRates={setRates} />
            </>
          )}
        </DialogContent>

        <DialogActions>
          <Button onClick={close} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default OwnerModal;
