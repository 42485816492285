import {
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { BookingItemCostType } from "@store/operations/bookingOperations";
import React, { memo, useCallback, useState } from "react";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  SelectValidator,
  TextValidator,
} from "react-material-ui-form-validator";
import { useUpdateEffect } from "react-use";
import QuoteItemType from "./QuoteItemType";

function QuoteItemCost({
  boat,
  onDelete,
  cost: inCost,
  setCost: inSetCost,
  readOnly,
  onBlur,
}) {
  const [cost, setCost] = useState(inCost);

  const handleChange = useCallback(
    (e) => {
      const name = e.target.name;
      const value = e.target.value;
      const c = { ...cost };
      switch (name) {
        case "pickUpWharf":
          c.pickUpWharf = { id: value };
          break;
        case "dropOffWharf":
          c.dropOffWharf = { id: value };
          break;
        case "package":
          c.package = { id: value };
          break;
        default:
          c[name] = value;
          break;
      }
      setCost(c);
    },
    [cost]
  );

  useUpdateEffect(() => {
    inSetCost(cost);
  }, [cost]);

  const dataCol = () => {
    switch (cost.type) {
      case BookingItemCostType.package:
        return (
          <SelectValidator
            select
            label="Select Package"
            fullWidth
            value={cost.package ? cost.package.id : ""}
            name="package"
            onChange={handleChange}
            onBlur={onBlur}
            variant="outlined"
            size="small"
            validators={["required"]}
            errorMessages={["Package is required"]}
          >
            {(boat?.boatPackages || []).map((bp) => (
              <MenuItem key={bp.id} value={bp.id}>
                {bp.name}
              </MenuItem>
            ))}
          </SelectValidator>
        );
      case BookingItemCostType.pickUp:
        return (
          <SelectValidator
            select
            label="Set wharf for Pickup"
            fullWidth
            value={cost.pickUpWharf ? cost.pickUpWharf.id : ""}
            name="pickUpWharf"
            onChange={handleChange}
            onBlur={onBlur}
            variant="outlined"
            size="small"
            validators={["required"]}
            errorMessages={["Wharf name is required"]}
          >
            {(boat?.wharfs || []).map((ap) => (
              <MenuItem key={ap.id} value={ap.id}>
                {ap.name}
              </MenuItem>
            ))}
          </SelectValidator>
        );
      case BookingItemCostType.dropOff:
        return (
          <SelectValidator
            select
            label="Set Wharf for Dropoff"
            fullWidth
            value={cost.dropOffWharf ? cost.dropOffWharf?.id : ""}
            name="dropOffWharf"
            onChange={handleChange}
            onBlur={onBlur}
            variant="outlined"
            size="small"
            validators={["required"]}
            errorMessages={["Wharf name is required"]}
          >
            {(boat?.wharfs || []).map((ap) => (
              <MenuItem key={ap.id} value={ap.id}>
                {ap.name}
              </MenuItem>
            ))}
          </SelectValidator>
        );
      default:
        return (
          <TextField
            label="Description"
            fullWidth
            name="description"
            onChange={handleChange}
            onBlur={onBlur}
            value={cost.description || ""}
            variant="outlined"
            size="small"
          />
        );
        break;
    }
  };

  return (
    <Grid item xs={12} container spacing={1} alignItems="center">
      <Grid item xs={2} container alignItems="center">
        <QuoteItemType type={cost.type} />
      </Grid>
      <Grid item xs={4}>
        {dataCol()}
      </Grid>

      <Grid item xs={6} container spacing={1} alignItems="center">
        <Grid item xs={6}>
          <TextValidator
            label="Cost"
            variant="outlined"
            size="small"
            disabled={!!readOnly}
            type="number"
            name="cost"
            fullWidth
            validators={["required"]}
            onChange={handleChange}
            onBlur={onBlur}
            value={cost.cost >= 0 ? cost.cost : ""}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item xs={5}>
          <TextValidator
            label="Commision"
            variant="outlined"
            size="small"
            disabled={!!readOnly}
            type="number"
            name="commision"
            fullWidth
            value={cost.commision >= 0 ? cost.commision : ""}
            onChange={handleChange}
            onBlur={onBlur}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">%</InputAdornment>
              ),
            }}
          />
        </Grid>
        {onDelete && (
          <Grid item xs={1}>
            <IconButton
              color="primary"
              onClick={() => onDelete(cost.id)}
              disabled={!!readOnly}
            >
              <DeleteIcon />
            </IconButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
}

export default memo(QuoteItemCost);
