import MediaSelectModal from "@components/media/MediaSelectModal";
import { Button } from "@material-ui/core";
import { AtomicBlockUtils, EditorState, Modifier } from "draft-js";
import React, { useState } from "react";
import { useEffect } from "react";

function MediaTool({ onChange, editorState, media }) {
  const [open, setOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [dimensions, setDimensions] = useState({
    height: "auto",
    width: "100%",
  });

  useEffect(() => {
    if (!selectedMedia.length) {
      return;
    }

    for (let index = 0; index < selectedMedia.length; index++) {
      const selected = selectedMedia[index];
      const src = selected.mediaFiles[0].path;
      const { height, width } = dimensions;
      const entityData = { src, height, width };
      const entityKey = editorState
        .getCurrentContent()
        .createEntity("IMAGE", "MUTABLE", entityData)
        .getLastCreatedEntityKey();

      const newEditorState = AtomicBlockUtils.insertAtomicBlock(
        editorState,
        entityKey,
        " "
      );
      onChange(newEditorState);
    }
  }, [selectedMedia]);

  useEffect(() => {
    return () => {
      if (open) {
        setSelectedMedia([]);
      }
    };
  }, [open]);
  return (
    <>
      <Button onClick={() => setOpen(true)}>Add media</Button>
      <MediaSelectModal
        open={open}
        setOpen={setOpen}
        media={media || []}
        selected={selectedMedia}
        setSelected={setSelectedMedia}
        dimensions={dimensions}
        setDimensions={setDimensions}
      />
    </>
  );
}

export default MediaTool;
