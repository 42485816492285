import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  Grid,
  Typography,
  makeStyles,
} from "@material-ui/core";

import Editor from "@components/editor/Editor";
import {
  useCreateBoatPackage,
  useUpdateBoatPackage,
} from "@actions/boatActions";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useEffect, useState } from "react";
import { useUpdateEffect } from "react-use";
import { CloudUpload } from "@material-ui/icons";
import { generateUploadedPath } from "utils/helpers";
import { useCreateMedia, useGetAllMedia } from "@store/actions/mediaActions";
import Uploader from "@components/media/Uploader";
import { produce } from "immer";
import MediaSelectModal from "@components/media/MediaSelectModal";
import AttachementRow from "@components/attachment/AttachementRow";

const useStyles = makeStyles(() => ({
  sidebar: {
    maxWidth: "995px",
  },
}));

const BoatPackageModal = ({
  open,
  setOpen,
  boatPackage,
  setBoatPackage,
  boat,
}) => {
  const [addBoatPackage] = useCreateBoatPackage(boat.id);
  const [updateBoatPackage] = useUpdateBoatPackage();
  const [content, setContent] = useState(
    boatPackage.slateJson ? JSON.parse(boatPackage.slateJson) : null
  );
  const { sidebar } = useStyles();
  const [addMedia] = useCreateMedia();
  const [mediaOpen, setMediaOpen] = useState(false);
  const { data: mediaData } = useGetAllMedia();

  useUpdateEffect(() => {
    setContent(
      boatPackage.slateJson ? JSON.parse(boatPackage.slateJson) : null
    );
  }, [boatPackage.id]);

  useEffect(() => {
    setBoatPackage((boatPackage) => ({
      ...boatPackage,
      slateJson: JSON.stringify(content),
    }));
  }, [content]);

  const close = () => {
    setBoatPackage({});
    setOpen(false);
  };

  const handleChange = (e) => {
    setBoatPackage({
      ...boatPackage,
      [e.target.name]: e.target.value,
    });
  };

  const onFinish = async (result, file) => {
    const source = generateUploadedPath(result.signedUrl.split("?")[0]);
    // save a media
    const { data: res } = await addMedia({
      variables: {
        input: {
          name: file.name,
          source,
        },
      },
    });
    setBoatPackage((bp) =>
      produce(bp, (draft) => {
        draft.media = res.createMedia;
      })
    );
  };

  const onAttachmentFinish = async (result, file) => {
    const source = generateUploadedPath(result.signedUrl.split("?")[0]);
    // save a media
    const { data: res } = await addMedia({
      variables: {
        input: {
          name: file.name,
          type: "PDF",
          source,
        },
      },
    });
    setBoatPackage((bp) =>
      produce(bp, (draft) => {
        draft.attachments = [...(bp.attachments || []), res.createMedia];
      })
    );
  };

  const removeAttachment = (att) => {
    const idx = boatPackage.attachments.findIndex((a) => a.id === att.id);
    let attachments = [...(boatPackage.attachments || [])];
    attachments.splice(idx, 1);
    setBoatPackage((bp) =>
      produce(bp, (draft) => {
        draft.attachments = attachments;
      })
    );
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    let input = {
      name: boatPackage.name,
      description: boatPackage.description,
      info: boatPackage.info,
      price: boatPackage.price,
      content: boatPackage.content,
      slateJson: boatPackage.slateJson,
      boatId: boat.id,
    };

    if (boatPackage.media && boatPackage.media.id) {
      input.media = {
        id: boatPackage.media.id,
        name: boatPackage.media.name,
        source: "",
      };
    }

    if (boatPackage.attachments?.length > 0) {
      input.attachments = boatPackage.attachments.map((a) => ({
        id: a.id,
        name: a.name,
        source: "",
      }));
    }

    const fn = boatPackage.id ? updateBoatPackage : addBoatPackage;
    await fn({
      variables: {
        id: boatPackage.id || null,
        input,
      },
    });
    close();
  };

  return (
    // <Dialog maxWidth="md" fullWidth open={open} onClose={close}>
    <Drawer
      anchor="right"
      // variant="persistent"
      open={open}
      onClose={close}
      classes={{
        paper: sidebar,
      }}
    >
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>Packages</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Set custom packages for this boats customers
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={5}>
              <TextValidator
                autoFocus
                name="name"
                label="Package Name"
                validators={["required"]}
                errorMessages={["Package name is required"]}
                fullWidth
                value={boatPackage.name || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12} style={{ background: "rgba(0,0,0,0.05)" }}>
              <Typography variant="h6" gutterBottom>
                Content
              </Typography>
            </Grid>
            <Grid
              item
              xs={12}
              style={{ minHeight: 300, background: "rgba(0,0,0,0.05)" }}
            >
              <Editor
                msg={content}
                setMsg={setContent}
                html={boatPackage.content}
                setHtml={(content) => {
                  setBoatPackage({
                    ...boatPackage,
                    content,
                  });
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                name="description"
                onChange={handleChange}
                value={boatPackage.description || ""}
                helperText="This is for backwards compatibility, If you set content it will override this description"
                label="Description"
                fullWidth
                multiline
                minRows={3}
                maxRows={6}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={5}>
              <Typography variant="h6" gutterBottom>
                Featured Image
              </Typography>
              <Box mb={2} mt={2}>
                <Button
                  color="primary"
                  variant="contained"
                  component="label"
                  startIcon={<CloudUpload />}
                >
                  Upload Featured Image
                  <Uploader
                    onFinish={onFinish}
                    hidden={true}
                    path={`${boat.id}/packages`}
                    accept="image/*"
                  />
                </Button>
              </Box>
              <Button
                color="primary"
                variant="contained"
                component="label"
                onClick={() => setMediaOpen(true)}
              >
                Select media
              </Button>
            </Grid>
            <Grid item xs={7}>
              {boatPackage.media && (
                <Box
                  style={{
                    height: 200,
                    overflow: "hidden",
                    position: "relative",
                  }}
                >
                  <img
                    style={{
                      position: "absolute",
                      left: "50%",
                      transform: "translateX(-50%)",
                    }}
                    src={boatPackage.media.mediaFiles[0].path}
                  />
                </Box>
              )}
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={4}>
              <Typography variant="h6" gutterBottom>
                Attachments
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Button
                color="primary"
                variant="contained"
                component="label"
                startIcon={<CloudUpload />}
              >
                Upload Attachments
                <Uploader
                  onFinish={onAttachmentFinish}
                  hidden={true}
                  path={`${boat.id}/packages/attachments`}
                  accept="application/pdf"
                />
              </Button>
            </Grid>
            {(boatPackage.attachments || []).map((a) => (
              <Grid key={a.id} item xs={7}>
                <AttachementRow
                  attachment={a}
                  removeAttachment={removeAttachment}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={7}>
              <TextValidator
                name="info"
                label="Pricing info"
                validators={["required"]}
                errorMessages={["Package Pricing info is required"]}
                fullWidth
                value={boatPackage.info || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={5}>
              <TextValidator
                name="price"
                label="Price"
                validators={["required"]}
                errorMessages={["Package Price is required"]}
                fullWidth
                type="number"
                value={boatPackage.price || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
      <MediaSelectModal
        open={mediaOpen}
        setOpen={setMediaOpen}
        media={mediaData?.medias || []}
        selected={[boatPackage.media]}
        setSelected={(medias) => {
          setBoatPackage((bp) =>
            produce(bp, (draft) => {
              draft.media = medias[medias.length - 1];
            })
          );
        }}
      />
    </Drawer>
  );
};
export default BoatPackageModal;
