import Loading from "@components/loading/Loading";
import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@material-ui/core";
import {
  useCreateBookingItemCost,
  useCreateQuoteItem,
  useDeleteBookingItemCost,
  useUpdateBookingItemCost,
  useUpdateQuoteItem,
} from "@store/actions/quoteActions";
import { BookingItemCostType } from "@store/operations/bookingOperations";
import { useBoatLowestPrice } from "components-library";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { usePreviousDistinct } from "react-use";
import { move } from "utils/arrays";
import NewQuoteItemCost from "./NewQuoteItemCost";
import QuoteItemCost from "./QuoteItemCost";

function QuoteItem({
  quote,
  booking,
  item,
  setItem,
  onRemove,
  checkSetValid,
  readOnly,
}) {
  const [recomended, setRecomended] = useState(0);
  const { getRate } = useBoatLowestPrice();
  const [quoteItem, setQuoteItem] = useState(item);
  const prevQuoteItem = usePreviousDistinct(quoteItem);
  const [addQuoteItem] = useCreateQuoteItem(quote);
  const [updateQuoteItem] = useUpdateQuoteItem(quote);
  const [updateItemCost] = useUpdateBookingItemCost();
  const [deleteItemCost] = useDeleteBookingItemCost(quote, item);
  const [addItemCost] = useCreateBookingItemCost(quote, item);
  const [open, setOpen] = useState(false);
  const [sortedCosts, setSortedCosts] = useState([]);
  const setCost = useCallback(
    (v) => {
      const costs = (item.costs || []).map((c) => (c.id === v.id ? v : c));
      setItem({
        ...item,
        costs,
      });
    },
    [item]
  );

  const isAdditional = useMemo(() => quote.type === "additional");

  const addWharfs = () => {
    let costs = item.costs || [];
    const dropOffIdx = costs
      .map((c) => c.type)
      .indexOf(BookingItemCostType.dropOff);
    const pickupIdx = costs
      .map((c) => c.type)
      .indexOf(BookingItemCostType.pickUp);
    if (dropOffIdx === -1) {
      costs = [{ type: BookingItemCostType.dropOff }, ...costs];
    }
    if (pickupIdx === -1) {
      costs = [{ type: BookingItemCostType.pickUp }, ...costs];
    }
    return costs;
  };

  useEffect(() => {
    if (!quoteItem) {
      return;
    }

    if (booking.cruiseDate && booking.hours && quoteItem.bookingItem.boat) {
      const baseCost = getRate(
        booking.cruiseDate,
        quoteItem.bookingItem.boat,
        booking.hours
      );
      setRecomended(baseCost);
    }

    async function saveCosts() {
      let costs = quoteItem.costs || [];
      let itemId = quoteItem.id;

      // we do not update quote item is read only as this means a transaction has happened
      if (!readOnly) {
        const pickUpIds = costs
          .filter((c) => c.pickUpWharf?.id)
          .slice()
          .sort();
        const prevPickUpIds = (prevQuoteItem?.costs || [])
          .filter((c) => c.pickUpWharf?.id)
          .slice()
          .sort();
        if (
          !prevQuoteItem ||
          Number(quoteItem.baseCost) !== Number(prevQuoteItem.baseCost) ||
          (pickUpIds.length > 0 &&
            !pickUpIds.every((value, index) => value === prevPickUpIds[index]))
        ) {
          const input = {
            baseCost: quoteItem.baseCost,
            commision: quoteItem.commision,
            bookingItem: { id: quoteItem.bookingItem.id },
          };
          if (itemId) {
            await updateQuoteItem({
              variables: {
                id: itemId,
                input,
              },
            });
          } else {
            const { data } = await addQuoteItem({
              variables: {
                quoteID: quote.id,
                input,
              },
            });
            itemId = data.createQuoteItem.id;
            // if creating and not additional then add dropoff and pick
            if (!isAdditional) {
              costs = addWharfs();
            }
          }
        }
      }

      if (!itemId) {
        return;
      }

      // iterate over costs, either create or update
      const promises = costs.filter((cost) => {
        // only save whats changed
        const prevIdx = (prevQuoteItem?.costs || [])
          .map((c) => c.id)
          .indexOf(cost.id);
        if (prevIdx === -1) {
          return true;
        }
        const preCost = prevQuoteItem.costs[prevIdx];
        return (
          preCost.description !== cost.description ||
          `${preCost.cost}` !== `${cost.cost}` ||
          `${preCost.commision}` !== `${cost.commision}` ||
          preCost.dropOffWharf?.id !== cost.dropOffWharf?.id ||
          preCost.pickUpWharf?.id !== cost.pickUpWharf?.id ||
          preCost.package?.id !== cost.package?.id
        );
      });

      for (let i = 0; i < promises.length; i++) {
        const cost = promises[i];
        let input = {
          description: cost.description,
          cost: cost.cost,
          commision: cost.commision,
          type: cost.type,
        };
        if (cost.dropOffWharf) {
          input.dropOffWharf = { id: cost.dropOffWharf.id };
        }
        if (cost.pickUpWharf) {
          input.pickUpWharf = { id: cost.pickUpWharf.id };
        }
        if (cost.package) {
          input.package = { id: cost.package.id };
        }

        cost.id
          ? await updateItemCost({
              variables: {
                id: cost.id,
                input,
              },
            })
          : await addItemCost({
              variables: {
                quoteItemID: itemId,
                input,
              },
            });
      }
    }

    saveCosts();
  }, [quoteItem]);

  const onDeleteCost = useCallback((id) => {
    deleteItemCost({
      variables: {
        id,
      },
    });
    setTimeout(checkSetValid, 1);
  }, []);

  const onBlur = useCallback(() => {
    setQuoteItem(item);
  }, [item]);

  useEffect(() => {
    let costs = [...(item.costs || [])]
      .map((c) => c)
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));

    const typeMap = costs.map((c) => c.type);
    const dropOffIdx = typeMap.indexOf(BookingItemCostType.dropOff);
    const pickupIdx = typeMap.indexOf(BookingItemCostType.pickUp);

    if (dropOffIdx >= 0) {
      setTimeout(() => {
        costs = move(costs, dropOffIdx, 0);
      }, 0);
    }
    if (pickupIdx >= 0) {
      costs = move(costs, pickupIdx, 0);
    }
    setSortedCosts(costs);
    setTimeout(checkSetValid, 1);
  }, [item]);

  if (!item.id) {
    return <Loading />;
  }

  return (
    <Grid item xs={12} container spacing={2} alignItems="center">
      <Grid item xs={12} container justify="space-between">
        <Typography variant="h6" gutterBottom>
          {item.bookingItem?.boat?.name}
        </Typography>
        <Button
          color="primary"
          variant="outlined"
          onClick={onRemove}
          disabled={!!readOnly}
        >
          Remove
        </Button>
      </Grid>
      <Grid item xs={12}>
        {/* <br /> */}
        <Divider light />
        <br />
      </Grid>

      {!isAdditional && (
        <>
          <Grid item xs={6}>
            <Typography variant="caption" display="inline">
              Rate for{" "}
            </Typography>
            <Typography variant="body1" display="inline" color="primary">
              {booking.hours} hrs{" "}
            </Typography>
            <Typography variant="caption" display="inline">
              for{" "}
            </Typography>
            <Typography variant="body1" display="inline" color="primary">
              {booking.groupSize} people
            </Typography>
          </Grid>
          <Grid item xs={6}>
            <TextField
              label="Rental Cost"
              variant="outlined"
              disabled={!!readOnly}
              helperText={`Recomended $${recomended} ( ${
                item.commision || 0
              }% commision)`}
              size="small"
              type="number"
              fullWidth
              value={item.baseCost || ""}
              onChange={(e) =>
                setItem({
                  ...item,
                  baseCost: e.target.value,
                })
              }
              onBlur={onBlur}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">$</InputAdornment>
                ),
              }}
            />
          </Grid>
        </>
      )}

      {sortedCosts.map((cost, idx) => (
        <QuoteItemCost
          boat={item.bookingItem?.boat}
          onDelete={onDeleteCost}
          cost={cost}
          setCost={setCost}
          key={cost.id || idx}
          readOnly={readOnly}
          onBlur={onBlur}
        />
      ))}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          disabled={!!readOnly}
          color="primary"
          size="small"
          onClick={() => setOpen(true)}
        >
          Add additional item
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
      <NewQuoteItemCost
        quote={quote}
        open={open}
        setOpen={setOpen}
        onSaved={() => setOpen(false)}
        booking={booking}
        item={item}
      />
    </Grid>
  );
}

export default memo(QuoteItem);
