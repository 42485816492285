import Loading from "@components/loading/Loading";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { Delete, Edit } from "@material-ui/icons";
import {
  useDeleteWaiver,
  useGetAllBoatWaivers,
} from "@store/actions/waiverActions";
import React, { useCallback, useState } from "react";
import { truncate } from "utils/strings";
import BoatWaiverDialog from "./BoatWaiverDialog";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";

function BoatWaivers({ boat }) {
  const { loading, data } = useGetAllBoatWaivers(boat.id);
  const [open, setOpen] = useState(false);
  const [selectedWaiver, setSelectedWaiver] = useState(null);
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showNotification } = useNotificationContext();
  const [deleteWaiver, { loading: deleteLoading }] = useDeleteWaiver(boat.id);

  const onNewWaiver = useCallback(() => {
    setSelectedWaiver(null);
    setOpen(true);
  }, []);

  const onEditWaiver = useCallback((waiver) => {
    setSelectedWaiver(waiver);
    setOpen(true);
  }, []);

  const onDelete = useCallback(
    (waiver) => {
      openConfirmation({
        title: `Delete ${waiver.name}`,
        body: `Deleting ${waiver.name} can not be undone, are you sure you want to continue?`,
        loading: deleteLoading,
        callback: async () => {
          await deleteWaiver({
            variables: {
              id: waiver.id,
            },
          });
          showNotification(`${waiver.name} Deleted!`);
          closeConfirmation();
        },
      });
    },
    [deleteLoading]
  );

  if (loading) return <Loading cover={true} />;
  return (
    <>
      <TableContainer component={Paper} variant="outlined">
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Content</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(data.boatWaivers || []).map((waiver) => (
              <TableRow key={waiver.id}>
                <TableCell>{waiver.name}</TableCell>
                <TableCell>{truncate(waiver.content || "", 50)}</TableCell>
                <TableCell align="right">
                  <ButtonGroup>
                    <IconButton onClick={() => onEditWaiver(waiver)}>
                      <Edit />
                    </IconButton>
                    <IconButton onClick={() => onDelete(waiver)}>
                      <Delete />
                    </IconButton>
                  </ButtonGroup>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box mt={4} display={"flex"} justifyContent={"flex-end"}>
        <Button variant="contained" color="primary" onClick={onNewWaiver}>
          Add New Waiver
        </Button>
      </Box>
      {open && (
        <BoatWaiverDialog
          open={open}
          setOpen={setOpen}
          waiver={selectedWaiver}
          boatId={boat.id}
        />
      )}
    </>
  );
}

export default BoatWaivers;
