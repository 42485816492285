"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _react = require("react");
function useDidUpdateEffect(fn, inputs) {
  var didMountRef = (0, _react.useRef)(false);
  (0, _react.useEffect)(function () {
    if (didMountRef.current) fn();else didMountRef.current = true;
  }, inputs);
}
var _default = exports["default"] = useDidUpdateEffect;