import {
  useCreateBooking,
  useCreateBookingItem,
  useUpdateBooking,
  useUpdateBookingItem,
} from "@store/actions/bookingActions";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export const BookingContext = createContext(null);
export const useBookingContext = () => useContext(BookingContext);

const BookingProvider = ({ children }) => {
  const [addBooking] = useCreateBooking();
  const [updateBooking] = useUpdateBooking();
  const [bookings, setBookings] = useState([]);
  const [booking, setBooking] = useState({});
  const [itemInput, setItemInput] = useState({});
  const [option, setOption] = useState({});
  const [quote, setQuote] = useState({});
  const [chatOpen, setChatOpen] = useState(false);
  const [addBookingItem] = useCreateBookingItem(booking);
  const [updateBookingItem] = useUpdateBookingItem(booking);

  const [bookingItem, setBookingItem] = useState({});

  const saveBooking = useCallback(() => {
    const input = {
      groupSize: booking.groupSize,
      hours: booking.hours,
      catering: booking.catering,
      event: booking.event,
      eventOther: booking.eventOther,
      available: booking.available,
      cruiseDate: booking.cruiseDate,
      notes: booking.notes,
      source: "Manual",
      lead: {
        id: booking.lead?.id,
      },
    };

    const fn = booking.id
      ? updateBooking({
          variables: {
            id: booking.id,
            input,
          },
        })
      : addBooking({
          variables: {
            input,
            itemInput,
          },
        });

    return fn;
  }, [addBooking, updateBooking, booking]);

  const saveBookingItem = useCallback(() => {
    if (!booking.id) {
      console.error("no booking id");
      // todo, handle validation
      return;
    }
    if (!bookingItem.boat) {
      console.warn("no boat id");
      // todo, handle validation
      return;
    }
    const input = {
      boat: {
        id: bookingItem.boat.id,
      },
      baseCost: bookingItem.baseCost,
      commision: bookingItem.commision,
    };

    if (bookingItem.boatPackage)
      input.boatPackage = {
        id: bookingItem.boatPackage.id,
      };

    if (bookingItem.skippers) {
      input.skippers = bookingItem.skippers.map(({ staff }) => ({
        id: staff.id,
      }));
    }
    if (bookingItem.deckies) {
      input.deckies = bookingItem.deckies.map(({ staff }) => ({
        id: staff.id,
      }));
    }

    const fn = bookingItem.id
      ? updateBookingItem({
          variables: {
            id: bookingItem.id,
            input,
          },
        })
      : addBookingItem({
          variables: {
            bookingID: booking.id,
            input,
          },
        });
    return fn;
  }, [booking, bookingItem]);

  const state = useMemo(
    () => ({
      bookings,
      setBookings,
      booking,
      setBooking,
      itemInput,
      setItemInput,
      saveBooking,
      bookingItem,
      setBookingItem,
      saveBookingItem,
      option,
      setOption,
      chatOpen,
      setChatOpen,
      quote,
      setQuote,
    }),
    [
      booking,
      bookings,
      itemInput,
      setItemInput,
      bookingItem,
      option,
      chatOpen,
      quote,
    ]
  );

  return (
    <BookingContext.Provider value={state}>{children}</BookingContext.Provider>
  );
};

export default BookingProvider;
