import CardTable from "@components/cardTable/CardTable";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { useDeletePackagePage } from "@store/actions/packagePageActions";
import { useConfirmationContext } from "providers/ConfirmationProvider";
import { useNotificationContext } from "providers/NotificationProvider";
import React from "react";
import { useHistory } from "react-router-dom";

function PackagePagesTable({ packagePages }) {
  const history = useHistory();
  const [deletePackagePage, { loading }] = useDeletePackagePage();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showNotification } = useNotificationContext();

  const onDelete = ({ id, name }) => {
    openConfirmation({
      title: `Delete ${name}`,
      body: `Deleting ${name} can not be undone, are you sure you want to continue?`,
      loading: () => loading,
      callback: () =>
        deletePackagePage({
          variables: {
            id,
          },
        }).then(() => {
          showNotification(`${name} Deleted!`);
          closeConfirmation();
        }),
    });
  };

  return (
    <CardTable
      tableHead={["name", "oneLiner"]}
      tableData={packagePages}
      actions={[
        {
          icon: <Edit color="primary" />,
          fn: ({ id }) => history.push(`/package-pages/${id}/edit`),
        },
        {
          icon: <Delete color="secondary" />,
          fn: onDelete,
        },
      ]}
    />
  );
}

export default PackagePagesTable;
