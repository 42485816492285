import CardTable from "@components/cardTable/CardTable";
import Loading from "@components/loading/Loading";
import UserModal from "@components/user/UserModal";
import { Button, Grid, Link, TextField, Typography } from "@material-ui/core";
import { Add, Edit, Refresh } from "@material-ui/icons";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useForgot } from "@store/actions/authActions";
import { useGetAllUser } from "@store/actions/userActions";
import React, { useMemo, useState } from "react";
import { friendlyDateTime } from "utils/date";
import { Link as RouterLink } from "react-router-dom";

const EmailCol = (email, row) => {
  if (!email) {
    return <>-</>;
  }
  return (
    <Typography variant="body2">
      <Link color="primary" component={RouterLink} to={`/users/${row.id}`}>
        {email}
      </Link>
    </Typography>
  );
};
const DateCol = (date) => {
  if (!date) {
    return <>-</>;
  }
  return <Typography variant="body2">{friendlyDateTime(date)}</Typography>;
};

function UsersPage() {
  const [search, setSearch] = useState("");
  const { loading, error, data } = useGetAllUser();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState({});
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showNotification } = useNotificationContext();
  const [forgot] = useForgot();

  const onEdit = (user) => {
    setUser(user);
    setOpen(true);
  };

  const onReset = ({ firstName, email }) => {
    openConfirmation({
      title: `Send Password Reset for ${firstName}?`,
      body: `This will send an email that will allow this user to set / reset their password`,
      callback: async () => {
        await forgot({ variables: { email } });
        showNotification("Reset email sent");
        closeConfirmation();
      },
    });
  };

  const filteredUser = useMemo(() => {
    if (!data) {
      return [];
    }
    return (data.users || [])
      .filter(
        (s) =>
          (s.firstName || "").toLowerCase().includes(search.toLowerCase()) ||
          (s.lastName || "").toLowerCase().includes(search.toLowerCase()) ||
          (s.email || "").toLowerCase().includes(search.toLowerCase()) ||
          (s.userName || "").toLowerCase().includes(search.toLowerCase())
      )
      .sort((a, b) => a.userName.localeCompare(b.userName));
  }, [data, search]);

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      justify="space-between"
      alignItems="center"
    >
      <Grid item xs={5}>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Typography variant="h5" component="span" display="inline">
              Users
            </Typography>
          </Grid>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => {
            setUser({});
            setOpen(true);
          }}
        >
          New User
        </Button>
      </Grid>
      <Grid item xs={12}>
        <CardTable
          tableHead={[
            "email",
            "userName",
            "firstName",
            "lastName",
            "resetSentOn",
            "lastLogin",
          ]}
          tableData={filteredUser}
          customColumns={[
            {
              name: "email",
              component: EmailCol,
            },
            {
              name: "resetSentOn",
              component: DateCol,
            },
            {
              name: "lastLogin",
              component: DateCol,
            },
          ]}
          actions={[
            {
              icon: <Edit color="primary" />,
              fn: onEdit,
            },
            {
              icon: <Refresh color="primary" />,
              fn: onReset,
            },
          ]}
        />
      </Grid>
      <UserModal open={open} setOpen={setOpen} user={user} setUser={setUser} />
    </Grid>
  );
}

export default UsersPage;
