import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_WHARF,
  UPDATE_WHARF,
  GET_ALL_WHARFS,
  DELETE_WHARF,
} from "@operations/wharfOpertations";

/* -------------------------------------------------------------------------- */
/*                                   Wharfs                                   */
/* -------------------------------------------------------------------------- */

export const useCreateWharf = () =>
  useMutation(CREATE_WHARF, {
    update: (cache, { data: { createWharf } }) => {
      const data = cache.readQuery({
        query: GET_ALL_WHARFS,
      });
      cache.writeQuery({
        query: GET_ALL_WHARFS,
        data: {
          wharfs: [...(data.wharfs || []), createWharf],
        },
      });
    },
  });

export const useUpdateWharf = () => useMutation(UPDATE_WHARF);

export const useGetAllWharfs = () => useQuery(GET_ALL_WHARFS);

export const useDeleteWharf = () =>
  useMutation(DELETE_WHARF, {
    update: (cache, { data: { deleteWharf } }) => {
      const data = cache.readQuery({
        query: GET_ALL_WHARFS,
      });
      const wharfs = data ? data.wharfs : [];
      cache.writeQuery({
        query: GET_ALL_WHARFS,
        data: {
          wharfs: wharfs.filter((w) => w.id !== deleteWharf.id),
        },
      });
    },
  });
