import {
  Avatar,
  Box,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Link,
  MenuItem,
  TextField,
  Typography,
  useTheme,
} from "@material-ui/core";
import { useCateringEnum, useEventEnum } from "@store/actions/bookingActions";
import { useGetAllLeads } from "@store/actions/leadActions";
import { useMemo } from "react";
import { TextValidator } from "react-material-ui-form-validator";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment-timezone";
import { Edit, Email, LocalPhone } from "@material-ui/icons";
import { useGetAllBoats } from "@store/actions/boatActions";
import { produce } from "immer";
import { camelToText, formatEnumVal } from "utils/strings";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

function BookingFormContent({
  booking,
  setBooking,
  itemInput,
  setItemInput,
  showLead,
  onEditItem,
  isNew,
}) {
  const { loading: leadsLoading, data: leadsData } = useGetAllLeads();
  const { data: boatsData } = useGetAllBoats();
  const { data: catering } = useCateringEnum();
  const { data: event } = useEventEnum();
  const theme = useTheme();
  const lastSentQuote = useMemo(() => {
    const sentQuotes = (booking.quotes || [])
      .filter((q) => q.sentOn)
      .sort(
        (a, b) => new moment(a.date).format() - new moment(b.date).format()
      );
    return sentQuotes[sentQuotes.length - 1];
  }, [booking]);

  const cateringOptions = useMemo(() => {
    if (!catering) {
      return [];
    }
    return catering.__type.enumValues.map((e) => e.name);
  }, [catering]);

  const eventOptions = useMemo(() => {
    if (!event) {
      return [];
    }
    return event.__type.enumValues.map((e) => e.name);
  }, [event]);

  const quotedItems = useMemo(() => {
    return (booking.items || [])
      .filter((item) => item.boat)
      .filter((item) => {
        return item.quoteItems && item.quoteItems.length > 0;
      })
      .map((item) => item);
  }, [booking]);

  const nonQuotedItems = useMemo(() => {
    return (booking.items || [])
      .filter((item) => item.boat)
      .filter((item) => {
        return !item.quoteItems || item.quoteItems.length === 0;
      })
      .map((item) => item);
  }, [booking]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const b = { ...booking };
    switch (name) {
      case "lead":
        b.lead = { id: value };
        break;
      default:
        b[name] = value;
        break;
    }
    setBooking(b);
  };

  const bookingLead = useMemo(() => {
    if (!booking.lead) {
      return null;
    }
    if (booking?.lead?.firstName) {
      return booking?.lead;
    }
    const lead = (leadsData.leads || []).find((l) => l.id === booking.lead.id);
    return lead;
  }, [booking]);

  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        {isNew && (
          <Box mb={3}>
            <TextField
              select
              fullWidth
              variant="outlined"
              size="small"
              label="Boat"
              value={itemInput?.boat?.id || ""}
              onChange={(e) =>
                setItemInput(
                  produce(itemInput, (draft) => {
                    if (!draft.boat) {
                      draft.boat = {};
                    }
                    draft.boat.id = e.target.value;
                  })
                )
              }
            >
              {(boatsData?.boats || []).map((boat) => (
                <MenuItem key={boat.id} value={boat.id}>
                  {boat.name}
                </MenuItem>
              ))}
            </TextField>
          </Box>
        )}
        <Box mb={3}>
          <TextField
            name="groupSize"
            onChange={handleChange}
            value={booking.groupSize || ""}
            fullWidth
            size="small"
            label="Group Size"
            variant="outlined"
            type="number"
          />
        </Box>
        <Box mb={3}>
          <TextField
            name="hours"
            onChange={handleChange}
            value={booking.hours || ""}
            fullWidth
            size="small"
            label="Number of hours"
            variant="outlined"
            type="number"
          />
        </Box>
        <Box mb={3}>
          <TextField
            name="catering"
            onChange={handleChange}
            value={booking.catering || ""}
            fullWidth
            size="small"
            label="Catering?"
            variant="outlined"
            select
          >
            {cateringOptions.map((c) => (
              <MenuItem key={c} value={c}>
                {formatEnumVal(c)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box mb={3}>
          <TextField
            name="event"
            onChange={handleChange}
            value={booking.event || ""}
            fullWidth
            size="small"
            label="Event?"
            variant="outlined"
            select
          >
            {eventOptions.map((c) => (
              <MenuItem key={c} value={c}>
                {c.toLowerCase() === "unspecified" ? "None" : camelToText(c)}
              </MenuItem>
            ))}
          </TextField>
        </Box>
        <Box mb={3}>
          <TextValidator
            name="eventOther"
            fullWidth
            margin="dense"
            label="Other event?"
            variant="outlined"
            value={booking.eventOther}
            onChange={handleChange}
          />
        </Box>
        <Box mb={3}>
          <Typography variant="body2">
            Cruise Date on : {moment(booking.cruiseDate).format("lll")}
          </Typography>
          <Typography variant="body2">
            Created on : {moment(booking.createdAt).format("lll")}
          </Typography>
          {lastSentQuote && (
            <Typography variant="body2">
              Last quote sent on : {moment(lastSentQuote.sentOn).format("lll")}
            </Typography>
          )}
        </Box>
        <Box mb={3}>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <DesktopDatePicker
                label="Pick a date"
                inputFormat="DD/MM/yyyy"
                value={booking.cruiseDate || null}
                onChange={(v) =>
                  setBooking((old) => ({
                    ...old,
                    cruiseDate: v,
                  }))
                }
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <TimePicker
                label="Pick a time"
                value={booking.cruiseDate || null}
                fullWidth
                minutesStep={5}
                onChange={(v) =>
                  setBooking((old) => ({
                    ...old,
                    cruiseDate: v,
                  }))
                }
                renderInput={(params) => (
                  <TextField variant="outlined" {...params} />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </Grid>
      <Grid item xs={6}>
        <Box
          px={4}
          style={{ borderLeft: `1px solid ${theme?.palette?.divider}` }}
        >
          <Box my={3}>
            <Typography variant="h6">Boats Quoted</Typography>
            <Box>
              {quotedItems.map((item) => (
                <Box
                  key={item.id}
                  sx={{
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Box>{item.boat.name}</Box>
                  {item.boat.skipperRequired && (
                    <>
                      {(item.skippers || []).length ? (
                        (item.skippers || []).map((s) => (
                          <Box key={s.id}>{s.staff.firstName}</Box>
                        ))
                      ) : (
                        <Typography variant="caption" color="error">
                          No Skippers Set
                        </Typography>
                      )}
                      {(item.deckies || []).length ? (
                        (item.deckies || []).map((s) => (
                          <Box key={s.id}>{s.staff.firstName}</Box>
                        ))
                      ) : (
                        <Typography variant="caption" color="error">
                          No Deckies Set
                        </Typography>
                      )}
                    </>
                  )}
                  <Box>
                    <IconButton size="small" onClick={() => onEditItem(item)}>
                      <Edit fontSize="14px" />
                    </IconButton>
                  </Box>
                </Box>
              ))}
            </Box>
          </Box>
          <Divider />
          <Box my={3}>
            <Typography variant="h6">Boats Not Quoted For</Typography>
            <Box>
              {nonQuotedItems.map((item) => (
                <Box key={item.id}>{item.boat.name}</Box>
              ))}
            </Box>
          </Box>
          <Divider />
          {showLead && !leadsLoading && (
            <Box mt={3}>
              {bookingLead ? (
                <Box display="flex" mb={3} alignItems="center">
                  <Avatar
                    style={{ backgroundColor: theme?.palette?.primary?.main }}
                  >
                    {bookingLead.firstName[0]}
                  </Avatar>
                  <Box ml={2}>
                    <Link
                      variant="caption"
                      color="textPrimary"
                      component={RouterLink}
                      to={`/leads/${bookingLead.id}`}
                    >
                      <Typography variant="h5">
                        {bookingLead.firstName} {bookingLead.lastName}
                      </Typography>
                    </Link>
                    <Box
                      style={{
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box mr={1} mt={1}>
                          <LocalPhone fontSize={"small"} />
                        </Box>
                        {bookingLead.phone}
                      </Typography>
                      <Typography
                        variant="body2"
                        style={{
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        <Box ml={3} mr={1} mt={1}>
                          <Email fontSize={"small"} />
                        </Box>
                        {bookingLead.email}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <Typography gutterBottom>For Which Lead?</Typography>
              )}
              <TextValidator
                fullWidth
                select
                value={bookingLead ? bookingLead.id : ""}
                name="lead"
                label="Lead"
                validators={["required"]}
                errorMessages={["Lead is required"]}
                onChange={handleChange}
                variant="outlined"
                size="small"
              >
                {leadsData.leads.map((l) => (
                  <MenuItem key={l.id} value={l.id}>
                    {l.email}
                  </MenuItem>
                ))}
              </TextValidator>
            </Box>
          )}
        </Box>
      </Grid>
    </Grid>
  );
}

BookingFormContent.defaultProps = {
  showLead: true,
};

export default BookingFormContent;
