import BoatWizard from "@components/boat/BoatWizard";
import {
  Box,
  CircularProgress,
  Divider,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import { useGetBoat } from "@actions/boatActions";
import { useBoatContext } from "providers/BoatProvider";
import React, { useEffect, useState } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";
import CardTable from "@components/cardTable/CardTable";
import {
  getQuotesTransactionsTotal,
  getQuotesTransactionsOwnerTotal,
} from "utils/models";
import moment from "moment";

const TransactionsCol = (_, row) => {
  if (!row) {
    return "";
  }
  const transactionTotal = getQuotesTransactionsTotal(row.quotes);
  const ownersTotal = getQuotesTransactionsOwnerTotal(row.quotes);
  return (
    <Typography variant="caption">
      ${transactionTotal} (${ownersTotal} owners cut)
    </Typography>
  );
};

const BoatPage = () => {
  const { boatid } = useParams();
  const { path } = useRouteMatch();
  const { loading, data } = useGetBoat(boatid);
  const [sortedBookings, setSortedBookings] = useState([]);
  const [transactionTotal, setTransactionTotal] = useState(0);
  const [transactionTotal30, setTransactionTotal30] = useState(0);
  const [ownersTotal, setOwnersTotal] = useState(0);
  const [ownersTotal30, setOwnersTotal30] = useState(0);

  const { boat, setBoat } = useBoatContext();

  useEffect(() => {
    if (data) {
      setBoat({
        ...data.boat,
      });
      const bookings = [
        ...new Set(
          data.boat?.bookingItems
            .map((bi) => {
              return bi.booking;
            })
            .filter((a) => a)
            .sort((a, b) => new Date(b?.cruiseDate) - new Date(a?.cruiseDate))
        ),
      ];
      setSortedBookings(bookings);
      const from = moment().subtract(30, "days").format();

      const tt = bookings.reduce((arg, booking) => {
        const bookingTt = booking
          ? getQuotesTransactionsTotal(booking.quotes)
          : 0;
        return arg + bookingTt;
      }, 0);

      const tt30 = bookings.reduce((arg, booking) => {
        const bookingTt = booking
          ? getQuotesTransactionsTotal(booking.quotes, from)
          : 0;
        return arg + bookingTt;
      }, 0);
      const to = bookings.reduce((arg, booking) => {
        const bookingTt = booking
          ? getQuotesTransactionsOwnerTotal(booking.quotes)
          : 0;
        return arg + bookingTt;
      }, 0);
      const to30 = bookings.reduce((arg, booking) => {
        const bookingTt = booking
          ? getQuotesTransactionsOwnerTotal(booking.quotes, from)
          : 0;
        return arg + bookingTt;
      }, 0);
      setTransactionTotal(tt);
      setTransactionTotal30(tt30);
      setOwnersTotal(to);
      setOwnersTotal30(to30);
    }
  }, [data, setBoat]);

  if (loading || !boat.id) return <CircularProgress />;

  return (
    <>
      <Switch>
        <Route path="/boats/:boatid/edit">
          <BoatWizard />
        </Route>
        <Route path={path}>
          <Grid container spacing={3}>
            <Grid item xs={2}>
              <Paper>
                <Box p={3}>
                  <Typography variant="subtitle1">
                    Total Transactions
                  </Typography>
                  <Typography variant="h5" component="p" color="primary">
                    ${transactionTotal}{" "}
                  </Typography>
                  <Typography variant="subtitle1" component="p" color="primary">
                    (${transactionTotal30}, last 30 days)
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={2}>
              <Paper>
                <Box p={3}>
                  <Typography variant="subtitle1">
                    Total Boat owners share
                  </Typography>
                  <Typography variant="h5" component="p" color="primary">
                    ${ownersTotal}
                  </Typography>
                  <Typography variant="subtitle1" component="p" color="primary">
                    (${ownersTotal30}, last 30 days)
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={7}>
              <CardTable
                title="Bookings"
                tableHead={["reference", "cruiseDate", "transactions"]}
                tableData={sortedBookings}
                customColumns={[
                  {
                    name: "transactions",
                    component: TransactionsCol,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Route>
      </Switch>
    </>
  );
};

export default BoatPage;
