import { gql } from "@apollo/client";

export const GET_ALL_BOATS = gql`
  query GetAllBoats {
    boats {
      id
      name
      oneLiner
      cardExtraOne
      cardExtraTwo
      description
      capacity
      minimumHire
      additionalOptions
      mediaOrder
      archived
      showContact
      skipperRequired
      googleCalendar
      googleCalendarWrite
      boatPackages {
        id
        name
        createdAt
        media {
          id
          name
          type
          mediaFiles {
            name
            path
            type
          }
        }
      }
      wharfs {
        id
        name
      }
    }
  }
`;

export const GET_RECOMMENDED_BOATS = gql`
  query RecommendedBoats($boatID: UUID, $bookingID: UUID) {
    recommendedBoats(boatID: $boatID, bookingID: $bookingID) {
      id
      name
      oneLiner
      cardExtraOne
      cardExtraTwo
      capacity
      minimumHire
      additionalOptions
      mediaOrder
      skipperRequired
      googleCalendar
      googleCalendarWrite
      features {
        id
        name
      }
      owner {
        id
        firstName
        email
      }
      boatTypes {
        id
        name
        path
      }
      medias {
        id
        name
        type
        mediaFiles {
          name
          path
          type
        }
      }
      boatPrice {
        id
        name
        baseHourlyRate
        description
        items {
          id
          from
          to
          rate
          unit
          days
          fixed
        }
      }
      boatPrices {
        id
        name
        baseHourlyRate
        description
        items {
          id
          from
          to
          rate
          unit
          days
          fixed
        }
      }
      boatPriceSpecials {
        id
        name
        to
        from
        recurring
        boatPrice {
          id
          name
          baseHourlyRate
          description
          items {
            id
            from
            to
            rate
            unit
            days
            fixed
          }
        }
      }
    }
  }
`;

export const GET_BOAT = gql`
  query Boat($id: UUID!) {
    boat(id: $id) {
      id
      name
      oneLiner
      cardExtraOne
      cardExtraTwo
      description
      capacity
      minimumHire
      additionalOptions
      mediaOrder
      videoLink
      archived
      showContact
      createdAt
      updatedAt
      attachmentsInfo
      skipperRequired
      googleCalendar
      googleCalendarWrite
      bookingItems {
        id
        booking {
          id
          reference
          cruiseDate
          quotes {
            id
            transactions {
              id
              total
              ownerCut
              createdAt
            }
          }
        }
      }
      owner {
        id
        firstName
        email
      }
      boatPageLayout {
        id
        name
      }
      features {
        id
        name
      }
      boatTypes {
        id
        name
        path
      }
      comms {
        id
        type
        commission
      }
      boatPrice {
        id
        name
        baseHourlyRate
        description
        items {
          id
          from
          to
          rate
          unit
          days
          fixed
        }
      }
      boatPrices {
        id
        name
        baseHourlyRate
        description
        items {
          id
          from
          to
          rate
          unit
          days
          fixed
        }
      }
      boatPriceSpecials {
        id
        name
        to
        from
        recurring
        boatPrice {
          id
          name
          baseHourlyRate
          description
          items {
            id
            from
            to
            rate
            unit
            days
            fixed
          }
        }
      }
      boatPackages {
        id
        name
        description
        price
        info
        content
        slateJson
        createdAt
        media {
          id
          name
          type
          mediaFiles {
            name
            path
            type
          }
        }
        attachments {
          id
          name
          type
          mediaFiles {
            name
            path
            type
          }
        }
      }
      medias {
        id
        name
        type
        mediaFiles {
          name
          path
          type
        }
      }
      attachments {
        id
        name
        type
        mediaFiles {
          name
          path
          type
        }
      }
      wharfs {
        id
        name
        createdAt
      }
      locations {
        id
        name
      }
    }
  }
`;

export const DELETE_BOAT = gql`
  mutation DeleteBoat($id: UUID!) {
    deleteBoat(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_BOAT = gql`
  mutation CreateBoat($input: BoatInput!) {
    createBoat(input: $input) {
      id
      name
      oneLiner
      cardExtraOne
      cardExtraTwo
      description
      capacity
      minimumHire
      additionalOptions
      mediaOrder
      videoLink
      skipperRequired
      googleCalendar
      googleCalendarWrite
    }
  }
`;

export const UPDATE_BOAT = gql`
  mutation UpdateBoat($id: UUID!, $input: BoatInput!) {
    updateBoat(id: $id, input: $input) {
      id
      name
      oneLiner
      cardExtraOne
      cardExtraTwo
      description
      capacity
      minimumHire
      additionalOptions
      mediaOrder
      archived
      showContact
      videoLink
      createdAt
      updatedAt
      attachmentsInfo
      skipperRequired
      googleCalendar
      googleCalendarWrite
      bookingItems {
        id
        booking {
          id
          reference
          cruiseDate
          quotes {
            id
            transactions {
              id
              total
              ownerCut
              createdAt
            }
          }
        }
      }
      owner {
        id
        firstName
        email
      }
      boatPageLayout {
        id
        name
      }
      features {
        id
        name
      }
      boatTypes {
        id
        name
        path
      }
      comms {
        id
        type
        commission
      }
      boatPrice {
        id
        name
        baseHourlyRate
        description
        items {
          id
          from
          to
          rate
          unit
          days
          fixed
        }
      }
      boatPrices {
        id
        name
        baseHourlyRate
        description
        items {
          id
          from
          to
          rate
          unit
          days
          fixed
        }
      }
      boatPriceSpecials {
        id
        name
        to
        from
        recurring
        boatPrice {
          id
          name
          baseHourlyRate
          description
          items {
            id
            from
            to
            rate
            unit
            days
            fixed
          }
        }
      }
      boatPackages {
        id
        name
        description
        price
        info
        content
        slateJson
        createdAt
        media {
          id
          name
          type
          mediaFiles {
            name
            path
            type
          }
        }
        attachments {
          id
          name
          type
          mediaFiles {
            name
            path
            type
          }
        }
      }
      medias {
        id
        name
        type
        mediaFiles {
          name
          path
          type
        }
      }
      attachments {
        id
        name
        type
        mediaFiles {
          name
          path
          type
        }
      }
      wharfs {
        id
        name
        createdAt
      }
      locations {
        id
        name
      }
    }
  }
`;

/* -------------------------------------------------------------------------- */
/*                                 boat layouts                               */
/* -------------------------------------------------------------------------- */
export const GET_ALL_BOAT_LAYOUTS = gql`
  query GetAllBoatLayouts {
    boatPageLayouts {
      id
      name
      boats {
        name
      }
      items {
        id
        content
        order
        featureGroup {
          id
          name
        }
      }
    }
  }
`;
export const GET_BOAT_LAYOUT = gql`
  query GetBoatLayout($id: UUID!) {
    boatPageLayout(id: $id) {
      id
      name
      boats {
        name
      }
      items {
        id
        content
        order
        featureGroup {
          id
          name
        }
      }
    }
  }
`;
export const DELETE_BOAT_LAYOUT = gql`
  mutation DeleteBoatLayout($id: UUID!) {
    deleteBoatPageLayout(id: $id) {
      id
    }
  }
`;
export const CREATE_BOAT_LAYOUT = gql`
  mutation CreateBoatLayout($input: BoatPageLayoutInput!) {
    createBoatPageLayout(input: $input) {
      id
      name
      boats {
        name
      }
      items {
        id
        content
        order
        featureGroup {
          id
          name
        }
      }
    }
  }
`;
export const UPDATE_BOAT_LAYOUT = gql`
  mutation UpdateeBoatLayout($id: UUID!, $input: BoatPageLayoutInput!) {
    updateBoatPageLayout(id: $id, input: $input) {
      id
      name
      items {
        id
        content
        order
        featureGroup {
          id
          name
        }
      }
    }
  }
`;

/* -------------------------------------------------------------------------- */
/*                                 boat owners                                */
/* -------------------------------------------------------------------------- */
export const GET_ALL_OWNERS = gql`
  query GetAllOwners {
    owners {
      id
      firstName
      lastName
      email
      phone
      defaultCommission
      createdAt
      iso
      stripeType
      isManual
      businessName
      address
      city
      state
      businessID
      boats {
        id
        name
      }
      rates {
        id
        type
        commission
      }
      currency
      stripeAccounts {
        accountId
        status
      }
    }
  }
`;

export const GET_OWNER = gql`
  query Owner($id: UUID!) {
    owner(id: $id) {
      id
      firstName
      lastName
      email
      phone
      iso
      stripeType
      defaultCommission
      isManual
      businessName
      address
      city
      state
      businessID
      boats {
        id
        name
      }
      rates {
        id
        type
        commission
      }
      currency
      stripeAccounts {
        accountId
        status
      }
    }
  }
`;

export const DELETE_OWNER = gql`
  mutation DeleteOwner($id: UUID!) {
    deleteOwner(id: $id) {
      id
    }
  }
`;

export const CREATE_OWNER = gql`
  mutation CreateOwner($input: OwnerInput!) {
    createOwner(input: $input) {
      id
      firstName
      lastName
      email
      phone
      defaultCommission
      iso
      stripeType
      isManual
      businessName
      address
      city
      state
      businessID
      boats {
        id
        name
      }
      rates {
        id
        type
        commission
      }
      currency
      stripeAccounts {
        accountId
        status
      }
    }
  }
`;

export const UPDATE_OWNER = gql`
  mutation UpdateOwner($id: UUID!, $input: OwnerInput!) {
    updateOwner(id: $id, input: $input) {
      id
      firstName
      lastName
      email
      phone
      defaultCommission
      iso
      stripeType
      isManual
      businessName
      address
      city
      state
      businessID
      boats {
        id
        name
      }
      rates {
        id
        type
        commission
      }
      currency
      stripeAccounts {
        accountId
        status
      }
    }
  }
`;

export const CREATE_STRIPE_ACCOUNT = gql`
  mutation CreateOwnerStripeAccount($id: UUID!) {
    createOwnerStripeAccount(id: $id) {
      id
      accountId
      owner {
        id
      }
    }
  }
`;

export const REMOVE_STRIPE_ACCOUNT = gql`
  mutation RemoveOwnerStripeAccount($id: UUID!) {
    removeOwnerStripeAccount(id: $id) {
      id
      firstName
      lastName
      email
      phone
      defaultCommission
      iso
      stripeType
      boats {
        id
        name
      }
      rates {
        id
        type
        commission
      }
      stripeAccounts {
        accountId
        status
      }
    }
  }
`;

/* -------------------------------------------------------------------------- */
/*                                 Boat Price                                 */
/* -------------------------------------------------------------------------- */

export const CREATE_BOAT_PRICE = gql`
  mutation CreateBoatPrice($input: BoatPriceInput!) {
    createBoatPrice(input: $input) {
      id
      name
      baseHourlyRate
      description
      items {
        id
        from
        to
        rate
        unit
        days
        fixed
      }
    }
  }
`;
export const UPDATE_BOAT_PRICE = gql`
  mutation UpdateBoatPrice($id: UUID!, $input: BoatPriceInput!) {
    updateBoatPrice(id: $id, input: $input) {
      id
      name
      baseHourlyRate
      description
      items {
        id
        from
        to
        rate
        unit
        days
        fixed
      }
    }
  }
`;

export const DELETE_BOAT_PRICE = gql`
  mutation DeleteBoatPrice($id: UUID!) {
    deleteBoatPrice(id: $id) {
      id
      name
    }
  }
`;

export const CREATE_BOAT_PRICE_ITEM = gql`
  mutation CreateBoatPriceItem($input: BoatPriceItemInput!) {
    createBoatPriceItem(input: $input) {
      id
      from
      to
      rate
      unit
      days
      fixed
    }
  }
`;

export const UPDATE_BOAT_PRICE_ITEM = gql`
  mutation UpdateBoatPriceItem($id: UUID!, $input: BoatPriceItemInput!) {
    updateBoatPriceItem(id: $id, input: $input) {
      id
      from
      to
      rate
      unit
      days
      fixed
    }
  }
`;

export const CREATE_BOAT_PRICE_SPECIAL = gql`
  mutation CreateBoatPriceSpecial($input: BoatPriceSpecialInput!) {
    createBoatPriceSpecial(input: $input) {
      name
      to
      from
      recurring
    }
  }
`;

export const UPDATE_BOAT_PRICE_SPECIAL = gql`
  mutation UpdateBoatPriceSpecial($id: UUID!, $input: BoatPriceSpecialInput!) {
    updateBoatPriceSpecial(id: $id, input: $input) {
      name
      to
      from
      recurring
      boatPrice {
        id
        name
      }
    }
  }
`;

export const DELETE_BOAT_PRICE_SPECIAL = gql`
  mutation DeleteBoatPriceSpecial($id: UUID!) {
    deleteBoatPriceSpecial(id: $id) {
      id
    }
  }
`;

export const CREATE_BOAT_PACKAGE = gql`
  mutation CreateBoatPackage($input: BoatPackageInput!) {
    createBoatPackage(input: $input) {
      id
      name
      description
      price
      info
      content
      slateJson
      createdAt
      media {
        id
        name
        type
        mediaFiles {
          name
          path
          type
        }
      }
      attachments {
        id
        name
        type
        mediaFiles {
          name
          path
          type
        }
      }
    }
  }
`;
export const UPDATE_BOAT_PACKAGE = gql`
  mutation UpdateBoatPackage($id: UUID!, $input: BoatPackageInput!) {
    updateBoatPackage(id: $id, input: $input) {
      id
      name
      description
      price
      info
      content
      slateJson
      createdAt
      media {
        id
        name
        type
        mediaFiles {
          name
          path
          type
        }
      }
      attachments {
        id
        name
        type
        mediaFiles {
          name
          path
          type
        }
      }
    }
  }
`;

export const DELETE_BOAT_PACKAGE = gql`
  mutation DeleteBoatPackage($id: UUID!) {
    deleteBoatPackage(id: $id) {
      id
    }
  }
`;

/* -------------------------------------------------------------------------- */
/*                                 BOAT TYPES                                 */
/* -------------------------------------------------------------------------- */

export const GET_ALL_BOAT_TYPES = gql`
  query GetAllBoatTypes {
    boatTypes {
      id
      name
      path
    }
  }
`;

export const CREATE_BOAT_TYPE = gql`
  mutation CreateBoatType($input: BoatTypeInput!) {
    createBoatType(input: $input) {
      id
      name
      path
    }
  }
`;

export const UPDATE_BOAT_TYPE = gql`
  mutation UpdateBoatType($id: UUID!, $input: BoatTypeInput!) {
    updateBoatType(id: $id, input: $input) {
      id
      name
      path
    }
  }
`;

export const DELETE_BOAT_TYPE = gql`
  mutation DeleteBoatType($id: UUID!) {
    deleteBoatType(id: $id) {
      id
    }
  }
`;
