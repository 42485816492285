import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_FEATURES,
  GET_ALL_FEATURE_GROUPS,
  DELETE_FEATURE_GROUP,
  DELETE_FEATURE,
  CREATE_FEATURE,
  CREATE_FEATURE_GROUP,
  UPDATE_FEATURE,
  UPDATE_FEATURE_GROUP,
} from "@operations/featureOperations";

export const useGetAllFeatures = () => useQuery(GET_ALL_FEATURES);

export const useCreateFeature = () => {
  return useMutation(CREATE_FEATURE, {
    update: (cache, { data: { createFeature } }) => {
      const data = cache.readQuery({ query: GET_ALL_FEATURES });
      cache.writeQuery({
        query: GET_ALL_FEATURES,
        data: {
          features: [...data.features, createFeature],
        },
      });
    },
  });
};

export const useUpdateFeature = () => useMutation(UPDATE_FEATURE);

export const useDeleteFeature = () => {
  return useMutation(DELETE_FEATURE, {
    update: (cache, { data: { deleteFeature } }) => {
      const data = cache.readQuery({
        query: GET_ALL_FEATURES,
      });
      cache.writeQuery({
        query: GET_ALL_FEATURES,
        data: {
          features: data.features.filter((fg) => fg.id !== deleteFeature.id),
        },
      });
    },
  });
};

export const useGetAllfeatureGroups = () => useQuery(GET_ALL_FEATURE_GROUPS);

export const useCreateFeatureGroup = () => {
  return useMutation(CREATE_FEATURE_GROUP, {
    update: (cache, { data: { createFeatureGroup } }) => {
      const data = cache.readQuery({
        query: GET_ALL_FEATURE_GROUPS,
      });
      cache.writeQuery({
        query: GET_ALL_FEATURE_GROUPS,
        data: {
          featureGroups: [...data.featureGroups, createFeatureGroup],
        },
      });
    },
  });
};

export const useUpdateFeatureGroup = () => useMutation(UPDATE_FEATURE_GROUP);

export const useDeleteFeatureGroup = () => {
  return useMutation(DELETE_FEATURE_GROUP, {
    update: (cache, { data: { deleteFeatureGroup } }) => {
      const data = cache.readQuery({
        query: GET_ALL_FEATURE_GROUPS,
      });
      cache.writeQuery({
        query: GET_ALL_FEATURE_GROUPS,
        data: {
          featureGroups: data.featureGroups.filter(
            (fg) => fg.id !== deleteFeatureGroup.id
          ),
        },
      });
    },
  });
};
