import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  InputAdornment,
  TextField,
} from "@material-ui/core";
import {
  useCreateBoatPrice,
  useCreateBoatPriceItem,
  useUpdateBoatPrice,
  useUpdateBoatPriceItem,
} from "@actions/boatActions";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import BoatPriceItems from "./BoatPriceItems";

const BoatPriceModal = ({ open, setOpen, boat, onSave, price, setPrice }) => {
  const [addBoatPrice] = useCreateBoatPrice(boat.id);
  const [updateBoatPrice] = useUpdateBoatPrice();

  const handleChange = (e) => {
    setPrice({
      ...price,
      [e.target.name]: e.target.value,
    });
  };

  const setItems = (items) => {
    setPrice({
      ...price,
      items,
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const input = {
      name: price.name,
      baseHourlyRate: price.baseHourlyRate,
      description: price.description,
      boatId: boat.id,
      items: (price.items || []).map((item) => ({
        from: item.from,
        to: item.to,
        rate: item.rate,
        unit: item.unit,
        days: item.days,
        fixed: item.fixed,
      })),
    };
    try {
      if (price.id) {
        const r = await updateBoatPrice({
          variables: {
            id: price.id,
            input,
          },
        });
        onSave(r.data.updateBoatPrice);
      } else {
        const r = await addBoatPrice({
          variables: {
            input,
          },
        });
        onSave(r.data.createBoatPrice);
      }
    } catch (error) {}
  };

  const close = () => {
    setPrice({
      items: [{}],
    });
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={close}
      disableBackdropClick={true}
    >
      <ValidatorForm onSubmit={handleSave}>
        <DialogTitle id="form-dialog-title">Boat Price</DialogTitle>
        <DialogContent>
          <DialogContentText>
            A boat price is a rate per day. A boat can have multiple prices
            which can be assigned for different time of the year
          </DialogContentText>
          <Grid container spacing={2}>
            <Grid item xs={8}>
              <TextField
                autoFocus
                name="name"
                label="Price Name"
                fullWidth
                value={price.name || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={4}>
              <TextValidator
                name="baseHourlyRate"
                label="Base Hourly rate"
                validators={["required"]}
                errorMessages={[
                  "Base rate is required, this is the rate per hour to use if not set in custom rates",
                ]}
                fullWidth
                value={price.baseHourlyRate || ""}
                onChange={handleChange}
                type="number"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">$</InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                label="Description"
                multiline
                minRows={3}
                maxRows={6}
                fullWidth
                value={price.description || ""}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={12}></Grid>
            <BoatPriceItems items={price.items} setItems={setItems} />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
};

export default BoatPriceModal;
