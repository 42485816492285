import BookingGeneral from "@components/booking/BookingGeneral";
import BookingOptions from "@components/booking/BookingOptions";
import BookingQuotes from "@components/booking/BookingQuotes";
import Breadcrumb from "@components/breadcrumb/Breadcrumb";
import Chat from "@components/chat/Chat";
import Loading from "@components/loading/Loading";
import { Divider, Drawer, Fab, makeStyles, Tab, Tabs } from "@material-ui/core";
import { useBookingContext } from "@providers/BookingProvider";
import { useLayoutContext } from "@providers/LayoutProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useRoutesContext } from "@providers/RoutesProvider";
import {
  useGetBooking,
  useGetBookingByRef,
} from "@store/actions/bookingActions";
import {
  useSendLeadMessage,
  useSendOwnerMessage,
} from "@store/actions/messageActions";
import { useEffect, useLayoutEffect, useState } from "react";
import classNames from "classnames";

import {
  useLocation,
  useParams,
  matchPath,
  Switch,
  Route,
} from "react-router-dom";
import { BookingOptionPage } from "./BookingOptionPage";
import QuotePage from "./QuotePage";
import { useGetAllOwners } from "@store/actions/boatActions";

export const useTabbedPageStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    display: "flex",
    height: `calc(100vh - ${theme.spacing(17)}px)`,
  },
  tabs: {
    minWidth: theme.spacing(20),
    maxWidth: theme.spacing(20),
    borderRight: `1px solid ${theme.palette.divider}`,
    marginRight: theme.spacing(4),
  },
}));

const useBookingPageStyles = makeStyles((theme) => ({
  drawer: {
    maxWidth: 550,
    width: 550,
    minWidth: 550,
  },
  fab: {
    zIndex: 9999,
    position: "absolute",
    bottom: theme.spacing(4),
    right: theme.spacing(4),
  },
  fabOpen: {
    right: `calc(${theme.spacing(4)}px + 550px )`,
  },
}));

export const TabPanel = ({ children, value, index }) => {
  return <div>{value === index && <>{children}</>}</div>;
};

function BookingPage() {
  const { bookingId } = useParams();
  const {
    booking,
    setBooking,
    option,
    quote,
    chatOpen: open,
    setChatOpen: setOpen,
  } = useBookingContext();
  const { loading, data, refetch } =
    bookingId.length > 10
      ? useGetBooking(bookingId)
      : useGetBookingByRef(bookingId);

  const [msgOwner, setMsgOwner] = useState(null);
  const [tab, setTab] = useState(0);
  const [mode, setMode] = useState("lead");
  const [sendSms, setSendSms] = useState(true);
  const { root, tabs } = useTabbedPageStyles();
  const { drawer, fab, fabOpen } = useBookingPageStyles();
  const location = useLocation();
  const { routes } = useRoutesContext();
  const { setContentRight } = useLayoutContext();
  const { showNotification } = useNotificationContext();
  const [sendLeadMessage] = useSendLeadMessage(bookingId, option?.id || null);
  const [sendOwnerMessage] = useSendOwnerMessage(bookingId, option?.id || null);
  const { data: ownersData } = useGetAllOwners();

  useLayoutEffect(() => {
    setContentRight(open ? 450 : 0);
    return () => {
      setContentRight("0");
    };
  }, [open]);

  useEffect(() => {
    if (data) {
      if (data.booking) {
        setBooking({
          ...data.booking,
        });
      } else {
        setBooking({
          ...data.bookingByRef,
        });
      }
    }
  }, [data, setBooking]);

  useEffect(() => {
    if (location.chatOpen) {
      setOpen(true);
    }

    const optionsMatch = matchPath(location.pathname, {
      path: "/bookings/:bookingId/options",
      exact: true,
      strict: false,
    });
    if (optionsMatch) {
      setTab(1);
    }
    const quotesMatch = matchPath(location.pathname, {
      path: "/bookings/:bookingId/quotes",
      exact: true,
      strict: false,
    });
    if (quotesMatch) {
      setTab(2);
    }
  }, [location]);

  const onSend = async (content) => {
    if (mode === "lead" && !booking.lead) {
      showNotification("The booking requires a lead to send a message");
      return;
    }
    if (mode === "owner" && !msgOwner) {
      showNotification("You must select a owner to send to");
      return;
    }

    let input = {
      content,
      booking: {
        id: booking.id,
      },
      sendSms,
    };

    if (option?.id) {
      input.bookingOption = {
        id: option.id,
      };
    }
    if (quote?.id) {
      input.quote = {
        id: quote.id,
      };
    }
    try {
      mode === "lead"
        ? await sendLeadMessage({
            variables: {
              input,
              toLeadID: booking.lead.id,
            },
          })
        : await sendOwnerMessage({
            variables: {
              input,
              toOwnerID: msgOwner.id,
            },
          });
      showNotification("Message sent");
    } catch (error) {
      console.error(error);
    }
  };

  const handleChange = (_, v) => {
    switch (v) {
      case 0:
        window.history.replaceState(null, null, `/bookings/${bookingId}`);
        break;
      case 1:
        window.history.replaceState(
          null,
          null,
          `/bookings/${bookingId}/options`
        );
        break;
      case 2:
        window.history.replaceState(null, null, `/bookings/${bookingId}/quote`);
        break;
      default:
        break;
    }
    setTab(v);
  };

  const toggleChat = () => {
    setOpen(!open);
  };

  if (loading) return <Loading cover={true} />;

  return (
    <>
      <Breadcrumb routes={routes} />
      <Divider />
      <br></br>
      <Switch>
        <Route path="/bookings/:bookingId/options/:bookingOptionId">
          <BookingOptionPage />
        </Route>
        <Route path="/bookings/:bookingId/quotes/:quoteId">
          <QuotePage />
        </Route>
        <Route path="/bookings/:bookingId">
          <div className={root}>
            <Tabs
              orientation="vertical"
              value={tab}
              onChange={handleChange}
              className={tabs}
            >
              <Tab label="General" id="general" />
              <Tab label="Options" id="options" />
              <Tab label="Quotes" id="quotes" />
            </Tabs>
            <TabPanel value={tab} index={0}>
              <BookingGeneral />
            </TabPanel>
            <TabPanel value={tab} index={1}>
              <BookingOptions />
            </TabPanel>
            <TabPanel value={tab} index={2}>
              <BookingQuotes refetch={refetch} />
            </TabPanel>
          </div>
        </Route>
      </Switch>
      <Drawer
        anchor="right"
        variant="persistent"
        open={open}
        classes={{
          paper: drawer,
        }}
      >
        {booking && (
          <Chat
            open={open}
            messages={booking.messages}
            onSend={onSend}
            mode={mode}
            setMode={setMode}
            owners={ownersData?.owners || []}
            owner={msgOwner}
            setOwner={setMsgOwner}
            sendSms={sendSms}
            setSendSms={setSendSms}
          />
        )}
      </Drawer>
      <Fab
        className={classNames(fab, {
          [fabOpen]: open,
        })}
        color="primary"
        size="large"
        variant="extended"
        onClick={toggleChat}
      >
        {open ? "Close Chat" : "Open Chat"}
      </Fab>
    </>
  );
}

export default BookingPage;
