import BlockDropzone from "@components/contentBlocks/BlockDropzone";
import BlockSelectionDrawer from "@components/contentBlocks/BlockSelectionDrawer";
import React, { useEffect, useState } from "react";
import { useCallback } from "react";

function LandingPageContentBlocks({ parent, setParent }) {
  const [items, setItems] = useState(parent.sections || []);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(true);
    return () => {
      setOpen(false);
    };
  }, []);

  useEffect(() => {
    setParent((prev) => ({
      ...prev,
      sections: items,
    }));
  }, [items]);

  const onSave = useCallback(
    (sections) => {
      setParent((prev) => ({
        ...prev,
        sections,
      }));
    },
    [parent]
  );
  return (
    <div>
      <BlockDropzone
        onSubmit={onSave}
        items={items}
        setItems={setItems}
        showSave={false}
      />
      <BlockSelectionDrawer open={open} />
    </div>
  );
}

export default LandingPageContentBlocks;
