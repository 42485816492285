import React, { useState } from "react";
import PropTypes from "prop-types";
import {
  alpha,
  Box,
  Button,
  ButtonGroup,
  capitalize,
  IconButton,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@material-ui/core";
import FindInPageIcon from "@material-ui/icons/FindInPage";
import { splitCamel } from "utils/strings";
import { memo } from "react";
import { useMemo } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { usePrevious, useUpdateEffect } from "react-use";

const useStyles = makeStyles((theme) => ({
  root: {
    overflow: "hidden",
    "& .warning": {
      background: alpha(theme.palette.warning.light, 0.4),
    },
  },
  empty: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    minHeight: "300px",
    "& svg": {
      fontSize: 100,
    },
  },
}));

const CustomTable = ({
  title,
  tableHead,
  tableData,
  showHead,
  customColumns,
  actions,
  groupActions,
  rowComponent: RowComponent,
  initialRows,
}) => {
  const previousTableData = usePrevious(tableData);
  const [numberRows, setNumberRows] = useState(initialRows || 20);
  const classes = useStyles();
  const fetchCustom = (column) => {
    if (!customColumns) {
      return null;
    }

    return customColumns.find((col, i) => col.name === column);
  };

  useUpdateEffect(() => {
    if (tableData.length !== previousTableData.length) {
      setNumberRows(initialRows || 20);
    }
  }, [tableData, previousTableData]);

  const rows = useMemo(
    () =>
      tableData
        .map((v) => {
          const keys = Object.keys(v);
          let out = {};
          tableHead.forEach((th) => {
            const idx = keys.indexOf(th);
            if (idx === -1) {
              if ((customColumns || []).map((cc) => cc.name).indexOf(th) >= 0) {
                out[th] = {};
              }
              return;
            } else {
              out[th] = v[th];
            }
          });

          out.ref = v;
          if (keys.includes("className")) {
            out.className = v["className"];
          }
          return out;
        })
        .splice(0, numberRows),
    [tableData, numberRows]
  );

  const actionRender = (row) =>
    actions.map((a, i) => {
      return a.icon && !groupActions ? (
        <IconButton size="small" onClick={() => a.fn(row.ref)} key={i}>
          {a.icon}
        </IconButton>
      ) : a.icon ? (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => a.fn(row.ref)}
          key={i}
        >
          {a.icon}
        </Button>
      ) : a.btnText ? (
        <Button
          variant="outlined"
          color="primary"
          size="small"
          onClick={() => a.fn(row.ref)}
          key={i}
        >
          {a.btnText}
        </Button>
      ) : (
        ""
      );
    });

  return (
    <div className={classes.root} id="infinitescroll">
      {title && (
        <Box p={2} bgcolor="primary.light" color="common.white">
          <Typography variant="h6">{title}</Typography>
        </Box>
      )}
      {!rows.length ? (
        <div className={classes.empty}>
          <FindInPageIcon />
          <Typography variant="h5">No results</Typography>
        </div>
      ) : (
        <InfiniteScroll
          style={{ width: "100%" }}
          height={"calc(100vh - 250px)"}
          dataLength={rows.length}
          next={() => {
            setTimeout(() => {
              setNumberRows(numberRows + 10);
            }, 200);
          }}
          hasMore={rows.length < tableData.length}
          loader={<h4>Loading more 10 rows...</h4>}
          scrollableTarget="infinitescroll"
        >
          <Table>
            {showHead ? (
              <TableHead>
                <TableRow>
                  {tableHead.map((prop, key) => {
                    return (
                      <TableCell key={key}>
                        {capitalize(splitCamel(prop))}
                      </TableCell>
                    );
                  })}
                  {actions && <TableCell></TableCell>}
                </TableRow>
              </TableHead>
            ) : null}
            <TableBody>
              {rows.map((row, key) => {
                if (RowComponent) {
                  return <RowComponent key={row.id || key} row={row} />;
                }
                return (
                  <TableRow key={row.id || key} className={row.className}>
                    {Object.entries(row)
                      .filter((f, k) => Object.keys(row)[k] !== "className")
                      .filter((f) => {
                        return (
                          fetchCustom(f[0]) || !f[1] || typeof f[1] !== "object"
                        );
                      })
                      .map((col, key) => {
                        return (
                          <TableCell key={key} style={{ maxWidth: "400px" }}>
                            {fetchCustom(col[0])
                              ? fetchCustom(col[0]).component(col[1], row.ref)
                              : col[1]}
                          </TableCell>
                        );
                      })}
                    {actions && (
                      <TableCell align="right" style={{ minWidth: "120px" }}>
                        {groupActions ? (
                          <>
                            <ButtonGroup color="primary">
                              {actionRender(row)}
                            </ButtonGroup>
                          </>
                        ) : (
                          actionRender(row)
                        )}
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </InfiniteScroll>
      )}
    </div>
  );
};

const CardTable = (props) => {
  return (
    <Paper>
      <CustomTable {...props} />
    </Paper>
  );
};

CardTable.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  showHead: PropTypes.bool,
  tableHead: PropTypes.arrayOf(PropTypes.string).isRequired,
  tableData: PropTypes.arrayOf(PropTypes.object).isRequired,
  customColumns: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      component: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
    })
  ),
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      icon: PropTypes.element.isRequired,
      fn: PropTypes.func.isRequired,
      btnText: PropTypes.string,
    })
  ),
  rowComponent: PropTypes.oneOfType([PropTypes.element, PropTypes.func]),
  groupActions: PropTypes.bool,
  initialRows: PropTypes.number,
};

CardTable.defaultProps = {
  showHead: true,
};

export default memo(CardTable);
