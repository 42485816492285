import PackagePageWizard from "@components/package/PackagePageWizard";
import Loading from "@components/loading/Loading";
import { Typography } from "@material-ui/core";
import { useGetPackagePage } from "@store/actions/packagePageActions";
import { usePackagePageContext } from "providers/PackagePageProvider";
import React, { useEffect } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

function PackagePagePage() {
  const { packagePageId } = useParams();
  const { loading, data } = useGetPackagePage(packagePageId);
  const { path } = useRouteMatch();
  const { packagePage, setPackagePage } = usePackagePageContext();

  useEffect(() => {
    if (data) {
      setPackagePage({
        ...data.packagePage,
      });
    }
  }, [data, setPackagePage]);

  if (loading) return <Loading />;

  return (
    <>
      <Switch>
        <Route path="/package-pages/:packagePageId/edit">
          <PackagePageWizard />
        </Route>
        <Route path={path}>
          <Typography variant="h1">name : {packagePage.name}</Typography>
        </Route>
      </Switch>
    </>
  );
}

export default PackagePagePage;
