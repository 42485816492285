import { Box, Grid, IconButton, styled } from "@material-ui/core";
import RemoveIcon from "@material-ui/icons/Remove";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

const StyledBox = styled(Box)(({ theme }) => ({
  background: "#e6eff4",
  color: theme.palette.text.primary,
}));

function AttachementRow({ attachment, removeAttachment }) {
  return (
    <StyledBox p={1}>
      <Grid container alignItems="center">
        <Grid item xs={1}>
          <PictureAsPdfIcon />
        </Grid>
        <Grid item xs={9}>
          {attachment.name}
        </Grid>
        <Grid item xs={2} container justify="flex-end">
          <IconButton
            variant="contained"
            color="default"
            size="small"
            onClick={() => removeAttachment(attachment)}
          >
            <RemoveIcon />
          </IconButton>
        </Grid>
      </Grid>
    </StyledBox>
  );
}

export default AttachementRow;
