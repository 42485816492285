import { gql } from "@apollo/client";

export const GET_SITETIMEZONE = gql`
  query Timezone {
    timezone
  }
`;

export const GET_SITEMETA = gql`
  query GetSiteMeta {
    siteMeta {
      id
      contactBlurb
      emailContactBlurb
      ownerEmailContactBlurb
      searchBox
      siteName
      googleConsole
      noIndex
      boatSEOTitleTemplate
      boatSEODescriptionTemplate
      showBoatTypeMenu
      showLocationMenu
      defaultLocation {
        id
        name
      }
      footerContent
      footerSlateJsons
      footerIframeLink
      footerIframeTitle
      footerTermsAndConditionsLink
      manualPayoutManagementEmails
      termsContentPage {
        id
        name
      }
    }
  }
`;

export const UPDATE_SITEMETA = gql`
  mutation UpdateSiteMeta($input: SiteMetaInput!) {
    updateSiteMeta(input: $input) {
      id
      contactBlurb
      emailContactBlurb
      ownerEmailContactBlurb
      searchBox
      siteName
      googleConsole
      noIndex
      boatSEOTitleTemplate
      boatSEODescriptionTemplate
      showBoatTypeMenu
      showLocationMenu
      defaultLocation {
        id
        name
      }
      footerContent
      footerSlateJsons
      footerIframeLink
      footerIframeTitle
      footerTermsAndConditionsLink
      manualPayoutManagementEmails
      termsContentPage {
        id
        name
      }
    }
  }
`;

export const SEARCH_ITEM_TYPE_ENUM = gql`
  query {
    __type(name: "SearchBoxItem") {
      name
      enumValues {
        name
      }
    }
  }
`;
