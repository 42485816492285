import { Grid, makeStyles, Typography } from "@material-ui/core";
import BoatPriceItem from "./BoatPriceItem";

const useStyles = makeStyles((theme) => ({
  root: {
    marginLeft: `-${theme.spacing(4)}px`,
    marginRight: `-${theme.spacing(4)}px`,
    background: theme.palette.background.default,
    padding: theme.spacing(2, 4),
  },
}));

function BoatPriceItems({ items, setItems }) {
  const { root } = useStyles();

  const setItem = (i) => (v) => {
    setItems([
      ...items.map((item, k) => {
        return k === i ? v : item;
      }),
    ]);
  };

  const addItem = (e) => {
    e.stopPropagation();
    setItems([...items, {}]);
  };
  const removeItem = (idx) => (e) => {
    e.stopPropagation();
    let newItems = [...items];
    newItems.splice(idx, 1);
    if (newItems.length === 0) {
      newItems = [{}];
    }
    setItems(newItems);
  };
  return (
    <div className={root}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" gutterBottom>
            You can set different prices per number of hours
          </Typography>
        </Grid>

        <Grid item xs={12}>
          {(items || []).map((item, i) => (
            <BoatPriceItem
              key={i}
              item={item}
              setItem={setItem(i)}
              addItem={addItem}
              removeItem={removeItem(i)}
            />
          ))}
        </Grid>
      </Grid>
    </div>
  );
}

export default BoatPriceItems;
