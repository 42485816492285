import CardTable from "@components/cardTable/CardTable";
import { Button } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";

const GoogleMapsLinkCol = (col) => {
  return (
    <Button variant="outlined" color="primary" size="small">
      <a href={col} target="_blank">
        Click To Open Map
      </a>
    </Button>
  );
};

function WharfTable({ wharfs, onEdit, onDelete }) {
  return (
    <CardTable
      tableHead={["name", "googleMapsLink", "taxablePostCode"]}
      tableData={[...wharfs].sort(
        (a, b) => new Date(a.createdAt) - new Date(b.createdAt)
      )}
      customColumns={[
        {
          name: "googleMapsLink",
          component: GoogleMapsLinkCol,
        },
      ]}
      actions={[
        {
          icon: <Edit color="primary" />,
          fn: onEdit,
        },
        {
          icon: <Delete color="secondary" />,
          fn: onDelete,
        },
      ]}
    />
  );
}

export default WharfTable;
