import CardTable from "@components/cardTable/CardTable";
import { Button, Grid, Typography, Link } from "@material-ui/core";
import {
  useDeleteThumbSection,
  useGetAllThumbSections,
} from "@store/actions/blockActions";
import { useGetAllLandingPages } from "@store/actions/landingPageActions";
import React, { useState, useEffect } from "react";
import ThumbLinkDialog from "./ThumbLinkDialog";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { LandingTile } from "components-library";
import { Link as RouterLink } from "react-router-dom";
import { useLandingTileOrder } from "components-library";

const landingPagesCol = (getOrderedTiles) => (col, row) => {
  const tiles = getOrderedTiles(row);
  return (
    <Grid container spacing={1} style={{ minWidth: 600 }}>
      {tiles.map((landingPage) => (
        <Grid key={landingPage.id} item xs={4}>
          <LandingTile
            title={landingPage.title}
            backgroundImage={
              landingPage.media && landingPage.media.mediaFiles
                ? landingPage.media.mediaFiles[0].path
                : null
            }
            LinkComponent={(props) => (
              <Link
                component={RouterLink}
                {...props}
                to={`/landing-pages/${landingPage.id}/edit`}
              >
                {props.children}
              </Link>
            )}
          />
        </Grid>
      ))}
    </Grid>
  );
};

function ThumbLinksTab() {
  const [thumbLinks, setThumbLinks] = useState([]);
  const [landingPages, setLandingPages] = useState([]);
  const [thumbLink, setThumbLink] = useState({});
  const [open, setOpen] = useState(false);
  const { data } = useGetAllThumbSections();
  const { data: landingData } = useGetAllLandingPages();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [deleteSection] = useDeleteThumbSection();
  const { getOrderedTiles } = useLandingTileOrder();

  useEffect(() => {
    if (data?.thumbLinkSections) {
      setThumbLinks(data?.thumbLinkSections);
    }
  }, [data]);

  useEffect(() => {
    if (landingData?.landingPages) {
      setLandingPages(landingData?.landingPages);
    }
  }, [landingData]);

  const newThumbLink = () => {
    setThumbLink({});
    setOpen(true);
  };

  const onEdit = (e) => {
    setThumbLink(e);
    setOpen(true);
  };

  const onDelete = (ref) => {
    openConfirmation({
      title: `Delete ${ref.name}`,
      body: `Deleting ${ref.name} can not be undone, are you sure you want to continue?`,
      callback: () => onDeleteSection(ref.id),
    });
  };

  const onDeleteSection = async (id) => {
    await deleteSection({
      variables: {
        id,
      },
    });
    closeConfirmation();
  };

  return (
    <Grid container style={{ minWidth: 400 }} spacing={3}>
      <Grid item xs={9}>
        <Typography variant="subtitle1">
          Thumb Link Sections are a list of landing pages to show.
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={newThumbLink}
        >
          New Thumb Link
        </Button>
      </Grid>
      <Grid item xs={12}>
        <CardTable
          tableHead={["name", "landingPages"]}
          tableData={thumbLinks}
          customColumns={[
            {
              name: "landingPages",
              component: landingPagesCol(getOrderedTiles),
            },
          ]}
          actions={[
            {
              icon: <EditIcon color="primary" />,
              fn: onEdit,
            },
            {
              icon: <DeleteIcon color="error" />,
              fn: onDelete,
            },
          ]}
        />
      </Grid>
      <ThumbLinkDialog
        open={open}
        setOpen={setOpen}
        thumbSection={thumbLink}
        setThumbSection={setThumbLink}
        landingPages={landingPages}
      />
    </Grid>
  );
}

export default ThumbLinksTab;
