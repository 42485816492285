import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

function MessageModal({
  open,
  setOpen,
  onSave,
  initialMsg = "",
  title = "Your personalised message",
}) {
  const [message, setMessage] = useState(initialMsg);
  const handleClose = () => {
    setOpen(false);
  };
  const handleSave = (e) => {
    e.preventDefault();
    onSave(message);
  };

  useEffect(() => {
    return () => {
      if (open) {
        setMessage(initialMsg);
      }
    };
  }, [open]);

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
      <form onSubmit={handleSave} style={{ width: 600 }}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            label="Messagee"
            variant="outlined"
            fullWidth
            multiline
            minRows={5}
            maxRows={15}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default MessageModal;
