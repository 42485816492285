import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
} from "@material-ui/core";
import { useCreateLead, useUpdateLead } from "@store/actions/leadActions";
import { ValidatorForm, TextValidator } from "react-material-ui-form-validator";

function LeadModal({ open, setOpen, lead, setLead, onSave }) {
  const [addLead] = useCreateLead();
  const [updateLead] = useUpdateLead();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setLead({
      ...lead,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    const input = {
      firstName: lead.firstName,
      lastName: lead.lastName,
      email: lead.email,
      phone: lead.phone,
    };

    const fn = lead.id
      ? updateLead({
          variables: {
            id: lead.id,
            input,
          },
        })
      : addLead({
          variables: {
            input,
          },
        });

    fn.then((res) =>
      onSave(lead.id ? res.data.updateLead : res.data.createLead)
    );
  };
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <ValidatorForm onSubmit={handleSave}>
        <DialogTitle>Lead</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the leads details below.
          </DialogContentText>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextValidator
                autoFocus
                margin="dense"
                label="First name"
                fullWidth
                value={lead.firstName || ""}
                name="firstName"
                validators={["required"]}
                errorMessages={["first name is required"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                margin="dense"
                label="Last name"
                fullWidth
                value={lead.lastName || ""}
                name="lastName"
                validators={["required"]}
                errorMessages={["last name is required"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                margin="dense"
                label="Email"
                fullWidth
                value={lead.email || ""}
                name="email"
                validators={["required", "isEmail"]}
                errorMessages={["email is required", "email is not valid"]}
                onChange={handleChange}
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                margin="dense"
                label="Phone"
                fullWidth
                value={lead.phone || ""}
                name="phone"
                onChange={handleChange}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default LeadModal;
