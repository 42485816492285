import Loading from "@components/loading/Loading";
import WharfModal from "@components/wharf/WharfModal";
import WharfTable from "@components/wharf/WharfTable";
import { Button, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useDeleteWharf, useGetAllWharfs } from "@store/actions/wharfActions";
import { useState } from "react";

function WharfsPage() {
  const [open, setOpen] = useState(false);
  const [wharf, setWharf] = useState({});
  const { loading, data, error } = useGetAllWharfs();
  const { showNotification } = useNotificationContext();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [deleteWharf, { loading: deleting }] = useDeleteWharf();

  const newWharf = () => {
    setWharf({});
    setOpen(true);
  };

  const onEdit = (wharf) => {
    setWharf(wharf);
    setOpen(true);
  };

  const onDelete = ({ name, id }) => {
    openConfirmation({
      title: `Delete ${name}`,
      body: `Deleting ${name} can not be undone, are you sure you want to continue?`,
      loading: () => deleting,
      callback: () =>
        deleteWharf({
          variables: {
            id,
          },
        }).then(() => {
          showNotification(`${name} Deleted!`);
          closeConfirmation();
        }),
    });
  };

  const onSave = ({ name, id }) => {
    showNotification(`${name} Saved!`);
    setOpen(false);
  };

  if (loading) return <Loading cover={true} />;
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          container
          item
          xs={10}
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={9}>
            <Typography variant="h5">Wharfs</Typography>
            <Typography variant="subtitle1">
              A wharf is where a customer can pickup and dropoff a boat
            </Typography>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={newWharf}
          >
            New Wharf
          </Button>
        </Grid>
        <Grid item xs={10}>
          <WharfTable
            wharfs={data.wharfs}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Grid>
      </Grid>
      <WharfModal
        open={open}
        setOpen={setOpen}
        onSave={onSave}
        wharf={wharf}
        setWharf={setWharf}
      />
    </>
  );
}

export default WharfsPage;
