import {
  CircularProgress,
  Paper,
  Box,
  Grid,
  TextField,
} from "@material-ui/core";
import React, { useMemo } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useUpdateEffect } from "react-use";
import { useSearchItemType } from "@store/actions/siteMetaActions";

function SearchEditForm({ gridSize, onSave, loading, siteMeta, setSiteMeta }) {
  const { data } = useSearchItemType();
  const options = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.__type.enumValues.map((e) => e.name);
  }, [data]);

  useUpdateEffect(() => {
    onSave();
  }, [siteMeta]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <Grid container>
      <Grid item xs={gridSize || 9}>
        <Paper>
          <Box p={2}>
            <Autocomplete
              multiple
              options={options}
              getOptionLabel={(option) => option || ""}
              onChange={(_, v) => {
                setSiteMeta({
                  ...siteMeta,
                  searchBox: v,
                });
              }}
              value={siteMeta?.searchBox || []}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  label="Search Box"
                  helperText="Select what items to show in search box (order matters)"
                  variant="outlined"
                />
              )}
            />
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default SearchEditForm;
