import React from "react";
import { Route, Switch } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import styles from "./style.js";
import Sidebar from "@components/sidebar/Sidebar";
import { useLayoutContext } from "@providers/LayoutProvider.js";
import { useRoutesContext } from "providers/RoutesProvider.js";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { useGetSiteTimezone } from "@store/actions/siteMetaActions";
import moment from "moment-timezone";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { CircularProgress } from "@mui/material";

const useStyles = makeStyles(styles);

const AdminLayout = () => {
  const classes = useStyles();
  const { routes } = useRoutesContext();
  const { contentRight } = useLayoutContext();
  const { data } = useGetSiteTimezone();

  if (!data) {
    return <CircularProgress />;
  }
  moment.tz.setDefault(data.timezone);

  const switchRoutes = (
    <Switch>
      {routes.map((prop, key) => {
        if (prop.layout === "/admin") {
          return (
            <Route
              exact={prop.exact || false}
              path={prop.path}
              component={prop.component}
              key={key}
            />
          );
        }
        return null;
      })}
    </Switch>
  );

  return (
    <LocalizationProvider dateAdapter={AdapterMoment} dateLibInstance={moment}>
      <div className={classes.wrapper}>
        <Sidebar routes={routes} />
        <div className={classes.mainPanel}>
          <div
            className={classes.content}
            style={{ marginRight: contentRight }}
          >
            <div className={classes.container}>{switchRoutes}</div>
          </div>
        </div>
      </div>
    </LocalizationProvider>
  );
};

export default AdminLayout;
