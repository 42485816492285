import { gql } from "@apollo/client";

export const GET_ALL_PENDING_RATINGS = gql`
  query GetAllPendingRatings {
    pendingRatings {
      id
      createdAt
      booking {
        id
        reference
        cruiseDate
      }
      lead {
        id
        firstName
        lastName
        email
      }
      overallRating
      cleanlinessRating
      comfortRating
      professionalismRating
      friendlinessRating
      punctualityRating
      valueRating
      rating
    }
  }
`;

export const VALIDATE_RATING = gql`
  mutation validateRating($id: UUID!, $input: RatingValidationInput!) {
    validateRating(id: $id, input: $input) {
      id
    }
  }
`;
