import {
  Drawer,
  Box,
  Tabs,
  Tab,
  ListItem,
  Paper,
  ListItemAvatar,
  Typography,
} from "@material-ui/core";
import { Code, CropOriginal, DirectionsBoat } from "@material-ui/icons";
import {
  useGetAllBoatSections,
  useGetAllCodeBlocks,
  useGetAllThumbSections,
} from "@store/actions/blockActions";
import { TabPanel } from "pages/bookings/BookingPage";
import React, { useEffect, useState } from "react";
import { useDrag } from "react-dnd";

const DragListItem = ({ item, title, icon }) => {
  const [{ isDragging }, drag] = useDrag({
    item: { type: "block-item", item },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.4 : 1;
  return (
    <ListItem
      ref={drag}
      component={Paper}
      variant="outlined"
      style={{ opacity, marginBottom: 8 }}
    >
      <ListItemAvatar>{icon}</ListItemAvatar>
      {title}
    </ListItem>
  );
};

function BlockSelectionDrawer({ open }) {
  const [tab, setTab] = useState(0);
  const { data } = useGetAllBoatSections();
  const { data: thumbData } = useGetAllThumbSections();
  const { data: codeData } = useGetAllCodeBlocks();
  const [boatSections, setBoatSections] = useState([]);
  const [thumbSections, setThumbSections] = useState([]);

  useEffect(() => {
    if (data?.boatSections) {
      setBoatSections(data?.boatSections);
    }
  }, [data]);

  useEffect(() => {
    if (thumbData?.thumbLinkSections) {
      setThumbSections(thumbData?.thumbLinkSections);
    }
  }, [thumbData]);

  return (
    <Drawer anchor="right" variant="persistent" open={open}>
      <Tabs orientation="horizontal" value={tab} onChange={(_, v) => setTab(v)}>
        <Tab label="Boat Sections" />
        <Tab label="Landing Pages" />
        <Tab label="HTML" />
      </Tabs>
      <TabPanel value={tab} index={0}>
        <Box p={3} style={{ width: 400 }}>
          <Typography gutterBottom>
            Boat Sections are a list of boats to show, these will be show as
            boat cards
          </Typography>
          <br />
          {boatSections.map((bs) => (
            <DragListItem
              key={bs.id}
              item={bs}
              title={bs.name}
              icon={<DirectionsBoat />}
            />
          ))}
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={1}>
        <Box p={3} style={{ width: 400 }}>
          <Typography gutterBottom>
            Thumb Link Sections are a list of landing pages to show.
          </Typography>
          <br />
          {thumbSections.map((t) => (
            <DragListItem
              key={t.id}
              item={t}
              title={t.name}
              icon={<CropOriginal />}
            />
          ))}
        </Box>
      </TabPanel>
      <TabPanel value={tab} index={2}>
        <Box p={3} style={{ width: 400 }}>
          <Typography gutterBottom>
            HTML Blocks are blocks of custom HTML code
            <br /> that can be inserted into the home page.
          </Typography>
          <br />
          {(codeData?.codeBlocks || []).map((c) => (
            <DragListItem key={c.id} item={c} title={c.name} icon={<Code />} />
          ))}
        </Box>
      </TabPanel>
    </Drawer>
  );
}

export default BlockSelectionDrawer;
