import { Drawer, Stack } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import Editor from "react-simple-code-editor";
import { highlight, languages } from "prismjs";
import { Button, Box, Typography } from "@material-ui/core";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import {
  useCreateCodeBlock,
  useUpdateCodeBlock,
} from "@store/actions/blockActions";
import "prism-themes/themes/prism-vsc-dark-plus.css";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useCustomHtmlStyles } from "components-library";

function CodeBlockDrawer({ open, setOpen, block }) {
  const [addBlock] = useCreateCodeBlock();
  const [updateBlock] = useUpdateCodeBlock();
  const [content, setContent] = useState("");
  const [name, setName] = useState("");
  const { showNotification } = useNotificationContext();
  const { wrapper } = useCustomHtmlStyles();

  useEffect(() => {
    if (!block) {
      return;
    }
    setName(block.name || "");
    setContent(block.value || "");
  }, [block]);

  const cancel = useCallback(() => {
    setOpen(false);
  }, []);

  const onSubmit = useCallback(async () => {
    try {
      block?.id
        ? await updateBlock({
            variables: {
              id: block.id,
              input: {
                name,
                value: content,
              },
            },
          })
        : await addBlock({
            variables: {
              input: {
                name,
                value: content,
              },
            },
          });
      showNotification(`${name} Saved!!`);
    } catch (error) {
      showNotification("There was an Internal error, Please try again.");
    }
  }, [content, name, block]);

  return (
    <Drawer
      anchor="right"
      open={open}
      hideBackdrop={true}
      PaperProps={{
        sx: { width: "calc(100% - 280px)" },
      }}
    >
      <Stack sx={{ height: "100%" }} spacing={3}>
        <Box p={2}>
          <ValidatorForm onSubmit={onSubmit}>
            <Stack spacing={3} mb={2}>
              <Stack
                direction="row"
                spacing={4}
                justifyContent={"space-between"}
                alignItems={"flex-start"}
              >
                <TextValidator
                  name="name"
                  label="HTML Block Name"
                  validators={["required"]}
                  variant="outlined"
                  errorMessages={["Block name is required"]}
                  fullWidth
                  value={name || ""}
                  onChange={(e) => setName(e.target.value)}
                  size="small"
                  style={{ minWidth: 400 }}
                />

                <Stack direction="row" spacing={2}>
                  <Button variant="contained" color="primary" type="submit">
                    Save
                  </Button>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={cancel}
                  >
                    Close
                  </Button>
                </Stack>
              </Stack>
              <Typography variant="body2">
                To create grid layouts, wrap your elements in the `.row` class.
                Then, you can use the `.col-1` to `.col-12` classes to define
                the number of columns the elements will occupy.
                <br />
                For mobile devices, you can also utilize the `.col-sm-1` to
                `.col-sm-12` classes to control the column layout.
              </Typography>
            </Stack>
          </ValidatorForm>

          <Box
            sx={{
              height: "40vh",
              overflowX: "scroll",
            }}
          >
            <Editor
              value={content}
              onValueChange={(code) => setContent(code)}
              highlight={(code) => highlight(code, languages.html, "html")}
              padding={10}
              style={{
                fontFamily: '"Fira code", "Fira Mono", monospace',
                fontSize: 14,
                background: "#1e1e1e",
                color: "#a9b7c6",
                minHeight: "100%",
              }}
            />
          </Box>
          <Box
            className={wrapper}
            dangerouslySetInnerHTML={{ __html: content }}
          />
        </Box>
      </Stack>
    </Drawer>
  );
}

export default CodeBlockDrawer;
