import FeatureGroupDropCard from "@components/feature/FeatureGroupDropCard";
import Loading from "@components/loading/Loading";
import {
  Box,
  Button,
  Divider,
  Drawer,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useLayoutContext } from "@providers/LayoutProvider";
import { useLayoutEffect, useMemo, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import FeatureGroupModal from "@components/feature/FeatureGroupModal";
import NewFeatureModal from "@components/feature/NewFeatureModal";
import FeatureSideDropZone from "@components/feature/FeatureSideDropZone";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import {
  useDeleteFeature,
  useDeleteFeatureGroup,
  useGetAllfeatureGroups,
  useGetAllFeatures,
  useUpdateFeature,
  useUpdateFeatureGroup,
} from "@actions/featureActions";
import { useNotificationContext } from "@providers/NotificationProvider";
import { Filter } from "components-library";

const useStyles = makeStyles(() => ({
  sidebar: {
    width: "450px",
  },
}));

const FeaturesPage = () => {
  const { sidebar } = useStyles();
  const { setContentRight } = useLayoutContext();
  const { showNotification } = useNotificationContext();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [open, setOpen] = useState(false);
  const [featureOpen, setFeatureOpen] = useState(false);
  const [featureGroup, setFeatureGroup] = useState({});
  const { loading, data, error } = useGetAllFeatures();
  const [updateFeature] = useUpdateFeature();
  const [
    deleteFeatureGroup,
    { loading: deleteFeatureGroupLoading },
  ] = useDeleteFeatureGroup();
  const [deleteFeature, { loading: deleteFeatureLoading }] = useDeleteFeature();
  const { loading: groupLoading, data: groupData } = useGetAllfeatureGroups();
  const [updateFeatureGroup] = useUpdateFeatureGroup();
  const [features, setFeatures] = useState([]);

  const featureGroupFeatureIds = useMemo(() => {
    if (!groupData) {
      return [];
    }
    return groupData.featureGroups.reduce((previous, current) => {
      const ids = current.features.map((f) => f.id);
      return [...previous, ...ids];
    }, []);
  }, [groupData]);

  const availableFeatures = useMemo(() => {
    if (!data || !featureGroupFeatureIds) {
      return [];
    }
    return (data.features || [])
      .filter((f) => !featureGroupFeatureIds.includes(f.id))
      .sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  }, [data, featureGroupFeatureIds]);

  const orderedFeatureGroups = useMemo(() => {
    if (!groupData) {
      return [];
    }
    return [...groupData.featureGroups].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  }, [groupData]);

  useLayoutEffect(() => {
    setContentRight("450px");
    return () => {
      setContentRight("0");
    };
  }, [setContentRight]);

  const onDrop = (featureGroup, feature) => {
    // check if was moved from another featuregroup
    const featureInGroup = groupData.featureGroups.find((fg) =>
      fg.features.map((f) => f.id).includes(feature.id)
    );
    if (featureInGroup && featureInGroup.id === featureGroup.id) {
      return;
    }

    doUpdate(featureGroup, [
      ...featureGroup.features,
      {
        id: feature.id,
        name: feature.name,
        icon: feature.icon,
      },
    ]);

    if (featureInGroup) {
      // need to remove from this featureGroup
      doUpdate(
        featureInGroup,
        featureInGroup.features.filter((f) => f.id !== feature.id)
      );
    }
  };

  const onUpdateFeatureGroup = (featureGroup) => {
    doUpdate(featureGroup, featureGroup.features);
  };

  const onUpdateFeature = (feature) => {
    updateFeature({
      variables: {
        id: feature.id,
        input: {
          name: feature.name,
          icon: feature.icon,
        },
      },
    }).then(() => {
      showNotification(`${feature.name} Saved!`);
    });
  };

  const onDeleteFeatureGroup = (featureGroup) => {
    openConfirmation({
      title: `Delete ${featureGroup.name}`,
      body: `Deleting ${featureGroup.name} can not be undone, are you sure you want to continue?`,
      loading: deleteFeatureGroupLoading,
      callback: () =>
        deleteFeatureGroup({
          variables: {
            id: featureGroup.id,
          },
        }).then(() => {
          closeConfirmation();
          showNotification(`${featureGroup.name} Deleted!`);
        }),
    });
  };

  const onFeatureDelete = (feature) => {
    openConfirmation({
      title: `Delete ${feature.name}`,
      body: `Deleting ${feature.name} can not be undone, are you sure you want to continue?`,
      loading: deleteFeatureLoading,
      callback: () =>
        deleteFeature({
          variables: {
            id: feature.id,
          },
        }).then(() => {
          closeConfirmation();
          showNotification(`${feature.name} Deleted!`);
        }),
    });
  };

  const onFeatureDrop = (feature) => {
    const featureInGroup = groupData.featureGroups.find((fg) =>
      fg.features.map((f) => f.id).includes(feature.id)
    );
    if (featureInGroup) {
      // need to remove from this featureGroup
      doUpdate(
        featureInGroup,
        featureInGroup.features.filter((f) => f.id !== feature.id)
      );
    }
  };

  const doUpdate = (featureGroup, features) => {
    const input = {
      name: featureGroup.name,
      live: featureGroup.live,
      features: features.map(({ id, name, icon }) => ({
        id,
        name,
        icon,
      })),
    };
    updateFeatureGroup({
      variables: {
        id: featureGroup.id,
        input,
      },
    }).then(() => {
      showNotification(`${featureGroup.name} Saved!`);
    });
  };

  const onNewFeature = () => {
    setFeatureOpen(false);
  };

  if (error) return `Error! ${error.message}`;

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          container
          item
          justify="space-between"
          alignItems="center"
          spacing={3}
        >
          <Grid item xs={8}>
            <Typography variant="h5">Our Features and Groups</Typography>
            <Typography variant="subtitle1">
              Our Boats have set features, these features are added to groups to
              allow filtering based on entire group
            </Typography>
          </Grid>

          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={() => setOpen(true)}
          >
            New Feature Group
          </Button>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={5}>
            <Grid item xs={8}>
              <Grid container spacing={3}>
                {groupLoading ? (
                  <Loading size={40} color="primary" cover={true} />
                ) : (
                  <>
                    {orderedFeatureGroups.map((featureGroup) => (
                      <Grid key={featureGroup.id} item xs={12}>
                        <FeatureGroupDropCard
                          onDrop={onDrop}
                          onDelete={onDeleteFeatureGroup}
                          featureGroup={featureGroup}
                          update={onUpdateFeatureGroup}
                        />
                      </Grid>
                    ))}
                  </>
                )}
              </Grid>
            </Grid>
            <Grid item xs={4}>
              <Typography variant="subtitle1">Preview</Typography>
              <Divider />
              <br />

              <Filter
                groups={orderedFeatureGroups}
                features={features}
                setFeatures={setFeatures}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Drawer
        variant="permanent"
        anchor="right"
        classes={{
          paper: sidebar,
        }}
      >
        <>
          <Box p={2}>
            <Grid container justify="space-between" alignItems="center">
              <Grid>
                <Typography variant="subtitle1">Features</Typography>
              </Grid>
              <Grid>
                <Button variant="outlined" onClick={() => setFeatureOpen(true)}>
                  New Feature
                </Button>
              </Grid>
            </Grid>
          </Box>
          {loading ? (
            <Loading size={40} color="primary" overlay={true} />
          ) : null}
          <FeatureSideDropZone
            features={availableFeatures}
            onDelete={onFeatureDelete}
            onDrop={onFeatureDrop}
            onUpdate={onUpdateFeature}
          />
        </>
      </Drawer>
      <FeatureGroupModal
        open={open}
        setOpen={setOpen}
        featureGroup={featureGroup}
        setFeatureGroup={setFeatureGroup}
      />
      <NewFeatureModal
        open={featureOpen}
        setOpen={setFeatureOpen}
        onSave={onNewFeature}
      />
    </>
  );
};

export default FeaturesPage;
