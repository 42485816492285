import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useBookingContext } from "@providers/BookingProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useGetAllBoats } from "@store/actions/boatActions";
import { useSendChangeToItem } from "@store/actions/bookingActions";
import { useUpdateBookingItemCost } from "@store/actions/quoteActions";
import { useGetAllStaff } from "@store/actions/staffActions";
import { useEffect, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import QuoteItemCost from "./QuoteItemCost";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { produce } from "immer";

function BookingItemModal({ open, setOpen, onSave }) {
  const { bookingItem, setBookingItem, saveBookingItem } = useBookingContext();
  const { loading: boatsLoading, data: boatsData } = useGetAllBoats();
  const { loading: staffLoading, data: staffData } = useGetAllStaff();
  const { showNotification } = useNotificationContext();
  const [quotes, setQuotes] = useState([]);
  const [updateItemCost] = useUpdateBookingItemCost();
  const [sendChanged] = useSendChangeToItem();

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const uniqueQuotes = [
      ...new Set(
        (bookingItem.quoteItems || [])
          .filter((qi) => qi.quote)
          .map((qi) => qi.quote)
      ),
    ]
      .filter((quote) => quote.type !== "additional")
      .map((q) => ({
        ...q,
        quoteItems: bookingItem.quoteItems
          .filter((qi) => qi.quote.id === q.id)
          .map((qi) => ({
            ...qi,
            bookingItem,
          })),
      }));

    setQuotes(uniqueQuotes);
  }, [bookingItem]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    const b = { ...bookingItem };
    switch (name) {
      case "boat":
        const boat = boatsData.boats.find((b) => b.id === value);
        b.boat = boat;
        break;
      case "boatPackage":
        b.boatPackage = { id: value };
        break;
      default:
        b[name] = value;
        break;
    }
    setBookingItem(b);
  };

  const handleItemChange = (quoteID, itemID) => (cost) => {
    const newQuotes = quotes.map((quote) => {
      if (quote.id === quoteID) {
        const quoteItems = quote.quoteItems.map((qi) => {
          if (qi.id === itemID) {
            const costs = qi.costs.map((c) => {
              if (c.id === cost.id) {
                return cost;
              }
              return c;
            });
            return {
              ...qi,
              costs,
            };
          }
          return qi;
        });
        return {
          ...quote,
          quoteItems,
        };
      }
      return quote;
    });
    setQuotes(newQuotes);
  };

  const saveAndInform = async (e) => {
    await handleSave(e);
    await sendChanged({
      variables: {
        id: bookingItem.id,
      },
    });
  };

  const handleSave = async (e) => {
    e.preventDefault();

    // update every quote costs
    const costs = quotes
      .map((quote) => quote.quoteItems.map((qi) => qi.costs))
      .flat(2);

    await Promise.all(
      costs.map(async (cost) => {
        let input = {
          description: cost.description,
          cost: cost.cost,
          commision: cost.commision,
          type: cost.type,
        };
        if (cost.dropOffWharf) {
          input.dropOffWharf = { id: cost.dropOffWharf.id };
        }
        if (cost.pickUpWharf) {
          input.pickUpWharf = { id: cost.pickUpWharf.id };
        }

        await updateItemCost({
          variables: {
            id: cost.id,
            input,
          },
        });
      })
    );

    await saveBookingItem();
    onSave && onSave();
    setOpen(false);
    showNotification(`Booking Item Saved`);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <DialogTitle>Booking Item</DialogTitle>
      <ValidatorForm onSubmit={handleSave}>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {!boatsLoading && boatsData && (
                <TextValidator
                  select
                  fullWidth
                  label="For this boat"
                  value={bookingItem?.boat ? bookingItem.boat.id : ""}
                  name="boat"
                  validators={["required"]}
                  errorMessages={["Boat is required"]}
                  onChange={handleChange}
                  variant="outlined"
                  size="small"
                >
                  {[...boatsData.boats]
                    .sort((a, b) => a.name.localeCompare(b.name))
                    .map((b) => (
                      <MenuItem key={b.id} value={b.id}>
                        {b.name}
                      </MenuItem>
                    ))}
                </TextValidator>
              )}
            </Grid>
            <Grid item xs={6}>
              {!staffLoading && (
                <Autocomplete
                  options={
                    staffData.staffList.map((s) => ({
                      staff: { ...s },
                    })) || []
                  }
                  getOptionLabel={(option) =>
                    `${option.staff.firstName} ${option.staff.lastName} (${option.staff.role})`
                  }
                  onChange={(_, v) => {
                    setBookingItem(
                      produce(bookingItem, (draft) => {
                        draft.skippers = v;
                      })
                    );
                  }}
                  value={bookingItem.skippers || []}
                  fullWidth
                  size="small"
                  multiple
                  margin="dense"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Skippers"
                      size="small"
                      margin="dense"
                    />
                  )}
                />
              )}
            </Grid>
            <Grid item xs={6}>
              {!staffLoading && (
                <Autocomplete
                  options={
                    staffData.staffList.map((s) => ({
                      staff: { ...s },
                    })) || []
                  }
                  getOptionLabel={(option) =>
                    `${option.staff.firstName} ${option.staff.lastName} (${option.staff.role})`
                  }
                  onChange={(_, v) => {
                    setBookingItem(
                      produce(bookingItem, (draft) => {
                        draft.deckies = v;
                      })
                    );
                  }}
                  value={bookingItem.deckies || []}
                  fullWidth
                  size="small"
                  multiple
                  margin="dense"
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="outlined"
                      label="Deckies"
                      size="small"
                      margin="dense"
                    />
                  )}
                />
              )}
            </Grid>
            {quotes.map((quote) => (
              <Grid key={quote.id} item xs={12} container spacing={2}>
                <Grid item xs={12}>
                  From Quote ({quote.reference})
                </Grid>
                {quote.quoteItems.map((qi) => (
                  <Grid key={qi.id} item xs={12} container spacing={3}>
                    {qi.costs.map((cost) => (
                      <QuoteItemCost
                        readOnly={true}
                        item={qi}
                        cost={cost}
                        setCost={handleItemChange(quote.id, qi.id)}
                        key={cost.id}
                      />
                    ))}
                  </Grid>
                ))}
              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Box
            p={2}
            pb={1}
            display="flex"
            justifyContent="space-between"
            style={{ width: "100%" }}
          >
            <Button color="primary" variant="outlined" onClick={saveAndInform}>
              Save And inform about change
            </Button>

            <div>
              <Button onClick={handleClose} color="secondary">
                Cancel
              </Button>
              <Button type="submit" color="primary">
                Save
              </Button>
            </div>
          </Box>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default BookingItemModal;
