import CardTable from "@components/cardTable/CardTable";
import Loading from "@components/loading/Loading";
import StaffModal from "@components/staff/StaffModal";
import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { Add, Delete, Edit } from "@material-ui/icons";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useDeleteStaff, useGetAllStaff } from "@store/actions/staffActions";
import React, { useMemo, useState } from "react";

function StaffListPage() {
  const [search, setSearch] = useState("");
  const { loading, error, data } = useGetAllStaff();
  const [open, setOpen] = useState(false);
  const [staff, setStaff] = useState({});
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showNotification } = useNotificationContext();
  const [deleteStaff] = useDeleteStaff();

  const onEdit = (staff) => {
    setStaff(staff);
    setOpen(true);
  };
  const onDelete = ({ firstName, id }) => {
    openConfirmation({
      title: `Delete ${firstName}`,
      body: `Deleting ${firstName} can not be undone, are you sure you want to continue?`,
      callback: () =>
        deleteStaff({
          variables: {
            id,
          },
        }).then(() => {
          showNotification(`${firstName} Deleted!`);
          closeConfirmation();
        }),
    });
  };

  const filteredStaff = useMemo(() => {
    if (!data) {
      return [];
    }
    return data.staffList.filter(
      (s) =>
        s.firstName.toLowerCase().includes(search.toLowerCase()) ||
        s.lastName.toLowerCase().includes(search.toLowerCase()) ||
        s.email.toLowerCase().includes(search.toLowerCase())
    );
  }, [data, search]);

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      justify="space-between"
      alignItems="center"
    >
      <Grid item xs={5}>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Typography variant="h5" component="span" display="inline">
              Boat Staff
            </Typography>
          </Grid>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={() => {
            setStaff({});
            setOpen(true);
          }}
        >
          New boat staff
        </Button>
      </Grid>
      <Grid item xs={12}>
        <CardTable
          tableHead={["firstName", "lastName", "email", "role"]}
          tableData={filteredStaff}
          actions={[
            {
              icon: <Edit color="primary" />,
              fn: onEdit,
            },
            {
              icon: <Delete color="error" />,
              fn: onDelete,
            },
          ]}
        />
      </Grid>
      <StaffModal
        open={open}
        setOpen={setOpen}
        staff={staff}
        setStaff={setStaff}
      />
    </Grid>
  );
}

export default StaffListPage;
