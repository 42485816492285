import LandingPagesTable from "@components/landing/LandingPagesTable";
import NewLandingModal from "@components/landing/NewLandingModal";
import Loading from "@components/loading/Loading";
import { Button, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useGetAllLandingPages } from "@store/actions/landingPageActions";
import LandingPageProvider from "providers/LandingPageProvider";
import { useNotificationContext } from "providers/NotificationProvider";
import React, { useCallback, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import LandingPagePage from "./LandingPagePage";

function LandingPagesPage() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { showNotification } = useNotificationContext();
  const { loading, data, error } = useGetAllLandingPages();

  const onSave = useCallback(
    ({ name, id }) => {
      showNotification(`${name} Created!`);
      setOpen(false);
      history.push(`/landing-pages/${id}/edit`);
    },
    [showNotification, history]
  );

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  return (
    <LandingPageProvider>
      <Switch>
        <Route path="/landing-pages/:landingPageId">
          <LandingPagePage />
        </Route>
        <Route exact path={path}>
          <Grid container spacing={3}>
            <Grid
              container
              item
              xs={10}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={9}>
                <Typography variant="h5">Landing pages</Typography>
                <Typography variant="subtitle1">
                  Landing pages are pages that will appear in the web portal. A
                  landing page might be something like "Sailing" and my default
                  incude all boats that have a feature of sailing
                </Typography>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setOpen(true)}
              >
                New Landing page
              </Button>
            </Grid>
            <Grid item xs={10}>
              <LandingPagesTable landingPages={data.landingPages} />
            </Grid>
          </Grid>
        </Route>
      </Switch>

      <NewLandingModal open={open} setOpen={setOpen} onSave={onSave} />
    </LandingPageProvider>
  );
}

export default LandingPagesPage;
