import { Typography, TextField, Grid } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

function LandingPageLocationSelect({ locations, parent, setParent }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="subtitle1">
          Select a location for this landing page
        </Typography>
      </Grid>
      <Grid item xs={6}>
        <Autocomplete
          options={locations || []}
          getOptionLabel={(option) => option.name || ""}
          onChange={(_, location) => {
            setParent({
              ...parent,
              location: location || {},
            });
          }}
          value={parent.location}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              variant="standard"
              label="Selected Location"
              variant="outlined"
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

export default LandingPageLocationSelect;
