import { useMutation, useQuery } from "@apollo/client";
import { useImperativelyCallQuery } from "./utils";
import {
  CREATE_MEDIA,
  DELETE_MEDIA,
  GET_ALL_MEDIA,
  GET_MEDIA,
  GET_PRESIGNED_URL,
} from "@operations/mediaOperations";

export const useImperativeGetPresignedURL = () =>
  useImperativelyCallQuery(GET_PRESIGNED_URL);

export const useGetAllMedia = () => useQuery(GET_ALL_MEDIA);
export const useGetMedia = () => useQuery(GET_MEDIA);

export const useImperativeGetMedia = () => useImperativelyCallQuery(GET_MEDIA);

export const useCreateMedia = () =>
  useMutation(CREATE_MEDIA, {
    update: (cache, { data: { createMedia } }) => {
      const data = cache.readQuery({
        query: GET_ALL_MEDIA,
      });
      const media = data ? data.medias : [];
      cache.writeQuery({
        query: GET_ALL_MEDIA,
        data: {
          medias: [...media, createMedia],
        },
      });
    },
  });

export const useDeleteMedia = () => {
  return useMutation(DELETE_MEDIA, {
    update: (cache, { data: { deleteMedia } }) => {
      const data = cache.readQuery({
        query: GET_ALL_MEDIA,
      });
      const media = data ? data.medias : [];

      cache.writeQuery({
        query: GET_ALL_MEDIA,
        data: {
          medias: [...media.filter((m) => m.id !== deleteMedia.id)],
        },
      });
    },
  });
};
