import { FormControlLabel, Grid, TextField, Switch } from "@material-ui/core";
import { useBoatContext } from "providers/BoatProvider";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";

const BoatInfoForm = ({ owners, layouts }) => {
  const { boat, setBoat } = useBoatContext();
  const handleChange = (e) => {
    setBoat({
      ...boat,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <TextField
            name="name"
            onChange={handleChange}
            value={boat.name}
            fullWidth
            size="small"
            label="Name"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="oneLiner"
            onChange={handleChange}
            value={boat.oneLiner || ""}
            fullWidth
            size="small"
            label="One liner"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="cardExtraOne"
            onChange={handleChange}
            value={boat.cardExtraOne || ""}
            fullWidth
            size="small"
            label="Card Extra Info 1"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="cardExtraTwo"
            onChange={handleChange}
            value={boat.cardExtraTwo || ""}
            fullWidth
            size="small"
            label="Card Extra Info 2"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <TextField
            name="description"
            onChange={handleChange}
            value={boat.description || ""}
            label="Description"
            fullWidth
            multiline
            minRows={3}
            maxRows={30}
            size="small"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="capacity"
            onChange={handleChange}
            value={boat.capacity || ""}
            fullWidth
            type="number"
            size="small"
            label="Capacity"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="minimumHire"
            onChange={handleChange}
            value={boat.minimumHire || ""}
            fullWidth
            type="number"
            size="small"
            label="Minimum hire"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={owners}
            fullWidth
            size="small"
            value={boat.owner || {}}
            getOptionLabel={(option) =>
              option.firstName ? `${option.firstName} - (${option.email})` : ""
            }
            onChange={(_, v) => {
              setBoat({
                ...boat,
                owner: v,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="Select owner"
                variant="outlined"
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item xs={6}>
          <Autocomplete
            options={layouts}
            fullWidth
            size="small"
            value={boat.boatPageLayout || {}}
            getOptionLabel={(option) => option.name}
            onChange={(_, v) => {
              setBoat({
                ...boat,
                boatPageLayout: v,
              });
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="Select Layout"
                variant="outlined"
              />
            )}
          ></Autocomplete>
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="videoLink"
            onChange={handleChange}
            value={boat.videoLink || ""}
            fullWidth
            size="small"
            label="Link To Video"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                checked={boat.archived || false}
                onChange={(_, v) =>
                  setBoat({
                    ...boat,
                    archived: v,
                  })
                }
                color="primary"
              />
            }
            label="Boat is Archived?"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                name="showContact"
                checked={boat.showContact || false}
                onChange={(_, v) =>
                  setBoat({
                    ...boat,
                    showContact: v,
                  })
                }
                color="primary"
              />
            }
            label="Show Contact Form"
          />
        </Grid>
        <Grid item xs={12}>
          <FormControlLabel
            control={
              <Switch
                name="skipperRequired"
                checked={boat.skipperRequired || false}
                onChange={(_, v) =>
                  setBoat({
                    ...boat,
                    skipperRequired: v,
                  })
                }
                color="primary"
              />
            }
            label="Requires Skipper"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            name="googleCalendar"
            onChange={handleChange}
            value={boat.googleCalendar || ""}
            fullWidth
            size="small"
            label="Google Calander Address"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={
              <Switch
                name="googleCalendarWrite"
                checked={boat.googleCalendarWrite || false}
                onChange={(_, v) =>
                  setBoat({
                    ...boat,
                    googleCalendarWrite: v,
                  })
                }
                color="primary"
              />
            }
            label="Allow writing to Google Calander"
          />
        </Grid>
      </Grid>
    </form>
  );
};

export default BoatInfoForm;
