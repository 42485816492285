import LeadModal from "@components/lead/LeadModal";
import LeadsTable from "@components/lead/LeadsTable";
import Loading from "@components/loading/Loading";
import { Button, Grid, Typography } from "@material-ui/core";
import { CloudDownload } from "@material-ui/icons";
import AddIcon from "@material-ui/icons/Add";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useDeleteLead, useGetAllLeads } from "@store/actions/leadActions";
import { APIURL } from "index";
import { useCallback, useState } from "react";
import { Route, Switch, useRouteMatch } from "react-router-dom";
import { getAuthToken } from "utils/helpers";

function LeadsPage() {
  const { path } = useRouteMatch();
  const [open, setOpen] = useState(false);
  const [lead, setLead] = useState({});
  const { loading, error, data } = useGetAllLeads();
  const { showNotification } = useNotificationContext();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [deleteLead, { loading: deleting }] = useDeleteLead();

  const newLead = useCallback(() => {
    setLead({});
    setOpen(true);
  }, []);

  const onEdit = useCallback((lead) => {
    setLead(lead);
    setOpen(true);
  }, []);

  const onDelete = ({ firstName, id }) => {
    openConfirmation({
      title: `Delete ${firstName}`,
      body: `Deleting ${firstName} can not be undone, are you sure you want to continue?`,
      loading: () => deleting,
      callback: () =>
        deleteLead({
          variables: {
            id,
          },
        }).then(() => {
          showNotification(`${firstName} Deleted!`);
          closeConfirmation();
        }),
    });
  };

  const onSave = useCallback(
    ({ firstName }) => {
      showNotification(`${firstName} Saved!`);
      setOpen(false);
    },
    [showNotification]
  );

  const exportLeads = useCallback(async () => {
    try {
      const response = await fetch(`${APIURL}/export-leads`, {
        method: "get",
        headers: new Headers({
          Authorization: `Bearer ${getAuthToken().user}`,
          "Content-Type": "text/csv",
          Accept: "text/csv",
        }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const a = document.createElement("a");
      a.href = url;
      a.download = "leads.csv";
      document.body.appendChild(a);
      a.click();
      a.remove();
    } catch (error) {
      console.error(error);
    }
  }, []);

  if (loading) return <Loading cover={true} />;
  if (error) return <div>{JSON.stringify(error)}</div>;

  return (
    <>
      <Switch>
        <Route path="/leads/:leadId">-- TODO --</Route>
        <Route exact path={path}>
          <Grid container spacing={3}>
            <Grid
              container
              item
              xs={10}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={9}>
                <Typography variant="h5">Leads</Typography>
                <Typography variant="subtitle1">
                  Leads are our customers, They will generally come from the
                  main website but you can create them manually aswell
                </Typography>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={newLead}
              >
                New Lead
              </Button>
              <Button
                variant="contained"
                color="primary"
                startIcon={<CloudDownload />}
                onClick={exportLeads}
              >
                Export Leads
              </Button>
            </Grid>
            <Grid item xs={10}>
              <LeadsTable
                leads={data.leads}
                onEdit={onEdit}
                onDelete={onDelete}
              />
            </Grid>
          </Grid>
        </Route>
      </Switch>

      <LeadModal
        open={open}
        setOpen={setOpen}
        onSave={onSave}
        lead={lead}
        setLead={setLead}
      />
    </>
  );
}

export default LeadsPage;
