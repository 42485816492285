import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { friendlyDateTime } from "utils/date";

const useStyles = makeStyles(({ mixins }) => ({
  longCol: {
    maxWidth: "180px",
    ...mixins.truncate,
  },
}));

const BoatPackagesTable = ({ packages, onEdit, onDelete }) => {
  const { longCol } = useStyles();
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Description</TableCell>
            <TableCell>Info</TableCell>
            <TableCell>Price</TableCell>
            <TableCell>Created at</TableCell>
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {[...packages]
            .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
            .map((p) => (
              <TableRow key={p.id}>
                <TableCell>{p.name}</TableCell>
                <TableCell className={longCol}>{p.description}</TableCell>
                <TableCell>{p.info}</TableCell>
                <TableCell>{p.price}</TableCell>
                <TableCell>{friendlyDateTime(p.createdAt)}</TableCell>
                <TableCell>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => onEdit(p)}
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => onDelete(p)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BoatPackagesTable;
