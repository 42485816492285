import {
  Box,
  Button,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  List,
  makeStyles,
  Paper,
  Switch,
  TextField,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { useDrop } from "react-dnd";
import classNames from "classnames";
import FeatureDragCard from "./FeatureDragCard";
import { useState } from "react";

const useStyles = makeStyles(({ palette }) => ({
  root: {
    position: "relative",
  },
  dropzone: {
    minHeight: "80px",
  },
  hovered: {
    background: palette.action.hover,
  },
  remove: {
    position: "absolute",
    top: 0,
    right: 0,
    transform: "translate(20%, -20%)",
  },
}));

const FeatureGroupDropCard = ({ featureGroup, onDrop, update, onDelete }) => {
  const { root, dropzone, hovered, remove } = useStyles();
  const [localName, setLocalName] = useState(featureGroup.name);
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "FetaureDragCard",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: ({ feature }) => {
      onDrop(featureGroup, feature);
    },
  });
  const isActive = canDrop && isOver;

  return (
    <Paper className={root}>
      <Box p={2}>
        <Grid container alignItems="flex-end" spacing={2}>
          <Grid item xs={6}>
            <TextField
              margin="dense"
              label="Feature Group Name"
              fullWidth
              value={localName || ""}
              onChange={(e) => setLocalName(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      size="small"
                      color="secondary"
                      onClick={(e) => {
                        update({ ...featureGroup, name: localName });
                      }}
                    >
                      <SaveIcon />
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <FormControlLabel
              label="Live"
              control={
                <Switch
                  checked={featureGroup.live}
                  onChange={(e) => {
                    update({ ...featureGroup, live: e.target.checked });
                  }}
                  name="live"
                />
              }
            />
          </Grid>

          <Button
            variant="contained"
            className={remove}
            size="small"
            color="secondary"
            onClick={() => onDelete(featureGroup)}
          >
            <DeleteIcon />
          </Button>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <div
              className={classNames(dropzone, {
                [hovered]: isActive,
              })}
              ref={drop}
            >
              <List dense={true}>
                {(featureGroup.features || []).map((feature) => (
                  <Grid key={feature.id} item xs={12}>
                    <FeatureDragCard feature={feature} />
                  </Grid>
                ))}
              </List>
            </div>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};

export default FeatureGroupDropCard;
