import {
  Box,
  CircularProgress,
  Divider,
  Fab,
  FormControlLabel,
  Grid,
  List,
  makeStyles,
  Paper,
  Switch,
  TextField,
  Button,
  Typography,
} from "@material-ui/core";
import { useMemo, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import ChatItem from "./ChatItem";
import Alert from "@material-ui/lab/Alert";
import Autocomplete from "@material-ui/lab/Autocomplete";

const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 650,
  },
  chatSection: {
    width: "100%",
    height: "100vh",
  },
  innerChat: {
    maxHeight: "100%",
    display: "flex",
    flexDirection: "column",
  },
  messageArea: {
    height: "100%",
    overflowY: "auto",
    background: theme.palette.grey[100],
  },
}));

function Chat({
  messages,
  onSend,
  open,
  mode,
  setMode,
  owners,
  owner,
  setOwner,
  sendSms,
  setSendSms,
}) {
  const classes = useStyles();
  const [message, setMessage] = useState("");

  const orderedMessages = useMemo(() => {
    if (!messages) {
      return [];
    }
    return [...messages].sort(
      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
    );
  }, [messages]);

  const handleSend = () => {
    onSend(message);
    setMessage("");
  };

  if (open !== undefined && !open) {
    return <CircularProgress />;
  }
  return (
    <ValidatorForm onSubmit={handleSend}>
      <Grid container component={Paper} className={classes.chatSection}>
        <Grid item xs={12} className={classes.innerChat}>
          <List className={classes.messageArea}>
            {orderedMessages.map((m) => (
              <ChatItem message={m} key={m.id} />
            ))}
          </List>
          {mode === "lead" ? (
            <Alert severity="info">
              You are talking to the booking lead right now.
            </Alert>
          ) : (
            <Alert severity="info">
              You are talking to the Boat owner right now.
            </Alert>
          )}
          <Box
            px={2}
            style={{ height: 80, alignItems: "center" }}
            display="flex"
          >
            <Grid item xs={6}>
              <FormControlLabel
                control={
                  <Switch
                    checked={mode === "lead"}
                    onChange={(_, v) =>
                      setMode(mode === "lead" ? "owner" : "lead")
                    }
                    color="primary"
                  />
                }
                label={`Switch conversation`}
              />
            </Grid>
            <Grid item xs={6}>
              {mode === "owner" && (
                <Autocomplete
                  options={owners}
                  fullWidth
                  size="small"
                  value={owner || {}}
                  getOptionLabel={(option) =>
                    option.firstName
                      ? `${option.firstName} - (${option.email})`
                      : ""
                  }
                  onChange={(_, v) => {
                    setOwner(v);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      margin="none"
                      label="Select owner"
                      variant="outlined"
                    />
                  )}
                />
              )}
            </Grid>
          </Box>
          <Divider />
          <Box p={2}>
            <TextValidator
              variant="outlined"
              label="Type Message"
              fullWidth
              multiline
              validators={["required"]}
              errorMessages={["Message must not be blank"]}
              minRows={3}
              maxRows={6}
              value={message}
              onChange={(e) => setMessage(e.target.value)}
            />
          </Box>
          <Box
            pr={2}
            mb={1}
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Box mr={2}>
              <FormControlLabel
                size="small"
                labelPlacement="start"
                control={
                  <Switch
                    size="small"
                    checked={sendSms}
                    onChange={(_, checked) => setSendSms(checked)}
                    color="primary"
                  />
                }
                label={<Typography variant="body2">Send SMS</Typography>}
              />
            </Box>

            <Button color="primary" type="submit">
              Send
            </Button>
          </Box>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

export default Chat;
