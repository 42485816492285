import {
  FormControlLabel,
  Checkbox,
  Grid,
  Typography,
  Button,
  makeStyles,
  Switch,
  Box,
} from "@material-ui/core";
import { useState } from "react";
import NewFeatureModal from "@components/feature/NewFeatureModal";
import { useGroupByFeatureGroup } from "components-library";
import { produce } from "immer";

const useStyles = makeStyles((theme) => ({
  group: {
    marginTop: theme.spacing(2),
  },
  item: {
    "& .MuiButtonBase-root": {
      paddingTop: theme.spacing(0.5),
      paddingBottom: theme.spacing(0.5),
    },
  },
}));

const FeaturesSelection = ({
  features,
  parent,
  setParent,
  title,
  allowGroupSelection = false,
}) => {
  const [open, setOpen] = useState(false);
  const { group, item } = useStyles();
  const { group: groupedFeatures } = useGroupByFeatureGroup(features);

  const getParentFeatureIndex = (feature) =>
    (parent.features || []).map((f) => f.id).indexOf(feature.id);

  const isChecked = (feature) => {
    return getParentFeatureIndex(feature) >= 0;
  };
  const handleChange = (feature) => {
    const idx = getParentFeatureIndex(feature);
    let features = [...(parent.features || [])];

    if (idx === -1) {
      features.push(feature);
    } else {
      features.splice(idx, 1);
    }
    setParent({
      ...parent,
      features,
    });
  };

  const onNewFeature = (feature) => {
    handleChange(feature);
    setOpen(false);
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="baseline">
            <Typography variant="subtitle1">{title}</Typography>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Add new feature
            </Button>{" "}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {(groupedFeatures || []).map((fg, i) => (
              <Grid key={fg.name} item xs={12} className={i > 0 ? group : null}>
                <Box mb={2}>
                  {(allowGroupSelection && fg.id && (
                    <FormControlLabel
                      control={
                        <Switch
                          size="small"
                          checked={
                            parent.featureGroups.findIndex(
                              (f) => f.id === fg.id
                            ) >= 0
                          }
                          onChange={() => {
                            return (
                              fg.features.filter(isChecked).length > 0 ||
                              setParent(
                                produce(parent, (draft) => {
                                  const idx = draft.featureGroups.findIndex(
                                    (f) => f.id === fg.id
                                  );

                                  if (idx >= 0) {
                                    draft.featureGroups.splice(idx, 1);
                                  } else {
                                    draft.featureGroups.push(fg);
                                  }
                                })
                              )
                            );
                          }}
                          color="primary"
                        />
                      }
                      label={`Show ${fg.name}`}
                    />
                  )) || (
                    <Typography variant="subtitle1" display="inline">
                      {fg.name}
                    </Typography>
                  )}{" "}
                  {fg.name === "other"
                    ? "(Not assigned)"
                    : !fg.live && (
                        <Typography variant="caption" color="textSecondary">
                          (Feature Group is not live so will not appear on
                          search page)
                        </Typography>
                      )}
                </Box>
                <Grid container>
                  {(fg.features || []).map((f) => (
                    <Grid key={f.id} item xs={4} className={item}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            checked={isChecked(f)}
                            onChange={() => handleChange(f)}
                            name={f.id}
                          />
                        }
                        label={f.name}
                      />
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <NewFeatureModal open={open} setOpen={setOpen} onSave={onNewFeature} />
    </>
  );
};

export default FeaturesSelection;
