import BookingModal from "@components/booking/BookingModal";
import BookingsTable from "@components/booking/BookingsTable";
import Loading from "@components/loading/Loading";
import { useBookingContext } from "@providers/BookingProvider";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import {
  useDeleteBooking,
  useGetAllBookings,
} from "@store/actions/bookingActions";
import { memo } from "react";
import { useCallback, useEffect, useState } from "react";
import { Route, Switch, useRouteMatch, useLocation } from "react-router-dom";
import { useUpdateEffect } from "react-use";
import BookingPage from "./BookingPage";

function BookingsPage() {
  const { path } = useRouteMatch();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [poll, setPoll] = useState(true);
  const { setBooking, setBookings, setItemInput } = useBookingContext();
  const { loading, error, data, startPolling, stopPolling } = useGetAllBookings(
    10000
  ); // update every 10 seconds
  const { showNotification } = useNotificationContext();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [deleteBooking, { loading: deleting }] = useDeleteBooking();

  useEffect(() => {
    if (data?.bookings) {
      setBookings(data.bookings);
    }
  }, [data]);

  useUpdateEffect(() => {
    if (poll) {
      startPolling();
    } else {
      stopPolling();
    }
  }, [poll]);

  useEffect(() => {
    if (location.pathname === "/bookings") {
      setPoll(true);
    } else {
      setPoll(false);
    }
  }, [location]);

  const newBooking = useCallback(() => {
    setBooking({});
    setItemInput({});
    setOpen(true);
  }, [setBooking]);

  const onEdit = useCallback(
    (booking) => {
      setBooking(booking);
      setOpen(true);
    },
    [setBooking]
  );

  const onDelete = useCallback(
    ({ reference, id }) => {
      openConfirmation({
        title: `Delete ${reference}`,
        body: `Deleting ${reference} can not be undone, are you sure you want to continue?`,
        loading: () => deleting,
        callback: () =>
          deleteBooking({
            variables: {
              id,
            },
          }).then(() => {
            showNotification(`${reference} Deleted!`);
            closeConfirmation();
          }),
      });
    },
    [deleting]
  );

  const onSave = useCallback(
    ({ reference }) => {
      showNotification(`${reference} Saved!`);
      setOpen(false);
    },
    [showNotification]
  );

  if (loading) return <Loading cover={true} />;
  if (error) return <div>{JSON.stringify(error)}</div>;

  return (
    <>
      <Switch>
        <Route path="/bookings/:bookingId">
          <BookingPage />
        </Route>
        <Route exact path={path}>
          <BookingsTable
            onEdit={onEdit}
            onDelete={onDelete}
            onNew={newBooking}
            poll={poll}
            setPoll={setPoll}
          />
        </Route>
      </Switch>

      <BookingModal open={open} setOpen={setOpen} onSave={onSave} />
    </>
  );
}

export default memo(BookingsPage);
