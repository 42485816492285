import React, { createContext, useContext, useMemo, useState } from "react";

export const ContentPageContext = createContext(null);
export const useContentPageContext = () => useContext(ContentPageContext);

const ContentPageProvider = ({ children }) => {
  const [contentPage, setContentPage] = useState({});

  const state = useMemo(
    () => ({
      contentPage,
      setContentPage,
    }),
    [contentPage]
  );

  return (
    <ContentPageContext.Provider value={state}>
      {children}
    </ContentPageContext.Provider>
  );
};

export default ContentPageProvider;
