const getJsonIndented = (obj) =>
  JSON.stringify(obj, null, 4).replace(/["{[,\}\]]/g, "");

function JsonDisplayer({ children }) {
  return (
    <div>
      <pre>{JSON.stringify(children, null, 2)}</pre>
    </div>
  );
}

export default JsonDisplayer;
