import Steps from "@components/Steps";
import {
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { usePackagePageContext } from "providers/PackagePageProvider";
import React, { useCallback, useEffect, useState } from "react";
import TitleIcon from "@material-ui/icons/Title";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import NavigateNext from "@material-ui/icons/NavigateNext";
import ImportantDevices from "@material-ui/icons/ImportantDevices";
import { useUpdatePackagePage } from "@store/actions/packagePageActions";
import Editor from "@components/editor/Editor";
import { useUpdateEffect } from "react-use";
import { useGetSiteMeta } from "@store/actions/siteMetaActions";
import PackagePageSEO from "./PackagePageSEO";
import PackagePageForm from "./PackagPageForm";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useGetAllBoats } from "@store/actions/boatActions";
import { useMemo } from "react";
import { Hero, PackageCard } from "components-library";

function PackagePageWizard() {
  const { packagePage, setPackagePage } = usePackagePageContext();
  const [updatePackagePage, { loading }] = useUpdatePackagePage();
  const { data: meta } = useGetSiteMeta();
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [valid, setValid] = useState(true);
  const [topContent, setTopContent] = useState(null);
  const [lowerContent, setLowerContent] = useState(null);
  const { data: boatsData } = useGetAllBoats();

  const featuredImg = useMemo(() => {
    return packagePage.media && packagePage.media.mediaFiles
      ? packagePage.media.mediaFiles[0].path
      : null;
  }, [packagePage]);

  useUpdateEffect(() => {
    setTopContent(
      packagePage.slateJsonTopContent
        ? JSON.parse(packagePage.slateJsonTopContent)
        : null
    );
    setLowerContent(
      packagePage.slateJsonLowerContent
        ? JSON.parse(packagePage.slateJsonLowerContent)
        : null
    );
  }, [packagePage.id]);

  useEffect(() => {
    setPackagePage({
      ...packagePage,
      slateJsonTopContent: JSON.stringify(topContent),
    });
  }, [topContent]);

  useEffect(() => {
    setPackagePage({
      ...packagePage,
      slateJsonLowerContent: JSON.stringify(lowerContent),
    });
  }, [lowerContent]);

  const boatPackages = useMemo(() => {
    return (boatsData?.boats || [])
      .filter((b) => (b.boatPackages || []).length > 0)
      .map((boat) =>
        boat.boatPackages.map((p) => ({
          boat,
          ...p,
        }))
      )
      .flat();
  }, [boatsData]);

  const getBoatByPackageId = useCallback(
    (packageId) => {
      for (let i = 0; i < boatsData?.boats.length; i++) {
        const boat = boatsData?.boats[i];
        for (let idx = 0; idx < boat.boatPackages.length; idx++) {
          const bp = boat.boatPackages[idx];
          if (bp.id === packageId) {
            return bp;
          }
        }
      }
    },
    [boatsData]
  );

  const handleUpdate = useCallback(
    (packagePage) => {
      let input = {
        name: packagePage.name,
        title: packagePage.title,
        oneLiner: packagePage.oneLiner,
        description: packagePage.description,
        seoTitle: packagePage.seoTitle,
        seoDescription: packagePage.seoDescription,
        path: packagePage.path,
        topContent: packagePage.topContent,
        slateJsonTopContent: packagePage.slateJsonTopContent,
        lowerContent: packagePage.lowerContent,
        slateJsonLowerContent: packagePage.slateJsonLowerContent,
        boatPackages: packagePage.boatPackages.map((fg) => ({ id: fg.id })),
        media:
          packagePage.media && packagePage.media.id
            ? {
                id: packagePage.media.id,
              }
            : null,
      };

      return updatePackagePage({
        variables: {
          id: packagePage.id,
          input,
        },
      });
    },
    [updatePackagePage]
  );

  useEffect(() => {
    setSteps([
      {
        label: "Head Section",
        component: (
          <PackagePageForm handleUpdate={handleUpdate} setValid={setValid} />
        ),
        icon: <TitleIcon fontSize="inherit" />,
      },
      {
        label: "Top Additional content",
        component: (
          <Editor
            key="up"
            msg={topContent}
            setMsg={setTopContent}
            html={packagePage.topContent}
            setHtml={(topContent) => {
              setPackagePage({
                ...packagePage,
                topContent,
              });
            }}
          />
        ),
        icon: <LineStyleIcon fontSize="inherit" />,
      },
      {
        label: "Link Packages",
        component: (
          <>
            <Autocomplete
              multiple
              options={boatPackages}
              getOptionLabel={(bp) => bp.name || ""}
              groupBy={(bp) => bp.boat.name}
              onChange={(_, v) => {
                setPackagePage({
                  ...packagePage,
                  boatPackages: v.map((bp) => bp),
                });
              }}
              value={packagePage.boatPackages}
              fullWidth
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Boat Packages"
                  helperText="Select what Boat Packages to show."
                />
              )}
            />
          </>
        ),
        icon: <ImportantDevices fontSize="inherit" />,
      },
      {
        label: "Lower Additional content",
        component: (
          <Editor
            key="down"
            msg={lowerContent}
            setMsg={setLowerContent}
            html={packagePage.lowerContent}
            setHtml={(lowerContent) => {
              setPackagePage({
                ...packagePage,
                lowerContent,
              });
            }}
          />
        ),
        icon: <LineStyleIcon fontSize="inherit" />,
      },
      {
        label: "SEO",
        component: <PackagePageSEO />,
        icon: <HelpOutlineIcon fontSize="inherit" />,
      },
    ]);
  }, [packagePage, setPackagePage, handleUpdate, boatPackages]);

  const handleSave = () => {
    handleUpdate(packagePage).then(() => {
      setActiveStep(activeStep + 1);
    });
  };

  const isStepComplete = (i) => {
    return i < activeStep;
  };

  const handleStep = (i) => {
    setActiveStep(i);
  };

  return (
    <>
      <Grid item xs={12}>
        <Hero
          title={packagePage.title}
          subTitle={packagePage.oneLiner}
          description={packagePage.description}
          backgroundImage={featuredImg}
        />
      </Grid>
      <Grid item container spacing={4}>
        <Grid item xs={9}>
          <Paper>
            <Steps
              steps={steps}
              activeStep={activeStep}
              isStepComplete={isStepComplete}
              handleStep={handleStep}
              next={
                <Button
                  disabled={loading || !valid}
                  variant="contained"
                  color="primary"
                  endIcon={
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <NavigateNext />
                    )
                  }
                  onClick={handleSave}
                >
                  Save & next
                </Button>
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" gutterBottom>
            Example Card from 1st links package
          </Typography>
          {packagePage.boatPackages && packagePage.boatPackages.length > 0 && (
            <PackageCard
              boatPackage={packagePage.boatPackages[0]}
              boat={getBoatByPackageId(packagePage.boatPackages[0].id)}
              useNoneGatsbyImage
              onClick={() => {}}
            />
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default PackagePageWizard;
