import {
  Box,
  Button,
  IconButton,
  TextField,
  Typography,
} from "@material-ui/core";
import { Remove } from "@material-ui/icons";
import { produce } from "immer";
import { useState } from "react";
import { useUpdateEffect } from "react-use";

function ManualPayoutSettings({ siteMeta, setSiteMeta, onSave }) {
  const [saveIt, setSaveIt] = useState(false);
  const [emails, setEmails] = useState(
    siteMeta.manualPayoutManagementEmails || []
  );
  const [newEmail, setNewEmail] = useState("");

  useUpdateEffect(() => {
    if (saveIt) {
      onSave();
      setNewEmail("");
      setEmails(siteMeta.manualPayoutManagementEmails || []);
      setSaveIt(false);
    }
  }, [saveIt]);

  const save = () => {
    let toAddEmails = [...emails];
    if (newEmail !== "") {
      toAddEmails.push(newEmail);
    }

    setSiteMeta(
      produce(siteMeta, (draft) => {
        draft.manualPayoutManagementEmails = toAddEmails;
      })
    );

    setSaveIt(true);
  };

  return (
    <div>
      <Typography variant="h5">Manual Payout Settings</Typography>
      <Box my={5}>
        {(emails || []).map((mp, i) => (
          <Box mb={1} key={i} sx={{ display: "flex", alignItems: "center" }}>
            <TextField
              value={mp || ""}
              label={`email ${i + 1}`}
              onChange={(e) =>
                setEmails(
                  produce(emails, (draft) => {
                    draft[i] = e.target.value;
                  })
                )
              }
              variant="outlined"
              size="small"
            />
            <IconButton
              onClick={() =>
                setEmails(
                  produce(emails, (draft) => {
                    draft.splice(i, 1);
                  })
                )
              }
            >
              <Remove />
            </IconButton>
          </Box>
        ))}
        <Box>
          <Typography gutterBottom>New Email</Typography>
          <TextField
            size="small"
            variant="outlined"
            label={`email ${
              (siteMeta.manualPayoutManagementEmails || []).length + 1
            }`}
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
          <Button
            disabled={newEmail === ""}
            onClick={() => {
              setEmails(
                produce(emails, (draft) => {
                  draft.push(newEmail);
                })
              );
              setNewEmail("");
            }}
          >
            Add Email Address
          </Button>
        </Box>
      </Box>
      <Button variant="contained" color="primary" onClick={save}>
        Save Payout Settings
      </Button>
    </div>
  );
}

export default ManualPayoutSettings;
