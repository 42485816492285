import { gql } from "@apollo/client";

export const GET_ALL_LEADS = gql`
  query GetAllLeads {
    leads {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

export const GET_LEAD = gql`
  query Lead($id: UUID!) {
    lead(id: $id) {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

export const CREATE_LEAD = gql`
  mutation CreateLead($input: LeadInput!) {
    createLead(input: $input) {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;
export const UPDATE_LEAD = gql`
  mutation UpdateLead($id: UUID!, $input: LeadInput!) {
    updateLead(id: $id, input: $input) {
      id
      firstName
      lastName
      email
      phone
    }
  }
`;

export const DELETE_LEAD = gql`
  mutation DeleteLead($id: UUID!) {
    deleteLead(id: $id) {
      id
    }
  }
`;
