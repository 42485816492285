import React from "react";
import { Redirect, Route } from "react-router-dom";
import { useAuthContext } from "@providers/AuthProvider";
export const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { authed } = useAuthContext();

  return (
    <Route
      {...rest}
      render={(props) =>
        authed === null ? (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};
