import Loading from "@components/loading/Loading";
import { Button, Grid, Typography } from "@material-ui/core";
import ContentPageProvider from "@providers/ContentPageProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useGetAllContentPages } from "@store/actions/landingPageActions";
import { useCallback, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import AddIcon from "@material-ui/icons/Add";
import NewContentPageDialog from "@components/contentPage/NewContentPageDialog";
import ContentPagesTable from "@components/contentPage/ContentPagesTable";
import ContentPagePage from "./ContentPagePage";

export default function ContenPagesPage() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { showNotification } = useNotificationContext();
  const { loading, data, error } = useGetAllContentPages();

  const onSave = useCallback(
    ({ name, id }) => {
      showNotification(`${name} Created!`);
      setOpen(false);
      history.push(`/content-pages/${id}/edit`);
    },
    [showNotification, history]
  );

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  return (
    <ContentPageProvider>
      <Switch>
        <Route path="/content-pages/:contentPageId">
          <ContentPagePage />
        </Route>
        <Route exact path={path}>
          <Grid container spacing={3}>
            <Grid
              container
              item
              xs={10}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={9}>
                <Typography variant="h5">Content pages</Typography>
                <Typography variant="subtitle1">
                  Content pages are pages that act as stand alone pages, e.g
                  terms etc
                </Typography>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setOpen(true)}
              >
                New Content page
              </Button>
            </Grid>
            <Grid item xs={10}>
              <ContentPagesTable contentPages={data.contentPages} />
            </Grid>
          </Grid>
        </Route>
      </Switch>
      <NewContentPageDialog open={open} setOpen={setOpen} onSave={onSave} />
    </ContentPageProvider>
  );
}
