import Loading from "@components/loading/Loading";
import { Button, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useGetAllPackagePages } from "@store/actions/packagePageActions";
import { useNotificationContext } from "providers/NotificationProvider";
import React, { useCallback, useState } from "react";
import { Route, Switch, useHistory, useRouteMatch } from "react-router-dom";
import NewPackagePageModal from "@components/package/NewPackagePageModal";
import PackagePageProvider from "@providers/PackagePageProvider";
import PackagePagesTable from "@components/package/PackagePagesTable";
import PackagePagePage from "./PackagePagePage";

function PackagePagesPage() {
  const { path } = useRouteMatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const { showNotification } = useNotificationContext();
  const { loading, data, error } = useGetAllPackagePages();

  const onSave = useCallback(
    ({ name, id }) => {
      showNotification(`${name} Created!`);
      setOpen(false);
      history.push(`/package-pages/${id}/edit`);
    },
    [showNotification, history]
  );

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  return (
    <PackagePageProvider>
      <Switch>
        <Route path="/package-pages/:packagePageId">
          <PackagePagePage />
        </Route>
        <Route exact path={path}>
          <Grid container spacing={3}>
            <Grid
              container
              item
              xs={10}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={9}>
                <Typography variant="h5">Package pages</Typography>
                <Typography variant="subtitle1">package page blurb</Typography>
              </Grid>
              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setOpen(true)}
              >
                New Package page
              </Button>
            </Grid>
            <Grid item xs={10}>
              <PackagePagesTable packagePages={data.packagePages} />
            </Grid>
          </Grid>
        </Route>
      </Switch>
      <NewPackagePageModal open={open} setOpen={setOpen} onSave={onSave} />
    </PackagePageProvider>
  );
}

export default PackagePagesPage;
