import {
  Box,
  Button,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import { Brand } from "components-library";
import React, { useState } from "react";
import { useLoginStyles } from "./Login";
import { Link as RouterLink } from "react-router-dom";
import { useForgot } from "@store/actions/authActions";
import { useNotificationContext } from "@providers/NotificationProvider";

function Forgot() {
  const { logo, bg, body, inner } = useLoginStyles();
  const [email, setEmail] = useState("");
  const [forgot, { loading }] = useForgot();
  const { showNotification } = useNotificationContext();

  const submit = async (e) => {
    e.preventDefault();
    try {
      await forgot({ variables: { email } });
      showNotification("Email sent");
    } catch (e) {
      showNotification("Invalid Email");
    }
  };

  return (
    <div className={bg}>
      <div className={body}>
        <Paper className={inner}>
          <Box p={5}>
            <form noValidate autoComplete="off" onSubmit={submit}>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                spacing={3}
              >
                <Grid item xs={8} className={logo}>
                  <Brand useLoad />
                </Grid>
                <Grid item>
                  <Typography>
                    Add email below, if email found we will email out a new
                    password for you
                  </Typography>
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    fullWidth
                    type="email"
                    disabled={loading}
                    size="small"
                    label="Email"
                    variant="outlined"
                  />
                </Grid>
                <Grid item container alignItems="center" justify="center">
                  <Button
                    disabled={loading}
                    color="primary"
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    Send reset
                  </Button>
                </Grid>
                <Grid item>
                  <RouterLink to="/login">{"<"} Back to login</RouterLink>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </div>
    </div>
  );
}

export default Forgot;
