import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useCreateUser, useUpdateUser } from "@store/actions/userActions";
import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

function UserModal({ open, setOpen, user, setUser }) {
  const [addUser] = useCreateUser();
  const [updateUser] = useUpdateUser();
  const { showNotification } = useNotificationContext();

  const close = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const type = e.target.type;
    const value = type === "checkbox" ? e.target.checked : e.target.value;
    setUser({
      ...user,
      [e.target.name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const input = {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user.email,
      userName: user.userName,
    };
    const fn = user.id ? updateUser : addUser;
    const res = await fn({
      variables: {
        id: user.id || null,
        input,
      },
    });
    showNotification(`${user.firstName} Saved!`);
    setUser((o) => ({
      ...o,
      id: o.id ? o.id : res.data.createUser.id,
    }));

    close();
  };

  useEffect(() => {
    return () => {
      if (open) {
        setUser({});
      }
    };
  }, [open]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={close}
      disableBackdropClick={true}
    >
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>
          <Grid container justify="space-between">
            <span>User</span>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <>
            <Grid container spacing={3}>
              <Grid item xs={9}>
                <TextValidator
                  autoFocus
                  variant="outlined"
                  name="userName"
                  label="User Name"
                  validators={["required"]}
                  errorMessages={["User requires user name"]}
                  fullWidth
                  value={user.userName || ""}
                  onChange={handleChange}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextValidator
                  variant="outlined"
                  name="firstName"
                  label="First Name"
                  validators={["required"]}
                  errorMessages={["User requires first name"]}
                  fullWidth
                  value={user.firstName || ""}
                  onChange={handleChange}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  value={user.lastName || ""}
                  onChange={handleChange}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  name="email"
                  label="Email"
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "Email is required",
                    "Must be a valid Email Address",
                  ]}
                  fullWidth
                  value={user.email || ""}
                  onChange={handleChange}
                  size="small"
                  disabled={!!user.id}
                />
              </Grid>
            </Grid>
          </>
        </DialogContent>

        <DialogActions>
          <Button onClick={close} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default UserModal;
