export const groupBy = (xs, key) => {
  return xs.reduce((rv, x) => {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};

const AuthToken = "AuthToken";

/**
 * saveAuthToken
 *
 * @param {{expiresAt: string; user: string}} data
 */
export const saveAuthToken = (data) => {
  localStorage.setItem(AuthToken, JSON.stringify(data));
};

/**
 * clearAuthToken
 *
 */
export const removeAuthToken = () => {
  localStorage.removeItem(AuthToken);
};

/**
 * getAuthToken
 *
 * @returns {{expiresAt: string; user: string} | null}
 */
export const getAuthToken = () => {
  const item = localStorage.getItem(AuthToken);
  try {
    if (item) {
      return JSON.parse(item);
    }
  } catch (e) {
    removeAuthToken();
    return null;
  }

  return null;
};

export const rootDomain = (s) => {
  return s.split(/\.(.*)/).filter((x) => x)[1];
};

export const generateUploadedPath = (s3Path) => {
  if (process.env.NODE_ENV === "development") {
    return s3Path;
  }

  const url = new URL(s3Path);
  return `${url.protocol}//${rootDomain(url.pathname)}`;
};
