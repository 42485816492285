import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useCreateFeature } from "@actions/featureActions";
import React, { useEffect, useState } from "react";

const NewFeatureModal = ({ open, setOpen, onSave }) => {
  const [name, setName] = useState("");
  const [icon, setIcon] = useState("");
  const [addFeature] = useCreateFeature();

  useEffect(() => {
    return () => {
      if (open) {
        setName("");
        setIcon("");
      }
    };
  }, [open]);

  const handleClose = () => {
    setName("");
    setIcon("");
    setOpen(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    addFeature({
      variables: {
        input: { name },
      },
    }).then((r) => {
      onSave(r.data.createFeature);
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <form onSubmit={handleSave}>
        <DialogTitle id="form-dialog-title">New Feature</DialogTitle>
        <DialogContent>
          <DialogContentText>Please enter the features name.</DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Feature Name"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          <TextField
            margin="dense"
            label="Icon"
            fullWidth
            value={icon}
            onChange={(e) => setIcon(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewFeatureModal;
