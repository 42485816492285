import {
  Badge,
  Box,
  Button,
  ButtonGroup,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Tooltip,
} from "@material-ui/core";

import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { SortBy } from "components-library";
import BookingMergeModal from "./BookingMergeModal";
import { useBookingContext } from "@providers/BookingProvider";
import { useDebounce, useUpdateEffect } from "react-use";
import AddIcon from "@material-ui/icons/Add";
import {
  getQuotesItemsTotal,
  getQuotesTransactionsTotal,
  getNonAdditionalQuotes,
} from "utils/models";
import MessageModal from "@components/message/MessageModal";
import { useSendAvailabilityCheck } from "@store/actions/bookingActions";
import { useNotificationContext } from "@providers/NotificationProvider";
import React from "react";
import { memo } from "react";
import BookingsTableInner from "./BookingsTableInner";
import { PriorityHigh } from "@material-ui/icons";

const useBookingTableStyles = makeStyles((theme) => ({
  btnBadge: {
    position: "static",
  },
}));

function BookingsTable({ onEdit, onDelete, onNew, poll, setPoll }) {
  const [open, setOpen] = useState(false);
  const [msgOpen, setMsgOpen] = useState(false);
  const [msgBooking, setMsgBooking] = useState({});
  const [leadBooking, setLeadBookings] = useState([]);
  const [selectedBooking, setSelectedBooking] = useState({});
  const [sortedBookings, setSortedBookings] = useState([]);
  const [sendAvailabilityCheck] = useSendAvailabilityCheck();
  const { showNotification } = useNotificationContext();
  const {
    booking,
    setBooking,
    saveBooking,
    bookings,
    setBookings,
  } = useBookingContext();
  const [sortOptions] = useState([
    "Newist First",
    "Oldist First",
    "Cruise date Soonist First",
    "Cruise date Latest First",
    "By Lead",
    "New Message",
  ]);
  const [sort, setSort] = useState("");
  const [search, setSearch] = useState("");
  const [searchModeInStatus, setSearchModeInStatus] = useState(true);
  const [dSearch, setDSearch] = useState("");
  useDebounce(
    () => {
      setDSearch(search);
    },
    1000,
    [search]
  );

  const [bookingFilters, setBookingFilters] = useState([
    {
      title: "New",
      type: "new",
      tooltip: "A new booking that has not had any interaction",
      bookings: bookings.filter(
        (booking) =>
          (!booking.options?.length || booking.options?.length === 0) &&
          (!booking?.quotes?.length || booking?.quotes?.length === 0) &&
          !booking.available &&
          !booking.availabilityCheckSentOn
      ),
    },
  ]);
  const [filter, setFilter] = useState("new");

  useEffect(() => {
    return () => {
      setBooking({});
    };
  }, []);
  useEffect(() => {
    setBookingFilters([
      {
        title: "New",
        type: "new",
        group: "new",
        tooltip: "A new booking that has not had any interaction",
        bookings: bookings.filter(
          (booking) =>
            (!booking.options?.length || booking.options?.length === 0) &&
            (!booking?.quotes?.length || booking?.quotes?.length === 0) &&
            !booking.available &&
            !booking.availabilityCheckSentOn
        ),
      },
      {
        title: "Open Enquiries",
        type: "open_enquiries",
        group: "new",
        tooltip: "A new booking that has had messages",
        bookings: bookings.filter((booking) => {
          const transactionTotal = getQuotesTransactionsTotal(
            getNonAdditionalQuotes(booking?.quotes)
          );
          return transactionTotal === 0 && booking.messages?.length > 0;
        }),
      },
      {
        title: "Options In progress",
        type: "options_progress",
        group: "options",
        tooltip:
          "A booking that has a option that has not been sent to the customer",
        bookings: bookings.filter((booking) => {
          const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
          const transactionTotal = getQuotesTransactionsTotal(
            nonAdditionalQuotes
          );
          return (
            transactionTotal === 0 &&
            booking.options?.length > 0 &&
            booking.options.filter((bo) => bo.sentOn).length !==
              booking.options.length
          );
        }),
      },
      {
        title: "Options Sent",
        type: "options_sent",
        group: "options",
        tooltip:
          "Options sent are bookings that have had all their options sent out but not seen",
        bookings: bookings.filter((booking) => {
          const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
          const transactionTotal = getQuotesTransactionsTotal(
            nonAdditionalQuotes
          );
          return (
            transactionTotal === 0 &&
            booking.options?.length > 0 &&
            booking.options.filter((bo) => bo.sentOn).length ===
              booking.options.length &&
            booking.options.filter((bo) => bo.seenOn).length !==
              booking.options.length
          );
        }),
      },
      {
        title: "Options Seen",
        type: "options_seen",
        group: "options",
        tooltip:
          "Options seen are bookings that have had all their options sent out to the customer and seen but no quote and no availablility check",
        bookings: bookings.filter((booking) => {
          const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
          const transactionTotal = getQuotesTransactionsTotal(
            nonAdditionalQuotes
          );
          return (
            transactionTotal === 0 &&
            !booking.available &&
            booking.options?.length > 0 &&
            booking.options.filter((bo) => bo.seenOn).length ===
              booking.options.length
          );
        }),
      },
      {
        title: "Availability not Checked",
        group: "availability",
        type: "availability_check",
        tooltip:
          "Bookings that have not had availability check not sent and not marked as available",
        bookings: bookings.filter((booking) => {
          const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
          const transactionTotal = getQuotesTransactionsTotal(
            nonAdditionalQuotes
          );
          return (
            transactionTotal === 0 &&
            !booking.available &&
            !booking.availabilityCheckSentOn
          );
        }),
      },
      {
        title: "Availability Check Sent",
        group: "availability",
        type: "availability_checked",
        tooltip:
          "Bookings that have had availability check sent and not marked as available",
        bookings: bookings.filter((booking) => {
          const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
          const transactionTotal = getQuotesTransactionsTotal(
            nonAdditionalQuotes
          );
          return (
            transactionTotal === 0 &&
            !booking.available &&
            booking.availabilityCheckSentOn
          );
        }),
      },
      {
        title: "Availability not confirmed",
        group: "availability",
        type: "availability_confimired",
        tooltip:
          "Bookings not marked as available but availability check has been seen (todo: has been seen part)",
        bookings: bookings.filter((booking) => {
          const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
          const transactionTotal = getQuotesTransactionsTotal(
            nonAdditionalQuotes
          );
          return transactionTotal === 0 && !booking.available;
        }),
      },
      {
        title: "Quote Needed",
        type: "quote_needed",
        group: "quote_start",
        tooltip:
          "Bookings that are availabe and have seen options (todo, approved options)",
        bookings: bookings.filter((booking) => {
          const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
          return (
            booking.available &&
            booking.options?.length > 0 &&
            booking.options.filter((bo) => bo.seenOn).length ===
              booking.options.length &&
            !booking?.quotes.length
          );
        }),
      },
      {
        title: "Quote not seen",
        type: "quote_not_seen",
        group: "quote_start",
        tooltip: "Bookings that has a quote that has not been seen",
        bookings: bookings.filter((booking) => {
          const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
          // has nay quote that has not been seen which has no transactions
          return (
            nonAdditionalQuotes.length > 0 &&
            nonAdditionalQuotes.filter(
              (q) => q.seenOn || (q.transactions || []).length > 0
            ).length !== nonAdditionalQuotes.length
          );
        }),
      },
      {
        title: "Quote seen not paid",
        type: "quote_seen",
        group: "quote_start",
        tooltip:
          "Bookings that has a quote that has been seen but no transactions",
        bookings: bookings.filter((booking) => {
          const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
          return (
            // has any quote that has been seen but not paid
            nonAdditionalQuotes.filter(
              (q) => q.seenOn && (q.transactions || []).length === 0
            ).length > 0
          );
        }),
      },
      {
        title: "Quote Deposit Paid",
        type: "quote_deposit",
        group: "quote",
        tooltip: "Bookings that has a quote who only has a deposit taken",
        bookings: bookings
          .filter((booking) => {
            const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
            const itemTotal = getQuotesItemsTotal(nonAdditionalQuotes);
            const transactionTotal = getQuotesTransactionsTotal(
              nonAdditionalQuotes
            );
            return transactionTotal > 0 && transactionTotal < itemTotal;
          })
          .map((b) => ({
            ...b,
            className: moment(b.cruiseDate).isBefore(moment().add(2, "weeks"))
              ? "warning"
              : "",
          })),
      },
      {
        title: "Quote Fully Paid",
        type: "quote_fully_paid",
        group: "quote",
        tooltip: "Bookings that have all there quoes fully paid",
        bookings: bookings.filter((booking) => {
          const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
          const itemTotal = getQuotesItemsTotal(nonAdditionalQuotes);
          const transactionTotal = getQuotesTransactionsTotal(
            nonAdditionalQuotes
          );
          return transactionTotal > 0 && transactionTotal >= itemTotal;
        }),
      },
      {
        title: "Quote Expired",
        type: "quote_expired",
        group: "quote",
        tooltip: "Bookings that have all expired quotes",
        bookings: bookings.filter((booking) => {
          const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
          const transactionTotal = getQuotesTransactionsTotal(
            nonAdditionalQuotes
          );
          const itemTotal = getQuotesItemsTotal(nonAdditionalQuotes);
          return (
            nonAdditionalQuotes.length > 0 &&
            transactionTotal < itemTotal &&
            nonAdditionalQuotes.length > 0 &&
            nonAdditionalQuotes.filter((q) => q.expired).length ===
              nonAdditionalQuotes.length
          );
        }),
      },
      {
        title: "Late Payments",
        type: "late_payments",
        group: "important",
        tooltip: "Bookings that have paid deposit but late paying full",
        bookings: bookings
          .filter((booking) => {
            const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
            const itemTotal = getQuotesItemsTotal(nonAdditionalQuotes);
            const transactionTotal = getQuotesTransactionsTotal(
              nonAdditionalQuotes
            );
            return transactionTotal > 0 && transactionTotal < itemTotal;
          })
          .filter((b) =>
            moment(b.cruiseDate).isBefore(moment().add(2, "weeks"))
          )
          .map((b) => ({
            ...b,
            className: moment(b.cruiseDate).isBefore(moment().add(2, "weeks"))
              ? "warning"
              : "",
          })),
      },
      {
        title: "Up Coming",
        type: "up_coming",
        group: "important",
        tooltip: "Bookings coming up",
        bookings: bookings
          .filter((booking) => {
            const nonAdditionalQuotes = getNonAdditionalQuotes(booking?.quotes);
            const transactionTotal = getQuotesTransactionsTotal(
              nonAdditionalQuotes
            );
            return transactionTotal > 0;
          })
          .map((b) => ({
            ...b,
            className: moment(b.cruiseDate).isBefore(moment().add(2, "weeks"))
              ? "warning"
              : "",
          })),
      },
    ]);
  }, [bookings]);

  const groupedBookingFilters = useMemo(() => {
    let grouped = {};
    bookingFilters.forEach((bf) => {
      if (bf.group) {
        if (!grouped.hasOwnProperty(bf.group)) {
          grouped[bf.group] = [];
        }
        grouped[bf.group].push(bf);
      } else {
        grouped[bf.type] = [bf];
      }
    });
    return grouped;
  }, [bookingFilters]);

  const sortFn = useCallback(
    (a, b) => {
      switch (sort) {
        case sortOptions[0]:
          return new Date(b.createdAt) - new Date(a.createdAt);
        case sortOptions[1]:
          return new Date(a.createdAt) - new Date(b.createdAt);
        case sortOptions[2]:
          return new Date(a.cruiseDate) - new Date(b.cruiseDate);
        case sortOptions[3]:
          return new Date(b.cruiseDate) - new Date(a.cruiseDate);
        case sortOptions[4]:
          if (a.lead.id < b.lead.id) {
            return -1;
          }
          if (a.lead.id > b.lead.id) {
            return 1;
          }
          return new Date(b.createdAt) - new Date(a.createdAt);
        case sortOptions[5]:
          const aMessages = (a.messages || []).filter(
            (m) => (m.lead || m.owner) && !m.seenOn
          );
          const bMessages = (b.messages || []).filter(
            (m) => (m.lead || m.owner) && !m.seenOn
          );

          if (aMessages.length > 0 && bMessages.length > 0) {
            return (
              new Date(bMessages[bMessages.length - 1].createdAt) -
              new Date(aMessages[aMessages.length - 1].createdAt)
            );
          } else if (aMessages.length > 0 && !bMessages.length) {
            return -1;
          } else if (!aMessages.length && bMessages.length > 0) {
            return 1;
          }
          return -1;

        // return new Date(b.createdAt) - new Date(a.createdAt);
      }
    },
    [sort, sortOptions]
  );

  useEffect(() => {
    setSort(sortOptions[0]);
    return () => {
      setSort(sortOptions[0]);
    };
  }, [sortOptions]);

  useUpdateEffect(() => {
    if (!searchModeInStatus) {
      setFilter(null);
    }
  }, [searchModeInStatus]);

  useEffect(() => {
    const foundBookingFilter = bookingFilters.find((bf) => bf.type === filter);
    const filteredBookings = foundBookingFilter
      ? foundBookingFilter.bookings
      : [...bookings];
    const sortedRes =
      dSearch.length > 3
        ? filteredBookings
            .filter((b) => searchFunction(b, dSearch))
            .sort(sortFn)
        : filteredBookings.sort(sortFn);
    setSortedBookings([...sortedRes]);
  }, [bookings, sort, filter, bookingFilters, booking, dSearch]);

  const newMessages = (bookings) => {
    return bookings.reduce((arg, v) => {
      return (
        arg +
        (v.messages || []).filter((m) => (m.lead || m.owner) && !m.seenOn)
          .length
      );
    }, 0);
  };

  const onSendAvailabilityCheck = async (message) => {
    try {
      await sendAvailabilityCheck({
        variables: {
          id: msgBooking.id,
          message,
        },
      });

      showNotification(`${msgBooking.reference} availability check sent!`);
    } catch (error) {
      console.error(error, "log error");
    }
    setMsgOpen(false);
    setMsgBooking({});
  };

  const mergeAction = useCallback(
    (booking) => {
      setSelectedBooking(booking);
      setLeadBookings(bookings.filter((b) => b.lead.id === booking.lead.id));
      setOpen(true);
    },
    [bookings]
  );

  const toggleAvailableAction = useCallback((booking) => {
    setBooking({ ...booking, available: !booking.available });
  }, []);

  useUpdateEffect(() => {
    if (!booking.id) {
      return;
    }
    saveBooking();
  }, [booking]);

  const checkAvailability = useCallback((booking) => {
    setMsgOpen(true);
    setMsgBooking(booking);
  }, []);

  const hasSearchedBooking = useCallback(
    (bookings) => {
      if (dSearch.length <= 3) {
        return false;
      }
      const found = bookings.filter((b) => searchFunction(b, dSearch));
      return found.length > 0;
    },
    [dSearch]
  );

  const searchFunction = useCallback((booking, search) => {
    return (
      booking.reference.toLowerCase()?.includes(search.toLowerCase()) ||
      booking.lead?.email?.toLowerCase().includes(search.toLowerCase()) ||
      booking.lead?.firstName?.toLowerCase().includes(search.toLowerCase()) ||
      booking.lead?.lastName?.toLowerCase().includes(search.toLowerCase()) ||
      booking.lead?.phone?.toLowerCase().includes(search.toLowerCase()) ||
      booking.cruiseDate?.includes(search.toLowerCase()) ||
      (booking.quotes || []).some(
        (q) =>
          q.reference &&
          q.reference !== "" &&
          q.reference.includes(search.toLowerCase())
      )
    );
  }, []);

  const { btnBadge } = useBookingTableStyles();
  return (
    <>
      <Grid container spacing={2}>
        <Grid
          container
          item
          xs={12}
          spacing={2}
          justify="space-between"
          alignItems="center"
          style={{ marginTop: "-15px" }}
        >
          <Grid item xs={10} container spacing={2} alignItems="center">
            <Grid item>
              <SortBy value={sort} setValue={setSort} options={sortOptions} />
            </Grid>
            <Grid item>
              <Button variant="outlined" onClick={() => setPoll(!poll)}>
                {poll ? "Turn Off Auto Refresh" : " Turn On Auto Refresh"}
              </Button>
            </Grid>
            <Grid item>
              <TextField
                style={{ minWidth: 390 }}
                size="small"
                variant="outlined"
                label="Search by ref, lead or cruise date (yyyy-mm-dd)."
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <FormControlLabel
                style={{ marginLeft: 4 }}
                control={
                  <Switch
                    checked={searchModeInStatus}
                    onChange={(e, c) => {
                      setSearchModeInStatus(c);
                    }}
                    color="primary"
                  />
                }
                label="Search In Status"
              />
            </Grid>
          </Grid>

          <div>
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={onNew}
            >
              New Booking
            </Button>
          </div>
        </Grid>
        <Grid container item xs={12} spacing={2}>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid container item xs={12} spacing={2}>
            {Object.keys(groupedBookingFilters).map((key) => (
              <Grid key={key} item>
                <ButtonGroup orientation="vertical" color="primary">
                  {groupedBookingFilters[key].map((bf) => (
                    <Tooltip
                      key={bf.type}
                      title={bf.tooltip}
                      placement="top-start"
                    >
                      <Button
                        style={{ minWidth: 100 }}
                        variant={filter === bf.type ? "contained" : "outlined"}
                        color={filter === bf.type ? "primary" : "default"}
                        onClick={() => {
                          setFilter(filter === bf.type ? null : bf.type);
                          setSearchModeInStatus(true);
                        }}
                        endIcon={
                          hasSearchedBooking(bf.bookings) ? (
                            <PriorityHigh
                              color={filter === bf.type ? "inherit" : "primary"}
                              style={{ fontSize: 16, padding: 0 }}
                            />
                          ) : undefined
                        }
                      >
                        <Badge
                          className={btnBadge}
                          badgeContent={newMessages(bf.bookings)}
                          color="secondary"
                        >
                          {bf.title} ({bf.bookings.length})
                        </Badge>
                      </Button>
                    </Tooltip>
                  ))}
                </ButtonGroup>
              </Grid>
            ))}
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <BookingsTableInner
            sortedBookings={sortedBookings}
            onEdit={onEdit}
            onDelete={onDelete}
            mergeAction={mergeAction}
            toggleAvailableAction={toggleAvailableAction}
            checkAvailability={checkAvailability}
          />
        </Grid>
      </Grid>
      <BookingMergeModal
        open={open}
        setOpen={setOpen}
        bookings={leadBooking}
        selectedBooking={selectedBooking}
      />
      <MessageModal
        open={msgOpen}
        setOpen={setMsgOpen}
        onSave={onSendAvailabilityCheck}
      />
    </>
  );
}

export default memo(BookingsTable);
