import Editor from "@components/editor/Editor";
import {
  Box,
  Grid,
  Typography,
  Button,
  TextField,
  Divider,
} from "@material-ui/core";
import React, { useState } from "react";
import { Footer } from "components-library";
import { produce } from "immer";
import { useUpdateEffect } from "react-use";

function FooterCol({ content, setContent, msg, setMsg }) {
  return (
    <Editor
      msg={content}
      setMsg={setContent}
      html={msg}
      setHtml={(content) => {
        setMsg(content);
      }}
    />
  );
}

function FooterCreator({ siteMeta, setSiteMeta, onSave }) {
  const [saveIt, setSaveIt] = useState(false);
  const [footerIframeLink, setFooterIframeLink] = useState(
    siteMeta.footerIframeLink || ""
  );
  const [footerIframeTitle, setFooterIframeTitle] = useState(
    siteMeta.footerIframeTitle || ""
  );
  const [
    footerTermsAndConditionsLink,
    setFooterTermsAndConditionsLink,
  ] = useState(siteMeta.footerTermsAndConditionsLink || "");

  const [slateJson, setSlateJson] = useState(
    siteMeta.footerSlateJsons
      ? siteMeta.footerSlateJsons.map((v) => JSON.parse(v))
      : ["", "", "", ""]
  );
  const [msgs, setMsgs] = useState(
    siteMeta.footerContent ? siteMeta.footerContent : ["", "", "", ""]
  );

  const save = () => {
    setSiteMeta(
      produce(siteMeta, (draft) => {
        draft.footerContent = msgs;
        draft.footerSlateJsons = slateJson.map((v) => JSON.stringify(v));
        draft.footerIframeLink = footerIframeLink;
        draft.footerIframeTitle = footerIframeTitle;
        draft.footerTermsAndConditionsLink = footerTermsAndConditionsLink;
      })
    );
    setSaveIt(true);
  };

  useUpdateEffect(() => {
    if (saveIt) {
      onSave();
      setSaveIt(false);
    }
  }, [saveIt]);

  return (
    <Box>
      <Typography variant="h4">Custom Footer</Typography>
      <Box mb={5}>
        <Footer
          useLoad
          siteName={siteMeta.siteName}
          cols={msgs}
          iframeLink={footerIframeLink}
          iframeTitle={footerIframeTitle}
          termsLink={footerTermsAndConditionsLink}
        />
      </Box>
      <Box mb={5}>
        <Button variant="contained" color="primary" onClick={save}>
          Save Custom Footer
        </Button>
      </Box>
      <Grid container spacing={2}>
        <Grid item xs={4}>
          <TextField
            label="Set Iframe Title"
            value={footerIframeTitle}
            variant="outlined"
            fullWidth
            onChange={(e) => setFooterIframeTitle(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Set Iframe Link"
            value={footerIframeLink}
            variant="outlined"
            fullWidth
            onChange={(e) => setFooterIframeLink(e.target.value)}
          />
        </Grid>
        <Grid item xs={4}>
          <TextField
            label="Footer Terms and Conditions Link"
            value={footerTermsAndConditionsLink}
            variant="outlined"
            fullWidth
            onChange={(e) => setFooterTermsAndConditionsLink(e.target.value)}
          />
        </Grid>
      </Grid>
      <Box my={5} sx={{ width: "100%" }}>
        <Divider />
      </Box>
      <Typography variant="h6" gutterBottom>
        Add each column below
      </Typography>
      <Grid container spacing={2}>
        {slateJson.map((c, i) => (
          <Grid item xs={3} key={i}>
            <FooterCol
              content={c}
              setContent={(v) =>
                setSlateJson(
                  produce(slateJson, (draft) => {
                    draft[i] = v;
                  })
                )
              }
              msg={msgs[i]}
              setMsg={(v) =>
                setMsgs(
                  produce(msgs, (draft) => {
                    draft[i] = v;
                  })
                )
              }
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
}

export default FooterCreator;
