import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_CONTENT_PAGE,
  CREATE_LANDING_PAGE,
  DELETE_CONTENT_PAGE,
  DELETE_LANDING_PAGE,
  GET_ALL_CONTENT_PAGES,
  GET_ALL_LANDING_PAGES,
  GET_CONTENT_PAGE,
  GET_LANDING_PAGE,
  UPDATE_CONTENT_PAGE,
  UPDATE_LANDING_PAGE,
} from "@operations/landingPageOpertaions";

/* -------------------------------------------------------------------------- */
/*                                LANDING PAGES                               */
/* -------------------------------------------------------------------------- */

export const useGetLandingPage = (id) =>
  useQuery(GET_LANDING_PAGE, {
    variables: {
      id,
    },
  });

export const useGetAllLandingPages = () => useQuery(GET_ALL_LANDING_PAGES);

export const useCreateLandingPage = () =>
  useMutation(CREATE_LANDING_PAGE, {
    update: (cache, { data: { createLandingPage } }) => {
      const data = cache.readQuery({
        query: GET_ALL_LANDING_PAGES,
      });
      const landingPages = data ? data.landingPages : [];

      writeLandingPagesCache(cache, [...landingPages, createLandingPage]);
    },
  });

export const useUpdateLandingPage = () => useMutation(UPDATE_LANDING_PAGE);

export const useDeleteLandingPage = () =>
  useMutation(DELETE_LANDING_PAGE, {
    update: (cache, { data: { deleteLandingPage } }) => {
      const data = cache.readQuery({
        query: GET_ALL_LANDING_PAGES,
      });
      const landingPages = data ? data.landingPages : [];

      writeLandingPagesCache(
        cache,
        landingPages.filter((lp) => lp.id !== deleteLandingPage.id)
      );
    },
  });

/* -------------------------------------------------------------------------- */
/*                                CONTENT PAGES                               */
/* -------------------------------------------------------------------------- */

export const useGetContentPage = (id) =>
  useQuery(GET_CONTENT_PAGE, {
    variables: {
      id,
    },
  });

export const useGetAllContentPages = () => useQuery(GET_ALL_CONTENT_PAGES);

export const useCreateContentPage = () =>
  useMutation(CREATE_CONTENT_PAGE, {
    update: (cache, { data: { createContentPage } }) => {
      const data = cache.readQuery({
        query: GET_ALL_CONTENT_PAGES,
      });
      const contentPages = data ? data.contentPages : [];

      writeContentPagesCache(cache, [...contentPages, createContentPage]);
    },
  });

export const useUpdateContentPage = () => useMutation(UPDATE_CONTENT_PAGE);

export const useDeleteContentPage = () =>
  useMutation(DELETE_CONTENT_PAGE, {
    update: (cache, { data: { deleteContentPage } }) => {
      const data = cache.readQuery({
        query: GET_ALL_CONTENT_PAGES,
      });
      const contentPages = data ? data.contentPages : [];

      writeContentPagesCache(
        cache,
        contentPages.filter((lp) => lp.id !== deleteContentPage.id)
      );
    },
  });

/* -------------------------------------------------------------------------- */
/*                                   HELPERS                                  */
/* -------------------------------------------------------------------------- */

const writeLandingPagesCache = (cache, landingPages) => {
  cache.writeQuery({
    query: GET_ALL_LANDING_PAGES,
    data: {
      landingPages,
    },
  });
};
const writeContentPagesCache = (cache, contentPages) => {
  cache.writeQuery({
    query: GET_ALL_CONTENT_PAGES,
    data: {
      contentPages,
    },
  });
};
