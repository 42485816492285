import { gql } from "@apollo/client";

export const BookingItemCostType = {
  pickUp: "pickUp",
  dropOff: "dropOff",
  package: "package",
  catering: "catering",
  drinks: "drinks",
  byoFood: "byoFood",
  byoDrink: "byoDrink",
  staff: "staff",
  other: "other",
};

export const GET_ALL_BOOKINGS = gql`
  query GetAllBookings {
    bookings {
      id
      reference
      groupSize
      hours
      cruiseDate
      available
      notes
      availabilityCheckSentOn
      incendiumKey
      catering
      event
      eventOther
      source
      messages {
        seenOn
        status
        lead {
          id
        }
        owner {
          id
        }
        toOwner {
          id
        }
        toLead {
          id
        }
      }
      quotes {
        id
        expired
        type
        seenOn
        transactions {
          total
        }
        items {
          baseCost
          costs {
            cost
          }
        }
      }
      lead {
        id
        email
        firstName
        lastName
        phone
      }
      options {
        id
        sentOn
        seenOn
      }
      items {
        id
        quoteItems {
          id
        }
        skippers {
          staff {
            id
            firstName
            lastName
            role
          }
        }
        deckies {
          staff {
            id
            firstName
            lastName
            role
          }
        }
        boat {
          id
          name
          skipperRequired
        }
        boatPackage {
          id
          name
        }
        baseCost
        commision
      }
      createdAt
    }
  }
`;

export const GET_BOOKING = gql`
  query GetBooking($id: UUID!) {
    booking(id: $id) {
      id
      reference
      groupSize
      hours
      cruiseDate
      createdAt
      available
      notes
      catering
      event
      eventOther
      source
      availabilityCheckSentOn
      lead {
        id
        firstName
        lastName
        phone
        email
      }
      options {
        id
        reference
        sentOn
        seenOn
        message
      }
      quotes {
        id
        type
        reference
        sentOn
        seenOn
        expired
        reminderSentOn
        transactions {
          total
        }
        items {
          baseCost
          costs {
            cost
          }
        }
      }
      items {
        id
        baseCost
        commision
        createdAt
        options {
          bookingOption {
            id
            reference
          }
        }
        skippers {
          staff {
            id
            firstName
            lastName
            role
          }
        }
        deckies {
          staff {
            id
            firstName
            lastName
            role
          }
        }
        quoteItems {
          id
          quote {
            id
            reference
            type
          }
          costs {
            id
            description
            cost
            commision
            dropOffWharf {
              id
              name
            }
            pickUpWharf {
              id
              name
            }
            package {
              id
              name
            }
            type
          }
        }
        boat {
          id
          name
          oneLiner
          capacity
          minimumHire
          showContact
          skipperRequired
          owner {
            id
            firstName
          }
          features {
            id
            name
          }
          boatTypes {
            id
            name
            path
          }
          medias {
            id
            name
            mediaFiles {
              name
              path
              type
            }
          }
          boatPrice {
            id
            name
            baseHourlyRate
            description
            items {
              id
              from
              to
              rate
              unit
              days
              fixed
            }
          }
          boatPrices {
            id
            name
            baseHourlyRate
            description
            items {
              id
              from
              to
              rate
              unit
              days
              fixed
            }
          }
          boatPackages {
            id
            name
          }
          boatPriceSpecials {
            id
            name
            to
            from
            recurring
            boatPrice {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
          }
          wharfs {
            id
            name
          }
        }
        boatPackage {
          id
          name
        }
      }
      messages {
        id
        content
        createdAt
        seenOn
        status
        lead {
          firstName
        }
        owner {
          firstName
        }
        toOwner {
          firstName
        }
        toLead {
          firstName
        }
        quote {
          id
          reference
        }
        booking {
          id
          reference
        }
        bookingOption {
          id
          reference
        }
      }
    }
  }
`;
export const GET_BOOKING_BY_REF = gql`
  query GetBookingByRef($ref: String!) {
    bookingByRef(ref: $ref) {
      id
      reference
      groupSize
      hours
      cruiseDate
      createdAt
      available
      notes
      catering
      event
      eventOther
      availabilityCheckSentOn
      lead {
        id
        firstName
        lastName
        phone
        email
      }
      options {
        id
        reference
        sentOn
        seenOn
        message
      }
      quotes {
        id
        type
        reference
        sentOn
        seenOn
        expired
        reminderSentOn
        transactions {
          total
        }
        items {
          baseCost
          costs {
            cost
          }
        }
      }
      items {
        id
        baseCost
        commision
        createdAt
        options {
          bookingOption {
            id
            reference
          }
        }
        skippers {
          staff {
            id
            firstName
            lastName
            role
          }
        }
        deckies {
          staff {
            id
            firstName
            lastName
            role
          }
        }
        quoteItems {
          id
          quote {
            id
            reference
            type
          }
          costs {
            id
            description
            cost
            commision
            dropOffWharf {
              id
              name
            }
            pickUpWharf {
              id
              name
            }
            package {
              id
              name
            }
            type
          }
        }
        boat {
          id
          name
          oneLiner
          capacity
          minimumHire
          showContact
          skipperRequired
          owner {
            id
            firstName
          }
          features {
            id
            name
          }
          boatTypes {
            id
            name
            path
          }
          medias {
            id
            name
            mediaFiles {
              name
              path
              type
            }
          }
          boatPrice {
            id
            name
            baseHourlyRate
            description
            items {
              id
              from
              to
              rate
              unit
              days
              fixed
            }
          }
          boatPrices {
            id
            name
            baseHourlyRate
            description
            items {
              id
              from
              to
              rate
              unit
              days
              fixed
            }
          }
          boatPackages {
            id
            name
          }
          boatPriceSpecials {
            id
            name
            to
            from
            recurring
            boatPrice {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
          }
          wharfs {
            id
            name
          }
        }
        boatPackage {
          id
          name
        }
      }
      messages {
        id
        content
        createdAt
        seenOn
        status
        lead {
          firstName
        }
        owner {
          firstName
        }
        toOwner {
          firstName
        }
        toLead {
          firstName
        }
        quote {
          id
          reference
        }
        booking {
          id
          reference
        }
        bookingOption {
          id
          reference
        }
      }
    }
  }
`;

export const CREATE_BOOKING = gql`
  mutation CreateBooking($input: BookingInput!, $itemInput: BookingItemInput) {
    createBooking(input: $input, itemInput: $itemInput) {
      id
      reference
      groupSize
      hours
      cruiseDate
      available
      notes
      catering
      event
      eventOther
      availabilityCheckSentOn
      source
      lead {
        id
      }
      items {
        boat {
          id
        }
        boatPackage {
          id
        }
      }
    }
  }
`;
export const UPDATE_BOOKING = gql`
  mutation UpdateBooking($id: UUID!, $input: BookingInput!) {
    updateBooking(id: $id, input: $input) {
      id
      reference
      groupSize
      hours
      cruiseDate
      available
      notes
      catering
      event
      eventOther
      availabilityCheckSentOn
      source
      lead {
        id
      }
      items {
        boat {
          id
        }
        boatPackage {
          id
        }
      }
    }
  }
`;

export const DELETE_BOOKING = gql`
  mutation DeleteBooking($id: UUID!) {
    deleteBooking(id: $id) {
      id
    }
  }
`;

export const MERGE_BOOKINGS = gql`
  mutation MergeBookings($from: [UUID!]!, $to: UUID!) {
    mergeBookings(from: $from, to: $to) {
      id
    }
  }
`;

export const CATERING_ENUM = gql`
  query {
    __type(name: "Catering") {
      name
      enumValues {
        name
      }
    }
  }
`;
export const EVENT_ENUM = gql`
  query {
    __type(name: "EventType") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const QUOTE_ITEM_TYPE_ENUM = gql`
  query {
    __type(name: "BookingItemCostType") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const CHECK_AVAILABILITY = gql`
  mutation CheckAvailability($id: UUID!) {
    checkAvailability(id: $id) {
      boatName
      isAvailable
    }
  }
`;

export const SEND_CHECK_AVAILABILITY = gql`
  mutation SendAvailabilityCheck($id: UUID!, $message: String!) {
    sendAvailabilityCheck(id: $id, message: $message) {
      id
      reference
      groupSize
      hours
      cruiseDate
      available
      notes
      availabilityCheckSentOn
      messages {
        seenOn
        createdAt
        lead {
          id
          email
          firstName
          lastName
        }
        toOwner {
          id
          email
          firstName
          lastName
        }
        toLead {
          id
          email
          firstName
          lastName
        }
      }
      quotes {
        id
        expired
        type
        seenOn
        transactions {
          total
        }
        items {
          baseCost
          costs {
            cost
          }
        }
      }
      lead {
        id
        email
        firstName
        lastName
      }

      options {
        id
        sentOn
        seenOn
      }
      items {
        boat {
          id
        }
        boatPackage {
          id
        }
        baseCost
        commision
      }
      createdAt
    }
  }
`;

export const SEND_CHANGED_ITEM = gql`
  mutation SendChangeInBookingItem($id: UUID!) {
    sendChangeInBookingItem(id: $id) {
      id
    }
  }
`;

/* -------------------------------------------------------------------------- */
/*                                BOOKING ITEM                                */
/* -------------------------------------------------------------------------- */

export const GET_BOOKING_ITEM = gql`
  query GetBookingItem($id: UUID!) {
    bookingItem(id: $id) {
      id
      baseCost
      commision
      boat {
        id
      }
      boatPackage {
        id
      }
      skippers {
        staff {
          id
          firstName
          lastName
          role
        }
      }
      deckies {
        staff {
          id
          firstName
          lastName
          role
        }
      }
    }
  }
`;

export const CREATE_BOOKING_ITEM = gql`
  mutation CreateBookingItem($bookingID: UUID!, $input: BookingItemInput!) {
    createBookingItem(bookingID: $bookingID, input: $input) {
      id
      baseCost
      commision
      boat {
        id
      }
      boatPackage {
        id
      }
    }
  }
`;
export const UPDATE_BOOKING_ITEM = gql`
  mutation UpdateBookingItem($id: UUID!, $input: BookingItemInput!) {
    updateBookingItem(id: $id, input: $input) {
      id
      baseCost
      commision
      boat {
        id
      }
      boatPackage {
        id
      }
      skippers {
        staff {
          id
          firstName
          lastName
          role
        }
      }
      deckies {
        staff {
          id
          firstName
          lastName
          role
        }
      }
    }
  }
`;

export const DELETE_BOOKING_ITEM = gql`
  mutation DeleteBookingItem($id: UUID!) {
    deleteBookingItem(id: $id) {
      id
    }
  }
`;

/* -------------------------------------------------------------------------- */
/*                               booking options                              */
/* -------------------------------------------------------------------------- */
export const CREATE_BOOKING_OPTION = gql`
  mutation CreateBookingOption($bookingID: UUID!) {
    createBookingOption(bookingID: $bookingID) {
      id
      reference
    }
  }
`;
export const UPDATE_BOOKING_OPTION = gql`
  mutation UpdateBookingOption(
    $id: UUID!
    $input: BookingOptionInput!
    $items: [BookingOptionItemInput!]
  ) {
    updateBookingOption(id: $id, input: $input, items: $items) {
      id
      reference
    }
  }
`;
export const SEND_BOOKING_OPTION = gql`
  mutation SendBookingOption($id: UUID!, $message: String!, $sendSms: Boolean) {
    sendBookingOption(id: $id, message: $message, sendSms: $sendSms) {
      id
      reference
      message
      sentOn
      seenOn
      messages {
        id
        content
        createdAt
        lead {
          firstName
        }
      }
      items {
        price
        hours
        bookingItem {
          id
          boat {
            id
            name
            oneLiner
            capacity
            minimumHire
            showContact
            features {
              id
              name
            }
            boatTypes {
              id
              name
              path
            }
            medias {
              id
              name
              mediaFiles {
                name
                path
                type
              }
            }
            boatPrice {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
            boatPrices {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
            boatPriceSpecials {
              id
              name
              to
              from
              recurring
              boatPrice {
                id
                name
                baseHourlyRate
                description
                items {
                  id
                  from
                  to
                  rate
                  unit
                  days
                  fixed
                }
              }
            }
          }
          boatPackage {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_BOOKING_OPTION = gql`
  query GetBookingOption($id: UUID!) {
    bookingOption(id: $id) {
      id
      reference
      message
      sentOn
      seenOn
      messages {
        id
        content
        createdAt
        seenOn
        status
        lead {
          firstName
        }
        quote {
          id
          reference
        }
        booking {
          id
          reference
        }
        bookingOption {
          id
          reference
        }
      }
      items {
        price
        hours
        bookingItem {
          id
          boat {
            id
            name
            oneLiner
            capacity
            minimumHire
            showContact
            features {
              id
              name
            }
            boatTypes {
              id
              name
              path
            }
            medias {
              id
              name
              mediaFiles {
                name
                path
                type
              }
            }
            boatPrice {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
            boatPrices {
              id
              name
              baseHourlyRate
              description
              items {
                id
                from
                to
                rate
                unit
                days
                fixed
              }
            }
            boatPriceSpecials {
              id
              name
              to
              from
              recurring
              boatPrice {
                id
                name
                baseHourlyRate
                description
                items {
                  id
                  from
                  to
                  rate
                  unit
                  days
                  fixed
                }
              }
            }
          }
          boatPackage {
            id
            name
          }
        }
      }
    }
  }
`;

export const DELETE_BOOKING_OPTION = gql`
  mutation DeleteBookingOption($id: UUID!) {
    deleteBookingOption(id: $id) {
      id
    }
  }
`;
