import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useQuoteItemType } from "@store/actions/bookingActions";
import { useCreateBookingItemCost } from "@store/actions/quoteActions";
import React, { useEffect, useMemo, useState } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import QuoteItemType from "./QuoteItemType";

function NewQuoteItemCost({ quote, open, setOpen, onSaved, item }) {
  const [addItemCost] = useCreateBookingItemCost(quote, item);
  const { data: quoteItemTypes } = useQuoteItemType();
  const [type, setType] = useState("");

  const types = useMemo(() => {
    if (!quoteItemTypes) {
      return [];
    }
    return quoteItemTypes.__type.enumValues
      .map((e) => e.name)
      .filter((v) => !["all", "hire"].includes(v));
  }, [quoteItemTypes]);

  const create = async () => {
    const input = {
      type,
    };

    await addItemCost({
      variables: {
        quoteItemID: item.id,
        input,
      },
    });
    onSaved();
  };

  useEffect(() => {
    return () => {
      if (open) {
        setType("");
      }
    };
  }, [open]);

  return (
    <Dialog
      maxWidth="sm"
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick={true}
    >
      <ValidatorForm onSubmit={create} style={{ minWidth: 400 }}>
        <DialogTitle>New Quote item</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            variant="outlined"
            size="small"
            label="Select item type"
            value={type}
            onChange={(e) => setType(e.target.value)}
            select
          >
            {types.map((type) => (
              <MenuItem key={type} value={type}>
                <QuoteItemType type={type} />
              </MenuItem>
            ))}
          </TextField>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Continue
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default NewQuoteItemCost;
