import { Box, Paper, Typography } from "@material-ui/core";
import BoatPriceTable from "./BoatPriceTable";
import BoatSpecialPriceTable from "./BoatSpecialPriceTable";

const PriceInfoCard = ({ boat }) => {
  return (
    <Paper>
      <Box p={2}>
        <Typography variant="subtitle1" gutterBottom>
          {boat.name} Price info
        </Typography>
        <BoatPriceTable boat={boat} readOnly={true} />
        <br />
        <BoatSpecialPriceTable boat={boat} readOnly={true} />
      </Box>
    </Paper>
  );
};

export default PriceInfoCard;
