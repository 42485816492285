import BookingItemCosts from "@components/booking/BookingItemCosts";
import QuoteBuilder from "@components/booking/QuoteBuilder";
import Loading from "@components/loading/Loading";
import MessageModal from "@components/message/MessageModal";
import {
  Box,
  Button,
  Divider,
  Drawer,
  FormControlLabel,
  Grid,
  makeStyles,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useBookingContext } from "@providers/BookingProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import {
  useGetQuote,
  useSendHoldRelease,
  useSendOnHold,
  useSendQuote,
  useUpdateQuote,
} from "@store/actions/quoteActions";
import React, { useCallback, useEffect, useState } from "react";
import moment from "moment";
import { baseURL } from "index";
import BookingModal from "@components/booking/BookingModal";

import { useConfirmationContext } from "@providers/ConfirmationProvider";
import BookingItemModal from "@components/booking/BookingItemModal";
import { usePrevious, useUnmount, useUpdateEffect } from "react-use";
import classNames from "classnames";
import QuotePutOnHoldModal from "pages/bookings/QuotePutOnHoldModal";
import { produce } from "immer";
import { Autocomplete } from "@mui/material";
import { friendlyDateTime } from "utils/date";

const useStyles = makeStyles((theme) => ({
  drawerPaper: {
    background: theme.custom.sidebar.background,
    width: 270,
    boxShadow: theme.shadows[1],
    flexShrink: 0,
    whiteSpace: "nowrap",
    padding: theme.spacing(0, 2),
    boxSizing: "border-box",
  },
  body: {
    marginRight: 270,
  },
  bodyOpen: {
    marginRight: 0,
  },
}));

function QuotePageInner({ quoteId }) {
  const { drawerPaper, body, bodyOpen } = useStyles();
  const { booking, quote, setQuote, chatOpen } = useBookingContext();
  const { data, loading } = useGetQuote(quoteId);
  const [open, setOpen] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [allHasOwners, setAllHasOwners] = useState(false);
  const [bookingItemOpen, setBookingItemOpen] = useState(false);
  const [owners, setOwners] = useState([]);
  const [holdOpen, setHoldOpen] = useState(false);
  const [bookingOpen, setBookingOpen] = useState(false);
  const [sendQuote] = useSendQuote(quoteId);
  const [sendOnHold] = useSendOnHold();
  const [sendHoldRelease] = useSendHoldRelease();
  const { showNotification } = useNotificationContext();
  const [updateQuote] = useUpdateQuote();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [sendSms, setSendSms] = useState(true);
  const [waivers, setWaivers] = useState([]);
  const prevWaiver = usePrevious(quote.waiver);

  useEffect(() => {
    if (data?.quote) {
      setQuote(data.quote);
      setOwners([
        ...new Set(data.quote.items.map((item) => item.bookingItem.boat.owner)),
      ]);
      if (data.quote.transactions.length > 0) {
        setReadOnly(true);
      }
      const hasOwners = (data.quote.items || []).filter(
        (it) => it.bookingItem.boat.owner
      );
      setAllHasOwners((data.quote.items || []).length === hasOwners.length);
      setWaivers(
        (data.quote.items || [])
          .map((item) => item.bookingItem?.boat?.waivers)
          .flat()
      );
    }
  }, [data]);

  useUpdateEffect(() => {
    if (
      (!quote.waiver?.id && !prevWaiver) ||
      (quote.waiver?.id && quote.waiver?.id === prevWaiver?.id)
    ) {
      return;
    }
    updateQuote({
      variables: {
        id: quote.id,
        input: {
          reference: quote.reference,
          expired: quote.expired,
          waiverId: quote.waiver?.id,
        },
      },
    });
    showNotification(
      quote.waiver?.id ? `Waiver ${quote.waiver.name} Set` : `Waiver Removed`
    );
  }, [quote.waiver, prevWaiver]);

  useUnmount(() => {
    setQuote({});
  });

  const validateForSend = () => {
    // to send a quote you first need to of set the booking date, hrs and groupsize
    return booking.groupSize && booking.cruiseDate && booking.hours;
  };

  const openMessage = () => {
    if (!validateForSend()) {
      setBookingOpen(true);
      return;
    }
    setBookingOpen(false);
    if (quote.type === "additional") {
      setOpen(true);
    } else {
      setHoldOpen(true);
    }
  };

  const onBookingSave = () => {
    openMessage();
  };

  const send = useCallback(
    async (message) => {
      await sendQuote({
        variables: {
          id: quote.id,
          message,
          sendSms,
        },
      });

      if (quote.type !== "additional") {
        await Promise.all(
          owners.map((o) =>
            sendOnHold({
              variables: {
                id: quote.id,
                ownerId: o.id,
              },
            })
          )
        );
      }

      setOpen(false);
      showNotification("Quote Sent");
    },
    [quote, sendSms]
  );

  const updateExpired = useCallback(
    (v) => {
      updateQuote({
        variables: {
          id: quote.id,
          input: {
            reference: quote.reference,
            expired: v,
            waiverId: quote.waiver?.id,
          },
        },
      });
    },
    [quote]
  );

  const toggleExpired = (v) => {
    if (v) {
      openConfirmation({
        title: `Mark quote as expired`,
        body: `Marking this quote as expired will send and email to the boat owner's informing them they can realease this hold?`,
        callback: async () => {
          await Promise.all(
            owners.map((o) =>
              sendHoldRelease({
                variables: {
                  id: quote.id,
                  ownerId: o.id,
                },
              })
            )
          );
          updateExpired(v);
          closeConfirmation();
        },
      });
    } else {
      updateExpired(v);
    }
  };

  if (loading) {
    return <Loading />;
  }
  return (
    <>
      <div
        className={classNames(body, {
          [bodyOpen]: chatOpen,
        })}
      >
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={9}>
            <Typography variant="h6" gutterBottom>
              Quote -{" "}
              <Tooltip title="Click link to preview quote, note the url must contain admin=true or the option will be marked as seen by the customer">
                <a
                  href={`${baseURL}/quote/?ref=${quote?.reference}&admin=true`}
                  target="_blank"
                >
                  {baseURL}/quote/?ref={quote?.reference}&admin=true
                </a>
              </Tooltip>
            </Typography>
            <Typography variant="body1">
              Drag Booking items from the list on the right to add as an quote
              item
            </Typography>
          </Grid>

          <FormControlLabel
            disabled={!!readOnly}
            control={
              <Switch
                checked={quote?.expired || false}
                onChange={() => toggleExpired(!quote?.expired)}
                color="primary"
              />
            }
            label="Is Expired"
          />
          <Grid item xs={12}>
            <QuoteBuilder
              booking={booking}
              quote={quote}
              setQuote={setQuote}
              setIsValid={setIsValid}
              readOnly={readOnly}
            />
          </Grid>
          <Grid item xs={12} container spacing={2} justify="flex-end">
            {waivers.length > 0 && (
              <Grid item container spacing={2}>
                {quote.waiverSignOn ? (
                  <Typography variant="body2">
                    Waiver {quote.waiver.name} signed on{" "}
                    {friendlyDateTime(quote.waiverSignOn)}
                  </Typography>
                ) : (
                  <>
                    <Grid item xs={6}>
                      <Typography variant="body2" color="textSecondary">
                        It looks like one or more of the boats included have
                        optional waivers.
                      </Typography>
                      <Typography variant="body2" color="textSecondary">
                        Would you like to attach a waiver that needs signing to
                        this booking
                      </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box>
                        <Autocomplete
                          options={waivers || []}
                          disabled={quote.waiverSignOn}
                          getOptionLabel={(option) => option.name || ""}
                          onChange={(_, v) => {
                            setQuote(
                              produce(quote, (draft) => {
                                draft.waiver = v;
                              })
                            );
                          }}
                          value={quote.waiver || {}}
                          fullWidth
                          size="small"
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              label="Select Waiver"
                              size="small"
                              name="waiver"
                              autoComplete={false}
                            />
                          )}
                        />
                      </Box>
                    </Grid>
                  </>
                )}
              </Grid>
            )}
            {quote?.sentOn && (
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  Last sent on {moment(quote.sentOn).format("lll")}
                </Typography>
              </Grid>
            )}
            {quote?.seenOn && (
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  Last seen on {moment(quote.seenOn).format("lll")}
                </Typography>
              </Grid>
            )}
            {!allHasOwners && (
              <Grid item>
                <Typography variant="body2" color="textSecondary">
                  Quote can not be sent as some boats do not have a owner
                </Typography>
              </Grid>
            )}

            <Grid item mr={2}>
              <Box mr={2}>
                <FormControlLabel
                  size="small"
                  labelPlacement="start"
                  control={
                    <Switch
                      size="small"
                      checked={sendSms}
                      onChange={(_, checked) => setSendSms(checked)}
                      color="primary"
                    />
                  }
                  label={<Typography variant="body2">Send SMS</Typography>}
                />
              </Box>
            </Grid>

            <Button
              disabled={quote.expired || !isValid || readOnly || !allHasOwners}
              variant="contained"
              color="primary"
              onClick={openMessage}
            >
              {quote.expired ? "Cant send expired quote" : "Send Quote"}
            </Button>
          </Grid>
        </Grid>
      </div>
      {!readOnly && (
        <Drawer
          anchor={"right"}
          variant="permanent"
          classes={{
            paper: drawerPaper,
          }}
          open
        >
          <br />
          <Grid container justify="space-between" alignItems="center">
            <Typography>Booking Items</Typography>
            <Button variant="outlined" onClick={() => setBookingItemOpen(true)}>
              Add Item
            </Button>
          </Grid>
          <br />
          <Divider />

          <BookingItemCosts booking={booking} />
        </Drawer>
      )}

      <QuotePutOnHoldModal
        open={holdOpen}
        setOpen={setHoldOpen}
        onSave={() => setOpen(true)}
        owners={owners}
        setOwners={setOwners}
      />
      <MessageModal open={open} setOpen={setOpen} onSave={send} />
      <BookingModal
        open={bookingOpen}
        setOpen={setBookingOpen}
        onSave={onBookingSave}
        title="Send a personalised message to the Customer"
        description="Booking requires group size,number of hours and cruise date to be able to send quote"
      />
      <BookingItemModal open={bookingItemOpen} setOpen={setBookingItemOpen} />
    </>
  );
}

export default QuotePageInner;
