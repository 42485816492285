import NewBoatModal from "@components/boat/NewBoatModal";
import Breadcrumb from "@components/breadcrumb/Breadcrumb";
import CardTable from "@components/cardTable/CardTable";
import {
  Box,
  Button,
  Divider,
  Grid,
  Link,
  Paper,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useEffect, useMemo, useState } from "react";
import {
  Link as RouterLink,
  Route,
  Switch,
  useHistory,
  useRouteMatch,
} from "react-router-dom";
import BoatPage from "./BoatPage";
import BoatProvider from "providers/BoatProvider";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useDeleteBoat, useGetAllBoats } from "@actions/boatActions";
import { useRoutesContext } from "providers/RoutesProvider";
import { BoolCol } from "@components/booking/BookingQuotes";

const BoatsPage = () => {
  const { routes } = useRoutesContext();
  const history = useHistory();
  const { path } = useRouteMatch();
  const [open, setOpen] = useState(false);
  const [boats, setBoats] = useState([]);
  const [search, setSearch] = useState("");
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { loading, error, data } = useGetAllBoats();
  const [deleteBoat, { loading: deleteLoading }] = useDeleteBoat();

  useEffect(() => {
    if (data?.boats) {
      setBoats(data?.boats);
    }
  }, [data]);

  useEffect(() => {
    if (search && search.length > 1) {
      setBoats(
        data?.boats.filter((b) =>
          b.name.toLowerCase().includes(search.toLowerCase())
        )
      );
      return;
    }
    setBoats(data?.boats || []);
  }, [search]);

  const nameColumn = (col, row) => {
    return (
      <Link
        variant="body1"
        color="primary"
        component={RouterLink}
        to={`/boats/${row.id}`}
      >
        {col}
      </Link>
    );
  };

  const toEditPage = (id) => {
    history.push(`/boats/${id}/edit`);
  };

  const onEdit = (ref) => {
    toEditPage(ref.id);
  };

  const onDelete = (ref) => {
    openConfirmation({
      title: `Delete ${ref.name}`,
      body: `Deleting ${ref.name} can not be undone, are you sure you want to continue?`,
      callback: () => onDeleteBoat(ref.id),
      loading: deleteLoading,
    });
  };

  const onDeleteBoat = (id) => {
    deleteBoat({
      variables: {
        id,
      },
    }).then(() => {
      closeConfirmation();
    });
  };

  if (loading) return "Loading...";
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <Breadcrumb routes={routes} />
      <Divider />
      <br />
      <Switch>
        <Route path="/boats/:boatid">
          <BoatProvider>
            <BoatPage />
          </BoatProvider>
        </Route>
        <Route exact path={path}>
          <Grid container spacing={3}>
            <Grid container item justify="space-between" alignItems="center">
              <Grid item xs={1}>
                <Typography variant="h5">Our boats</Typography>
              </Grid>
              <Grid item xs={9}>
                <TextField
                  label="search"
                  variant="outlined"
                  size="small"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Grid>

              <Button
                variant="contained"
                color="primary"
                startIcon={<AddIcon />}
                onClick={() => setOpen(true)}
              >
                New boat
              </Button>
            </Grid>
            <Grid item xs={12}>
              <CardTable
                tableHead={[
                  "name",
                  "oneLiner",
                  "capacity",
                  "minimumHire",
                  "archived",
                ]}
                tableData={boats}
                customColumns={[
                  {
                    name: "name",
                    component: nameColumn,
                  },
                  {
                    name: "archived",
                    component: BoolCol,
                  },
                ]}
                actions={[
                  {
                    icon: <EditIcon color="primary" />,
                    fn: onEdit,
                  },
                  {
                    icon: <DeleteIcon color="error" />,
                    fn: onDelete,
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Route>
      </Switch>
      <NewBoatModal open={open} setOpen={setOpen} onSave={toEditPage} />
    </>
  );
};

export default BoatsPage;
