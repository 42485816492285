import LocationsList from "@components/location/LocationsList";
import { Button, Divider, Grid, Typography } from "@material-ui/core";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import AddIcon from "@material-ui/icons/Add";
import {
  useDeleteLocation,
  useGetAllLocations,
} from "@store/actions/locationActions";
import { useEffect, useState } from "react";
import Loading from "@components/loading/Loading";
import LocationModal from "@components/location/LocationModal";
import { LocationsSection } from "components-library";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Uploader from "@components/media/Uploader";
import { generateUploadedPath } from "utils/helpers";
import { useCreateMedia, useGetAllMedia } from "@store/actions/mediaActions";
import {
  useGetHomepage,
  useUpdateHomepage,
} from "@store/actions/homepageActions";
import MediaSelectModal from "@components/media/MediaSelectModal";

function LocationsPage() {
  const [open, setOpen] = useState(false);
  const [location, setLocation] = useState({});
  const { data: homepageData } = useGetHomepage();
  const { loading, data, error } = useGetAllLocations();
  const { showNotification } = useNotificationContext();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [deleteLocation, { loading: deleting }] = useDeleteLocation();
  const [addMedia] = useCreateMedia();
  const [updateHomepage, { loading: uploading }] = useUpdateHomepage();
  const [locationImage, setLocationImage] = useState("");
  const [openMedia, setOpenMedia] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const { data: mediaData } = useGetAllMedia();

  useEffect(() => {
    if (!homepageData) {
      return;
    }
    setLocationImage(homepageData?.homepage?.locationImage?.mediaFiles[0].path);
    if (homepageData?.homepage?.locationImage) {
      setSelectedMedia([homepageData?.homepage?.locationImage]);
    }
  }, [homepageData]);

  const onSelectMedia = (sel) => {
    const media = sel.length ? sel[sel.length - 1] : {};
    setSelectedMedia([media]);
    handleImageSelected(media);
    setOpenMedia(false);
  };

  const onFinish = async (result, file) => {
    const source = generateUploadedPath(result.signedUrl.split("?")[0]);

    // save a media
    const { data: res } = await addMedia({
      variables: {
        input: {
          name: file.name, // todo, user controlled
          source,
        },
      },
    });

    handleImageSelected(res.createMedia);
  };

  const handleImageSelected = async (media) => {
    const {
      title,
      subTitle,
      seoTitle,
      seoDescription,
      featuredImage,
    } = homepageData.homepage;

    const input = {
      title,
      subTitle,
      seoTitle,
      seoDescription,
      locationImage: {
        id: media.id,
      },
    };

    if (featuredImage && featuredImage.id) {
      input.featuredImage = {
        id: featuredImage.id,
      };
    }

    try {
      await updateHomepage({
        variables: {
          input,
        },
      });

      showNotification("Homepage Location Image Saved");
    } catch (e) {
      showNotification("Failed to Save Homepage Location Image");
    }
    setLocationImage(media.mediaFiles[0].path);
  };

  const newLocation = () => {
    setLocation({});
    setOpen(true);
  };

  const onEdit = (location) => {
    setLocation(location);
    setOpen(true);
  };

  const onDelete = ({ name, id }) => {
    openConfirmation({
      title: `Delete ${name}`,
      body: `Deleting ${name} can not be undone, are you sure you want to continue?`,
      loading: () => deleting,
      callback: () =>
        deleteLocation({
          variables: {
            id,
          },
        }).then(() => {
          showNotification(`${name} Deleted!`);
          closeConfirmation();
        }),
    });
  };

  const onSave = ({ name, id }) => {
    showNotification(`${name} Saved!`);
    setOpen(false);
  };

  if (loading) return <Loading cover={true} />;
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          container
          item
          xs={10}
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={9}>
            <Typography variant="h5">Locations</Typography>
            <Typography variant="subtitle1">
              Locations are areas where we offer our services
            </Typography>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={newLocation}
          >
            New Location
          </Button>
        </Grid>
        <Grid item xs={10}>
          <LocationsList
            locations={data.locations}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Grid>
        <Grid item xs={10}>
          <Divider />
        </Grid>
        <Grid item xs={10}>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Typography>
                Below is Preview of what this will look like on the homepage
              </Typography>
              <Typography>
                You can also add a image to the homepage locations section
              </Typography>
            </Grid>
            <Grid item xs={3}>
              <Button
                color="primary"
                variant="contained"
                component="label"
                fullWidth
                startIcon={<CloudUpload />}
              >
                Upload Location Image
                <Uploader
                  path="homepage/location"
                  onFinish={onFinish}
                  hidden={true}
                  accept="image/*"
                />
              </Button>
            </Grid>
            <Grid item xs={2}>
              <Button
                color="primary"
                variant="contained"
                component="label"
                onClick={() => setOpenMedia(true)}
              >
                Select media
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={10}>
          <Divider />
        </Grid>

        <Grid item xs={10}>
          <LocationsSection
            image={locationImage}
            locations={data.locations}
            LinkComponent={({ children }) => <a>{children}</a>}
          />
        </Grid>
      </Grid>

      <LocationModal
        open={open}
        setOpen={setOpen}
        onSave={onSave}
        location={location}
        setLocation={setLocation}
      />
      <MediaSelectModal
        open={openMedia}
        setOpen={setOpenMedia}
        media={mediaData?.medias || []}
        selected={selectedMedia}
        setSelected={onSelectMedia}
        min={1}
        max={2} // 2 to allow swapping
      />
    </>
  );
}

export default LocationsPage;
