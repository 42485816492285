import React, { useEffect, useMemo } from "react";
import {
  Avatar,
  Badge,
  Grid,
  Link,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { format } from "date-fns";
import useVisibility from "hooks/useVisibility";
import { useMarkAsSeen } from "@store/actions/messageActions";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  message: {
    background: theme.palette.background.paper,
    borderRadius: theme.shape.borderRadius,
    width: "auto",
  },
  ownerMsg: {
    background: theme.palette.grey[700],
    color: theme.palette.common.white,
    "& .MuiTypography-root": {
      color: theme.palette.common.white,
    },
  },
  toOwnerMsg: {
    background: theme.palette.grey[600],
    color: theme.palette.common.white,
    "& .MuiTypography-root": {
      color: theme.palette.common.white,
    },
  },
  sales: {
    margin: theme.spacing(1, 20, 2, 2),
  },
  lead: {
    margin: theme.spacing(1, 2, 2, 20),
  },
  userAvatar: {
    color: theme.palette.action.contrastText,
    backgroundColor: theme.palette.action.main,
  },
  leadAvatar: {
    color: theme.palette.primary.contrastText,
    backgroundColor: theme.palette.primary.main,
  },
  ownerAvatar: {
    color: theme.palette.secondary.contrastText,
    backgroundColor: theme.palette.secondary.main,
  },
}));

function ChatItem({ message: m }) {
  const classes = useStyles();
  const [isVisible, ref] = useVisibility();
  const [markAsSeen] = useMarkAsSeen();

  useEffect(() => {
    if (m.seenOn || (!m.lead && !m.owner)) {
      return;
    }
    markAsSeen({
      variables: {
        id: m.id,
      },
    });
  }, [isVisible]);

  const isSales = useMemo(() => {
    return !m.lead && !m.owner;
  }, [m]);

  const contact = useMemo(() => {
    return m.lead ? m.lead : m.owner ? m.owner : null;
  }, [m]);

  return (
    <div className={!isSales ? classes.lead : classes.sales} ref={ref}>
      <Badge
        ref={ref}
        style={{ display: "block" }}
        color={
          m.status === "error"
            ? "error"
            : m.status === "sent" || m.status === "recieved"
            ? "default"
            : "secondary"
        }
        variant="dot"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        {m.owner && (
          <Typography variant="caption" color="secondary">
            (Boat Owner)
          </Typography>
        )}
        <ListItem
          alignItems="flex-start"
          className={classNames(classes.message, {
            [classes.ownerMsg]: m.owner,
            [classes.toOwnerMsg]: m.toOwner,
          })}
        >
          <ListItemAvatar>
            <Avatar
              className={
                m.lead
                  ? classes.leadAvatar
                  : m.owner
                  ? classes.ownerAvatar
                  : classes.userAvatar
              }
            >
              {!isSales ? contact.firstName[0] : "?"}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={
              <>
                <Typography variant="body2">
                  {m.toLead
                    ? `To ${m.toLead?.firstName || ""}`
                    : m.toOwner
                    ? `To ${m.toOwner?.firstName || ""} (Owner)`
                    : m.lead
                    ? `From ${m.lead?.firstName || ""}`
                    : `From ${m.owner?.firstName || ""} (Owner)`}
                </Typography>
                <Typography variant="body2">
                  Message :{" "}
                  {m.content.split("\n").map((text, idx) => (
                    <Typography
                      display={idx === 0 ? "inline" : "block"}
                      key={idx}
                      variant="body2"
                    >
                      {text}
                    </Typography>
                  ))}
                </Typography>
              </>
            }
            secondary={
              <>
                <Typography
                  component="span"
                  variant="body2"
                  className={classes.inline}
                  color="textPrimary"
                ></Typography>{" "}
                {format(new Date(m.createdAt), "PPp")}
              </>
            }
          />
        </ListItem>
      </Badge>

      <Grid container justify="space-between" alignItems="center">
        {m.booking && (
          <div>
            {m.quote && (
              <Typography variant="caption">
                (Quote Ref :{" "}
                <Link
                  color="primary"
                  component={RouterLink}
                  to={`/bookings/${m.booking.id}/quotes/${m.quote.id}`}
                >
                  {m.quote.reference}
                </Link>
                )
              </Typography>
            )}
            {m.bookingOption && (
              <Typography variant="caption">
                (Options Ref :{" "}
                <Link
                  color="primary"
                  component={RouterLink}
                  to={`/bookings/${m.booking.id}/options/${m.bookingOption.id}`}
                >
                  {m.bookingOption.reference}
                </Link>
                )
              </Typography>
            )}
          </div>
        )}
        <Typography variant="caption" color="textSecondary">
          {m.seenOn && format(new Date(m.seenOn), "PPp")}
        </Typography>
      </Grid>
    </div>
  );
}

export default ChatItem;
