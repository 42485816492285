import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@material-ui/core";
import {
  useCreateBoatPriceSpecial,
  useUpdateBoatPriceSpecial,
} from "@actions/boatActions";
import { useEffect } from "react";
import { DatePicker } from "@mui/x-date-pickers";

const BoatSpecialRateModal = ({
  open,
  setOpen,
  boat,
  priceSpecial,
  setPriceSpecial,
}) => {
  const [addBoatPriceSpecial] = useCreateBoatPriceSpecial(boat.id);

  const [updateBoatPriceSpecial] = useUpdateBoatPriceSpecial(boat.id);

  const handleChange = (name) => (value) => {
    setPriceSpecial({
      ...priceSpecial,
      [name]: value,
    });
  };

  useEffect(() => {
    return () => {
      if (open) {
        setPriceSpecial({});
      }
    };
  }, [open]);

  const handleSubmit = (e) => {
    e.preventDefault();
    const { name, from, to, boatPriceId, id, recurring } = priceSpecial;
    const input = {
      name,
      from,
      to,
      recurring,
      boatId: boat.id,
      boatPriceId,
    };
    if (id) {
      updateBoatPriceSpecial({
        variables: {
          id,
          input,
        },
      }).then(() => setOpen(false));
    } else {
      addBoatPriceSpecial({
        variables: {
          input,
        },
      }).then(() => setOpen(false));
    }
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick={true}
    >
      <form onSubmit={handleSubmit}>
        <DialogTitle id="form-dialog-title">Boat Speical rate</DialogTitle>
        <DialogContent>
          <DialogContentText>
            You can set a special rate to run for a time period, e.g low season
          </DialogContentText>

          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextField
                autoFocus
                label="Name"
                fullWidth
                value={priceSpecial.name || ""}
                onChange={(event) => handleChange("name")(event.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <InputLabel id="rate">Rate</InputLabel>
                <Select
                  labelId="rate"
                  value={priceSpecial.boatPriceId || ""}
                  autoWidth
                  onChange={(event) =>
                    handleChange("boatPriceId")(event.target.value)
                  }
                >
                  {boat.boatPrices.map((bp) => (
                    <MenuItem key={bp.id} value={bp.id}>
                      {bp.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="From"
                value={priceSpecial.from || null}
                onChange={handleChange("from")}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="To"
                minDate={priceSpecial.from || null}
                value={priceSpecial.to || null}
                onChange={handleChange("to")}
                renderInput={(params) => <TextField fullWidth {...params} />}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControlLabel
                checked={priceSpecial.recurring || false}
                control={
                  <Checkbox
                    color="primary"
                    onChange={() =>
                      handleChange("recurring")(!priceSpecial.recurring)
                    }
                  />
                }
                label="Recurring"
                labelPlacement="end"
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default BoatSpecialRateModal;
