import {
  Breadcrumbs,
  Icon,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useApolloClient } from "@apollo/client";
import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  link: {
    display: "flex",
  },
  icon: {
    transform: "translateY(-3px)",
  },
}));

const LinkRouter = (props) => <Link {...props} component={RouterLink} />;

const Breadcrumb = ({ routes }) => {
  const client = useApolloClient();
  const location = useLocation();
  const [items, setItems] = useState([]);
  const { root, link, icon } = useStyles();

  useEffect(() => {
    setTimeout(() => {
      const pathnames = location.pathname.split("/").filter((x) => x);
      const ps = pathnames.map((_, index) => {
        let p = {};
        p.last = index === pathnames.length - 1;
        p.to = `/${pathnames.slice(0, index + 1).join("/")}`;
        const foundRoute = routes.find((r) => {
          // we'll match all parts
          const routeParts = (r.layout + (r.path === "/" ? "" : r.path))
            .split("/")
            .filter((x) => x);
          // need to add admin to `to` for check
          // really not sure why we still need admin here, I got rid of that???
          const toParts = ("/admin" + p.to).split("/").filter((x) => x);
          return (
            routeParts.length === toParts.length &&
            routeParts.every((v, i) => {
              return v[0] === ":" || v === toParts[i];
            })
          );
        });
        p = {
          ...p,
          ...foundRoute,
        };
        if (p.fragment) {
          const d = client.readFragment({
            id: `${p.fragment.model}:${pathnames[index]}`,
            fragment: p.fragment.on,
          });
          if (d) {
            p.name = d[p.fragment.field] || p.name;
          }
        }
        return p;
      });
      setItems(ps);
    }, 100);
  }, [routes, client, location]);

  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb" className={root}>
      {items.map((item) => {
        return item.last ? (
          <Typography className={link} color="primary" key={item.to}>
            {(item.name || "").toLowerCase() === "home" ? (
              <Icon
                className={icon}
                color="primary"
                component={item.icon}
              ></Icon>
            ) : (
              item.name
            )}
          </Typography>
        ) : (
          <LinkRouter
            className={link}
            color="inherit"
            to={item.to}
            key={item.to}
          >
            {(item.name || "").toLowerCase() === "home" ? (
              <Icon
                className={icon}
                color="inherit"
                component={item.icon}
              ></Icon>
            ) : (
              item.name
            )}
          </LinkRouter>
        );
      })}
    </Breadcrumbs>
  );
};

export default Breadcrumb;
