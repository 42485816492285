import React from "react";
import { makeStyles } from "@material-ui/core";
import StreetviewIcon from "@material-ui/icons/Streetview";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import FastfoodIcon from "@material-ui/icons/Fastfood";
import AccessibilityIcon from "@material-ui/icons/Accessibility";
import CardGiftcardIcon from "@material-ui/icons/CardGiftcard";
import { useCallback } from "react";
import { BookingItemCostType } from "@store/operations/bookingOperations";
import { capatalise, splitCamel } from "utils/strings";

const useCostItemStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
}));

function QuoteItemType({ type }) {
  const { icon } = useCostItemStyles();
  const render = useCallback(() => {
    switch (type) {
      case BookingItemCostType.pickUp:
        return (
          <>
            <StreetviewIcon className={icon} /> Pickup
          </>
        );
      case BookingItemCostType.dropOff:
        return (
          <>
            <StreetviewIcon className={icon} /> Drop Off
          </>
        );
      case BookingItemCostType.package:
        return (
          <>
            <CardGiftcardIcon className={icon} /> Package
          </>
        );
      case BookingItemCostType.catering:
      case BookingItemCostType.drinks:
      case BookingItemCostType.byoFood:
      case BookingItemCostType.byoDrink:
        return (
          <>
            <FastfoodIcon className={icon} /> {capatalise(splitCamel(type))}
          </>
        );
      case BookingItemCostType.staff:
        return (
          <>
            <AccessibilityIcon className={icon} /> Staff
          </>
        );
      case BookingItemCostType.other:
        return (
          <>
            <HelpOutlineIcon className={icon} /> Other
          </>
        );

      default:
        break;
    }
  }, [type]);
  return <>{render()}</>;
}

export default QuoteItemType;
