import React, { useState, useCallback } from "react";
import { jsx } from "slate-hyperscript";
import { Transforms, createEditor } from "slate";
import { withHistory } from "slate-history";
import { Slate, Editable, withReact } from "slate-react";
import { SlateToolbar } from "./SlateToolbar";
import {
  onPaste,
  serialize,
  SlateElement,
  SlateLeaf,
} from "./SlateEditorUtils";
import { useEffect } from "react";
import { withFlexGrid } from "./MuiGridPlugin";
import { Box, useTheme } from "@material-ui/core";
import { useEditorStyles } from "components-library";

const ELEMENT_TAGS = {
  A: (el) => ({ type: "link", url: el.getAttribute("href") }),
  BLOCKQUOTE: () => ({ type: "quote" }),
  H1: () => ({ type: "heading-one" }),
  H2: () => ({ type: "heading-two" }),
  H3: () => ({ type: "heading-three" }),
  H4: () => ({ type: "heading-four" }),
  H5: () => ({ type: "heading-five" }),
  H6: () => ({ type: "heading-six" }),
  IMG: (el) => ({ type: "image", url: el.getAttribute("src") }),
  LI: () => ({ type: "list-item" }),
  OL: () => ({ type: "numbered-list" }),
  P: () => ({ type: "paragraph" }),
  PRE: () => ({ type: "code" }),
  UL: () => ({ type: "bulleted-list" }),
};

// COMPAT: `B` is omitted here because Google Docs uses `<b>` in weird ways.
const TEXT_TAGS = {
  CODE: () => ({ code: true }),
  DEL: () => ({ strikethrough: true }),
  EM: () => ({ italic: true }),
  I: () => ({ italic: true }),
  S: () => ({ strikethrough: true }),
  STRONG: () => ({ bold: true }),
  U: () => ({ underline: true }),
};

export const deserialize = (el) => {
  if (el.nodeType === 3) {
    return el.textContent;
  } else if (el.nodeType !== 1) {
    return null;
  } else if (el.nodeName === "BR") {
    return "\n";
  }

  const { nodeName } = el;
  let parent = el;

  if (
    nodeName === "PRE" &&
    el.childNodes[0] &&
    el.childNodes[0].nodeName === "CODE"
  ) {
    parent = el.childNodes[0];
  }
  const children = Array.from(parent.childNodes).map(deserialize).flat();

  if (el.nodeName === "BODY") {
    return jsx("fragment", {}, children);
  }

  if (ELEMENT_TAGS[nodeName]) {
    const attrs = ELEMENT_TAGS[nodeName](el);
    return jsx("element", attrs, children);
  }

  if (TEXT_TAGS[nodeName]) {
    const attrs = TEXT_TAGS[nodeName](el);
    return children.map((child) => jsx("text", attrs, child));
  }

  return children;
};

const plugins = [];

const Editor = ({ msg, setMsg, html, setHtml, basic, minHeight }) => {
  const theme = useTheme();
  const { root } = useEditorStyles();
  const [value, setValue] = useState(
    msg || [
      {
        type: "element",
        children: [{ text: "" }],
      },
    ]
  );

  const renderElement = useCallback((props) => <SlateElement {...props} />, []);
  const renderLeaf = useCallback((props) => <SlateLeaf {...props} />, []);
  const [editor] = useState(() =>
    withHtml(withReact(withHistory(withFlexGrid(createEditor()))))
  );

  useEffect(() => {
    if (html && !msg) {
      // try parse old
      // editor.insertData(html);
      const parsed = new DOMParser().parseFromString(html, "text/html");
      const fragment = deserialize(parsed.body);
      setValue(
        fragment
          .filter((f) => f.text !== "\n")
          .map((f) => {
            return {
              ...f,
              children: f.children.length ? f.children : [{ text: "" }],
            };
          })
      );
    }
  }, []);

  useEffect(() => {
    setMsg(value);

    const html = value.map(serialize).join("\n");
    setHtml(html);
  }, [value]);

  return (
    <Box
      sx={{
        borderBottom: `4px solid ${theme.palette.divider}`,
        height: "100%",
      }}
      mb={4}
    >
      <Slate
        editor={editor}
        initialValue={value}
        onChange={(value) => {
          setValue(value);
        }}
        plugins={plugins}
      >
        <SlateToolbar basic={basic} />
        <Editable
          className={root}
          onPaste={onPaste(editor)}
          renderElement={renderElement}
          renderLeaf={renderLeaf}
          placeholder="Add content here..."
          style={{
            minHeight: minHeight || 50,
            outline: "none",
          }}
        />
      </Slate>
    </Box>
  );
};

const withHtml = (editor) => {
  const { insertData, isInline, isVoid } = editor;

  editor.isInline = (element) => {
    return element.type === "link" ? true : isInline(element);
  };

  editor.isVoid = (element) => {
    return element.type === "image" ? true : isVoid(element);
  };

  editor.insertData = (data) => {
    const html = data.getData("text/html");
    if (html) {
      const parsed = new DOMParser().parseFromString(html, "text/html");
      const fragment = deserialize(parsed.body);
      Transforms.insertFragment(editor, fragment);
      return;
    }

    insertData(data);
  };

  return editor;
};

export default Editor;
