import CommsItemFormBody from "@components/comms/CommsItemFormBody";
import { useBoatContext } from "@providers/BoatProvider";
import React, { useState } from "react";
import { useUpdateEffect } from "react-use";

function BoatCommision() {
  const { boat, setBoat } = useBoatContext();
  const [rates, setRates] = useState(boat.comms || []);

  useUpdateEffect(() => {
    setBoat((b) => ({
      ...b,
      comms: rates,
    }));
  }, [rates]);
  return <CommsItemFormBody rates={rates} setRates={setRates} />;
}

export default BoatCommision;
