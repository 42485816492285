import {
  Box,
  Fab,
  List,
  makeStyles,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import { useGetRecommendedBoats } from "@store/actions/boatActions";
import React, { useState } from "react";
import BookingItemDrag from "./BookingItemDrag";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

const useStyles = makeStyles((theme) => ({
  root: {
    transition: "all 0.4s",
  },
  fab: {
    position: "absolute",
    bottom: theme.spacing(2),
  },
  tabT: {
    "& .MuiTab-wrapper": {
      alignItems: "flex-start",
    },
  },
}));

function TabPanel({ children, value, index }) {
  return <div>{value === index && <>{children}</>}</div>;
}

function BookingOptionItems({ booking, option }) {
  const { root, fab, tabT } = useStyles();
  const [tab, setTab] = useState(0);
  const [collapsed, setCollapsed] = useState(false);
  const { data } = useGetRecommendedBoats(booking?.id);

  return (
    <div
      className={root}
      style={{
        minWidth: collapsed ? "230px" : tab === 2 ? "600px" : "370px",
        maxWidth: collapsed ? "230px" : "600px",
      }}
    >
      <Tabs
        value={tab}
        onChange={(_, v) => setTab(v)}
        orientation={collapsed ? "vertical" : "horizontal"}
      >
        <Tab
          className={tabT}
          label="Booking Items"
          onClick={() => setCollapsed(false)}
        />
        <Tab
          className={tabT}
          label="Boats"
          onClick={() => setCollapsed(false)}
        />
      </Tabs>
      {!collapsed && (
        <>
          <TabPanel value={tab} index={0}>
            <Box p={2}>
              <Typography>Select an exsiting booking item</Typography>
            </Box>

            <List>
              {booking?.items
                ?.filter((item) => item.boat)
                .map((item) => (
                  <BookingItemDrag key={item.id} item={item} />
                ))}
            </List>
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <Box p={2}>
              <Typography>Select a recommended Boat </Typography>
              <Typography variant="body2">
                (this will add this boat to your booking items)
              </Typography>
            </Box>
            <List>
              {data?.recommendedBoats
                .map((boat) => ({
                  boat,
                }))
                .map((item) => (
                  <BookingItemDrag key={item.boat.id} item={item} />
                ))}
            </List>
          </TabPanel>
        </>
      )}
      <Fab
        className={fab}
        size="medium"
        color="primary"
        onClick={() => setCollapsed(!collapsed)}
      >
        {collapsed ? <ChevronLeftIcon /> : <ChevronRightIcon />}
      </Fab>
    </div>
  );
}

export default BookingOptionItems;
