import { gql } from "@apollo/client";

export const GET_ALL_ROUTES = gql`
  query GetAllRoutes {
    routes @client {
      path
      name
      icon
      component
      layout
      exact
      showMenu
      fragment
      group
    }
  }
`;
