import {
  Button,
  Divider,
  Grid,
  makeStyles,
  Typography,
} from "@material-ui/core";
import { useBoatContext } from "providers/BoatProvider";
import { useState } from "react";
import BoatPriceModal from "./BoatPriceModal";
import BoatSpecialRateModal from "./BoatSpecialRateModal";
import BoatPriceTable from "./BoatPriceTable";
import BoatSpecialPriceTable from "./BoatSpecialPriceTable";
import {
  useDeleteBoatPrice,
  useDeleteBoatPriceSpecial,
} from "@actions/boatActions";

const useStyles = makeStyles(({ palette }) => ({
  empty: {
    minHeight: "200px",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  defaultRow: {
    background: palette.action.selected,
  },
}));

const BoatPrices = ({ updateBoat }) => {
  const { empty } = useStyles();
  const { boat, setBoat } = useBoatContext();
  const [open, setOpen] = useState(false);
  const [openSpecial, setOpenSpecial] = useState(false);
  const [price, setPrice] = useState({
    items: [{}],
  });
  const [priceSpecial, setPriceSpecial] = useState({});
  const [deleteBoatPrice] = useDeleteBoatPrice(boat.id);
  const [deleteBoatPriceSpecial] = useDeleteBoatPriceSpecial(boat.id);

  const handleOnSave = async (boatPrice) => {
    // if not default then set
    if (!boat.boatPrice) {
      const updatedBoat = {
        ...boat,
        boatPrice,
      };
      setBoat(updatedBoat);
      await updateBoat(updatedBoat);
    }

    setPrice({
      items: [{}],
    });
    setOpen(false);
  };

  const setDefault = (boatPrice) => {
    const updatedBoat = {
      ...boat,
      boatPrice,
    };
    setBoat(updatedBoat);
    updateBoat(updatedBoat);
  };

  const editRate = (boatPrice) => {
    setOpen(true);
    setPrice({
      ...boatPrice,
      items: boatPrice.items.length ? boatPrice.items : [{}],
    });
  };

  const editSpecialRate = (boatPriceSpecial) => {
    setOpenSpecial(true);
    setPriceSpecial({
      ...boatPriceSpecial,
      boatPriceId: boatPriceSpecial.boatPrice
        ? boatPriceSpecial.boatPrice.id
        : null,
    });
  };

  const deleteSpecialRate = ({ id }) => {
    deleteBoatPriceSpecial({
      variables: {
        id,
      },
    });
  };

  return (
    <>
      {!boat.boatPrices || boat.boatPrices.length === 0 ? (
        <div className={empty}>
          <Typography variant="subtitle1" gutterBottom>
            Boat currently has no default price{" "}
          </Typography>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setOpen(true)}
          >
            Click here to create one
          </Button>
        </div>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={9}>
                <Typography variant="subtitle2">
                  These are the hourly / daily rates for this boat.
                  <br /> The default rate shall be used for any time of the year
                  that you have not created a seasonal rate for.
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpen(true)}
                >
                  Add hourly / day new rate
                </Button>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <BoatPriceTable
              boat={boat}
              setDefault={setDefault}
              onEdit={editRate}
              onDelete={deleteBoatPrice}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Grid
              container
              spacing={2}
              justify="space-between"
              alignItems="center"
            >
              <Grid item xs={9}>
                <Typography variant="subtitle2">
                  You can create season rates and connect a hourly / day rate to
                  it.
                  <br />
                  e.g you could create a low hourly rate and high hourly rate,
                  you then might want to connect the high rate to christmas and
                  summer and low rates to the rest of the year
                </Typography>
              </Grid>
              <Grid item xs={3}>
                <Button
                  fullWidth
                  variant="outlined"
                  color="primary"
                  onClick={() => {
                    setPriceSpecial({
                      recurring: true,
                    });
                    setOpenSpecial(true);
                  }}
                >
                  Connect to months of the year
                </Button>
              </Grid>
              <Grid item xs={12}>
                <BoatSpecialPriceTable
                  boat={boat}
                  onEdit={editSpecialRate}
                  onDelete={deleteSpecialRate}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}
      <BoatPriceModal
        open={open}
        setOpen={setOpen}
        boat={boat}
        onSave={handleOnSave}
        price={price}
        setPrice={setPrice}
      />
      <BoatSpecialRateModal
        open={openSpecial}
        setOpen={setOpenSpecial}
        boat={boat}
        priceSpecial={priceSpecial}
        setPriceSpecial={setPriceSpecial}
      />
    </>
  );
};

export default BoatPrices;
