import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import { useCreateBookingOption } from "@store/actions/bookingActions";

function NewBookingOption({ open, setOpen, booking, onSaved }) {
  const [addBookingOption] = useCreateBookingOption(booking);

  const create = async () => {
    const res = await addBookingOption({
      variables: {
        bookingID: booking.id,
      },
    });
    setOpen(false);
    onSaved(res?.data?.createBookingOption?.id);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick={true}
    >
      <DialogTitle id="form-dialog-title">New Booking Option</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Click continue to create a new set of options to send to this lead
        </DialogContentText>
        <DialogContentText>
          On the next page you will be able to customise your options
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button color="primary" onClick={create}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewBookingOption;
