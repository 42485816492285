import {
  DialogActions,
  DialogContent,
  Grid,
  Button,
  TextField,
} from "@material-ui/core";
import { Dialog, DialogTitle } from "@material-ui/core";
import React, { useEffect } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import {
  useCreateBoatSection,
  useUpdateBoatSection,
} from "@store/actions/blockActions";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useBoatTileOrder } from "components-library";
import { useState } from "react";

function BoatSectionDialog({
  open,
  setOpen,
  boatSection,
  setBoatSection,
  boats,
}) {
  const [addBoatSection] = useCreateBoatSection();
  const [updateBoatSection] = useUpdateBoatSection();
  const { showNotification } = useNotificationContext();
  const { boats: orderedBoats } = useBoatTileOrder(boatSection);
  const [setBoats, setSetBoats] = useState(orderedBoats);

  useEffect(() => {
    setSetBoats(orderedBoats);
  }, [orderedBoats]);

  const close = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setBoatSection({
      ...boatSection,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const input = {
      name: boatSection.name,
      title: boatSection.title,
      boats: boatSection.boats.map((b) => ({
        id: b.id,
      })),
      boatsOrder: (boatSection.boats || []).map((l) => l.id),
    };
    const fn = boatSection.id ? updateBoatSection : addBoatSection;
    const res = await fn({
      variables: {
        id: boatSection.id || null,
        input,
      },
    });
    showNotification(`${boatSection.name} Saved!`);
    setBoatSection((o) => ({
      ...o,
      id: o.id ? o.id : res.data.createBoatSection.id,
    }));
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={close}
      disableBackdropClick={true}
    >
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>
          {boatSection.id ? `Edit ${boatSection.name}` : `Create Boat Section`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextValidator
                autoFocus
                variant="outlined"
                name="name"
                label="Name"
                validators={["required"]}
                errorMessages={["Boat Section requires a Name"]}
                fullWidth
                value={boatSection.name || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextValidator
                variant="outlined"
                name="title"
                label="Title"
                validators={["required"]}
                errorMessages={["Boat Section requires a Title"]}
                fullWidth
                value={boatSection.title || ""}
                onChange={handleChange}
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={boats}
                getOptionLabel={(boat) => boat.name || ""}
                onChange={(_, v) => {
                  setBoatSection({
                    ...boatSection,
                    boatsOrder: v.map((b) => b.id),
                    boats: v,
                  });
                }}
                value={setBoats || []}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Boats"
                    helperText="Select what Boats to show."
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default BoatSectionDialog;
