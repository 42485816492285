import {
  Box,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React from "react";

function DefaultLocationSelector({ locations, siteMeta, setSiteMeta }) {
  return (
    <Box mt={2}>
      <Typography variant="h6">Default Location? (Optional)</Typography>
      <Typography gutterBottom>
        If set this will be the default location in the search box on homepage
        and explore page
      </Typography>
      <Box mt={1} sx={{ width: 400 }}>
        <TextField
          select
          InputProps={{
            endAdornment: (
              <IconButton
                onClick={() => {
                  setSiteMeta({
                    ...siteMeta,
                    defaultLocation: undefined,
                  });
                }}
                style={{ order: 1, marginRight: 10 }}
              >
                <Clear color="disabled" fontSize="small" />
              </IconButton>
            ),
          }}
          fullWidth
          variant="outlined"
          value={siteMeta.defaultLocation?.id || ""}
          onChange={(e) =>
            setSiteMeta({
              ...siteMeta,
              defaultLocation: {
                id: e.target.value,
              },
            })
          }
        >
          {(locations || []).map((l) => (
            <MenuItem key={l.id} value={l.id}>
              {l.name}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    </Box>
  );
}

export default DefaultLocationSelector;
