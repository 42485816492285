import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_USER,
  GET_ALL_USERS,
  UPDATE_USER,
} from "@store/operations/userOperations";

export const useCreateUser = () =>
  useMutation(CREATE_USER, {
    update: (cache, { data: { createUser } }) => {
      const data = cache.readQuery({
        query: GET_ALL_USERS,
      });
      cache.writeQuery({
        query: GET_ALL_USERS,
        data: {
          users: [...(data.users || []), createUser],
        },
      });
    },
  });

export const useUpdateUser = () => useMutation(UPDATE_USER);

export const useGetAllUser = () => useQuery(GET_ALL_USERS);
