import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useCreateFeatureGroup } from "@actions/featureActions";
import React from "react";

function FeatureGroupModal({ featureGroup, setFeatureGroup, open, setOpen }) {
  const [createFeatureGroup] = useCreateFeatureGroup();

  const onSubmit = (e) => {
    e.preventDefault();
    const input = {
      name: featureGroup.name,
      live: featureGroup.live,
    };
    createFeatureGroup({
      variables: {
        input,
      },
    }).then(close);
  };

  const close = () => {
    setFeatureGroup({});
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={close}
      disableBackdropClick={true}
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>New Feature Group</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Feature groups allow you to group features for the filters
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Feature Group Name"
            fullWidth
            value={featureGroup.name || ""}
            onChange={(e) =>
              setFeatureGroup({ ...featureGroup, name: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default FeatureGroupModal;
