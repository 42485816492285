"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.formatNumber = void 0;
var formatFloat = function formatFloat() {
  var n = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return n.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
};
var formatInt = function formatInt() {
  var n = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return n.toLocaleString();
};

// formats and returns string representation
var formatNumber = exports.formatNumber = function formatNumber() {
  var n = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return isNaN(n) ? "0" : Number.isInteger(n) ? formatInt(n) : formatFloat(n);
};