export const formatFloat = (n = 0) =>
  n.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

export const formatInt = (n = 0) => n.toLocaleString();

// formats and returns string representation
export const formatNumber = (n = 0) =>
  isNaN(n) ? "0" : Number.isInteger(n) ? formatInt(n) : formatFloat(n);
