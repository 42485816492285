import { Slide, Snackbar } from "@material-ui/core";
import React, { createContext, useContext, useMemo, useState } from "react";

export const NotificationContext = createContext(null);
export const useNotificationContext = () => useContext(NotificationContext);

const initialNotification = {
  open: false,
  msg: null,
};

const NotificationProvider = ({ children }) => {
  const [notification, setNotification] = useState(initialNotification);
  const showNotification = (msg) => {
    setNotification({
      open: true,
      msg,
    });
  };

  const state = useMemo(
    () => ({
      showNotification,
    }),
    []
  );

  const handleClose = () => {
    setNotification(initialNotification);
  };

  return (
    <NotificationContext.Provider value={state}>
      <>
        <Snackbar
          TransitionComponent={Slide}
          TransitionProps={{ direction: "left" }}
          autoHideDuration={5000}
          anchorOrigin={{ vertical: "top", horizontal: "right" }}
          open={notification.open}
          onClose={handleClose}
          message={notification.msg}
        />
        {children}
      </>
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
