import {
  Button,
  ButtonGroup,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  MenuItem,
  Switch,
  TextField,
} from "@material-ui/core";
import { useNotificationContext } from "@providers/NotificationProvider";
import {
  useCreateStaff,
  useStaffRoleEnum,
  useUpdateStaff,
} from "@store/actions/staffActions";
import { produce } from "immer";
import React, { useEffect, useMemo, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

function StaffModal({ open, setOpen, staff, setStaff }) {
  const [addStaff] = useCreateStaff();
  const [updateStaff] = useUpdateStaff();
  const { data: roles } = useStaffRoleEnum();
  const { showNotification } = useNotificationContext();

  const close = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    const type = e.target.type;
    const value = type === "checkbox" ? e.target.checked : e.target.value;
    setStaff({
      ...staff,
      [e.target.name]: value,
    });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const input = {
      firstName: staff.firstName,
      lastName: staff.lastName,
      email: staff.email,
      role: staff.role,
    };
    const fn = staff.id ? updateStaff : addStaff;
    const res = await fn({
      variables: {
        id: staff.id || null,
        input,
      },
    });
    showNotification(`${staff.firstName} Saved!`);
    setStaff((o) => ({
      ...o,
      id: o.id ? o.id : res.data.createStaff.id,
    }));

    close();
  };

  const rolesOptions = useMemo(() => {
    if (!roles) {
      return [];
    }
    return roles.__type?.enumValues.map((e) => e.name);
  }, [roles]);

  useEffect(() => {
    return () => {
      if (open) {
        setStaff({});
      }
    };
  }, [open]);

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={close}
      disableBackdropClick={true}
    >
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>
          <Grid container justify="space-between">
            <span>Boat Staff</span>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <>
            <DialogContentText>Set boat staffs details below</DialogContentText>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextValidator
                  autoFocus
                  variant="outlined"
                  name="firstName"
                  label="First Name"
                  validators={["required"]}
                  errorMessages={["Staff requires first name"]}
                  fullWidth
                  value={staff.firstName || ""}
                  onChange={handleChange}
                  size="small"
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  name="lastName"
                  label="Last Name"
                  fullWidth
                  value={staff.lastName || ""}
                  onChange={handleChange}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  name="email"
                  label="Email"
                  validators={["required", "isEmail"]}
                  errorMessages={[
                    "Email is required",
                    "Must be a valid Email Address",
                  ]}
                  fullWidth
                  value={staff.email || ""}
                  onChange={handleChange}
                  size="small"
                />
              </Grid>
              <Grid item xs={12}>
                <TextValidator
                  variant="outlined"
                  name="role"
                  label="Role"
                  validators={["required"]}
                  errorMessages={["Role is required"]}
                  fullWidth
                  value={staff.role || ""}
                  onChange={handleChange}
                  size="small"
                  select
                >
                  {rolesOptions.map((m, i) => (
                    <MenuItem value={m} key={i}>
                      {m}
                    </MenuItem>
                  ))}
                </TextValidator>
              </Grid>
            </Grid>
          </>
        </DialogContent>

        <DialogActions>
          <Button onClick={close} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default StaffModal;
