import { Grid, TextField } from "@material-ui/core";
import { useLandingPageContext } from "@providers/LandingPageProvider";
import React from "react";

function LandingPageSEO() {
  const { landingPage, setLandingPage } = useLandingPageContext();
  const handleChange = (e) => {
    setLandingPage({
      ...landingPage,
      [e.target.name]: e.target.value,
    });
  };
  return (
    <form noValidate autoComplete="off">
      <Grid container spacing={3} direction="column">
        <Grid item xs={4}>
          <TextField
            name="seoTitle"
            onChange={handleChange}
            value={landingPage.seoTitle || ""}
            fullWidth
            size="small"
            label="Title"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={7}>
          <TextField
            name="seoDescription"
            onChange={handleChange}
            value={landingPage.seoDescription || ""}
            fullWidth
            multiline
            size="small"
            minRows={3}
            maxRows={6}
            label="Description"
            variant="outlined"
          />
        </Grid>
      </Grid>
    </form>
  );
}

export default LandingPageSEO;
