import { gql } from "@apollo/client";

export const GET_ALL_BOAT_SECTIONS = gql`
  query GetAllBoatSections {
    boatSections {
      id
      name
      title
      boatsOrder
      boats {
        id
        name
      }
    }
  }
`;

export const GET_BOAT_SECTION = gql`
  query GetBoatSection($input: BoatSectionInput!) {
    getBoatSection(input: $input) {
      id
      name
      title
      boatsOrder
      boats {
        id
        name
      }
    }
  }
`;

export const CREATE_BOAT_SECTION = gql`
  mutation CreateBoatSection($input: BoatSectionInput!) {
    createBoatSection(input: $input) {
      id
      name
      title
      boatsOrder
      boats {
        id
        name
      }
    }
  }
`;

export const UPDATE_BOAT_SECTION = gql`
  mutation UpdateBoatSection($id: UUID!, $input: BoatSectionInput!) {
    updateBoatSection(id: $id, input: $input) {
      id
      name
      title
      boatsOrder
      boats {
        id
        name
      }
    }
  }
`;

export const DELETE_BOAT_SECTION = gql`
  mutation DeleteBoatSection($id: UUID!) {
    deleteBoatSection(id: $id) {
      id
    }
  }
`;

export const GET_ALL_THUMB_SECTIONS = gql`
  query GetAllThumbSections {
    thumbLinkSections {
      id
      name
      landingPagesOrder
      landingPages {
        id
        name
        path
        title
        oneLiner
        description
        media {
          id
          name
          mediaFiles {
            name
            path
            type
          }
        }
      }
    }
  }
`;

export const CREATE_THUMB_SECTION = gql`
  mutation CreateThumbSection($input: ThumbLinkSectionInput!) {
    createThumbLinkSection(input: $input) {
      id
      name
      landingPagesOrder
      landingPages {
        id
        name
        path
        title
        oneLiner
        description
        media {
          id
          name
          mediaFiles {
            name
            path
            type
          }
        }
      }
    }
  }
`;

export const UPDATE_THUMB_SECTION = gql`
  mutation UpdateThumbSection($id: UUID!, $input: ThumbLinkSectionInput!) {
    updateThumbLinkSection(id: $id, input: $input) {
      id
      name
      landingPagesOrder
      landingPages {
        id
        name
        path
        title
        oneLiner
        description
        media {
          id
          name
          mediaFiles {
            name
            path
            type
          }
        }
      }
    }
  }
`;

export const DELETE_Thumb_SECTION = gql`
  mutation DeleteThumbSection($id: UUID!) {
    deleteThumbLinkSection(id: $id) {
      id
    }
  }
`;

export const GET_ALL_HTML_BLOCKS = gql`
  query GetAllCodeBlocks {
    codeBlocks {
      id
      name
      value
      updatedAt
    }
  }
`;

export const GET_HTML_BLOCK = gql`
  query GetCodeBlock($input: CodeBlockInput!) {
    getCodeBlock(input: $input) {
      id
      name
      value
    }
  }
`;

export const CREATE_HTML_BLOCK = gql`
  mutation CreateCodeBlock($input: CodeBlockInput!) {
    createCodeBlock(input: $input) {
      id
      name
      value
    }
  }
`;

export const UPDATE_HTML_BLOCK = gql`
  mutation UpdateCodeBlock($id: UUID!, $input: CodeBlockInput!) {
    updateCodeBlock(id: $id, input: $input) {
      id
      name
      value
    }
  }
`;

export const DELETE_HTML_BLOCK = gql`
  mutation DeleteCodeBlock($id: UUID!) {
    deleteCodeBlock(id: $id) {
      id
    }
  }
`;
