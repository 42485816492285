import Loading from "@components/loading/Loading";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  FormControlLabel,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { ArrowDownward, ArrowUpward } from "@material-ui/icons";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@material-ui/icons/KeyboardArrowUp";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { produce } from "immer";
import { useCallback, useEffect, useMemo, useState } from "react";

function MenuRow({
  row,
  options,
  index,
  onSave,
  onChangeOrder,
  length,
  onDelete,
}) {
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [path, setPath] = useState("");
  const [order, setOrder] = useState("");
  const [selected, setSelected] = useState([]);
  const [deleting, setDeleting] = useState(false);
  const { showNotification } = useNotificationContext();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();

  useEffect(() => {
    setName(row.name);
    setPath(row.path);
    setSelected(row.landingPages);
    setOrder(row.order || index + 1);
  }, [row]);

  const isChecked = (option) => {
    return selected.map((s) => s.id).includes(option.id);
  };

  const handleChange = (option) => {
    if (isChecked(option)) {
      setSelected([...selected.filter((s) => s.id !== option.id)]);
    } else {
      setSelected([...selected, option]);
    }
  };

  const handleSave = async (e) => {
    e.preventDefault();
    await onSave({
      id: row.id,
      name,
      path,
      landingPages: selected.map((s) => ({ id: s.id })),
      order,
    });

    showNotification(`${row.name} Updated`);
  };

  const handleDelete = () => {
    openConfirmation({
      title: `Delete ${row.name}`,
      body: `Deleting ${row.name} can not be undone, are you sure you want to continue?`,
      loading: deleting,
      callback: async () => {
        setDeleting(true);
        await onDelete({
          variables: {
            id: row.id,
          },
        });
        setDeleting(false);
        closeConfirmation();
        showNotification(`${row.name} Deleted!`);
      },
    });
  };

  return (
    <>
      <TableRow key={row.id}>
        <TableCell>
          <IconButton
            onClick={() => onChangeOrder("-", index)}
            disabled={index === 0}
          >
            <ArrowUpward fontSize={"small"} />
          </IconButton>
          {order}

          <IconButton
            onClick={() => onChangeOrder("+", index)}
            disabled={index === length - 1}
          >
            <ArrowDownward fontSize={"small"} />
          </IconButton>
        </TableCell>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {"  "}
          {row.name}
        </TableCell>
        <TableCell>{row.path}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={3}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box p={1}>
              <Paper variant="outlined">
                <Box p={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={6}>
                      <TextField
                        autoFocus
                        margin="dense"
                        label="Name"
                        fullWidth
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        margin="dense"
                        label="Path"
                        fullWidth
                        value={path}
                        onChange={(e) => setPath(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="subtitle2">
                        Select landing pages to display under menu item
                      </Typography>
                      {options.map((option) => (
                        <FormControlLabel
                          key={option.id}
                          control={
                            <Checkbox
                              color="primary"
                              name="t"
                              checked={isChecked(option)}
                              onChange={() => handleChange(option)}
                              name={option.id}
                            />
                          }
                          label={option.name}
                        />
                      ))}
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="primary"
                        onClick={handleSave}
                      >
                        Save
                      </Button>
                    </Grid>
                    <Grid item xs={1}>
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={handleDelete}
                      >
                        Delete
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Paper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function MenusList({ menus, loading, options, onSave, onDelete }) {
  const onChangeOrder = useCallback(
    async (t, idx) => {
      const newArr = [...menus];
      const item = newArr.splice(idx, 1)[0];
      const to = t === "+" ? idx + 1 : idx - 1;
      newArr.splice(to, 0, item);
      onSave(
        newArr.map((m, i) => ({
          id: m.id,
          name: m.name,
          path: m.path,
          landingPages: m.landingPages,
          order: i + 1,
        }))
      );
    },
    [menus]
  );

  const saveMenuItem = useCallback((item) => {
    onSave(
      produce(menus, (draft) => {
        const idx = draft.findIndex((d) => d.id === item.id);
        if (idx >= 0) {
          draft[idx] = item;
        }
      })
    );
  }, []);

  const inMenus = useMemo(() => {
    return [...menus].sort((a, b) => a.order - b.order);
  }, [menus]);

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          <TableCell>Order</TableCell>
          <TableCell>Name</TableCell>
          <TableCell>Path</TableCell>
          <TableCell></TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {loading ? (
          <Loading />
        ) : inMenus.length > 0 ? (
          inMenus.map((menu, i) => (
            <MenuRow
              key={menu.id}
              row={menu}
              options={options}
              index={i}
              onSave={saveMenuItem}
              onDelete={onDelete}
              onChangeOrder={onChangeOrder}
              length={inMenus.length}
            />
          ))
        ) : (
          <Typography variant="subtitle1">--</Typography>
        )}
      </TableBody>
    </Table>
  );
}

export default MenusList;
