import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
  TextField,
} from "@material-ui/core";
import { useCreateQuote, useQuoteTypeEnum } from "@store/actions/quoteActions";
import { useEffect, useState } from "react";
import { capatalise, splitCamel } from "utils/strings";

function NewBookingQuote({ open, setOpen, booking, onSaved }) {
  const [addQuote] = useCreateQuote(booking);
  const { data: quoteTypes } = useQuoteTypeEnum();
  const [type, setType] = useState("");
  const [types, setTypes] = useState([]);

  useEffect(() => {
    if (!quoteTypes) {
      return;
    }
    const types = quoteTypes.__type.enumValues.map((e) => e.name);
    setTypes(types);
    setType(types[0]);
  }, [quoteTypes]);

  const create = async () => {
    const res = await addQuote({
      variables: {
        bookingID: booking.id,
        type,
      },
    });
    setOpen(false);
    onSaved(res?.data?.createQuote?.id);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick={true}
    >
      <DialogTitle id="form-dialog-title">New Quote</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Select the type of quote this is and click continue
        </DialogContentText>
        <DialogContentText>
          On the next page you will be able to customise your quote
        </DialogContentText>
        <TextField
          select
          variant="outlined"
          name="type"
          label="Type"
          margin="normal"
          fullWidth
          value={type}
          onChange={(e) => setType(e.target.value)}
          size="small"
        >
          {types.map((type) => (
            <MenuItem key={type} value={type}>
              {capatalise(splitCamel(type))}
            </MenuItem>
          ))}
        </TextField>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)} color="secondary">
          Cancel
        </Button>
        <Button color="primary" onClick={create}>
          Continue
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default NewBookingQuote;
