"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toCurrency = void 0;
var toCurrency = exports.toCurrency = function toCurrency(amount, code) {
  var sign = "$";
  switch (code) {
    case "GBP":
      sign = "£";
      break;
  }
  return "".concat(sign).concat((amount || 0).toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  }));
};