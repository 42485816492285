import React, { createContext, useContext, useMemo, useState } from "react";

export const PackagePageContext = createContext(null);
export const usePackagePageContext = () => useContext(PackagePageContext);

const PackagePageProvider = ({ children }) => {
  const [packagePage, setPackagePage] = useState({});

  const state = useMemo(
    () => ({
      packagePage,
      setPackagePage,
    }),
    [packagePage]
  );

  return (
    <PackagePageContext.Provider value={state}>
      {children}
    </PackagePageContext.Provider>
  );
};

export default PackagePageProvider;
