import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useCreateContentPage } from "@store/actions/landingPageActions";
import React, { useState } from "react";

const NewContentPageDialog = ({ open, setOpen, onSave }) => {
  const [name, setName] = useState("");
  const [addContentPage] = useCreateContentPage();

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = async (e) => {
    e.preventDefault();
    const { data } = await addContentPage({
      variables: {
        input: { name },
      },
    });
    onSave(data.createContentPage);
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <form onSubmit={handleSave}>
        <DialogTitle id="form-dialog-title">New Content page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the content pages name, after which you will be taken
            to the content page setup wizard.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Content page Name"
            fullWidth
            value={name}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewContentPageDialog;
