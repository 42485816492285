import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useNotificationContext } from "@providers/NotificationProvider";
import {
  useCreateThumbSection,
  useUpdateThumbSection,
} from "@store/actions/blockActions";
import { useLandingTileOrder } from "components-library";
import { useEffect } from "react";

function ThumbLinkDialog({
  open,
  setOpen,
  thumbSection,
  setThumbSection,
  landingPages,
}) {
  const { showNotification } = useNotificationContext();
  const [addThumbSection] = useCreateThumbSection();
  const [updateThumbSection] = useUpdateThumbSection();
  const { tiles } = useLandingTileOrder(thumbSection);
  const [setTiles, setSetTiles] = useState(tiles);

  useEffect(() => {
    setSetTiles(tiles);
  }, [tiles]);

  const onSubmit = async (e) => {
    e.preventDefault();
    const input = {
      name: thumbSection.name,
      landingPages: (thumbSection.landingPages || []).map((l) => ({
        id: l.id,
      })),
      landingPagesOrder: (thumbSection.landingPages || []).map((l) => l.id),
    };
    const fn = thumbSection.id ? updateThumbSection : addThumbSection;
    const res = await fn({
      variables: {
        id: thumbSection.id || null,
        input,
      },
    });
    showNotification(`${thumbSection.name} Saved!`);
    setThumbSection((o) => ({
      ...o,
      id: o.id ? o.id : res.data.createThumbLinkSection.id,
    }));
    setOpen(false);
  };

  const close = () => {
    setOpen(false);
  };

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      open={open}
      onClose={close}
      disableBackdropClick={true}
    >
      <ValidatorForm onSubmit={onSubmit}>
        <DialogTitle>
          {thumbSection.id
            ? `Edit ${thumbSection.name}`
            : `Create Thumb Link Section`}
        </DialogTitle>
        <DialogContent>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <TextValidator
                autoFocus
                variant="outlined"
                name="name"
                label="Name"
                validators={["required"]}
                errorMessages={["Thumb Link Section requires a Name"]}
                fullWidth
                value={thumbSection.name || ""}
                onChange={(e) =>
                  setThumbSection({ ...thumbSection, name: e.target.value })
                }
                size="small"
              />
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                multiple
                options={landingPages || []}
                getOptionLabel={(landingPage) => landingPage.name || ""}
                onChange={(_, v) => {
                  setThumbSection({
                    ...thumbSection,
                    landingPagesOrder: v.map((f) => f.id),
                    landingPages: v,
                  });
                }}
                value={setTiles || []}
                fullWidth
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Landing Pages"
                    helperText="Select what Landing Pages to show."
                    variant="outlined"
                  />
                )}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default ThumbLinkDialog;
