const sidebarStyle = (theme) => {
  const { drawerWidth, transition } = theme.custom;

  return {
    drawerPaper: {
      background: theme.custom.sidebar.background,
      width: drawerWidth,
      boxShadow: theme.shadows[1],
      flexShrink: 0,
      whiteSpace: "nowrap",
      padding: theme.spacing(0, 2),
      boxSizing: "border-box",
    },
    item: {
      marginBottom: theme.spacing(1),
    },
    active: {
      "& .MuiButtonBase-root": {
        ...transition,
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        borderRadius: theme.shape.borderRadius,
      },
      "& .MuiSvgIcon-root": {
        color: theme.palette.primary.contrastText,
      },
    },
    branding: {
      paddingBottom: theme.spacing(1),
      marginBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.divider}`,
    },
    sidebarInner: {
      display: "flex",
      flexDirection: "column",
      height: "100vh",
      justifyContent: "space-between",
      paddingBottom: theme.spacing(2),
    },
  };
};

export default sidebarStyle;
