import { gql } from "@apollo/client";

export const GET_ALL_WHARFS = gql`
  query GetAllWharfs {
    wharfs {
      id
      name
      googleMapsLink
      taxablePostCode
      createdAt
    }
  }
`;

export const CREATE_WHARF = gql`
  mutation CreateWharf($input: WharfInput!) {
    createWharf(input: $input) {
      id
      name
      googleMapsLink
      taxablePostCode
      createdAt
    }
  }
`;
export const UPDATE_WHARF = gql`
  mutation UpdateWharf($id: UUID!, $input: WharfInput!) {
    updateWharf(id: $id, input: $input) {
      id
      name
      googleMapsLink
      taxablePostCode
      createdAt
    }
  }
`;

export const DELETE_WHARF = gql`
  mutation DeleteWharf($id: UUID!) {
    deleteWharf(id: $id) {
      id
    }
  }
`;
