import Loading from "@components/loading/Loading";
import LocationModal from "@components/location/LocationModal";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { useBoatContext } from "@providers/BoatProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useGetAllLocations } from "@store/actions/locationActions";
import React, { useCallback, useState } from "react";

function BoatLocations() {
  const [open, setOpen] = useState(false);
  const { boat, setBoat } = useBoatContext();
  const [location, setLocation] = useState({
    name: "",
  });
  const { loading, data } = useGetAllLocations();
  const { showNotification } = useNotificationContext();

  const onSave = useCallback(
    ({ name }) => {
      showNotification(`${name} Created!`);
      setOpen(false);
    },
    [showNotification]
  );
  const getBoatLocationIndex = useCallback(
    (location) => (boat.locations || []).map((l) => l.id).indexOf(location.id),
    [boat]
  );

  const isChecked = (location) => {
    return getBoatLocationIndex(location) >= 0;
  };

  const handleChange = (location) => {
    const idx = getBoatLocationIndex(location);
    let locations = [...(boat.locations || [])];
    if (idx === -1) {
      locations.push(location);
    } else {
      locations.splice(idx, 1);
    }
    setBoat({
      ...boat,
      locations,
    });
  };

  if (loading) return <Loading />;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid
            container
            justify="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={9}>
              <Typography variant="subtitle1">
                Select what location this boat can be booked from,
              </Typography>
              <Typography variant="subtitle2">
                A boat can be more than one location
              </Typography>
            </Grid>

            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Add new location
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {data.locations.map((l) => (
              <Grid key={l.id} item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isChecked(l)}
                      onChange={() => handleChange(l)}
                      name={l.id}
                    />
                  }
                  label={l.name}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <LocationModal
        open={open}
        setOpen={setOpen}
        onSave={onSave}
        location={location}
        setLocation={setLocation}
      />
    </>
  );
}

export default BoatLocations;
