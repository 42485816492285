import CardTable from "@components/cardTable/CardTable";
import Loading from "@components/loading/Loading";
import { Grid, Link, Typography } from "@material-ui/core";
import { useGetOwner } from "@store/actions/boatActions";
import React from "react";
import { useHistory, useParams } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import EditIcon from "@material-ui/icons/Edit";

const nameColumn = (col, row) => {
  return (
    <Link
      variant="body1"
      color="primary"
      component={RouterLink}
      to={`/boats/${row.id}`}
    >
      {col}
    </Link>
  );
};

function OwnerPage() {
  const { ownerid } = useParams();
  const { loading, data } = useGetOwner(ownerid);
  const history = useHistory();

  const onEdit = (boat) => {
    history.push(`/boats/${boat.id}/edit`);
  };

  if (loading || !data?.owner?.id) return <Loading />;
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">
          Owner page ({data.owner.firstName})
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant="body1">{data.owner.firstName}'s Boats</Typography>
      </Grid>
      <Grid item xs={7}>
        <CardTable
          tableHead={["name"]}
          tableData={data?.owner?.boats || []}
          customColumns={[
            {
              name: "name",
              component: nameColumn,
            },
          ]}
          actions={[
            {
              icon: <EditIcon color="primary" />,
              fn: onEdit,
            },
          ]}
        />
      </Grid>
    </Grid>
  );
}

export default OwnerPage;
