import WysiwygEditor from "@components/WysiwygEditor/WysiwygEditor";
import { Button, Grid } from "@material-ui/core";
import React from "react";

function Contact({ siteMeta, setSiteMeta, onSave, field }) {
  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <WysiwygEditor
          msg={siteMeta[field]}
          setMsg={(value) => {
            setSiteMeta({
              ...siteMeta,
              [field]: value,
            });
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <Button variant="contained" color="primary" onClick={onSave}>
          Save Contact Info
        </Button>
      </Grid>
    </Grid>
  );
}

export default Contact;
