import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch,
} from "react-router-dom";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import AdminLayout from "@layouts/admin/Admin";
import loadTheme from "@assets/jss/theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import Loading from "@components/loading/Loading";
import classNames from "classnames";
import LayoutProvider from "@providers/LayoutProvider";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import ConfirmationProvider from "@providers/ConfirmationProvider";
import RoutesProvider from "providers/RoutesProvider";
import BookingProvider from "@providers/BookingProvider";
import Login from "pages/auth/Login";
import { ProtectedRoute } from "@components/protectedroute/protectedroute";
import Forgot from "pages/auth/Forgot";
import Reset from "pages/auth/Reset";

const useStyles = makeStyles({
  app: {
    display: "block",
    visibility: "hidden",
    opacity: 0,
    transition: "visibility 0s, opacity 0.2s linear",
  },
  active: {
    visibility: "visible",
    opacity: 1,
  },
});

const App = () => {
  const [theme, setTheme] = useState(null);
  const [themeLoaded, setThemeLoaded] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const effectTheme = async () => {
      const t = await loadTheme();
      setTheme(t);
      // set time out so that transiation effect works
      setTimeout(() => {
        setThemeLoaded(true);
      }, 1);
    };
    effectTheme();
  }, []);

  if (!theme) {
    return <Loading />;
  }

  return (
    <ThemeProvider theme={theme}>
      <DndProvider backend={HTML5Backend}>
        <RoutesProvider>
          <LayoutProvider>
            {/* <NotificationProvider> */}
            <ConfirmationProvider>
              <BookingProvider>
                <CssBaseline />
                <Router>
                  <span
                    className={classNames(classes.app, {
                      [classes.active]: themeLoaded,
                    })}
                  >
                    <Switch>
                      <Route path="/login" exact component={Login} />
                      <Route path="/forgot" exact component={Forgot} />
                      <Route
                        path="/reset-password/:code"
                        exact
                        component={Reset}
                      />
                      <Route exact path="/">
                        <Redirect to="/bookings" />
                      </Route>
                      <ProtectedRoute path="/" component={AdminLayout} />
                    </Switch>
                  </span>
                </Router>
              </BookingProvider>
            </ConfirmationProvider>
            {/* </NotificationProvider> */}
          </LayoutProvider>
        </RoutesProvider>
      </DndProvider>
    </ThemeProvider>
  );
};

export default App;
