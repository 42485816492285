import React, { useEffect, useMemo, useState } from "react";
import { Hero, Header, HomeHero } from "components-library";
import {
  Box,
  Button,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Switch,
  Tooltip,
} from "@material-ui/core";
import classNames from "classnames";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import DesktopMacIcon from "@material-ui/icons/DesktopMac";
import {
  useGetHomepage,
  useUpdateHomepage,
} from "@store/actions/homepageActions";
import { useNotificationContext } from "@providers/NotificationProvider";
import Loading from "@components/loading/Loading";
import { useGetAllLocations } from "@store/actions/locationActions";
import { useGetAllBoatTypes } from "@store/actions/boatActions";
import {
  useCreateMenu,
  useCreateTopBarMenu,
  useDeleteMenu,
  useDeleteTopBarMenu,
  useGetAllMenus,
  useGetAllTopBarMenus,
  useUpdateMenu,
  useUpdateTopBarMenu,
} from "@store/actions/menuActions";
import MenusList from "@components/menu/MenusList";
import NewMenuItemModal from "@components/menu/NewMenuItemModal";
import { useGetAllLandingPages } from "@store/actions/landingPageActions";
import HomeAccordian from "@components/home/HomeAccordian";
import HomeForm from "@components/home/HomeForm";
import SearchEditForm from "@components/home/SearchEditForm";
import BlockSelectionDrawer from "@components/contentBlocks/BlockSelectionDrawer";
import BlockDropzone from "@components/contentBlocks/BlockDropzone";
import { useCallback } from "react";
import { usePreviousDistinct, useUpdateEffect } from "react-use";
import { Help } from "@material-ui/icons";
import {
  useGetSiteMeta,
  useUpdateSiteMeta,
} from "@store/actions/siteMetaActions";
import DefaultLocationSelector from "@components/defaultLocationSelector/DefaultLocationSelector";

const useStyles = makeStyles((theme) => ({
  heroContainer: {
    position: "relative",
    paddingTop: "0!important",
  },
  heroContainerMobile: {
    maxWidth: "420px",
    minHeight: 700,
    margin: "auto",
  },
  blockDrop: {
    width: "100%",
    paddingRight: 360,
  },
}));

export const dummyLink = ({ children, className }) => (
  <a className={className}>{children}</a>
);

const HomePage = () => {
  const [addMenu] = useCreateMenu();
  const [deleteMenu] = useDeleteMenu();
  const [updateMenu] = useUpdateMenu();
  const [addTopMenu] = useCreateTopBarMenu();
  const [deleteTopMenu] = useDeleteTopBarMenu();
  const [updateTopMenu] = useUpdateTopBarMenu();
  const { heroContainer, heroContainerMobile, blockDrop } = useStyles();
  const { loading, data } = useGetHomepage();
  const { loading: menusLoading, data: menusData } = useGetAllMenus();
  const {
    loading: topMenusLoading,
    data: topMenusData,
  } = useGetAllTopBarMenus();
  const { data: landingPageData } = useGetAllLandingPages();
  const { loading: metaLoading, data: meta } = useGetSiteMeta();
  const [updateSitemeta] = useUpdateSiteMeta();
  const [open, setOpen] = useState(false);
  const [blocksOpen, setBlocksOpen] = useState(false);
  const [homepage, setHomepage] = useState({});
  const [items, setItems] = useState([]);
  const prevSections = usePreviousDistinct(homepage.sections);
  const [siteMeta, setSiteMeta] = useState({});
  const [backgroundImage, setBackgroundImage] = useState("");
  const [mode, setMode] = useState("desktop");
  const [sectionsChanged, setSectionsChanged] = useState(false);
  const { showNotification } = useNotificationContext();
  const {
    loading: locationsLoading,
    data: locationsData,
  } = useGetAllLocations();
  const {
    loading: boatTypesLoading,
    data: boatTypesData,
  } = useGetAllBoatTypes();
  const [location, setLocation] = useState({});
  const [selectedBoatTypes, setSelectedBoatTypes] = useState([]);
  const [maxPrice, setMaxPrice] = useState("");
  const [capacity, setCapacity] = useState("");
  const [updateHomepage, { loading: saving }] = useUpdateHomepage();

  useEffect(() => {
    if (!data) {
      return;
    }
    const { homepage } = data;
    setHomepage(homepage);
    if (homepage.featuredImage) {
      setBackgroundImage(homepage.featuredImage.mediaFiles[0].path);
    }
    if (homepage.sections) {
      setItems(homepage.sections);
    }
  }, [data]);

  useEffect(() => {
    if (!meta) {
      return;
    }
    setSiteMeta(meta.siteMeta);
  }, [meta]);

  const testOnSearch = (location, boatType, capcity) => {
    showNotification(
      `Search for location : ${location.name}, boatType : ${boatType.name}, capcity: ${capcity} `
    );
  };

  const saveMenu = async ({ id, name, path, landingPages, order }, fn) => {
    const input = {
      name,
      path,
      landingPages: landingPages.map((s) => ({ id: s.id })),
      order,
    };
    await fn({
      variables: {
        id,
        input,
      },
    });
  };
  const onSaveMenu = useCallback(async (menus) => {
    await Promise.all(menus.map((args) => saveMenu(args, updateMenu)));
  }, []);
  const onSaveTopMenu = useCallback(async (menus) => {
    await Promise.all(menus.map((args) => saveMenu(args, updateTopMenu)));
  }, []);

  const onSaveSiteMeta = async () => {
    const input = {
      searchBox: siteMeta.searchBox,
      showBoatTypeMenu: siteMeta.showBoatTypeMenu,
      showLocationMenu: siteMeta.showLocationMenu,
      defaultLocationID: siteMeta.defaultLocation
        ? siteMeta.defaultLocation.id
        : undefined,
    };
    try {
      await updateSitemeta({
        variables: {
          input,
        },
      });

      showNotification("Site Meta Updated");
    } catch (e) {
      showNotification("Failed to Update site meta");
    }
  };

  const orderMenus = (menus) =>
    [...(menus || [])].sort((a, b) => {
      if (a.order > b.order) return 1;
      if (a.order < b.order) return -1;
      return a.name.localeCompare(b.name);
    });
  const menus = useMemo(() => {
    if (!menusData) {
      return [];
    }
    return orderMenus([...(menusData.menus || [])]);
  }, [menusData]);

  const topMenus = useMemo(() => {
    if (!topMenusData) {
      return [];
    }
    return orderMenus([...(topMenusData.topBarMenus || [])]);
  }, [topMenusData]);

  const topLinks = useMemo(() => {
    if (!topMenus) {
      return [];
    }
    return topMenus.map((m) => ({
      name: m.name,
      path: m.path,
      nested: m.landingPages.filter((lp) => lp.path),
    }));
  }, [topMenus]);

  const links = useMemo(() => {
    if (!menus) {
      return [];
    }
    return menus.map((m) => ({
      name: m.name,
      nested: m.landingPages.filter((lp) => lp.path),
    }));
  }, [menus]);

  const saveHomepage = useCallback(
    async (e) => {
      if (e) {
        e.preventDefault();
      }

      const {
        title,
        subTitle,
        seoTitle,
        seoDescription,
        featuredImage,
        videoLink,
        showContact,
        sections,
      } = homepage;
      const input = {
        title,
        subTitle,
        seoTitle,
        seoDescription,
        videoLink,
        showContact,
      };
      if (featuredImage && featuredImage.id) {
        input.featuredImage = {
          id: featuredImage.id,
        };
      }

      if (sections?.length) {
        input.sections = sections.map((s) => ({
          title: s.title,
          type: s.type,
          link: s.link,
          linkText: s.linkText,
          colWidth: s.colWidth,
          order: s.order,
          section: {
            id:
              s.type === "boatSection"
                ? s.boatSection.id
                : s.type === "codeBlock"
                ? s.codeBlock.id
                : s.thumbLinkSection.id,
          },
        }));
      }

      try {
        await updateHomepage({
          variables: {
            input,
          },
        });

        showNotification("Homepage Saved");
      } catch (e) {
        showNotification("Failed to Save Homepage");
      }
    },
    [homepage]
  );

  useUpdateEffect(() => {
    if (!sectionsChanged) {
      return;
    }

    saveHomepage();
    setSectionsChanged(false);
  }, [sectionsChanged]);

  const onSaveBlocks = useCallback(
    (sections) => {
      setHomepage((prev) => ({
        ...prev,
        sections,
      }));
      setSectionsChanged(true);
    },
    [homepage]
  );

  const boatTypeLinks = useMemo(() => {
    if (!siteMeta.showBoatTypeMenu || !boatTypesData) return [];
    return (boatTypesData.boatTypes || []).filter((bt) => bt.path);
  }, [boatTypesData?.boatTypes, siteMeta]);

  const locationLinks = useMemo(() => {
    if (!siteMeta.showLocationMenu) return [];
    return (locationsData?.locations || [])
      .filter((l) => l.name)
      .map((l) => ({
        ...l,
        path: l.name,
      }));
  }, [locationsData?.locations, siteMeta]);

  if (loading || locationsLoading || boatTypesLoading)
    return <Loading cover={true} />;

  const { title, subTitle, videoLink } = homepage;

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <div
            className={classNames(heroContainer, {
              [heroContainerMobile]: mode === "mobile",
            })}
          >
            <Header
              LinkComponent={dummyLink}
              isMobile={mode === "mobile"}
              boatTypes={boatTypeLinks}
              locations={locationLinks}
              links={links}
              topLinks={topLinks}
              useLoad={true}
            />
            <HomeHero
              backgroundImage={backgroundImage}
              title={title}
              subTitle={subTitle}
              locations={locationsData?.locations || []}
              boatTypes={boatTypesData?.boatTypes}
              onSearch={testOnSearch}
              location={location}
              setLocation={setLocation}
              selectedBoatTypes={selectedBoatTypes}
              setSelectedBoatTypes={setSelectedBoatTypes}
              videoLink={videoLink}
              maxPrice={maxPrice}
              setMaxPrice={setMaxPrice}
              capacity={capacity}
              setCapacity={setCapacity}
              searchBoxItems={siteMeta?.searchBox}
            />
          </div>
        </Grid>
        <Container>
          <Grid item>
            <IconButton
              color={mode === "desktop" ? "primary" : "inherit"}
              onClick={() => setMode("desktop")}
            >
              <DesktopMacIcon fontSize="inherit" />
            </IconButton>
            <IconButton
              color={mode != "desktop" ? "primary" : "inherit"}
              onClick={() => setMode("mobile")}
            >
              <PhoneAndroidIcon fontSize="inherit" />
            </IconButton>
          </Grid>
          <Grid container spacing={3} justify="space-between">
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <HomeAccordian
                title="Edit Top Bar Menu Options"
                actions={
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen("top")}
                  >
                    Add new top bar menu option
                  </Button>
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Paper>
                      <MenusList
                        menus={topMenus || []}
                        options={landingPageData?.landingPages || []}
                        loading={topMenusLoading}
                        onSave={onSaveTopMenu}
                        onDelete={deleteTopMenu}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </HomeAccordian>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <HomeAccordian
                title="Edit Menu Options"
                actions={
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => setOpen("menu")}
                  >
                    Add new menu option
                  </Button>
                }
              >
                <Grid container spacing={2}>
                  <Grid item xs={5} container alignItems="center">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!siteMeta.showBoatTypeMenu}
                          onChange={() =>
                            setSiteMeta({
                              ...siteMeta,
                              showBoatTypeMenu: !siteMeta.showBoatTypeMenu,
                            })
                          }
                          color="primary"
                        />
                      }
                      label="Show Generated boat types menu option"
                    />
                    <Tooltip title="This will show a menu item at the start with all generated boat type pages. This will only show if there are boat types set">
                      <Help />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={5} container alignItems="center">
                    <FormControlLabel
                      control={
                        <Switch
                          checked={!!siteMeta.showLocationMenu}
                          onChange={() =>
                            setSiteMeta({
                              ...siteMeta,
                              showLocationMenu: !siteMeta.showLocationMenu,
                            })
                          }
                          color="primary"
                        />
                      }
                      label="Show Generated locations menu option"
                    />
                    <Tooltip title="This will show a menu item at the start with all generated location pages. This will only show if there are locations set">
                      <Help />
                    </Tooltip>
                  </Grid>
                  <Grid item xs={12}>
                    <Paper>
                      <MenusList
                        menus={menus || []}
                        options={landingPageData?.landingPages || []}
                        loading={menusLoading}
                        onSave={onSaveMenu}
                        onDelete={deleteMenu}
                      />
                    </Paper>
                  </Grid>
                </Grid>
              </HomeAccordian>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <HomeAccordian title="Edit Options in search box">
                <Box sx={{ width: "100%" }}>
                  <SearchEditForm
                    onSave={onSaveSiteMeta}
                    siteMeta={siteMeta}
                    setSiteMeta={setSiteMeta}
                    loading={metaLoading}
                  />
                  <DefaultLocationSelector
                    locations={locationsData?.locations}
                    siteMeta={siteMeta}
                    setSiteMeta={setSiteMeta}
                  />
                </Box>
              </HomeAccordian>
            </Grid>

            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <HomeAccordian title="Edit fields below to manage Homepage">
                <HomeForm
                  homepage={homepage}
                  setHomepage={setHomepage}
                  saveHomepage={saveHomepage}
                  backgroundImage={backgroundImage}
                  setBackgroundImage={setBackgroundImage}
                  saving={saving}
                />
              </HomeAccordian>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <HomeAccordian
                title="Edit Homepage Content Blocks"
                onChange={(_, o) => setBlocksOpen(o)}
              >
                <div className={blockDrop}>
                  <BlockDropzone
                    onSubmit={onSaveBlocks}
                    items={items}
                    setItems={setItems}
                  />
                </div>
              </HomeAccordian>
            </Grid>
          </Grid>
        </Container>
      </Grid>
      <NewMenuItemModal
        menus={open === "menu" ? menus : topMenus}
        open={!!open}
        setOpen={setOpen}
        onSave={() => setOpen(false)}
        createMenu={open === "menu" ? addMenu : addTopMenu}
      />
      <BlockSelectionDrawer open={blocksOpen} />
    </>
  );
};

export default HomePage;
