import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Checkbox,
  TableBody,
  TableContainer,
} from "@material-ui/core";
import moment from "moment";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import { useCallback, useEffect, useState } from "react";
import { useMergeBookings } from "@store/actions/bookingActions";
import { useNotificationContext } from "@providers/NotificationProvider";

function BookingMergeModal({ open, setOpen, selectedBooking, bookings }) {
  const [from, setFrom] = useState([]);
  const [to, setTo] = useState({});
  const [all, setAll] = useState(false);
  const { showNotification } = useNotificationContext();
  const [mergeBookings] = useMergeBookings();

  useEffect(() => {
    setTo(selectedBooking);
  }, [selectedBooking]);

  useEffect(() => {
    if (all) {
      setFrom([...bookings.filter((b) => to.id !== b.id)]);
    } else {
      setFrom([]);
    }
  }, [all, to]);

  const isChecked = useCallback(
    (booking) => {
      return from.map((f) => f.id).indexOf(booking.id) >= 0;
    },
    [from]
  );

  const onCheck = (booking) => {
    if (isChecked(booking)) {
      setFrom(from.filter((f) => f.id !== booking.id));
    } else {
      setFrom([...from, booking]);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const merge = async () => {
    await mergeBookings({
      variables: {
        from: (from || []).map((b) => b.id),
        to: to.id,
      },
    });
    showNotification(`${to.reference} Merged`);
    setOpen(false);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <DialogTitle>Merge Bookings</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          You can merge multiple bookings into one booking, this will whipe the
          bookings you are merging but add the messages from these bookings to
          the new booking
        </Typography>
        <Grid container spacing={2} alignItems="center" justify="space-between">
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={8}>
            <TableContainer>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={all}
                        onChange={() => setAll(!all)}
                      ></Checkbox>
                    </TableCell>
                    <TableCell>Reference</TableCell>
                    <TableCell>Enquiry date</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {bookings.map((booking) => (
                    <TableRow key={booking.id} value={booking}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={booking.id === to.id}
                          checked={isChecked(booking)}
                          onChange={() => onCheck(booking)}
                        ></Checkbox>
                      </TableCell>
                      <TableCell>{booking.reference}</TableCell>
                      <TableCell>
                        {moment(booking.createdAt).format("ll")}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <ChevronRightIcon />
          <Grid item xs={3}>
            <TextField
              fullWidth
              variant="outlined"
              select
              label="Merge into"
              size="small"
              value={to}
              onChange={(e) => setTo(e.target.value)}
            >
              {bookings.map((booking) => (
                <MenuItem key={booking.id} value={booking}>
                  {booking.reference}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={merge} color="primary">
          Merge
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default BookingMergeModal;
