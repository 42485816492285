import ContentPageWizard from "@components/contentPage/ContentPageWizard";
import Loading from "@components/loading/Loading";
import { Typography } from "@material-ui/core";
import { useContentPageContext } from "@providers/ContentPageProvider";
import { useGetContentPage } from "@store/actions/landingPageActions";
import { useEffect } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

function ContentPagePage() {
  const { contentPageId } = useParams();
  const { loading, data } = useGetContentPage(contentPageId);
  const { path } = useRouteMatch();
  const { contentPage, setContentPage } = useContentPageContext();

  useEffect(() => {
    if (data) {
      setContentPage({
        ...data.contentPage,
      });
    }
  }, [data, setContentPage]);

  if (loading) return <Loading />;

  return (
    <>
      <Switch>
        <Route path="/content-pages/:contentPageId/edit">
          <ContentPageWizard />
        </Route>
        <Route path={path}>
          <Typography variant="h1">name : {contentPage.name}</Typography>
        </Route>
      </Switch>
    </>
  );
}

export default ContentPagePage;
