import { gql } from "@apollo/client";

export const GET_LANDING_PAGE = gql`
  query LandingPage($id: UUID!) {
    landingPage(id: $id) {
      id
      name
      path
      title
      oneLiner
      description
      seoTitle
      seoDescription
      extra
      slateJson
      showOnHome
      showContact
      features {
        id
        name
        featureGroup {
          id
        }
      }
      featureGroups {
        id
        name
      }
      boatTypes {
        id
        name
      }
      location {
        id
        name
      }
      sections {
        title
        type
        order
        link
        linkText
        colWidth
        codeBlock {
          id
          name
          value
        }
        boatSection {
          id
          boatsOrder
          boats {
            id
            name
          }
        }
        thumbLinkSection {
          id
          landingPagesOrder
          landingPages {
            id
            name
          }
        }
      }
      media {
        id
        name
        mediaFiles {
          name
          path
          type
        }
      }
    }
  }
`;

export const GET_ALL_LANDING_PAGES = gql`
  query GetAllLandingPages {
    landingPages {
      id
      name
      path
      title
      oneLiner
      description
      seoTitle
      seoDescription
      showOnHome
      showContact
    }
  }
`;

export const CREATE_LANDING_PAGE = gql`
  mutation CreateLandingPage($input: LandingPageInput!) {
    createLandingPage(input: $input) {
      id
      name
    }
  }
`;
export const UPDATE_LANDING_PAGE = gql`
  mutation UpdateLandingPage($id: UUID!, $input: LandingPageInput!) {
    updateLandingPage(id: $id, input: $input) {
      id
      name
      path
      title
      oneLiner
      description
      seoTitle
      seoDescription
      extra
      slateJson
      showOnHome
      showContact
      features {
        id
        name
      }
      featureGroups {
        id
        name
      }
      boatTypes {
        id
        name
      }
      location {
        id
        name
      }
      sections {
        title
        type
        order
        link
        linkText
        colWidth
        codeBlock {
          id
          name
          value
        }
        boatSection {
          id
          boatsOrder
          boats {
            id
            name
          }
        }
        thumbLinkSection {
          id
          landingPagesOrder
          landingPages {
            id
            name
          }
        }
      }
      media {
        id
        name
        mediaFiles {
          name
          path
          type
        }
      }
    }
  }
`;

export const DELETE_LANDING_PAGE = gql`
  mutation DeleteLandingPage($id: UUID!) {
    deleteLandingPage(id: $id) {
      id
    }
  }
`;

export const GET_CONTENT_PAGE = gql`
  query ContentPage($id: UUID!) {
    contentPage(id: $id) {
      id
      name
      path
      title
      seoTitle
      seoDescription
      showContact
      content
      slateJson
      sections {
        title
        type
        order
        link
        linkText
        colWidth
        codeBlock {
          id
          name
          value
        }
        boatSection {
          id
          boatsOrder
          boats {
            id
            name
          }
        }
        thumbLinkSection {
          id
          landingPagesOrder
          landingPages {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_ALL_CONTENT_PAGES = gql`
  query GetAllContentPages {
    contentPages {
      id
      name
      path
      title
      seoTitle
      seoDescription
      showContact
      content
      slateJson
    }
  }
`;

export const CREATE_CONTENT_PAGE = gql`
  mutation CreateContentPage($input: ContentPageInput!) {
    createContentPage(input: $input) {
      id
      name
    }
  }
`;

export const UPDATE_CONTENT_PAGE = gql`
  mutation UpdateContentPage($id: UUID!, $input: ContentPageInput!) {
    updateContentPage(id: $id, input: $input) {
      id
      name
      path
      title
      seoTitle
      seoDescription
      showContact
      content
      slateJson
      sections {
        title
        type
        order
        link
        linkText
        colWidth
        codeBlock {
          id
          name
          value
        }
        boatSection {
          id
          boatsOrder
          boats {
            id
            name
          }
        }
        thumbLinkSection {
          id
          landingPagesOrder
          landingPages {
            id
            name
          }
        }
      }
    }
  }
`;

export const DELETE_CONTENT_PAGE = gql`
  mutation DeleteContentPage($id: UUID!) {
    deleteContentPage(id: $id) {
      id
    }
  }
`;
