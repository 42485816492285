import Loading from "@components/loading/Loading";
import QuotePageInner from "@components/quote/QuotePageInner";
import { useParams } from "react-router-dom";

function QuotePage() {
  const { quoteId } = useParams();
  if (!quoteId) {
    return <Loading />;
  }
  return <QuotePageInner quoteId={quoteId} />;
}

export default QuotePage;
