import { Button, Grid, Typography } from "@material-ui/core";
import {
  useCreateMedia,
  useDeleteMedia,
  useGetAllMedia,
} from "@store/actions/mediaActions";
import { MediaSelector } from "components-library";
import CloudUpload from "@material-ui/icons/CloudUpload";
import Uploader from "@components/media/Uploader";
import { generateUploadedPath } from "utils/helpers";
import DeleteIcon from "@material-ui/icons/Delete";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useCallback } from "react";
import { FileCopy } from "@material-ui/icons";

function MediaPage() {
  const { loading: mediaLoading, data: mediaData } = useGetAllMedia();
  const [addMedia] = useCreateMedia();
  const { showNotification } = useNotificationContext();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [deleteMedia, { loading: deleteMediaLoading }] = useDeleteMedia();

  const onFinish = async (result, file) => {
    const source = generateUploadedPath(result.signedUrl.split("?")[0]);

    // save a media
    const { data: res } = await addMedia({
      variables: {
        input: {
          name: file.name,
          source,
        },
      },
    });
    showNotification(`${res.createMedia.name} Added`);
  };

  const onClick = (media) => {
    openConfirmation({
      title: `Delete ${media.name}`,
      body: `Deleting ${media.name} can not be undone, this, are you sure you want to continue? -- todo, check for other uses`,
      loading: deleteMediaLoading,
      callback: () => {
        deleteMedia({
          variables: {
            id: media.id,
          },
        }).then(() => {
          closeConfirmation();
          showNotification(`${media.name} Deleted!`);
        });
      },
    });
  };

  const onCopy = useCallback((media) => {
    navigator.clipboard.writeText(media.mediaFiles[0].path);
  }, []);

  return (
    <Grid container spacing={3}>
      <Grid item xs={8}>
        <Typography variant="h5" gutterBottom>
          Media management
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          component="label"
          startIcon={<CloudUpload />}
        >
          Upload New media
          <Uploader
            path="homepage"
            onFinish={onFinish}
            hidden={true}
            multiple={true}
            accept="image/*"
          />
        </Button>
      </Grid>
      <Grid item xs={10}>
        <MediaSelector
          medias={mediaData?.medias || []}
          cols={6}
          onClick={[onClick, onCopy]}
          actionIcon={[<DeleteIcon />, <FileCopy />]}
        />
      </Grid>
    </Grid>
  );
}

export default MediaPage;
