import React, { useMemo, useState } from "react";
import styles from "./style";
import { makeStyles } from "@material-ui/core/styles";
import { NavLink } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Icon from "@material-ui/core/Icon";
import { Brand } from "components-library";
import { groupBy } from "utils/helpers";
import {
  Badge,
  Box,
  Button,
  capitalize,
  Divider,
  Grid,
  ListSubheader,
  withStyles,
} from "@material-ui/core";
import SingleBedOutlined from "@material-ui/icons/SingleBedOutlined";
import BuildIcon from "@material-ui/icons/Build";
import { useAuthContext } from "@providers/AuthProvider";
import { useDeploy } from "@store/actions/customActions";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useGetAllMessages } from "@store/actions/messageActions";
const useStyles = makeStyles(styles);

const StyledBadge = withStyles(() => ({
  badge: {
    transform: "translateY(50%)",
  },
}))(Badge);

const Sidebar = ({ routes }) => {
  const { data: msgData } = useGetAllMessages(
    JSON.stringify({
      booking_id: null,
      owner_id: null,
      to_owner_id: null,
      enquiries_only: true,
    })
  );

  const classes = useStyles();
  const [runDeploy] = useDeploy();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showNotification } = useNotificationContext();
  const isActive = (match, location) => {
    if (!match) {
      return false;
    }
    return match.isExact || match.url.split("/").length > 1;
  };

  const groupedRoutes = useMemo(() => {
    if (!routes) {
      return [];
    }
    return groupBy(routes, "group");
  }, [routes]);

  const { doLogout } = useAuthContext();

  const doDeploy = () => {
    openConfirmation({
      title: `Deploy`,
      body: `Are you sure you want to deploy, this will take a while, remember not to oer do deploying as we only have a set amount of deploy credits`,
      callback: async () => {
        await runDeploy();
        showNotification(`Deployment started`);
        closeConfirmation();
      },
    });
  };

  return (
    <Drawer
      anchor={"left"}
      variant="permanent"
      classes={{
        paper: classes.drawerPaper,
      }}
      open
    >
      <div className={classes.sidebarWrapper}>
        <div className={classes.sidebarInner}>
          <div>
            <Box className={classes.branding} p={1}>
              <Brand useLoad />
            </Box>

            {Object.keys(groupedRoutes)
              .sort((a) => {
                return a === "sales" ? -1 : 0;
              })
              .map((r, i) => {
                const group = groupedRoutes[r]
                  .filter((r) => r.showMenu)
                  .map((prop, key) => {
                    return (
                      <NavLink
                        to={prop.path}
                        key={key}
                        activeClassName={classes.active}
                        isActive={isActive}
                      >
                        <StyledBadge
                          style={{ display: "block" }}
                          badgeContent={
                            prop.name === "Enquries"
                              ? (msgData?.messages || []).length
                              : null
                          }
                          color="primary"
                        >
                          <ListItem button className={classes.item}>
                            <ListItemIcon>
                              <Icon
                                color="primary"
                                component={prop.icon}
                              ></Icon>
                            </ListItemIcon>
                            <ListItemText primary={prop.name} />
                          </ListItem>
                        </StyledBadge>
                      </NavLink>
                    );
                  });
                return (
                  <List
                    key={i}
                    className={classes.list}
                    subheader={
                      <ListSubheader color="primary">
                        {capitalize(r)}
                      </ListSubheader>
                    }
                  >
                    {group}
                    {i + 1 < Object.keys(groupedRoutes).length && <Divider />}
                  </List>
                );
              })}
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="primary"
                fullWidth
                onClick={doDeploy}
                startIcon={<BuildIcon />}
              >
                Deploy
              </Button>
            </Grid>
            <Grid item xs={12}>
              <Button
                variant="contained"
                color="default"
                fullWidth
                onClick={doLogout}
                startIcon={<SingleBedOutlined />}
              >
                Logout
              </Button>
            </Grid>
          </Grid>
        </div>
      </div>
    </Drawer>
  );
};

export default Sidebar;
