import {
  Box,
  Grid,
  makeStyles,
  Step,
  StepButton,
  StepContent,
  Stepper,
} from "@material-ui/core";
import React from "react";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {},
  stepper: {
    "& .MuiStepLabel-root": {
      fontSize: theme.typography.h6.fontSize,
    },
    "& .MuiStepLabel-label": {
      transition: "all 0.12s ease-in-out",
    },
    "& .MuiStepLabel-iconContainer": {
      paddingBottom: "2px",
    },
  },
  complete: {
    "& .MuiStepLabel-iconContainer": {
      color: theme.palette.primary.light,
    },
    "& .MuiStepLabel-label": {
      color: theme.palette.primary.light,
    },
  },
  active: {
    "& .MuiStepLabel-iconContainer": {
      color: theme.palette.primary.main,
    },
    "& .MuiStepLabel-root": {
      fontSize: theme.typography.h5.fontSize,
    },

    "& .MuiStepLabel-label": {
      color: theme.palette.primary.main,
      fontSize: theme.typography.h5.fontSize,
      transform: `translateX(${theme.spacing(2)}px)`,
    },
  },
}));

function Steps({ steps, activeStep, isStepComplete, handleStep, next }) {
  const { stepper, active, complete } = useStyles();
  return (
    <Stepper
      orientation="vertical"
      className={stepper}
      nonLinear
      activeStep={activeStep}
    >
      {steps.map(({ label, component, icon }, index) => (
        <Step key={index}>
          <StepButton
            className={classNames({
              [active]: activeStep === index,
              [complete]: isStepComplete(index),
            })}
            icon={icon}
            onClick={() => handleStep(index)}
            completed={isStepComplete(index)}
          >
            {label}
          </StepButton>
          <StepContent>
            <Box p={2}>
              <Grid container spacing={3}>
                <Grid xs={12} item>
                  {component}
                </Grid>
                <Grid item>{next}</Grid>
              </Grid>
            </Box>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

export default React.memo(Steps);
