import CardTable from "@components/cardTable/CardTable";
import { Button, Grid, Typography } from "@material-ui/core";
import {
  useDeleteCodeBlock,
  useGetAllCodeBlocks,
} from "@store/actions/blockActions";
import { useCallback, useEffect, useState } from "react";
import CodeBlockDrawer from "./CodeBlockDrawer";
import { Delete, Edit } from "@material-ui/icons";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { truncate } from "utils/strings";

const htmlCol = (value) => {
  return <Typography variant="caption">{truncate(value, 200)}</Typography>;
};

function CodeBlockTab() {
  const [open, setOpen] = useState(false);
  const [selectedBlock, setSelectedBlock] = useState();
  const { data } = useGetAllCodeBlocks();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [deleteCodeBlock] = useDeleteCodeBlock();

  const newBlock = useCallback(() => {
    setOpen(true);
  }, []);

  const onEdit = useCallback((row) => {
    setSelectedBlock(row);
    setOpen(true);
  }, []);

  const onDelete = useCallback((ref) => {
    openConfirmation({
      title: `Delete ${ref.name}`,
      body: `Deleting ${ref.name} can not be undone, are you sure you want to continue?`,
      callback: () => onDeleteCodeBlock(ref.id),
    });
  }, []);

  const onDeleteCodeBlock = async (id) => {
    await deleteCodeBlock({
      variables: {
        id,
      },
    });
    closeConfirmation();
  };

  useEffect(() => {
    if (!open) {
      setSelectedBlock(undefined);
    }
  }, [open]);

  return (
    <Grid container style={{ minWidth: 400 }} spacing={3}>
      <Grid item xs={9}>
        <Typography variant="subtitle1">
          HTML Blocks are blocks of custom HTML code that can be inserted into
          different pages.
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={newBlock}
        >
          New HTML Block
        </Button>
      </Grid>

      <Grid item xs={12}>
        <CardTable
          tableHead={["name", "value"]}
          tableData={(data?.codeBlocks ? [...data?.codeBlocks] : []).sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          )}
          customColumns={[
            {
              name: "value",
              component: htmlCol,
            },
          ]}
          actions={[
            {
              icon: <Edit color="primary" />,
              fn: onEdit,
            },
            {
              icon: <Delete color="error" />,
              fn: onDelete,
            },
          ]}
        />
      </Grid>
      {open && (
        <CodeBlockDrawer open={open} setOpen={setOpen} block={selectedBlock} />
      )}
    </Grid>
  );
}

export default CodeBlockTab;
