import { Brand } from "components-library";
import { Box, Button, Grid, Paper, TextField } from "@material-ui/core";
import { useAuthContext } from "@providers/AuthProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useLogin } from "@store/actions/authActions";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { getAuthToken, saveAuthToken } from "utils/helpers";
import { makeStyles } from "@material-ui/core/styles";
import { Link as RouterLink } from "react-router-dom";

export const useLoginStyles = makeStyles(({ spacing, palette }) => ({
  bg: {
    "&:after": {
      zIndex: -1,
      opacity: 0.3,
      content: "''",
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
      background: `repeating-linear-gradient(
        -45deg,
        transparent 0,
        transparent 25%,
        ${palette.primary.main} 0,
        ${palette.primary.main} 50%
   ),
   repeating-linear-gradient(
        45deg,
        transparent 0,
        transparent 25%,
        ${palette.secondary.main} 0,
        ${palette.secondary.main} 50%
    ),
    repeating-linear-gradient(
        transparent 0,
        transparent 25%,
        gold 0,
        gold 50%
    ), white;`,
      backgroundBlendMode: "multiply",
      backgroundSize: "500px 500px",
    },
  },
  body: {
    height: "100vh",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  inner: {
    zIndex: 0,
    minWidth: 400,
    maxWidth: 400,
    transform: `translateY(-${spacing(10)}px)`,
    background: "white",
  },
  logo: {
    minHeight: 90,
    "& svg": {
      width: "100%",
    },
  },
}));

function Login() {
  const { bg, body, inner, logo } = useLoginStyles();
  const [email, setEmail] = useState("");
  const [pwd, setPwd] = useState("");
  const { authed, setAuthed } = useAuthContext();
  const history = useHistory();
  const { showNotification } = useNotificationContext();
  const [login, { loading }] = useLogin();

  const submit = async (e) => {
    e.preventDefault();
    try {
      const results = await login({ variables: { email, pass: pwd } });
      const resp = results.data.authenticate;
      saveAuthToken(resp);
      setAuthed(resp);

      showNotification("Login Successfull");
    } catch (e) {
      showNotification("Invalid Email or Password");
    }
  };

  useEffect(() => {
    const data = getAuthToken();
    if (data) {
      setAuthed(data);
      history.push("/");
    }
  }, [authed]);

  return (
    <div className={bg}>
      <div className={body}>
        <Paper className={inner}>
          <Box p={5}>
            <form noValidate autoComplete="off" onSubmit={submit}>
              <Grid
                container
                direction="column"
                alignItems="stretch"
                spacing={3}
              >
                <Grid item xs={8} className={logo}>
                  <Brand style={{ height: 60 }} useLoad />
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    fullWidth
                    type="email"
                    disabled={loading}
                    size="small"
                    label="Email"
                    variant="outlined"
                  />
                </Grid>
                <Grid item>
                  <TextField
                    onChange={(e) => setPwd(e.target.value)}
                    value={pwd}
                    fullWidth
                    disabled={loading}
                    type="password"
                    size="small"
                    label="Password"
                    variant="outlined"
                  />
                </Grid>
                <Grid item container alignItems="center" justify="center">
                  <Button
                    disabled={loading}
                    color="primary"
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                  >
                    Login
                  </Button>
                </Grid>
                <Grid item>
                  <RouterLink to="/forgot">Forgot password?</RouterLink>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </div>
    </div>
  );
}

export default Login;
