import { useQuery } from "@apollo/client";
import { GET_ALL_ROUTES } from "@operations/routeOperations";
import React, { createContext, useContext, useMemo } from "react";

export const RoutesContext = createContext(null);
export const useRoutesContext = () => useContext(RoutesContext);

const RoutesProvider = ({ children }) => {
  const { data, loading } = useQuery(GET_ALL_ROUTES);

  const state = useMemo(() => {
    return {
      routes: !loading ? data.routes : [],
    };
  }, [data, loading]);

  return (
    <RoutesContext.Provider value={state}>{children}</RoutesContext.Provider>
  );
};

export default RoutesProvider;
