import { gql } from "@apollo/client";

export const GET_ALL_MENUS = gql`
  query GetAllMenus {
    menus {
      id
      name
      path
      landingPages {
        id
        name
        path
      }
      order
    }
  }
`;

export const CREATE_MENU = gql`
  mutation CreateMenu($input: MenuInput!) {
    createMenu(input: $input) {
      id
      name
      path
      order
    }
  }
`;
export const UPDATE_MENU = gql`
  mutation UpdateMenu($id: UUID!, $input: MenuInput!) {
    updateMenu(id: $id, input: $input) {
      id
      name
      path
      order
      landingPages {
        id
        name
        path
      }
    }
  }
`;

export const DELETE_MENU = gql`
  mutation DeleteMenu($id: UUID!) {
    deleteMenu(id: $id) {
      id
    }
  }
`;

export const GET_ALL_TOP_BAR_MENUS = gql`
  query GetAllTopBarMenus {
    topBarMenus {
      id
      name
      path
      landingPages {
        id
        name
        path
      }
      order
    }
  }
`;

export const CREATE_TOP_BAR_MENU = gql`
  mutation CreateTopBarMenu($input: TopBarMenuInput!) {
    createTopBarMenu(input: $input) {
      id
      name
      path
      order
    }
  }
`;
export const UPDATE_TOP_BAR_MENU = gql`
  mutation UpdateTopBarMenu($id: UUID!, $input: TopBarMenuInput!) {
    updateTopBarMenu(id: $id, input: $input) {
      id
      name
      path
      order
      landingPages {
        id
        name
        path
      }
    }
  }
`;

export const DELETE_TOP_BAR_MENU = gql`
  mutation DeleteTopBarMenu($id: UUID!) {
    deleteTopBarMenu(id: $id) {
      id
    }
  }
`;
