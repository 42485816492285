"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _react = require("react");
function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }
function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _iterableToArrayLimit(r, l) { var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"]; if (null != t) { var e, n, i, u, a = [], f = !0, o = !1; try { if (i = (t = t.call(r)).next, 0 === l) { if (Object(t) !== t) return; f = !1; } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0); } catch (r) { o = !0, n = r; } finally { try { if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return; } finally { if (o) throw n; } } return a; } }
function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }
function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }
function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }
function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }
function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }
function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }
function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) arr2[i] = arr[i]; return arr2; }
function useGroupByFeatureGroup(features) {
  var groupFeatures = function groupFeatures(features) {
    var g = features.reduce(function (acc, feature) {
      var idx;
      var id;
      var name;
      var live = false;
      if (feature.featureGroup) {
        id = feature.featureGroup.id;
        name = feature.featureGroup.name;
        live = feature.featureGroup.live;
        idx = (acc || []).map(function (o) {
          return o.name;
        }).indexOf(name);
      } else {
        id = "";
        idx = (acc || []).map(function (o) {
          return o.name;
        }).indexOf("other");
        live = false;
        name = "other";
      }
      if (idx === -1) {
        return [].concat(_toConsumableArray(acc), [{
          name: name,
          id: id,
          live: live,
          features: [feature]
        }]);
      } else {
        acc[idx].features = [].concat(_toConsumableArray(acc[idx].features), [feature]);
      }
      return _toConsumableArray(acc);
    }, []);
    var idx = (g || []).map(function (o) {
      return o.name;
    }).indexOf("other");
    if (idx === -1) {
      return g;
    }
    var other = g[idx];
    g.splice(idx, 1);
    return [].concat(_toConsumableArray(g), [other]);
  };
  var _useState = (0, _react.useState)(groupFeatures(features)),
    _useState2 = _slicedToArray(_useState, 2),
    group = _useState2[0],
    setGroup = _useState2[1];
  (0, _react.useEffect)(function () {
    if (!features) {
      return;
    }
    setGroup(groupFeatures(features));
  }, [features]);
  return {
    group: group
  };
}
var _default = exports["default"] = useGroupByFeatureGroup;