import { Box, List, makeStyles } from "@material-ui/core";
import React from "react";
import { useDrop } from "react-dnd";
import FeatureDragCard from "./FeatureDragCard";
import classNames from "classnames";

export const useDropStyles = makeStyles(({ palette }) => ({
  dropzone: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    border: `1px dashed ${palette.divider}`,
  },
  inner: {
    flex: "1",
    minHeight: 50,
  },
  hovered: {
    background: palette.action.hover,
  },
}));

function FeatureSideDropZone({ features, onDrop, onDelete, onUpdate }) {
  const { dropzone, hovered, inner } = useDropStyles();
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "FetaureDragCard",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: ({ feature }) => {
      onDrop(feature);
    },
  });
  const isActive = canDrop && isOver;
  return (
    <Box
      p={2}
      className={classNames(dropzone, {
        [hovered]: isActive,
      })}
    >
      <List className={inner} ref={drop} dense={true}>
        {features.map((feature) => (
          <FeatureDragCard
            key={feature.id}
            feature={feature}
            onDelete={onDelete}
            onUpdate={onUpdate}
          />
        ))}
      </List>
    </Box>
  );
}

export default React.memo(FeatureSideDropZone);
