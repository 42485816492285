import CardTable from "@components/cardTable/CardTable";
import { Button, ButtonGroup, Grid, Link, Typography } from "@material-ui/core";
import { useBookingContext } from "@providers/BookingProvider";
import React, { useState } from "react";
import { Link as RouterLink, useHistory } from "react-router-dom";
import NewBookingQuote from "./NewBookingQuote";
import Delete from "@material-ui/icons/Delete";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useDeleteQuote } from "@store/actions/quoteActions";
import CheckIcon from "@material-ui/icons/Check";
import { getQuotesItemsTotal, getQuotesTransactionsTotal } from "utils/models";
import {
  CREATE_TRANSACTION,
  GET_QUOTE_BREAKDOWN,
} from "@store/operations/quoteOperations";
import { useApolloClient, useMutation } from "@apollo/client";

const quoteLink = (booking) => (ref, row) => (
  <>
    <Link
      variant="body1"
      color="primary"
      component={RouterLink}
      to={`/bookings/${booking.id}/quotes/${row.id}`}
    >
      {ref}
    </Link>
  </>
);

export const BoolCol = (val) => {
  return val ? <CheckIcon /> : null;
};

const transactionsCol = (transactions, quote) => {
  const itemTotal = getQuotesItemsTotal([quote]);
  const transactionTotal = getQuotesTransactionsTotal([quote]);
  return (
    <Typography color={transactionTotal >= itemTotal ? "primary" : "error"}>
      Paid ${transactionTotal} out of ${itemTotal}
    </Typography>
  );
};

const manualPaymentCol = (makeTransaction) => (_, quote) => {
  const itemTotal = getQuotesItemsTotal([quote]);
  const transactionTotal = getQuotesTransactionsTotal([quote]);
  return (
    <ButtonGroup size="small" color="primary">
      {itemTotal > 0 && transactionTotal === 0 && (
        <Button onClick={() => makeTransaction(true, quote)}>
          make deposit
        </Button>
      )}
      {itemTotal > 0 && transactionTotal < itemTotal && (
        <Button onClick={() => makeTransaction(false, quote)}>
          make full payment
        </Button>
      )}
    </ButtonGroup>
  );
};

function BookingQuotes({ refetch }) {
  const { booking } = useBookingContext();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showNotification } = useNotificationContext();
  const [deleteQuote, { loading: deleting }] = useDeleteQuote(booking);
  const [createTransaction] = useMutation(CREATE_TRANSACTION);
  const client = useApolloClient();

  const onSaved = (id) => {
    history.push(`/bookings/${booking.id}/quotes/${id}`);
  };

  const onDelete = ({ id }) => {
    openConfirmation({
      title: `Delete this Quote`,
      body: `Deleting this quote can not be undone, are you sure you want to continue?`,
      loading: () => deleting,
      callback: () =>
        deleteQuote({
          variables: {
            id,
          },
        }).then(() => {
          showNotification(`Quote Deleted!`);
          closeConfirmation();
        }),
    });
  };

  const makeTransaction = async (deposit, quote) => {
    const res = await client.query({
      query: GET_QUOTE_BREAKDOWN,
      variables: {
        ref: quote.reference,
      },
    });

    const data = res.data.quotePriceBreakdownByRef;
    if (deposit && !data.canDeposit) {
      showNotification(
        "you cant currently make a deposit for this quote, try a full payment"
      );
      return;
    }
    if (data.total === 0) {
      showNotification("Nothing to pay");
      return;
    }
    const input = {
      paid: Math.trunc((deposit ? data.depositTotal : data.total) * 100),
      total: Math.trunc((deposit ? data.depositTotal : data.total) * 100),
      paymentFees: 0,
      ourCut: Math.trunc(
        (deposit ? data.depositOurCutTotal : data.ourCutTotal) * 100
      ),
      ownerCut: Math.trunc(
        (deposit ? data.depositOwnerCutTotal : data.ownerCutTotal) * 100
      ),
      transactionId: quote.id + "_" + booking.id,
      transactionMeta: JSON.stringify({}),
      tax: (Math.trunc(deposit ? data.depositTax : data.tax) || 0) * 100,
      taxMode: data.taxMode || "",
    };
    createTransaction({
      variables: {
        quoteID: quote.id,
        bookingID: booking.id,
        input,
        deposit,
        process: true,
      },
    });
    refetch();
    showNotification("Transaction Logged");
  };

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <Typography variant="h6">Quotes</Typography>
          <Typography variant="body1" gutterBottom>
            Quotes are what are sent out to reserve booking, they can include
            multiple boats
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
          >
            New Quote
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CardTable
          tableHead={[
            "reference",
            "type",
            "sentOn",
            "seenOn",
            "reminderSentOn",
            "expired",
            "transactions",
            "manualPayment",
          ]}
          tableData={booking?.quotes || []}
          customColumns={[
            {
              name: "reference",
              component: quoteLink(booking),
            },
            {
              name: "expired",
              component: BoolCol,
            },
            {
              name: "transactions",
              component: transactionsCol,
            },
            {
              name: "manualPayment",
              component: manualPaymentCol(makeTransaction),
            },
          ]}
          actions={[
            {
              icon: <Delete color="secondary" />,
              fn: onDelete,
            },
          ]}
        ></CardTable>
      </Grid>
      <NewBookingQuote
        open={open}
        setOpen={setOpen}
        booking={booking}
        onSaved={onSaved}
      />
    </Grid>
  );
}

export default BookingQuotes;
