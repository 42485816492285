import { gql } from "@apollo/client";

export const GET_LOCATION = gql`
  query Location($id: UUID!) {
    location(id: $id) {
      id
      name
    }
  }
`;

export const GET_ALL_LOCATIONS = gql`
  query GetAllLocations {
    locations {
      id
      name
      boats {
        id
      }
    }
  }
`;

export const CREATE_LOCATION = gql`
  mutation CreateLocation($input: LocationInput!) {
    createLocation(input: $input) {
      id
      name
    }
  }
`;
export const UPDATE_LOCATION = gql`
  mutation UpdateLocation($id: UUID!, $input: LocationInput!) {
    updateLocation(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const DELETE_LOCATION = gql`
  mutation DeleteLocation($id: UUID!) {
    deleteLocation(id: $id) {
      id
    }
  }
`;
