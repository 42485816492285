import { gql } from "@apollo/client";

export const GET_PACKAGE_PAGE = gql`
  query PackagePage($id: UUID!) {
    packagePage(id: $id) {
      id
      name
      path
      title
      oneLiner
      description
      seoTitle
      seoDescription
      slateJsonTopContent
      topContent
      slateJsonLowerContent
      lowerContent
      media {
        id
        name
        mediaFiles {
          name
          path
          type
        }
      }
      boatPackages {
        id
        name
        description
        price
        info
        content
        slateJson
        media {
          id
          name
          type
          mediaFiles {
            name
            path
            type
          }
        }
        attachments {
          id
          name
          type
          mediaFiles {
            name
            path
            type
          }
        }
      }
    }
  }
`;

export const GET_ALL_PACKAGE_PAGES = gql`
  query GetAllPackagePages {
    packagePages {
      id
      name
      path
      title
      oneLiner
      description
      seoTitle
      seoDescription
      slateJsonTopContent
      topContent
      slateJsonLowerContent
      lowerContent
      media {
        id
        name
        mediaFiles {
          name
          path
          type
        }
      }
      boatPackages {
        id
        name
        description
        price
        info
        content
        slateJson
        media {
          id
          name
          type
          mediaFiles {
            name
            path
            type
          }
        }
        attachments {
          id
          name
          type
          mediaFiles {
            name
            path
            type
          }
        }
      }
    }
  }
`;

export const CREATE_PACKAGE_PAGE = gql`
  mutation CreatePackagePage($input: PackagePageInput!) {
    createPackagePage(input: $input) {
      id
      name
    }
  }
`;
export const UPDATE_PACKAGE_PAGE = gql`
  mutation UpdatePackagePage($id: UUID!, $input: PackagePageInput!) {
    updatePackagePage(id: $id, input: $input) {
      id
      name
      path
      title
      oneLiner
      description
      seoTitle
      seoDescription
      slateJsonTopContent
      topContent
      slateJsonLowerContent
      lowerContent
      media {
        id
        name
        mediaFiles {
          name
          path
          type
        }
      }
      boatPackages {
        id
        name
        description
        price
        info
        content
        slateJson
        media {
          id
          name
          type
          mediaFiles {
            name
            path
            type
          }
        }
        attachments {
          id
          name
          type
          mediaFiles {
            name
            path
            type
          }
        }
      }
    }
  }
`;

export const DELETE_PACKAGE_PAGE = gql`
  mutation DeletePackagePage($id: UUID!) {
    deletePackagePage(id: $id) {
      id
    }
  }
`;
