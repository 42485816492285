import {
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";

function QuotePutOnHoldModal({
  open,
  setOpen,
  owners: originalOwners,
  setOwners: setOriginalOwners,
  onSave,
}) {
  const [owners, setOwners] = useState([]);

  useEffect(() => {
    setOwners(originalOwners);
  }, [originalOwners]);

  const handleSave = (e) => {
    e.preventDefault();
    setOriginalOwners(owners);
    setOpen(false);
    onSave();
  };

  const isChecked = (id) => {
    return owners.findIndex((o) => o.id === id) >= 0;
  };
  const handleChange = (owner) => {
    const idx = owners.findIndex((o) => o.id === owner.id);
    let localOwners = [...(owners || [])];
    if (idx === -1) {
      localOwners.push(owner);
    } else {
      localOwners.splice(idx, 1);
    }

    setOwners(localOwners);
  };

  const handleClose = () => {
    setOwners(originalOwners);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose} disableBackdropClick={true}>
      <form onSubmit={handleSave} style={{ width: 600 }}>
        <DialogTitle>
          Let boat owners know to put their boats on hold
        </DialogTitle>
        <DialogContent>
          {originalOwners
            .filter((o) => o)
            .map((owner) => (
              <FormControlLabel
                key={owner.id}
                control={
                  <Checkbox
                    color="primary"
                    checked={isChecked(owner.id)}
                    onChange={() => handleChange(owner)}
                  />
                }
                label={`Let ${owner.firstName} know to put this booking on hold?`}
              />
            ))}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Ok
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default QuotePutOnHoldModal;
