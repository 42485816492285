import { gql } from "@apollo/client";

export const CREATE_USER = gql`
  mutation CreateUser($input: UserInput!) {
    createUser(input: $input) {
      id
      firstName
      lastName
      userName
      email
      resetSentOn
      lastLogin
      createdAt
      updatedAt
    }
  }
`;
export const UPDATE_USER = gql`
  mutation UpdateUser($id: UUID!, $input: UserInput!) {
    updateUser(id: $id, input: $input) {
      id
      firstName
      lastName
      userName
      email
      resetSentOn
      lastLogin
      createdAt
      updatedAt
    }
  }
`;

export const GET_ALL_USERS = gql`
  query GetAllUsers {
    users {
      id
      firstName
      lastName
      userName
      email
      resetSentOn
      lastLogin
      createdAt
      updatedAt
    }
  }
`;
