import BoatLayoutsDialog from "@components/boatLayout/BoatLayoutsDialog";
import CardTable from "@components/cardTable/CardTable";
import Loading from "@components/loading/Loading";
import { Button, Grid, Typography } from "@material-ui/core";
import Add from "@material-ui/icons/Add";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import {
  useDeleteBoatPageLayout,
  useGetAllBoatPageLayouts,
} from "@store/actions/boatActions";
import React, { useState } from "react";
import { useEffect } from "react";

const boatsCol = (col, row) => {
  return (row.boats || []).map((b) => b.name).join(" - ");
};

function BoatLayoutsPage() {
  const { loading, error, data } = useGetAllBoatPageLayouts();
  const [deleteLayout] = useDeleteBoatPageLayout();
  const [layout, setLayout] = useState({});
  const [layouts, setLayouts] = useState([]);
  const [open, setOpen] = useState(false);
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showNotification } = useNotificationContext();

  useEffect(() => {
    if (!data) {
      return;
    }
    setLayouts(data.boatPageLayouts || []);
  }, [data]);

  const onNew = () => {
    setLayout({
      items: [{}],
    });
    setOpen(true);
  };

  const onEdit = (l) => {
    setLayout({ ...l });
    setOpen(true);
  };

  const onDelete = ({ name, id }) => {
    openConfirmation({
      title: `Delete ${name}`,
      body: `Deleting ${name} can not be undone, are you sure you want to continue?`,
      callback: async () => {
        await deleteLayout({
          variables: {
            id,
          },
        });
        showNotification(`${name} Deleted!`);
        closeConfirmation();
      },
    });
  };

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;

  return (
    <Grid
      container
      item
      xs={10}
      spacing={2}
      justify="space-between"
      alignItems="center"
    >
      <Grid item xs={6}>
        <Typography variant="h5" component="span" display="inline">
          Boat Page Layouts
        </Typography>
      </Grid>

      <Grid item>
        <Button
          variant="contained"
          color="primary"
          startIcon={<Add />}
          onClick={onNew}
        >
          New boat layout
        </Button>
      </Grid>
      <Grid item xs={12}>
        <CardTable
          tableHead={["name", "boats"]}
          tableData={layouts}
          customColumns={[
            {
              name: "boats",
              component: boatsCol,
            },
          ]}
          actions={[
            {
              icon: <Edit color="primary" />,
              fn: onEdit,
            },
            {
              icon: <Delete color="error" />,
              fn: onDelete,
            },
          ]}
        />
      </Grid>
      <BoatLayoutsDialog
        open={open}
        setOpen={setOpen}
        layout={layout}
        setLayout={setLayout}
      />
    </Grid>
  );
}

export default BoatLayoutsPage;
