import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { format } from "date-fns";

const BoatSpecialPriceTable = ({ boat, onEdit, onDelete, readOnly }) => {
  return (
    <TableContainer component={Paper} variant="outlined">
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            <TableCell>Rate</TableCell>

            <TableCell>From</TableCell>
            <TableCell>To</TableCell>
            {!readOnly && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {(boat.boatPriceSpecials || []).map((bps) => (
            <TableRow key={bps.id}>
              <TableCell>{bps.name}</TableCell>
              <TableCell>{bps.boatPrice ? bps.boatPrice.name : "-"}</TableCell>
              <TableCell>{format(new Date(bps.from), "yyyy-MM-dd")}</TableCell>
              <TableCell>{format(new Date(bps.to), "yyyy-MM-dd")}</TableCell>
              {!readOnly && (
                <TableCell>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => onEdit(bps)}
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => onDelete(bps)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BoatSpecialPriceTable;
