import { CircularProgress, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import classNames from "classnames";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    height: "100vh",
    width: "100vw",
    justifyContent: "center",
    alignItems: "center",
  },
  cover: {
    height: "100%",
    width: "100%",
    position: "absolute",
  },
  overlay: {
    height: "100%",
    width: "100%",
    position: "absolute",
    zIndex: 999,
    "&:before": {
      content: "''",
      position: "absolute",
      height: "100%",
      width: "100%",
      background: theme.palette.action.disabled,
    },
  },
}));

const Loading = (props) => {
  const { root, overlay, cover } = useStyles();

  // todo: custom hook for this
  const filteredtProps = useMemo(() => {
    Object.keys(props)
      .filter((key) => !["overlay", "cover"].includes(key))
      .reduce((obj, key) => {
        obj[key] = props[key];
        return obj;
      }, {});
  }, [props]);
  return (
    <div
      className={classNames(root, {
        [overlay]: props.overlay,
        [cover]: props.cover,
      })}
    >
      <CircularProgress {...filteredtProps} />
    </div>
  );
};

Loading.propTypes = {
  overlay: PropTypes.bool,
  cover: PropTypes.bool,
};

Loading.defaultProps = {
  overlay: false,
};

export default React.memo(Loading);
