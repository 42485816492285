import { useMutation, useQuery } from "@apollo/client";
import {
  GET_SITEMETA,
  GET_SITETIMEZONE,
  SEARCH_ITEM_TYPE_ENUM,
  UPDATE_SITEMETA,
} from "@store/operations/siteMetaOperations";

export const useGetSiteTimezone = () => useQuery(GET_SITETIMEZONE);
export const useGetSiteMeta = () => useQuery(GET_SITEMETA);
export const useUpdateSiteMeta = () => useMutation(UPDATE_SITEMETA);
export const useSearchItemType = () => useQuery(SEARCH_ITEM_TYPE_ENUM);
