import { BookingLink } from "@components/booking/BookingsTableInner";
import CardTable from "@components/cardTable/CardTable";
import {
  Button,
  ButtonGroup,
  Grid,
  TableCell,
  TableRow,
  Typography,
  styled,
} from "@material-ui/core";
import Rating from "@material-ui/lab/Rating";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useValidateRating } from "@store/actions/ratingActions";
import React, { useCallback } from "react";

const StyledRating = styled(Rating)(({ theme }) => ({
  color: theme.palette.primary.main,
}));

function Row({ row }) {
  const rating = row.ref;
  const [validateRating] = useValidateRating();
  const { showNotification } = useNotificationContext();

  const onReject = useCallback(() => {
    validateRating({
      variables: {
        id: rating.id,
        input: {
          rejectedAt: new Date(),
        },
      },
    });
    showNotification(`Rating for booking ${rating.booking.reference} Rejected`);
  }, [rating]);

  const onApprove = useCallback(() => {
    validateRating({
      variables: {
        id: rating.id,
        input: {
          approvedAt: new Date(),
        },
      },
    });
    showNotification(`Rating for booking ${rating.booking.reference} Approved`);
  }, [rating]);

  return (
    <TableRow className={row.className}>
      <TableCell style={{ maxWidth: "400px" }}>
        <BookingLink row={rating.booking} />
      </TableCell>

      <TableCell>
        <StyledRating readOnly value={rating.overallRating} />
      </TableCell>
      <TableCell>
        <Grid container spacing={2}>
          <Grid item sm={4} xs={12} container>
            <Grid item xs={6}>
              <Typography variant="body1">Cleaniness</Typography>
            </Grid>
            <Grid item xs={6}>
              <StyledRating
                readOnly
                name="cleanlinessRating"
                value={rating.cleanlinessRating}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12} container>
            <Grid item xs={6}>
              <Typography variant="body2">Professionalism</Typography>
            </Grid>
            <Grid item xs={6}>
              <StyledRating
                readOnly
                name="professionalismRating"
                value={rating.professionalismRating}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12} container>
            <Grid item xs={6}>
              <Typography variant="body2">Punctuality</Typography>
            </Grid>
            <Grid item xs={6}>
              <StyledRating
                readOnly
                name="punctualityRating"
                value={rating.punctualityRating}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12} container>
            <Grid item xs={6}>
              <Typography variant="body2">Comfort</Typography>
            </Grid>
            <Grid item xs={6}>
              <StyledRating
                readOnly
                name="comfortRating"
                value={rating.comfortRating}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12} container>
            <Grid item xs={6}>
              <Typography variant="body2">Friendliness</Typography>
            </Grid>
            <Grid item xs={6}>
              <StyledRating
                readOnly
                name="friendlinessRating"
                value={rating.friendlinessRating}
                size="small"
              />
            </Grid>
          </Grid>
          <Grid item sm={4} xs={12} container>
            <Grid item xs={6}>
              <Typography variant="body2">Value</Typography>
            </Grid>
            <Grid item xs={6}>
              <StyledRating
                readOnly
                name="valueRating"
                value={rating.valueRating}
                size="small"
              />
            </Grid>
          </Grid>
        </Grid>
      </TableCell>
      <TableCell align="right" style={{ minWidth: "120px" }}>
        <ButtonGroup color="primary">
          <Button onClick={onReject}>Reject</Button>
          <Button onClick={onApprove}>Approve</Button>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
}

function RatingTable({ ratings }) {
  return (
    <CardTable
      initialRows={10}
      tableHead={[
        "Booking Info",
        "Overall Rating",
        "Aditional Ratings",
        "Actions",
      ]}
      tableData={ratings}
      rowComponent={Row}
    />
  );
}

export default RatingTable;
