import React, { createContext, useContext, useMemo, useState } from "react";

export const LayoutContext = createContext(null);
export const useLayoutContext = () => useContext(LayoutContext);

const LayoutProvider = ({ children }) => {
  const [contentRight, setContentRight] = useState({});

  const state = useMemo(
    () => ({
      contentRight,
      setContentRight,
    }),
    [contentRight]
  );

  return (
    <LayoutContext.Provider value={state}>{children}</LayoutContext.Provider>
  );
};

export default LayoutProvider;
