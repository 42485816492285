import BoatSectionsTab from "@components/contentBlocks/BoatSectionsTab";
import ThumbLinksTab from "@components/contentBlocks/ThumbLinksTab";
import { Grid, Tab, Tabs, Typography } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { TabPanel, useTabbedPageStyles } from "../bookings/BookingPage";
import CodeBlockTab from "@components/contentBlocks/CodeBlockTab";

function ContentBlocksPage() {
  const [tab, setTab] = useState(0);
  const { root, tabs } = useTabbedPageStyles();

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h5" gutterBottom>
          Content Blocks
        </Typography>
        <Typography>
          Content Blocks are small snippets that can be inserted into certain
          pages
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div className={root}>
          <Tabs
            orientation="vertical"
            value={tab}
            onChange={(_, v) => setTab(v)}
            className={tabs}
          >
            <Tab label="Boat Sections" id="bs" />
            <Tab label="Thumb Links" id="tl" />
            <Tab label="HTML Blocks" id="htmlb" />
          </Tabs>
          <TabPanel value={tab} index={0}>
            <BoatSectionsTab />
          </TabPanel>
          <TabPanel value={tab} index={1}>
            <ThumbLinksTab />
          </TabPanel>
          <TabPanel value={tab} index={2}>
            <CodeBlockTab />
          </TabPanel>
        </div>
      </Grid>
    </Grid>
  );
}

export default ContentBlocksPage;
