import { makeStyles } from "@material-ui/core";
import { Editor, Transforms, Element, Range } from "slate";
import { useSelected, useFocused } from "slate-react";
import imageExtensions from "image-extensions";
import isUrl from "is-url";
import escapeHtml from "escape-html";
import { Text } from "slate";
import { Grid } from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import {
  FlexGridElement,
  FlexGridItemElement,
  FLEX_GRID,
  FLEX_GRID_COL,
} from "./MuiGridPlugin";

const youtubeRegex = /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:youtube\.com|youtu.be))(?:\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(?:\S+)?$/;
const vimeoRegex = /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:(?:vimeo\.com|vimeo.be))(?:\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(?:\S+)?$/;

export const HOTKEYS = {
  "mod+b": "bold",
  "mod+i": "italic",
  "mod+u": "underline",
  "mod+`": "code",
};
const LIST_TYPES = ["numbered-list", "bulleted-list"];

export const SlateElement = (props) => {
  const { attributes, children, element } = props;
  return handleSlateElement(attributes, children, element);
};

const handleSlateElement = (attributes, children, element, asString) => {
  const { align, colour } = element;
  switch (element.type) {
    default:
      return asString ? (
        `<p style="text-align: ${align || "left"}; color: ${
          colour || "inherit"
        }">${children}</p>`
      ) : (
        <p
          style={{ textAlign: align || "left", color: colour || "inherit" }}
          {...attributes}
        >
          {children}
        </p>
      );
      x;
    case FLEX_GRID:
      return FlexGridElement({ attributes, children, element, asString });

    case FLEX_GRID_COL:
      return FlexGridItemElement({ attributes, children, element, asString });

    case "paragraph":
      return asString ? (
        `<p style="text-align: ${align || "left"}; color: ${
          colour || "inherit"
        }"">${children}</p>`
      ) : (
        <p
          style={{ textAlign: align || "left", color: colour || "inherit" }}
          {...attributes}
        >
          {children}
        </p>
      );
    case "quote":
      return asString ? (
        `<blockquote style="text-align: ${align || "left"}; color: ${
          colour || "inherit"
        }"">${children}</blockquote>`
      ) : (
        <blockquote
          style={{ textAlign: align || "left", color: colour || "inherit" }}
          {...attributes}
        >
          {children}
        </blockquote>
      );
    case "code":
      return asString ? (
        `<pre style="text-align: ${align || "left"}; color: ${
          colour || "inherit"
        }""><code>${children}</code></pre>`
      ) : (
        <pre>
          <code
            style={{ textAlign: align || "left", color: colour || "inherit" }}
            {...attributes}
          >
            {children}
          </code>
        </pre>
      );
    case "bulleted-list":
      return asString ? (
        `<ul style="text-align: ${align || "left"}; color: ${
          colour || "inherit"
        }"">${children}</ul>`
      ) : (
        <ul
          style={{ textAlign: align || "left", color: colour || "inherit" }}
          {...attributes}
        >
          {children}
        </ul>
      );
    case "heading-one":
      return asString ? (
        `<h1 style="text-align: ${align || "left"}; color: ${
          colour || "inherit"
        }"">${children}</h1>`
      ) : (
        <h1
          style={{ textAlign: align || "left", color: colour || "inherit" }}
          {...attributes}
        >
          {children}
        </h1>
      );
    case "heading-two":
      return asString ? (
        `<h2 style="text-align: ${align || "left"}; color: ${
          colour || "inherit"
        }"">${children}</h2>`
      ) : (
        <h2
          style={{ textAlign: align || "left", color: colour || "inherit" }}
          {...attributes}
        >
          {children}
        </h2>
      );
    case "heading-three":
      return asString ? (
        `<h3 style="text-align: ${align || "left"}; color: ${
          colour || "inherit"
        }"">${children}</h3>`
      ) : (
        <h3
          style={{ textAlign: align || "left", color: colour || "inherit" }}
          {...attributes}
        >
          {children}
        </h3>
      );
    case "heading-two":
      return asString ? (
        `<h2 style="text-align: ${align || "left"}; color: ${
          colour || "inherit"
        }"">${children}</h2>`
      ) : (
        <h2
          style={{ textAlign: align || "left", color: colour || "inherit" }}
          {...attributes}
        >
          {children}
        </h2>
      );
    case "list-item":
      return asString ? (
        `<li style="text-align: ${align || "left"}; color: ${
          colour || "inherit"
        }"">${children}</li>`
      ) : (
        <li
          style={{ textAlign: align || "left", color: colour || "inherit" }}
          {...attributes}
        >
          {children}
        </li>
      );
    case "numbered-list":
      return asString ? (
        `<ol style="text-align: ${align || "left"}; color: ${
          colour || "inherit"
        }"">${children}</ol>`
      ) : (
        <ol
          style={{ textAlign: align || "left", color: colour || "inherit" }}
          {...attributes}
        >
          {children}
        </ol>
      );
    case "link":
      return asString ? (
        ` <a style="text-align: ${align || "left"}; color: ${
          colour || "inherit"
        }"" href="${escapeHtml(element.url)}">
      ${children}
    </a>`
      ) : (
        <a
          href={element.url}
          style={{ textAlign: align || "left", color: colour || "inherit" }}
          {...attributes}
        >
          {children}
        </a>
      );
    case "image":
      return asString ? (
        `<div style="text-align: ${
          align || "left"
        }"><img style="display: block; max-width: 100%;" src="${escapeHtml(
          element.url
        )}" /></div>`
      ) : (
        <ImageElement
          attributes={attributes}
          children={children}
          element={element}
          style={{ textAlign: align || "left" }}
        />
      );
    case "youtube":
      return asString ? (
        `<div style="paddingBottom: 56.25%; position: relative; textAlign: ${
          align || "left"
        }" >
          <iframe
            contenteditable="false"
            src="https://www.youtube.com/embed/${element?.videoId}"
            frameBorder="0"
            style="width: 1px; min-width: 100%; height: 100%; position: absolute; top: 0"
          />
        </div>`
      ) : (
        <div
          {...attributes}
          style={{
            paddingBottom: "56.25%",
            position: "relative",
            textAlign: align || "left",
          }}
        >
          <iframe
            contentEditable={false}
            src={`https://www.youtube.com/embed/${element?.videoId}`}
            frameBorder="0"
            style={{
              width: "1px",
              minWidth: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
            }}
          />
          {children}
        </div>
      );
    case "vimeo":
      return asString ? (
        `<div style="paddingBottom: 56.25%; position: relative; textAlign: ${
          align || "left"
        }">
          <iframe
            contenteditable="false"
            src="//player.vimeo.com/video/${element?.videoId}"
            frameBorder="0"
            style="width: 1px; min-width: 100%; height: 100%; position: absolute; top: 0"
          />
        </div>`
      ) : (
        <div
          {...attributes}
          style={{
            paddingBottom: "56.25%",
            position: "relative",
            textAlign: align || "left",
          }}
        >
          <iframe
            contentEditable={false}
            title="Vidmo video"
            src={`//player.vimeo.com/video/${element?.videoId}`}
            frameBorder="0"
            style={{
              width: "1px",
              minWidth: "100%",
              height: "100%",
              position: "absolute",
              top: 0,
            }}
          ></iframe>
          {children}
        </div>
      );
  }
};

export const serialize = (node) => {
  if (Text.isText(node)) {
    let string = escapeHtml(node.text);
    if (node.bold) {
      string = `<strong>${string}</strong>`;
    }
    if (node.code) {
      string = `<code>${string}</code>`;
    }

    if (node.italic) {
      string = `<em>${string}</em>`;
    }

    if (node.underline) {
      string = `<u>${string}</u>`;
    }

    if (node.strikethrough) {
      string = `<del>${string}</del>`;
    }
    return string;
  }

  const children = node.children.map((n) => serialize(n)).join("");
  return handleSlateElement({}, children, node, true);
};

export const SlateLeaf = ({ attributes, children, leaf }) => {
  if (leaf.bold) {
    children = <strong>{children}</strong>;
  }

  if (leaf.code) {
    children = <code>{children}</code>;
  }

  if (leaf.italic) {
    children = <em>{children}</em>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  if (leaf.strikethrough) {
    children = <del>{children}</del>;
  }

  return <span {...attributes}>{children}</span>;
};

export const onPaste = (editor) => (event) => {
  const pastedText = event.clipboardData?.getData("text")?.trim();
  if (
    handleVideoEmbed(editor, "youtube", youtubeRegex, pastedText) ||
    handleVideoEmbed(editor, "vimeo", vimeoRegex, pastedText)
  ) {
    event.preventDefault();
  }
};

export const insertGrid = (editor, size) => {
  const id = uuidv4();
  const flexGrid = {
    type: "flex-container",
    id,
    children: [
      { type: "flex-item", id: uuidv4(), cols: 4, children: [{ text: "" }] },
      { type: "flex-item", id: uuidv4(), cols: 4, children: [{ text: "" }] },
      { type: "flex-item", id: uuidv4(), cols: 4, children: [{ text: "" }] },
    ],
  };

  Transforms.insertNodes(editor, flexGrid);
};

const handleVideoEmbed = (editor, type, regex, text) => {
  const matches = text.match(regex);
  if (matches != null) {
    const [_, videoId] = matches;
    Transforms.insertNodes(editor, [
      {
        type: type,
        videoId,
        children: [
          {
            text: "",
          },
        ],
      },
      {
        children: [
          {
            text: "",
          },
        ],
      },
    ]);
    return true;
  }
  return false;
};

const useImageStyles = makeStyles((theme) => ({
  img: (props) => ({
    display: "block",
    maxWidth: "100%",
    boxShadow: `${
      props.selected && props.focused ? "0 0 0 2px blue;" : "none"
    }`,
  }),
}));

const ImageElement = ({ attributes, children, element, style }) => {
  const selected = useSelected();
  const focused = useFocused();
  const classes = useImageStyles({ selected, focused });
  return (
    <div {...attributes} style={style}>
      {children}
      <img src={element.url} className={classes.img} />
    </div>
  );
};

export const isBlockActive = (editor, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n.type === format,
  });
  return !!match;
};

export const toggleBlock = (editor, format) => {
  const isActive = isBlockActive(editor, format);
  const isList = LIST_TYPES.includes(format);

  Transforms.unwrapNodes(editor, {
    match: (n) => LIST_TYPES.includes(n.type),
    split: true,
  });

  Transforms.setNodes(editor, {
    type: isActive ? "paragraph" : isList ? "list-item" : format,
  });

  if (!isActive && isList) {
    const block = { type: format, children: [] };
    Transforms.wrapNodes(editor, block);
  }
};

export const isMarkActive = (editor, format) => {
  const marks = Editor.marks(editor);
  return marks ? marks[format] === true : false;
};

export const isLinkActive = (editor) => {
  const [link] = Editor.nodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
  });
  return !!link;
};

export const toggleMark = (editor, format) => {
  const isActive = isMarkActive(editor, format);

  if (isActive) {
    Editor.removeMark(editor, format);
  } else {
    Editor.addMark(editor, format, true);
  }
};

export const isImageUrl = (url) => {
  if (!url) return false;
  if (!isUrl(url)) return false;
  const ext = new URL(url).pathname.split(".").pop();
  return imageExtensions.includes(ext);
};

export const insertImage = (editor, url) => {
  const text = { text: "" };
  const image = { type: "image", url, children: [text] };
  Transforms.insertNodes(editor, image);
};

export const insertVideo = (editor, url) => {
  if (!url) return false;
  handleVideoEmbed(editor, "youtube", youtubeRegex, url) ||
    handleVideoEmbed(editor, "vimeo", vimeoRegex, url);
};

export const insertLink = (editor, url) => {
  if (editor.selection) {
    wrapLink(editor, url);
  }
};

export const wrapLink = (editor, url) => {
  if (isLinkActive(editor)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link = {
    type: "link",
    url,
    children: isCollapsed ? [{ text: url }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: "end" });
  }
};

export const unwrapLink = (editor) => {
  Transforms.unwrapNodes(editor, {
    match: (n) =>
      !Editor.isEditor(n) && Element.isElement(n) && n.type === "link",
  });
};

export const toggleStyle = (editor, key, format) => {
  // Toggle the alignment by applying/removing the format to/from the selected nodes.
  const isActive = isAlignmentActive(editor, key, format);
  const isBlock = Editor.isBlock(editor, editor.selection);
  if (isBlock) {
    const [block] = Editor.nodes(editor, {
      at: editor.selection,
      match: (n) => {
        return (
          Element.isElement(n) &&
          !["flex-grid", "flex-grid-col"].includes(n.type)
        );
      },
    });

    if (block) {
      const [, path] = block;
      const value = isActive ? null : format;
      Transforms.setNodes(editor, { [key]: value }, { at: path });
    }
  }
};

export const isAlignmentActive = (editor, key, format) => {
  const [match] = Editor.nodes(editor, {
    match: (n) => n[key] === format,
    universal: true,
  });
  return !!match;
};
