import { useEffect } from "react";
import { ValidatorForm } from "react-material-ui-form-validator";
import { validPath } from "utils/validators";

function useIsPathValidValidation() {
  const name = "isValidPath";
  useEffect(() => {
    ValidatorForm.addValidationRule(name, (value) => validPath(value));
    return () => {
      ValidatorForm.removeValidationRule(name);
    };
  }, []);
  return name;
}

export default useIsPathValidValidation;
