"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.toUrl = exports.locationPagePath = exports.formatVideoLink = exports.boatPagePath = exports.appendQueryParam = void 0;
var toUrl = exports.toUrl = function toUrl(path) {
  var invalidCharacters = /[^A-Za-z0-9\-._~:/?#[\]@!$&'()*+,;=]/g;
  return encodeURI(path.replace(/\/\//g, "/").replace(/\s+/g, "-").replace(invalidCharacters, ""));
};
var boatPagePath = exports.boatPagePath = function boatPagePath(path) {
  var f = !process.env.API_URL || ["https://api.staging.boathireexperts.hk/query", "https://api.v2.ucruisesydney.com.au/query", "https://api.ucruisesydney.com.au/query"].includes(process.env.API_URL) ? "cruise-partners" : "boats";
  return toUrl("/".concat(f, "/").concat(path.toLowerCase()));
};
var locationPagePath = exports.locationPagePath = function locationPagePath(path) {
  return toUrl("/locations/".concat(path.toLowerCase()));
};
var appendQueryParam = exports.appendQueryParam = function appendQueryParam(u, key, value) {
  u = u.includes("http") ? u : "https://".concat(u);
  var url = new URL(u);
  url.searchParams.set(key, value);
  var last = url.pathname.slice(-1);
  url.pathname = last === "/" ? url.pathname : url.pathname + "/";
  return url.toString();
};
var formatVideoLink = exports.formatVideoLink = function formatVideoLink(videoLink) {
  if (!videoLink) {
    return null;
  }
  var s = videoLink.includes("autoplay") ? videoLink : appendQueryParam(videoLink, "autoplay", 1);
  s = s.includes("mute") ? s : appendQueryParam(s, "mute", 1);
  s = s.includes("muted") ? s : appendQueryParam(s, "muted", 1);
  s = s.includes("controls") ? s : appendQueryParam(s, "controls", 0);
  s = s.includes("loop") ? s : appendQueryParam(s, "loop", 1);
  if (s.includes("youtube") && !s.includes("playlist")) {
    // try get id
    var pattern = /\/embed\/([\w-]+)/;
    var match = s.match(pattern);
    if (match && match.length > 1) {
      var videoId = match[1];
      s = appendQueryParam(s, "playlist", videoId);
    }
  }
  return s;
};