const rental = ["bre-com", "mi-bre-com", "boatrentalexperts", "miami"]; // e.g mi-bre-com
const hire = ["bhe", "boathire", "localhost", "conz", "bhehk"]; // boathire-com-nz

const brand = typeof process !== "undefined" && process.env && process.env.REACT_APP_BRAND && process.env.REACT_APP_BRAND !== "" ? process.env.REACT_APP_BRAND : "bhe";
const path = hire.some(s => brand.includes(s)) ? "boathire" : rental.some(s => brand.includes(s)) ? "boatrental" : "ucruise-com-au";
exports.Logo = require(`../themes/${path}/logo.svg`);
exports.Theme = require(`../themes/${path}/config.json`);

// uses window.location
exports.LoadTheme = async () => {
  const theme = window.location.hostname || "";
  const path = hire.some(s => (window.location.hostname || "").includes(s)) ? "boathire" : rental.some(s => (window.location.hostname || "").includes(s)) ? "boatrental" : "ucruise-com-au";
  const conf = await import(`../themes/${path}/config.json`);
  const logo = await import(`../themes/${path}/logo.svg`);
  return {
    conf,
    logo
  };
};