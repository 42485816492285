import Loading from "@components/loading/Loading";
import {
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@material-ui/core";
import { useBoatContext } from "@providers/BoatProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useGetAllBoatTypes } from "@store/actions/boatActions";
import React, { useCallback, useState } from "react";
import BoatTypeModal from "./BoatTypeModal";

function BoatTypes({ parent, setParent }) {
  const [open, setOpen] = useState(false);
  const [boatType, setBoatType] = useState({});
  const { loading, data } = useGetAllBoatTypes();
  const { showNotification } = useNotificationContext();

  const getBoatTypeIndex = useCallback(
    (boatType) =>
      (parent.boatTypes || []).map((bt) => bt.id).indexOf(boatType.id),
    [parent]
  );

  const onNewBoatType = useCallback(
    ({ name }) => {
      showNotification(`${name} Created!`);
      setOpen(false);
    },
    [showNotification]
  );

  const isChecked = (boatType) => {
    return getBoatTypeIndex(boatType) >= 0;
  };

  const handleChange = (boatType) => {
    const idx = getBoatTypeIndex(boatType);
    let boatTypes = [...(parent.boatTypes || [])];
    if (idx === -1) {
      boatTypes.push(boatType);
    } else {
      boatTypes.splice(idx, 1);
    }
    setParent({
      ...parent,
      boatTypes,
    });
  };

  if (loading) return <Loading />;
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Grid container justify="space-between" alignItems="center">
            <Grid item xs={9}>
              <Typography variant="subtitle1">Select boat types,</Typography>
              <Typography variant="subtitle2">
                There can be more than one type
              </Typography>
            </Grid>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setOpen(true)}
            >
              Add new boat type
            </Button>
          </Grid>
        </Grid>
        <Grid item xs={12}>
          <Grid container>
            {data.boatTypes.map((bt) => (
              <Grid key={bt.id} item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      color="primary"
                      checked={isChecked(bt)}
                      onChange={() => handleChange(bt)}
                      name={bt.id}
                    />
                  }
                  label={bt.name}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      <BoatTypeModal
        open={open}
        setOpen={setOpen}
        onSave={onNewBoatType}
        boatType={boatType}
        setBoatType={setBoatType}
      />
    </>
  );
}

export default BoatTypes;
