import React, { createContext, useContext, useMemo, useState } from "react";

export const LandingPageContext = createContext(null);
export const useLandingPageContext = () => useContext(LandingPageContext);

const LandingPageProvider = ({ children }) => {
  const [landingPage, setLandingPage] = useState({});

  const state = useMemo(
    () => ({
      landingPage,
      setLandingPage,
    }),
    [landingPage]
  );

  return (
    <LandingPageContext.Provider value={state}>
      {children}
    </LandingPageContext.Provider>
  );
};

export default LandingPageProvider;
