import Steps from "@components/Steps";
import { Button, CircularProgress, Grid, Link, Paper } from "@material-ui/core";
import { useLandingPageContext } from "providers/LandingPageProvider";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import TitleIcon from "@material-ui/icons/Title";
import HelpOutlineIcon from "@material-ui/icons/HelpOutline";
import OutdoorGrillIcon from "@material-ui/icons/OutdoorGrill";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import LineStyleIcon from "@material-ui/icons/LineStyle";
import WebIcon from "@material-ui/icons/Web";
import LandingPageForm from "./LandingPageForm";
import NavigateNext from "@material-ui/icons/NavigateNext";
import RowingIcon from "@material-ui/icons/Rowing";
import { useUpdateLandingPage } from "@store/actions/landingPageActions";
import FeaturesSelection from "@components/feature/FeaturesSelection";
import { useGetAllFeatures } from "@store/actions/featureActions";
import LandingPageSEO from "./LandingPageSEO";
import { Hero, LandingTile } from "components-library";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useGetAllLocations } from "@store/actions/locationActions";
import { useGetAllBoatTypes } from "@store/actions/boatActions";
import Loading from "@components/loading/Loading";
import BoatTypes from "@components/boat/BoatTypes";
import LandingPageLocationSelect from "./LandingPageLocationSelect";
import LandingPageContentBlocks from "./LandingPageContentBlocks";
import Editor from "@components/editor/Editor";
import { useUpdateEffect } from "react-use";
import { useGetSiteMeta } from "@store/actions/siteMetaActions";
import { produce } from "immer";
import { isHtmlEmpty } from "utils/html";

function LandingPageWizard() {
  const { landingPage, setLandingPage } = useLandingPageContext();
  const [updateLandingPage, { loading }] = useUpdateLandingPage();
  const { data: meta } = useGetSiteMeta();
  const [steps, setSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const { data: featuresData } = useGetAllFeatures();
  const { showNotification } = useNotificationContext();
  const [valid, setValid] = useState(true);
  const {
    loading: locationsLoading,
    data: locationsData,
  } = useGetAllLocations();
  const {
    loading: boatTypesLoading,
    data: boatTypesData,
  } = useGetAllBoatTypes();
  const [location, setLocation] = useState({});
  const [siteMeta, setSiteMeta] = useState({});
  const [selectedBoatTypes, setSelectedBoatTypes] = useState([]);
  const [maxPrice, setMaxPrice] = useState("");
  const [capacity, setCapacity] = useState("");
  const [extraContent, setExtraContent] = useState(null);

  useUpdateEffect(() => {
    setExtraContent(
      landingPage.slateJson ? JSON.parse(landingPage.slateJson) : null
    );
  }, [landingPage.id]);

  useEffect(() => {
    if (!meta) {
      return;
    }
    setSiteMeta(meta.siteMeta);
  }, [meta]);

  useEffect(() => {
    setLandingPage({
      ...landingPage,
      slateJson: JSON.stringify(extraContent),
    });
  }, [extraContent]);

  useUpdateEffect(() => {
    if (!landingPage?.features || !featuresData?.features) {
      return;
    }

    setLandingPage(
      produce(landingPage, (draft) => {
        const groups = [
          ...new Set([
            ...draft.featureGroups.map((fg) => fg.id),
            ...draft.features
              .filter((f) => f.featureGroup)
              .map((f) => f.featureGroup.id),
          ]),
        ];
        // if group already seleted dont change
        draft.featureGroups = groups.map((id) => ({
          id,
        }));
      })
    );
  }, [landingPage.features, featuresData]);

  const handleUpdate = useCallback(
    (landingPage) => {
      let input = {
        name: landingPage.name,
        title: landingPage.title,
        oneLiner: landingPage.oneLiner,
        description: landingPage.description,
        seoTitle: landingPage.seoTitle,
        seoDescription: landingPage.seoDescription,
        path: landingPage.path,
        extra:
          landingPage.extra && !isHtmlEmpty(landingPage.extra)
            ? landingPage.extra
            : undefined,
        slateJson: landingPage.slateJson,
        showOnHome: landingPage.showOnHome,
        showContact: landingPage.showContact,
        features: landingPage.features.map((f) => ({ id: f.id, name: f.name })),
        featureGroups: landingPage.featureGroups.map((fg) => ({ id: fg.id })),
        boatTypes: landingPage.boatTypes.map((bt) => ({ id: bt.id })),
        location:
          landingPage.location && landingPage.location.id
            ? {
                id: landingPage.location.id,
              }
            : null,
        media:
          landingPage.media && landingPage.media.id
            ? {
                id: landingPage.media.id,
              }
            : null,
      };

      if (landingPage.sections?.length) {
        input.sections = landingPage.sections.map((s) => ({
          title: s.title,
          type: s.type,
          link: s.link,
          linkText: s.linkText,
          colWidth: s.colWidth,
          order: s.order,
          section: {
            id:
              s.type === "boatSection"
                ? s.boatSection.id
                : s.type === "codeBlock"
                ? s.codeBlock.id
                : s.thumbLinkSection.id,
          },
        }));
      }
      return updateLandingPage({
        variables: {
          id: landingPage.id,
          input,
        },
      });
    },
    [updateLandingPage]
  );

  useEffect(() => {
    setSteps([
      {
        label: "Head Section",
        component: (
          <LandingPageForm handleUpdate={handleUpdate} setValid={setValid} />
        ),
        icon: <TitleIcon fontSize="inherit" />,
      },
      {
        label: "Boat Types",
        component: (
          <BoatTypes parent={landingPage} setParent={setLandingPage} />
        ),
        icon: <RowingIcon fontSize="inherit" />,
      },
      {
        label: "Location",
        component: (
          <LandingPageLocationSelect
            locations={locationsData?.locations || []}
            parent={landingPage}
            setParent={setLandingPage}
          />
        ),
        icon: <LocationOnIcon fontSize="inherit" />,
      },
      {
        label: "Features",
        component: (
          <FeaturesSelection
            allowGroupSelection
            features={featuresData ? featuresData.features : []}
            parent={landingPage}
            setParent={setLandingPage}
            title="Select which features this landing page has set"
          />
        ),
        icon: <OutdoorGrillIcon fontSize="inherit" />,
      },
      {
        label: "Additional content",
        component: (
          <Editor
            msg={extraContent}
            setMsg={setExtraContent}
            html={landingPage.extra}
            setHtml={(extra) => {
              setLandingPage({
                ...landingPage,
                extra,
              });
            }}
          />
        ),
        icon: <LineStyleIcon fontSize="inherit" />,
      },
      {
        label: "Content Blocks",
        component: (
          <LandingPageContentBlocks
            parent={landingPage}
            setParent={setLandingPage}
          />
        ),
        icon: <WebIcon fontSize="inherit" />,
      },
      {
        label: "SEO",
        component: <LandingPageSEO />,
        icon: <HelpOutlineIcon fontSize="inherit" />,
      },
    ]);
  }, [landingPage, setLandingPage, featuresData, locationsData, handleUpdate]);

  const featuredImg = useMemo(() => {
    return landingPage.media && landingPage.media.mediaFiles
      ? landingPage.media.mediaFiles[0].path
      : null;
  }, [landingPage]);

  const handleSave = () => {
    handleUpdate(landingPage).then(() => {
      setActiveStep(activeStep + 1);
    });
  };

  const testOnSearch = (location, boatType, capcity) => {
    showNotification(
      `Search for location : ${location.name}, boatType : ${boatType.name}, capcity: ${capcity} `
    );
  };

  const isStepComplete = (i) => {
    return i < activeStep;
  };

  const handleStep = (i) => {
    setActiveStep(i);
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        {locationsLoading || boatTypesLoading ? (
          <Loading />
        ) : (
          <Hero
            showSearch={true} // todo ???
            title={landingPage.title}
            subTitle={landingPage.oneLiner}
            description={landingPage.description}
            backgroundImage={featuredImg}
            locations={locationsData.locations}
            boatTypes={boatTypesData.boatTypes}
            onSearch={testOnSearch}
            location={location}
            setLocation={setLocation}
            selectedBoatTypes={selectedBoatTypes}
            setSelectedBoatTypes={setSelectedBoatTypes}
            capacity={capacity}
            setCapacity={setCapacity}
            maxPrice={maxPrice}
            setMaxPrice={setMaxPrice}
            searchBoxItems={siteMeta?.searchBox}
          />
        )}
      </Grid>

      <Grid item container spacing={5}>
        <Grid item xs={9}>
          <Paper>
            <Steps
              steps={steps}
              activeStep={activeStep}
              isStepComplete={isStepComplete}
              handleStep={handleStep}
              next={
                <Button
                  disabled={loading || !valid}
                  variant="contained"
                  color="primary"
                  endIcon={
                    loading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <NavigateNext />
                    )
                  }
                  onClick={handleSave}
                >
                  Save & next
                </Button>
              }
            />
          </Paper>
        </Grid>
        <Grid item xs={3}>
          <LandingTile
            title={landingPage.title}
            subTitle={landingPage.oneLiner}
            backgroundImage={
              landingPage.media && landingPage.media.mediaFiles
                ? landingPage.media.mediaFiles[0].path
                : null
            }
            LinkComponent={(props) => (
              <Link {...props} to={landingPage.path}>
                {props.children}
              </Link>
            )}
          />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default LandingPageWizard;
