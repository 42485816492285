"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var _core = require("@material-ui/core");
function _typeof(o) { "@babel/helpers - typeof"; return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) { return typeof o; } : function (o) { return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o; }, _typeof(o); }
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(obj, key, value) { key = _toPropertyKey(key); if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }
function _toPropertyKey(arg) { var key = _toPrimitive(arg, "string"); return _typeof(key) === "symbol" ? key : String(key); }
function _toPrimitive(input, hint) { if (_typeof(input) !== "object" || input === null) return input; var prim = input[Symbol.toPrimitive]; if (prim !== undefined) { var res = prim.call(input, hint || "default"); if (_typeof(res) !== "object") return res; throw new TypeError("@@toPrimitive must return a primitive value."); } return (hint === "string" ? String : Number)(input); }
var useCustomHtmlStyles = (0, _core.makeStyles)(function (theme) {
  var cols = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  var perColumn = 100 / cols.length;
  var gridStyles = _objectSpread(_objectSpread({
    "& .row": {
      display: "flex",
      margin: "0 -8px",
      flexWrap: "wrap"
    },
    '& [class^="col-"]': {
      padding: "0 8px"
    }
  }, cols.reduce(function (styles, col, idx) {
    styles["& .col-".concat(col)] = {
      flexGrow: 0,
      flexBasis: "calc(".concat((perColumn * (idx + 1)).toFixed(3), "% )"),
      maxWidth: "calc(".concat((perColumn * (idx + 1)).toFixed(3), "% )")
    };
    return styles;
  }, {})), {}, {
    "@media (max-width: 767px)": _objectSpread({}, cols.reduce(function (styles, col, idx) {
      styles["& .col-sm-".concat(col)] = {
        flexGrow: 0,
        flexBasis: "calc(".concat((perColumn * (idx + 1)).toFixed(3), "% )"),
        maxWidth: "calc(".concat((perColumn * (idx + 1)).toFixed(3), "% )"),
        marginBottom: "40px",
        "&:last-child": {
          margin: 0
        }
      };
      return styles;
    }, {}))
  });
  return {
    wrapper: _objectSpread({
      "& img": {
        maxWidth: "100%"
      }
    }, gridStyles)
  };
});
var _default = exports["default"] = useCustomHtmlStyles;