import { Grid } from "@mui/material";
import { Editor, Range, Transforms } from "slate";
import { v4 as uuidv4 } from "uuid";

export const FLEX_GRID = "flex-grid";
export const FLEX_GRID_COL = "flex-grid-col";

const insertFlexGrid = (editor, cols) => {
  const id = uuidv4();
  const size = 12 / cols;

  const children = [...Array(12 / size)].map(() => ({
    type: FLEX_GRID_COL,
    id: uuidv4(),
    cols: size,
    children: [
      {
        id: uuidv4(),
        type: "paragraph",
        children: [
          {
            text: "",
          },
        ],
      },
    ],
  }));

  const flexGrid = {
    type: FLEX_GRID,
    id,
    children,
  };

  Transforms.insertNodes(editor, [
    flexGrid,
    {
      id: uuidv4(),
      type: "paragraph",
      children: [
        {
          text: "",
        },
      ],
    },
  ]);
};

export const FlexGridElement = ({
  attributes,
  children,
  element,
  asString,
}) => {
  return asString ? (
    `<div class="MuiGrid-container MuiGrid-spacing-xs-2">${children}</div>`
  ) : (
    <Grid container spacing={2} {...attributes}>
      {children}
    </Grid>
  );
};

export const FlexGridItemElement = ({
  attributes,
  children,
  element,
  asString,
}) => {
  return asString ? (
    `<div class="MuiGrid-root MuiGrid-item MuiGrid-grid-xs-12 MuiGrid-grid-sm-${
      element.cols || 12
    }">${children}</div>`
  ) : (
    <Grid
      item
      sm={element.cols || 12}
      xs={12}
      sx={{
        backgroundColor: "rgba(0,0,0,0.02)",
        backgroundClip: "content-box",
        position: "relative",
        "&:before": {
          position: "absolute",
          content: "''",
          background: "red",
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
          backgroundColor: "rgba(0,0,0,0.02)",
          pointerEvents: "none",
        },
      }}
      {...attributes}
    >
      {children}
    </Grid>
  );
};

export const withFlexGrid = (editor) => {
  editor.insertFlexGrid = insertFlexGrid;
  return editor;
};
