import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_STAFF,
  UPDATE_STAFF,
  GET_ALL_STAFF,
  DELETE_STAFF,
  STAFF_ROLE_ENUM,
} from "@operations/staffOperations";

/* -------------------------------------------------------------------------- */
/*                                   Staffs                                    */
/* -------------------------------------------------------------------------- */

export const useCreateStaff = () =>
  useMutation(CREATE_STAFF, {
    update: (cache, { data: { createStaff } }) => {
      const data = cache.readQuery({
        query: GET_ALL_STAFF,
      });
      cache.writeQuery({
        query: GET_ALL_STAFF,
        data: {
          staffList: [...(data.staffList || []), createStaff],
        },
      });
    },
  });

export const useUpdateStaff = () => useMutation(UPDATE_STAFF);

export const useGetAllStaff = () => useQuery(GET_ALL_STAFF);

export const useDeleteStaff = () =>
  useMutation(DELETE_STAFF, {
    update: (cache, { data: { deleteStaff } }) => {
      const data = cache.readQuery({
        query: GET_ALL_STAFF,
      });
      const staffList = data ? data.staffList : [];
      cache.writeQuery({
        query: GET_ALL_STAFF,
        data: {
          staffList: staffList.filter((w) => w.id !== deleteStaff.id),
        },
      });
    },
  });

export const useStaffRoleEnum = () => useQuery(STAFF_ROLE_ENUM);
