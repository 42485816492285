import {
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  Typography,
} from "@material-ui/core";
import { Email, Person, Phone } from "@material-ui/icons";
import { Grid, Stack, Box } from "@mui/material";
import React from "react";

function AiDrawer({ open, setOpen, booking, checkAvailabilityResponse }) {
  const onClose = () => {
    setOpen(false);
  };
  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      PaperProps={{
        style: {
          width: "60%",
        },
      }}
    >
      <DialogTitle>Actions for booking {booking.reference}</DialogTitle>
      <DialogContent>
        <Grid container spacing={2} mb={4}>
          <Grid item xs={6}>
            {booking.lead && (
              <Box mt={1}>
                <Box sx={{ display: "flex" }}>
                  <Email fontSize="small" />
                  <Box sx={{ marginLeft: 4 }}>
                    <Typography variant="body2">
                      {booking.lead.email}
                    </Typography>
                  </Box>
                </Box>

                <Box sx={{ display: "flex" }}>
                  <Person fontSize="small" />
                  <Box sx={{ marginLeft: 4 }}>
                    <Typography variant="body2">
                      {booking.lead.firstName} {booking.lead.lastName}
                    </Typography>
                  </Box>
                </Box>
                <Box sx={{ display: "flex" }}>
                  <Phone fontSize="small" />
                  <Box sx={{ marginLeft: 4 }}>
                    <Typography variant="body2">
                      {booking.lead.phone || "-"}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Grid>
          <Grid item xs={6}></Grid>
        </Grid>
        <Divider />
        <Box mt={4}>
          <DialogContentText>
            I can see{" "}
            <strong>
              {checkAvailabilityResponse.map((c) => c.boatName).join(" and ")}
            </strong>{" "}
            {checkAvailabilityResponse.length > 1 ? "are" : "is"} available,
            would you like me to generate a quote?
          </DialogContentText>
          <Stack direction={"row"} spacing={2}>
            {checkAvailabilityResponse.map((ch) => (
              <Button variant="outlined" key={ch.boatId} color="secondary">
                Generate {ch.boatName}
              </Button>
            ))}
            {checkAvailabilityResponse.length >= 2 && (
              <Button variant="outlined" color="secondary">
                Generate for all
              </Button>
            )}
          </Stack>
        </Box>
      </DialogContent>
    </Drawer>
  );
}

export default AiDrawer;
