import PriceInfoCard from "@components/boat/PriceInfoCard";
import { Grid, makeStyles } from "@material-ui/core";
import { useDeleteQuoteItem } from "@store/actions/quoteActions";
import { useBoatLowestPrice } from "components-library";
import React, { useEffect, useMemo, useRef } from "react";
import { useDrop } from "react-dnd";
import { ValidatorForm } from "react-material-ui-form-validator";
import QuoteItem from "./QuoteItem";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "300px",
    border: `2px dashed ${theme.palette.divider}`,
    alignItems: "flex-start",
    padding: theme.spacing(2),
    margin: 0,
  },
}));

function QuoteBuilder({ booking, quote, setQuote, setIsValid, readOnly }) {
  const [deleteQuoteItem] = useDeleteQuoteItem(quote);
  const { getRate } = useBoatLowestPrice();
  const { root } = useStyles();

  const orderedItems = useMemo(() => {
    return (quote.items || [])
      .map((c) => c)
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
  }, [quote.items]);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "bookingItem",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: ({ item }) => {
      onDrop(item);
    },
  });

  const onDrop = async (bookingItem) => {
    // create quote item from booking item
    let baseCost = 0;
    if (quote.type !== "additional" && booking.cruiseDate && booking.hours) {
      baseCost = getRate(booking.cruiseDate, bookingItem.boat, booking.hours);
    }

    const items = [
      ...(quote?.items || []),
      {
        baseCost,
        bookingItem,
      },
    ];

    setQuote({
      ...quote,
      items,
    });
  };

  const setQuoteItem = (id) => (v) => {
    const items = (quote.items || []).map((item) =>
      item.id === id ? v : item
    );
    setQuote({
      ...quote,
      items,
    });
    setTimeout(checkSetValid, 1);
  };

  const removeItem = (id) => () => {
    deleteQuoteItem({
      variables: { id },
    });
    setTimeout(checkSetValid, 1);
  };

  useEffect(() => {
    checkSetValid();
  }, [quote.items]);

  const checkSetValid = () => {
    setTimeout(async () => {
      const valid = await formRef.current.isFormValid();
      setIsValid(valid);
    }, 1);
    if (!formRef.current) {
      return;
    }
  };

  const isActive = canDrop && isOver;
  const formRef = useRef();
  return (
    <ValidatorForm onSubmit={() => {}} ref={formRef}>
      <div className={root} ref={drop} style={{ opacity: isActive ? 0.5 : 1 }}>
        <Grid container spacing={3}>
          {orderedItems.map((item, idx) => (
            <Grid container item xs={12} key={item.id + idx}>
              <Grid item xs={8}>
                <QuoteItem
                  quote={quote}
                  booking={booking}
                  item={item}
                  setItem={setQuoteItem(item.id)}
                  onRemove={removeItem(item.id)}
                  checkSetValid={checkSetValid}
                  readOnly={readOnly}
                />
              </Grid>
              <Grid item xs={4}>
                {quote.type !== "additional" && (
                  <PriceInfoCard boat={item?.bookingItem?.boat} />
                )}
              </Grid>
            </Grid>
          ))}
        </Grid>
      </div>
    </ValidatorForm>
  );
}

export default QuoteBuilder;
