import Loading from "@components/loading/Loading";
import BoatTypesList from "@components/boat/BoatTypesList";
import { Button, Grid, Typography } from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import {
  useDeleteBoatType,
  useGetAllBoatTypes,
} from "@store/actions/boatActions";
import { useState } from "react";
import BoatTypeModal from "@components/boat/BoatTypeModal";

function BoatTypesPage() {
  const [open, setOpen] = useState(false);
  const [boatType, setBoatType] = useState({});
  const { loading, data, error } = useGetAllBoatTypes();
  const { showNotification } = useNotificationContext();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [deleteBoatType, { loading: deleting }] = useDeleteBoatType();

  const newBoatType = () => {
    setBoatType({});
    setOpen(true);
  };

  const onEdit = (boatType) => {
    setBoatType(boatType);
    setOpen(true);
  };

  const onDelete = ({ name, id }) => {
    openConfirmation({
      title: `Delete ${name}`,
      body: `Deleting ${name} can not be undone, are you sure you want to continue?`,
      loading: () => deleting,
      callback: () =>
        deleteBoatType({
          variables: {
            id,
          },
        }).then(() => {
          showNotification(`${name} Deleted!`);
          closeConfirmation();
        }),
    });
  };

  const onSave = ({ name, id }) => {
    showNotification(`${name} Saved!`);
    setOpen(false);
  };

  if (loading) return <Loading cover={true} />;
  if (error) return `Error! ${error.message}`;

  return (
    <>
      <Grid container spacing={3}>
        <Grid
          container
          item
          xs={10}
          justify="space-between"
          alignItems="center"
        >
          <Grid item xs={9}>
            <Typography variant="h5">Boat Types</Typography>
            <Typography variant="subtitle1">
              A Boat Type is the style of boat a bat can be
            </Typography>
          </Grid>
          <Button
            variant="contained"
            color="primary"
            startIcon={<AddIcon />}
            onClick={newBoatType}
          >
            New Boat Type
          </Button>
        </Grid>
        <Grid item xs={10}>
          <BoatTypesList
            boatTypes={data.boatTypes}
            onEdit={onEdit}
            onDelete={onDelete}
          />
        </Grid>
      </Grid>
      <BoatTypeModal
        open={open}
        setOpen={setOpen}
        onSave={onSave}
        boatType={boatType}
        setBoatType={setBoatType}
      />
    </>
  );
}

export default BoatTypesPage;
