import { Button, Grid, Typography } from "@material-ui/core";
import { useBoatContext } from "providers/BoatProvider";
import { useState } from "react";
import BoatPackageModal from "./BoatPackageModal";
import BoatPackagesTable from "./BoatPackagesTable";
import { useDeleteBoatPackage } from "@actions/boatActions";

const BoatPackages = () => {
  const { boat } = useBoatContext();
  const [open, setOpen] = useState(false);
  const [boatPackage, setBoatPackage] = useState({});
  const [deleteBoatPackage] = useDeleteBoatPackage(boat.id);

  const editPackage = (p) => {
    setBoatPackage({ ...p });
    setOpen(true);
  };

  const deletePackage = ({ id }) => {
    deleteBoatPackage({
      variables: {
        id,
      },
    });
  };
  return (
    <>
      <Grid container spacing={2} justify="space-between" alignItems="center">
        <Grid item xs={9}>
          <Typography variant="subtitle1">
            Boat packages are special packages a customer could buy
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
          >
            Add new package
          </Button>
        </Grid>
        <Grid item xs={12}>
          <BoatPackagesTable
            packages={boat.boatPackages}
            onEdit={editPackage}
            onDelete={deletePackage}
          />
        </Grid>
      </Grid>
      {open && (
        <BoatPackageModal
          open={open}
          setOpen={setOpen}
          boatPackage={boatPackage}
          setBoatPackage={setBoatPackage}
          boat={boat}
        />
      )}
    </>
  );
};

export default BoatPackages;
