import CardTable from "@components/cardTable/CardTable";
import { Link } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { Link as RouterLink } from "react-router-dom";

const LeadLink = (col, row) => {
  return (
    <Link
      variant="body1"
      color="primary"
      component={RouterLink}
      to={`/leads/${row.id}`}
    >
      {col}
    </Link>
  );
};

function LeadsTable({ leads, onEdit, onDelete }) {
  return (
    <CardTable
      tableHead={["firstName", "lastName", "email", "phone"]}
      tableData={leads.filter((l) => !l.firstName.includes("=?"))}
      customColumns={[
        {
          name: "firstName",
          component: LeadLink,
        },
      ]}
      actions={[
        {
          icon: <Edit color="primary" />,
          fn: onEdit,
        },
        {
          icon: <Delete color="secondary" />,
          fn: onDelete,
        },
      ]}
    />
  );
}

export default LeadsTable;
