import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useCreateWharf, useUpdateWharf } from "@store/actions/wharfActions";

function WharfModal({ open, setOpen, wharf, setWharf, onSave }) {
  const [addWharf] = useCreateWharf();
  const [updateWharf] = useUpdateWharf();

  const handleClose = () => {
    setOpen(false);
  };

  const handleChange = (e) => {
    setWharf({
      ...wharf,
      [e.target.name]: e.target.value,
    });
  };

  const handleSave = (e) => {
    e.preventDefault();
    const input = {
      name: wharf.name,
      taxablePostCode: wharf.taxablePostCode,
      googleMapsLink: wharf.googleMapsLink,
    };

    const fn = wharf.id
      ? updateWharf({
          variables: {
            id: wharf.id,
            input,
          },
        })
      : addWharf({
          variables: {
            id: wharf.id,
            input,
          },
        });

    fn.then((res) =>
      onSave(wharf.id ? res.data.updateWharf : res.data.createWharf)
    );
  };
  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <form onSubmit={handleSave}>
        <DialogTitle>Wharf</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the wharf name and google map link.
          </DialogContentText>
          <Box mb={1}>
            <TextField
              autoFocus
              margin="dense"
              label="Wharf Name"
              fullWidth
              value={wharf.name || ""}
              name="name"
              onChange={handleChange}
            />
          </Box>
          <Box mb={1}>
            <TextField
              margin="dense"
              label="google map link"
              fullWidth
              value={wharf.googleMapsLink || ""}
              name="googleMapsLink"
              onChange={handleChange}
            />
          </Box>
          <TextField
            margin="dense"
            label="Tax Zip / Post code"
            fullWidth
            value={wharf.taxablePostCode || ""}
            name="taxablePostCode"
            onChange={handleChange}
            helperText="This is the Zip / Post code of the wharf that will be used to calculate tax. This Tax code will be used if this wharf is the pickup loctaion for the booking."
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}

export default WharfModal;
