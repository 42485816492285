import { gql } from "@apollo/client";

export const GET_ALL_WAIVERS = gql`
  query GetAllWaivers {
    waivers {
      id
      name
      content
      slateJson
      createdAt
      boat {
        id
      }
    }
  }
`;
export const GET_BOAT_WAIVERS = gql`
  query GetAllWaivers($boatId: UUID!) {
    boatWaivers(boatId: $boatId) {
      id
      name
      content
      slateJson
      createdAt
      boat {
        id
      }
    }
  }
`;

export const CREATE_WAIVER = gql`
  mutation CreateWaiver($input: WaiverInput!) {
    createWaiver(input: $input) {
      id
      name
      content
      slateJson
      createdAt
      boat {
        id
      }
    }
  }
`;
export const UPDATE_WAIVER = gql`
  mutation UpdateWaiver($id: UUID!, $input: WaiverInput!) {
    updateWaiver(id: $id, input: $input) {
      id
      name
      content
      slateJson
      createdAt
      boat {
        id
      }
    }
  }
`;

export const DELETE_WAIVER = gql`
  mutation DeleteWaiver($id: UUID!) {
    deleteWaiver(id: $id) {
      id
    }
  }
`;
