import CardTable from "@components/cardTable/CardTable";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Link,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";
import React, { memo } from "react";
import { useState } from "react";
import {
  useCheckAvailability,
  useUpdateBooking,
} from "@store/actions/bookingActions";
import { Email, Person, Phone } from "@material-ui/icons";
import { useMount } from "react-use";
import AiDrawer from "./AiDrawer";
import { baseURL } from "index";

export const BookingLink = ({ row }) => {
  const newMessages = (row.messages || []).filter(
    (m) => (m.lead || m.owner) && !m.seenOn
  ).length;

  return (
    <Box>
      <Link
        variant="body1"
        color="primary"
        component={RouterLink}
        to={`/bookings/${row.id}`}
      >
        {row.reference}
      </Link>
      <Typography variant="body2">
        Created at : {moment(row.createdAt).format("ll")}
      </Typography>
      {row.availabilityCheckSentOn && (
        <Typography variant="body2" color="secondary">
          Availability Check :{" "}
          {moment(row.availabilityCheckSentOn).format("ll")}
        </Typography>
      )}

      {row.lead && (
        <Box mt={1}>
          <Link
            variant="body1"
            color="primary"
            component={RouterLink}
            to={`/leads/${row.lead.id}`}
          >
            <Box sx={{ display: "flex" }}>
              <Email fontSize="small" color="primary" />
              <Box sx={{ marginLeft: 4 }}>{row.lead.email}</Box>
            </Box>
          </Link>

          <Box sx={{ display: "flex" }}>
            <Person fontSize="small" />
            <Box sx={{ marginLeft: 4 }}>
              <Typography variant="body2">
                {row.lead.firstName} {row.lead.lastName}
              </Typography>
            </Box>
          </Box>
          <Box sx={{ display: "flex" }}>
            <Phone fontSize="small" />
            <Box sx={{ marginLeft: 4 }}>
              <Typography variant="body2">{row.lead.phone || "-"}</Typography>
            </Box>
          </Box>
        </Box>
      )}
      <Box mt={0.5}>
        <Typography variant="subtitle2">Rating Link:</Typography>
        <a href={`${baseURL}/rating/?ref=${row?.reference}`} target="_blank">
          {baseURL}/rating/?ref={row?.reference}
        </a>
      </Box>
      {newMessages > 0 && (
        <Box mt={1}>
          <Button variant="outlined" size="small" fullWidth color="primary">
            <RouterLink
              to={{
                pathname: `/bookings/${row.id}`,
                chatOpen: true,
              }}
            >
              {newMessages} new message(s)
            </RouterLink>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export const InfoCol = ({ row }) => {
  const missingSkippers = (row.items || []).filter(
    (i) => i.boat?.skipperRequired && i.skippers.length === 0
  );
  const missingDeckies = (row.items || []).filter(
    (i) => i.boat?.skipperRequired && i.deckies.length === 0
  );
  const interestedIn = (row.items || []).filter((item) => {
    return !item.quoteItems || item.quoteItems.length === 0;
  });
  const quotedFor = (row.items || []).filter((item) => {
    return item.quoteItems && item.quoteItems.length > 0;
  });
  const packages = (row.items || [])
    .map((i) => i.boatPackage)
    .filter((p) => p?.id);

  return (
    <>
      <Typography
        variant="body2"
        color={moment().isAfter(row.cruiseDate) ? "error" : "inherit"}
      >
        {moment().isAfter(row.cruiseDate) && "(Expired) "}
        Cruise Date :{" "}
        {row.cruiseDate ? moment(row.cruiseDate).format("ll") : ""}
      </Typography>
      <Typography variant="body2">
        Group Size : {row.groupSize} - Hrs : {row.hours}
      </Typography>

      {packages.length > 0 && (
        <Box>
          <Typography variant="caption">
            <Typography
              variant="caption"
              display="inline"
              style={{
                fontWeight: "bold",
              }}
            >
              Interested Packages:{" "}
            </Typography>
            {packages.map((p, i) => (
              <span key={p.id}>
                {p.name}
                {i + 1 < p.length && ", "}
              </span>
            ))}
          </Typography>
        </Box>
      )}
      {interestedIn.length > 0 && (
        <Typography variant="caption">
          <strong>Interested Boats: </strong>
          {row.items.map((item, i) => (
            <span key={i}>
              {item.boat?.name}
              {i + 1 < row.items.length && ", "}
            </span>
          ))}
        </Typography>
      )}
      {quotedFor.length > 0 && (
        <Typography variant="caption">
          <strong>Quoted Boats: </strong>
          {row.items.map((item, i) => (
            <span key={i}>
              {item.boat?.name}
              {i + 1 < row.items.length && ", "}
            </span>
          ))}
        </Typography>
      )}
      {missingSkippers.length > 0 && (
        <Box>
          <Typography
            variant="caption"
            display="inline"
            style={{
              fontWeight: "bold",
            }}
          >
            Skipper Missing For:{" "}
            {missingSkippers.map((s, i) => (
              <Typography variant="caption" key={i}>
                {s.boat.name}
                {i < missingSkippers.length - 1 && ", "}
              </Typography>
            ))}
          </Typography>
        </Box>
      )}
      {missingDeckies.length > 0 && (
        <Box>
          <Typography
            variant="caption"
            display="inline"
            style={{
              fontWeight: "bold",
            }}
          >
            Deckies Missing For:{" "}
            {missingDeckies.map((s, i) => (
              <Typography variant="caption" key={i}>
                {s.boat.name}
                {i < missingDeckies.length - 1 && ", "}
              </Typography>
            ))}
          </Typography>
        </Box>
      )}
      <Box>
        <Typography variant="caption">
          Source: {row.source || "Unknown"}
        </Typography>
      </Box>
    </>
  );
};

const NotesCol = ({ row }) => {
  const [notes, setNotes] = useState(row.notes || "");
  const [updateBooking] = useUpdateBooking();
  const [checkAvailability] = useCheckAvailability();
  const [hasAvailability, setHasAvailability] = useState(false);
  const [open, setOpen] = useState(false);
  const [checkAvailabilityResponse, setCheckAvailabilityResponse] = useState(
    null
  );

  useMount(() => {
    const fn = async () => {
      const res = await checkAvailability({
        variables: {
          id: row.id,
        },
      });
      setCheckAvailabilityResponse(res?.data?.checkAvailability);
      setHasAvailability(
        (res?.data?.checkAvailability || []).filter((ch) => ch?.isAvailable)
          .length > 0
      );
    };
    fn();
  });

  const patchBooking = async (booking, notes) => {
    const input = {
      groupSize: booking.groupSize,
      hours: booking.hours,
      catering: booking.catering,
      event: booking.event,
      eventOther: booking.eventOther,
      available: booking.available,
      cruiseDate: booking.cruiseDate,
      notes: notes,
      lead: {
        id: booking.lead?.id,
      },
    };
    await updateBooking({
      variables: {
        id: booking.id,
        input,
      },
    });
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <TextField
        style={{ minWidth: 300 }}
        label="notes"
        variant="outlined"
        multiline
        minRows={3}
        maxRows={5}
        value={notes}
        onChange={(e) => setNotes(e.target.value)}
        onBlur={() => patchBooking(row, notes)}
      />
      {hasAvailability && (
        <>
          <Box mt={2}>
            <Button
              disabled
              variant="contained"
              color="primary"
              onClick={() => setOpen(true)}
            >
              AI Actions Available
            </Button>
          </Box>
          <AiDrawer
            open={open}
            setOpen={setOpen}
            booking={row}
            checkAvailabilityResponse={checkAvailabilityResponse}
          />
        </>
      )}
    </Box>
  );
};

const Row = (mergeFn, availableFn, availableCheckFn, onEdit, onDelete) => ({
  row,
}) => {
  return (
    <TableRow className={row.className}>
      <TableCell style={{ maxWidth: "400px" }}>
        <BookingLink row={row.ref} />
      </TableCell>
      <TableCell>
        <InfoCol row={row.ref} />
      </TableCell>
      <TableCell>
        <ActionsCol
          row={row.ref}
          mergeFn={mergeFn}
          availableFn={availableFn}
          availableCheckFn={availableCheckFn}
        />
      </TableCell>
      <TableCell>
        <NotesCol row={row.ref} />
      </TableCell>

      <TableCell align="right" style={{ minWidth: "120px" }}>
        <ButtonGroup color="primary">
          <IconButton size="small" onClick={() => onEdit(row.ref)}>
            <Edit />
          </IconButton>
          <IconButton size="small" onClick={() => onDelete(row.ref)}>
            <Delete />
          </IconButton>
        </ButtonGroup>
      </TableCell>
    </TableRow>
  );
};

const ActionsCol = ({ mergeFn, availableFn, availableCheckFn, row }) => {
  return (
    <div style={{ maxWidth: "200px" }}>
      <ButtonGroup orientation="vertical" color="primary">
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => mergeFn(row)}
        >
          Merge with another
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          component={RouterLink}
          to={`/bookings/${row.id}/options`}
        >
          Manage Options
        </Button>

        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => availableCheckFn(row)}
        >
          Send Availability Check
        </Button>

        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => availableFn(row)}
        >
          Mark as {row.available ? "un available" : "available"}
        </Button>
        <Button
          variant="outlined"
          size="small"
          color="primary"
          component={RouterLink}
          to={`/bookings/${row.id}/quotes`}
        >
          Manage Quotes
        </Button>
      </ButtonGroup>
    </div>
  );
};

function BookingsTableInner({
  sortedBookings,
  onEdit,
  onDelete,
  mergeAction,
  toggleAvailableAction,
  checkAvailability,
}) {
  return (
    <CardTable
      initialRows={10}
      tableHead={["reference", "info", "actions", "notes"]}
      tableData={sortedBookings}
      rowComponent={Row(
        mergeAction,
        toggleAvailableAction,
        checkAvailability,
        onEdit,
        onDelete
      )}
    />
  );
}

export default memo(BookingsTableInner);
