import {
  Button,
  Grid,
  IconButton,
  MenuItem,
  TextField,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import { useQuoteItemType } from "@store/actions/bookingActions";
import React, { useEffect, useMemo, useState } from "react";
import { capatalise, splitCamel } from "utils/strings";

const RateRow = ({ rate, types, setRates, onRemove, idx }) => {
  const [type, setType] = useState(rate.type || "");
  const [commission, setCommission] = useState(
    typeof rate.commission === "number" ? rate.commission : 20
  );

  useEffect(() => {
    setRates((rate) =>
      rate.map((r, i) => ({
        ...r,
        type: i === idx ? type : r.type,
        commission: i === idx ? commission : r.commission,
      }))
    );
  }, [type, commission]);

  return (
    <Grid container item xs={12} spacing={3}>
      <Grid item xs={6}>
        <TextField
          select
          variant="outlined"
          name="type"
          label="Type"
          fullWidth
          value={type}
          onChange={(e) => setType(e.target.value)}
          size="small"
        >
          {types.map((type) => (
            <MenuItem key={type} value={type}>
              {capatalise(splitCamel(type))}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={5}>
        <TextField
          variant="outlined"
          name="commission"
          label="Commission"
          fullWidth
          type="number"
          value={commission}
          onChange={(e) => setCommission(e.target.value)}
          size="small"
        />
      </Grid>

      <Grid item xs={1}>
        <IconButton color="primary" onClick={onRemove}>
          <DeleteIcon />
        </IconButton>
      </Grid>
    </Grid>
  );
};

function CommsItemFormBody({ rates, setRates }) {
  const { data: quoteItemTypes } = useQuoteItemType();

  const types = useMemo(() => {
    if (!quoteItemTypes) {
      return [];
    }
    return quoteItemTypes.__type.enumValues.map((e) => e.name);
  }, [quoteItemTypes]);

  return (
    <Grid container spacing={3}>
      {rates.map((rate, idx) => (
        <RateRow
          rate={rate}
          key={idx + rate.type}
          types={types}
          setRates={setRates}
          idx={idx}
          onRemove={() =>
            setRates(() =>
              rates.filter((_, i) => {
                return i !== idx;
              })
            )
          }
        />
      ))}
      <Grid item xs={12}>
        <Button
          variant="outlined"
          color="primary"
          onClick={() => setRates((r) => [...r, {}])}
        >
          Add New Rate
        </Button>
      </Grid>
    </Grid>
  );
}

export default CommsItemFormBody;
