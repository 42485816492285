import React, { useEffect, useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import { makeStyles, useTheme } from "@material-ui/core";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import MediaTool from "./MediaTool";
import { useGetAllMedia } from "@store/actions/mediaActions";

const useStyles = makeStyles((theme) => ({
  toolRoot: {
    border: `1px solid ${theme.palette.divider}`,
  },
  editorRoot: {
    background: theme.palette.background.paper,
    border: `1px solid ${theme.palette.divider}`,
    padding: theme.spacing(4, 2),
    "& figure": {
      margin: 0,
    },
  },
}));

function WysiwygEditor({ msg, setMsg }) {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const { editorRoot, toolRoot } = useStyles();
  const { data: mediaData } = useGetAllMedia();
  const theme = useTheme();

  useEffect(() => {
    const contentBlock = htmlToDraft(msg || "");
    const contentState = ContentState.createFromBlockArray(
      contentBlock.contentBlocks,
      contentBlock.entityMap
    );
    setEditorState(EditorState.createWithContent(contentState));
  }, []);

  useEffect(() => {
    let html = draftToHtml(
      convertToRaw(editorState.getCurrentContent()),
      undefined,
      false,
      (a) => {
        if (a.type === "IMAGE") {
          const { data } = a;
          return `<img src="${data.src}" style="display: block; height: ${
            data.height
          }; maxWidth: 100%; width: ${data.width}; float: ${
            data.alignment
          }; margin: ${data.alignment === "none" ? "auto" : "inherit"}" />`;
        }
      }
    );
    setMsg(html);
  }, [editorState]);

  const onEditorStateChange = (e) => {
    setEditorState(e);
  };

  return (
    <Editor
      toolbarClassName={toolRoot}
      editorClassName={editorRoot}
      toolbar={{
        options: [
          "inline",
          "blockType",
          "fontSize",
          "list",
          "textAlign",
          "colorPicker",
          "link",
          "embedded",
          "emoji",
          "history",
        ],
        colorPicker: {
          colors: [
            theme.palette.common.default,
            theme.palette.text.primary,
            theme.palette.text.secondary,
            theme.palette.primary.main,
            theme.palette.primary.light,
            theme.palette.primary.dark,
            theme.palette.secondary.main,
            theme.palette.secondary.light,
            theme.palette.secondary.dark,
            theme.palette.common.paper,
          ],
        },
      }}
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
      toolbarCustomButtons={[<MediaTool media={mediaData?.medias} />]}
      stripPastedStyles
    />
  );
}

WysiwygEditor.defaultProps = {
  msg: "",
};

export default WysiwygEditor;
