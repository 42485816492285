import ConfirmationModal from "@components/confirmationModal/ConfirmationModal";
import React, {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";

export const ConfirmationContext = createContext(null);
export const useConfirmationContext = () => useContext(ConfirmationContext);

const initialConfirmation = {
  open: false,
  callback: () => {},
  title: "",
  body: "",
};

const ConfirmationProvider = ({ children }) => {
  const [confirmation, setConfirmation] = useState(initialConfirmation);
  const [loading, setLoading] = useState(false);
  const openConfirmation = ({ title, body, callback, loading }) => {
    setConfirmation({
      open: true,
      title,
      body,
      callback,
    });
    setLoading(loading);
  };
  const closeConfirmation = () => {
    setConfirmation(initialConfirmation);
  };

  const setOpen = (open) => {
    setConfirmation({
      ...confirmation,
      open,
    });
  };

  const state = useMemo(
    () => ({
      openConfirmation,
      closeConfirmation,
    }),
    []
  );

  return (
    <ConfirmationContext.Provider value={state}>
      <>
        {children}
        <ConfirmationModal
          open={confirmation.open}
          setOpen={setOpen}
          onConfirm={confirmation.callback}
          title={confirmation.title}
          body={confirmation.body}
          loading={loading}
          setLoading={setLoading}
        />
      </>
    </ConfirmationContext.Provider>
  );
};

export default ConfirmationProvider;
