const adminStyle = (theme) => {
  const { drawerWidth, transition, container } = theme.custom;

  return {
    wrapper: {
      position: "relative",
      height: "100vh",
      background: theme.palette.background.default,
    },
    mainPanel: {
      width: `calc(100% - ${drawerWidth}px)`,
      overflow: "auto",
      position: "relative",
      float: "right",
      ...transition,
      maxHeight: "100%",
    },
    content: {
      padding: theme.spacing(4, 2),
      minHeight: "100vh",
      maxWidth: "1600px",
      transition: "all .15s ease-out",
    },
    container,
  };
};
export default adminStyle;
