import { gql } from "@apollo/client";

export const DO_LOGIN = gql`
  mutation Login($email: String!, $pass: String!) {
    authenticate(input: { email: $email, password: $pass }) {
      user
      expiresAt
    }
  }
`;

export const DO_FORGOT = gql`
  mutation Forgot($email: String!) {
    forgot(email: $email)
  }
`;

export const DO_RESEST = gql`
  mutation ResetPassword($code: UUID!, $password: String!) {
    resetPassword(input: { code: $code, password: $password })
  }
`;
