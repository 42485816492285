import { FormControlLabel, Grid, Switch, TextField } from "@material-ui/core";
import { useContentPageContext } from "@providers/ContentPageProvider";
import useIsPathValidValidation from "hooks/useIsPathValidValidation";
import React, { useEffect, useRef } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

export default function ContentPageForm({ setValid }) {
  const { contentPage, setContentPage } = useContentPageContext();
  const isPathValid = useIsPathValidValidation();
  const pathRef = useRef();

  useEffect(() => {
    setValid(pathRef.current.isValid());
    return () => {
      setValid(true);
    };
  }, []);

  const handleChange = (e) => {
    setContentPage({
      ...contentPage,
      [e.target.name]:
        e.target.type === "checkbox"
          ? !contentPage[e.target.name]
          : e.target.value,
    });
    if (e.target.name === "path") {
      pathRef.current.validate(e.target.value);
      setTimeout(() => {
        setValid(e.target.value && pathRef.current.isValid());
      }, 0);
    }
  };

  return (
    <ValidatorForm noValidate autoComplete="off" onSubmit={() => {}}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <TextField
                name="name"
                onChange={handleChange}
                value={contentPage.name || ""}
                fullWidth
                size="small"
                label="Name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={10}>
              <TextField
                name="title"
                onChange={handleChange}
                value={contentPage.title || ""}
                fullWidth
                size="small"
                label="Title"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={10}>
              <TextValidator
                name="path"
                onChange={handleChange}
                value={contentPage.path || ""}
                fullWidth
                size="small"
                label="Path"
                variant="outlined"
                ref={pathRef}
                validators={[
                  isPathValid,
                  "minStringLength:3",
                  "maxStringLength:255",
                ]}
                errorMessages={[
                  "Path is not valid, it must start with `/` and be url friendy",
                  "Path must be more than 3 characters long",
                  "Path must be less than 255 characters long",
                ]}
              />
            </Grid>
            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Switch
                    name="showContact"
                    checked={contentPage.showContact || false}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Show Contact Form"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}
