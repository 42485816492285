import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { useCreateLandingPage } from "@store/actions/landingPageActions";

const NewLandingModal = ({ open, setOpen, onSave }) => {
  const [name, setName] = useState("");
  const [addLandingPage] = useCreateLandingPage();

  const handleChange = (e) => {
    setName(e.target.value);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    addLandingPage({
      variables: {
        input: { name },
      },
    }).then(({ data }) => {
      onSave(data.createLandingPage);
    });
  };

  return (
    <Dialog
      maxWidth="xs"
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <form onSubmit={handleSave}>
        <DialogTitle id="form-dialog-title">New Landing page</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the landing pages name, after which you will be taken
            to the landing page setup wizard.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Landing page Name"
            fullWidth
            value={name}
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default NewLandingModal;
