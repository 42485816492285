import CardTable from "@components/cardTable/CardTable";
import { Link, Typography } from "@material-ui/core";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";
import { Link as RouterLink } from "react-router-dom";
import React from "react";

const BoatCol = (_, row) => (
  <Typography variant="body1">{row?.boat?.name || ""}</Typography>
);
const PackageCol = (_, row) => (
  <Typography variant="body1">
    {row.boatPackage ? row?.boatPackage?.name : ""}
  </Typography>
);

const SkippersCol = (_, row) => {
  return (
    <Typography variant="body2">
      {row.skippers
        ? row?.skippers.map((s) => s.staff.firstName).join("-")
        : ""}
    </Typography>
  );
};
const DeckiesCol = (_, row) => {
  return (
    <Typography variant="body2">
      {row.skippers ? row?.deckies.map((s) => s.staff.firstName).join("-") : ""}
    </Typography>
  );
};

const QuoteCol = (booking) => (_, row) => {
  const uniqueRows = [
    ...new Set(row.quoteItems.filter((qi) => qi.quote).map((qi) => qi.quote)),
  ];
  return uniqueRows.map((q, i) => (
    <React.Fragment key={q.id}>
      <Link
        variant="caption"
        color={q.type === "additional" ? "secondary" : "primary"}
        component={RouterLink}
        to={`/bookings/${booking.id}/quotes/${q.id}`}
      >
        {q.reference}
      </Link>
      {i + 1 < uniqueRows.length && <> - </>}
    </React.Fragment>
  ));
};
const OptionCol = (booking) => (_, row) => {
  const uniqueRows = [
    ...new Set(
      row.options.filter((o) => o.bookingOption).map((o) => o.bookingOption)
    ),
  ];
  return uniqueRows.map((q, i) => (
    <React.Fragment key={q.id}>
      <Link
        variant="caption"
        color={"primary"}
        component={RouterLink}
        to={`/bookings/${booking.id}/options/${q.id}`}
      >
        {q.reference}
      </Link>
      {i + 1 < uniqueRows.length && <> - </>}
    </React.Fragment>
  ));
};

function BookingItemsList({ booking, bookingItems, onEdit, onDelete }) {
  return (
    <CardTable
      tableHead={[
        "Boat",
        "Package",
        "Options",
        "Quotes",
        "Skippers",
        "Deckies",
      ]}
      tableData={bookingItems}
      customColumns={[
        {
          name: "Boat",
          component: BoatCol,
        },
        {
          name: "Package",
          component: PackageCol,
        },
        {
          name: "Options",
          component: OptionCol(booking),
        },
        {
          name: "Quotes",
          component: QuoteCol(booking),
        },
        {
          name: "Skippers",
          component: SkippersCol,
        },
        {
          name: "Deckies",
          component: DeckiesCol,
        },
      ]}
      actions={[
        {
          icon: <Edit color="primary" />,
          fn: onEdit,
        },
        {
          icon: <Delete color="secondary" />,
          fn: onDelete,
        },
      ]}
    />
  );
}

export default BookingItemsList;
