import { useMutation, useQuery } from "@apollo/client";
import {
  CREATE_WAIVER,
  UPDATE_WAIVER,
  GET_ALL_WAIVERS,
  DELETE_WAIVER,
  GET_BOAT_WAIVERS,
} from "@operations/waiverOperations";

/* -------------------------------------------------------------------------- */
/*                                   Waiver                                   */
/* -------------------------------------------------------------------------- */

export const useCreateWaiver = () =>
  useMutation(CREATE_WAIVER, {
    update: (cache, { data: { createWaiver } }) => {
      // update all
      const data = cache.readQuery({
        query: GET_ALL_WAIVERS,
      });
      if (data) {
        cache.writeQuery({
          query: GET_ALL_WAIVERS,
          data: {
            waivers: [...(data.waivers || []), createWaiver],
          },
        });
      }

      // update boat
      const boatData = cache.readQuery({
        query: GET_BOAT_WAIVERS,
        variables: { boatId: createWaiver.boat.id },
      });
      if (boatData) {
        cache.writeQuery({
          query: GET_BOAT_WAIVERS,
          variables: { boatId: createWaiver.boat.id },
          data: {
            boatWaivers: [...(boatData.boatWaivers || []), createWaiver],
          },
        });
      }
    },
  });

export const useUpdateWaiver = () => useMutation(UPDATE_WAIVER);

export const useGetAllWaivers = () => useQuery(GET_ALL_WAIVERS);

export const useGetAllBoatWaivers = (boatId) =>
  useQuery(GET_BOAT_WAIVERS, { variables: { boatId } });

export const useDeleteWaiver = (boatId) =>
  useMutation(DELETE_WAIVER, {
    update: (cache, { data: { deleteWaiver } }) => {
      // remove from all
      const data = cache.readQuery({
        query: GET_ALL_WAIVERS,
      });
      const waivers = data ? data.waivers : [];
      cache.writeQuery({
        query: GET_ALL_WAIVERS,
        data: {
          waivers: waivers.filter((w) => w.id !== deleteWaiver.id),
        },
      });

      if (!boatId) {
        return;
      }
      // remove from boat
      const boatData = cache.readQuery({
        query: GET_BOAT_WAIVERS,
        variables: { boatId },
      });
      const boatWaivers = boatData ? boatData.boatWaivers : [];

      cache.writeQuery({
        query: GET_BOAT_WAIVERS,
        variables: { boatId },
        data: {
          boatWaivers: boatWaivers.filter((w) => w.id !== deleteWaiver.id),
        },
      });
    },
  });
