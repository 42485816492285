import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Button,
  TextField,
  Typography,
} from "@material-ui/core";
import { MediaSelector } from "components-library";
import { useCallback, useEffect, useState } from "react";

function MediaSelectModal({
  open,
  setOpen,
  media: initialMedia,
  selected,
  setSelected,
  Preview,
  min,
  max,
  dimensions,
  setDimensions,
  multiple,
}) {
  const close = () => {
    setOpen(false);
  };
  const clear = () => {
    setSelected([]);
  };
  const [search, setSearch] = useState("");
  const [media, setMedia] = useState([]);

  useEffect(() => {
    setMedia(initialMedia);
  }, [initialMedia]);

  useEffect(() => {
    if (search && search.length > 2) {
      setMedia(initialMedia.filter((m) => m.name.includes(search)));
      return;
    }
    setMedia(initialMedia);
  }, [search]);

  const onSelected = useCallback(
    (v) => {
      setSelected(v);
      if (!multiple) {
        close();
      }
    },
    [multiple]
  );
  return (
    <Dialog
      maxWidth="md"
      fullWidth
      open={open}
      onClose={close}
      disableBackdropClick
    >
      <DialogTitle>
        <Grid container alignItems="center" spacing={5}>
          <Grid item> Media Select</Grid>
          <Grid item>
            <TextField
              label="Search"
              variant="outlined"
              size="small"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={3} justify="space-between">
          <Grid item xs={9}>
            <DialogContentText>
              Selected {selected.length} Images{" "}
              {min < 0 && (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={clear}
                >
                  Clear all
                </Button>
              )}
            </DialogContentText>
            {dimensions && (
              <Grid container spacing={2} alignItems="center">
                <Grid item xs={3}>
                  <Typography> Set Dimensions</Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="height"
                    value={dimensions.height}
                    onChange={(e) =>
                      setDimensions({
                        ...dimensions,
                        height: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    variant="outlined"
                    size="small"
                    label="width"
                    value={dimensions.width}
                    onChange={(e) =>
                      setDimensions({
                        ...dimensions,
                        width: e.target.value,
                      })
                    }
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
          <Grid item>
            <Button variant="outlined" onClick={close}>
              Close
            </Button>
          </Grid>
          <Grid item xs={Preview ? 8 : 12}>
            <MediaSelector
              medias={media || []}
              cols={Preview ? 3 : 5}
              selected={selected}
              setSelected={onSelected}
              min={min}
              max={max}
            />
          </Grid>
          {Preview && (
            <Grid item xs={4}>
              <Preview />
            </Grid>
          )}
        </Grid>
      </DialogContent>
    </Dialog>
  );
}

export default MediaSelectModal;
