import CardTable from "@components/cardTable/CardTable";
import { Button, Grid, Link, Typography } from "@material-ui/core";
import { useBookingContext } from "@providers/BookingProvider";
import { useState } from "react";
import NewBookingOption from "./NewBookingOption";
import { Link as RouterLink, useHistory } from "react-router-dom";
import Delete from "@material-ui/icons/Delete";
import { useDeleteBookingOption } from "@store/actions/bookingActions";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { baseURL } from "index";

const optionLink = (booking) => (ref, row) => (
  <>
    <Link
      variant="body1"
      color="primary"
      component={RouterLink}
      to={`/bookings/${booking.id}/options/${row.id}`}
    >
      {ref}
    </Link>
  </>
);

const messageCol = (msg) => {
  if (!msg) {
    return "";
  }
  return msg.length > 50 ? msg.slice(0, 50) + "..." : msg;
};

const linkCol = (_, row) => {
  return (
    <a href={`${baseURL}/options/?ref=${row?.reference}&admin=true`}>
      {baseURL}/options/?ref={row?.reference}&admin=true
    </a>
  );
};

function BookingOptions() {
  const { booking } = useBookingContext();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showNotification } = useNotificationContext();
  const [deleteBookingOption, { loading: deleting }] = useDeleteBookingOption(
    booking
  );

  const onSaved = (id) => {
    history.push(`/bookings/${booking.id}/options/${id}`);
  };

  const onDelete = ({ id }) => {
    openConfirmation({
      title: `Delete this booking option`,
      body: `Deleting this booking option can not be undone, are you sure you want to continue?`,
      loading: () => deleting,
      callback: () =>
        deleteBookingOption({
          variables: {
            id,
          },
        }).then(() => {
          showNotification(`Booking option Deleted!`);
          closeConfirmation();
        }),
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid container item xs={12}>
        <Grid item xs={10}>
          <Typography variant="h6">Booking Options</Typography>
          <Typography variant="body1" gutterBottom>
            Booking Options are collections of booking items that are sent to a
            customer for review
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Button
            fullWidth
            variant="outlined"
            color="primary"
            onClick={() => setOpen(true)}
          >
            New Option
          </Button>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <CardTable
          tableHead={["reference", "message", "sentOn", "seenOn", "link"]}
          tableData={booking.options || []}
          customColumns={[
            {
              name: "reference",
              component: optionLink(booking),
            },
            {
              name: "message",
              component: messageCol,
            },
            {
              name: "link",
              component: linkCol,
            },
          ]}
          actions={[
            {
              icon: <Delete color="secondary" />,
              fn: onDelete,
            },
          ]}
        ></CardTable>
      </Grid>
      <NewBookingOption
        open={open}
        setOpen={setOpen}
        booking={booking}
        onSaved={onSaved}
      />
    </Grid>
  );
}

export default BookingOptions;
