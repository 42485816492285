import Uploader from "@components/media/Uploader";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  makeStyles,
  Paper,
  TextField,
  Typography,
  Checkbox,
} from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";
import HomeIcon from "@material-ui/icons/Home";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useUpdateHomepage } from "@store/actions/homepageActions";
import { useCreateMedia, useGetAllMedia } from "@store/actions/mediaActions";
import { generateUploadedPath } from "utils/helpers";
import { MediaSelector } from "components-library";
import Loading from "@components/loading/Loading";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  preview: {
    paddingTop: "56.25%", // 16:9
    background: theme.palette.action.hover,
    position: "relative",
    overflow: "hidden",
    "& img": {
      position: "absolute",
      top: 0,
      width: "100%",
    },
  },
  mediaSelection: {
    height: "585px",
    overflow: "auto",
    marginBottom: theme.spacing(2),
  },
}));

function HomeForm({
  homepage,
  setHomepage,
  backgroundImage,
  setBackgroundImage,
  saveHomepage,
  saving,
}) {
  const { preview, mediaSelection } = useStyles();
  const [addMedia] = useCreateMedia();
  const { loading: mediaLoading, data: mediaData } = useGetAllMedia();
  const [selected, setSelected] = useState([]);
  const [search, setSearch] = useState("");
  const [media, setMedia] = useState([]);

  useEffect(() => {
    if (mediaData?.medias) {
      setMedia(mediaData?.medias);
    }
  }, [mediaData]);

  useEffect(() => {
    if (search && search.length > 1) {
      setMedia(
        mediaData?.medias.filter((m) =>
          m.name.toLowerCase().includes(search.toLowerCase())
        )
      );
      return;
    }
    setMedia(mediaData?.medias);
  }, [search]);

  useEffect(() => {
    setSelected(homepage.featuredImage ? [homepage.featuredImage] : []);
  }, [homepage]);

  const onFinish = async (result, file) => {
    const source = generateUploadedPath(result.signedUrl.split("?")[0]);

    // save a media
    const { data: res } = await addMedia({
      variables: {
        input: {
          name: file.name,
          source,
        },
      },
    });

    mediaSelected(res.createMedia);
  };

  const onChange = (e) => {
    setHomepage({
      ...homepage,
      [e.target.name]:
        e.target.type === "checkbox" ? e.target.checked : e.target.value,
    });
  };

  const mediaSelected = (media) => {
    setSelected([media]);
    setHomepage({
      ...homepage,
      featuredImage: media,
    });

    setBackgroundImage(media.mediaFiles[0].path);
  };

  const {
    title,
    subTitle,
    seoTitle,
    seoDescription,
    videoLink,
    showContact,
  } = homepage;
  return (
    <Grid container spacing={3}>
      <Grid item xs={6}>
        <Paper>
          <Box p={3}>
            <form noValidate autoComplete="off" onSubmit={saveHomepage}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Typography>Page details</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    onChange={onChange}
                    name="title"
                    value={title}
                    fullWidth
                    size="small"
                    label="Title"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={onChange}
                    name="subTitle"
                    value={subTitle}
                    fullWidth
                    size="small"
                    label="Sub title"
                    variant="outlined"
                    helperText="Enclose a word or sentance with [ ] to highlight those words, e.g [book now]"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={6}>
                      <Paper className={preview}>
                        <img src={backgroundImage} />
                      </Paper>
                    </Grid>
                    <Grid item xs={6}>
                      <Button
                        fullWidth
                        color="primary"
                        variant="contained"
                        component="label"
                        startIcon={<CloudUpload />}
                      >
                        Upload Home Image
                        <Uploader
                          path="homepage"
                          onFinish={onFinish}
                          hidden={true}
                          accept="image/*"
                        />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2} alignItems="flex-end">
                    <Grid item xs={12}>
                      <Typography>
                        Optional, you can add homepage video link, note this
                        will only be shown on desktop, you still need to add a
                        homepage image for mobile
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        onChange={onChange}
                        name="videoLink"
                        value={videoLink}
                        fullWidth
                        size="small"
                        label="Video Link"
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                <Grid item xs={12}>
                  <Typography>SEO</Typography>
                </Grid>
                <Grid item xs={8}>
                  <TextField
                    onChange={onChange}
                    name="seoTitle"
                    value={seoTitle}
                    fullWidth
                    size="small"
                    label="SEO Title"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    onChange={onChange}
                    name="seoDescription"
                    value={seoDescription}
                    fullWidth
                    size="small"
                    label="SEO Description"
                    variant="outlined"
                  />
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    checked={showContact || false}
                    control={
                      <Checkbox
                        color="primary"
                        name="showContact"
                        onChange={onChange}
                      />
                    }
                    label="Show Contact Form"
                    labelPlacement="end"
                  />
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>

                <Grid item xs={12} container justify="flex-end">
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    endIcon={
                      saving ? (
                        <CircularProgress size={20} color="inherit" />
                      ) : (
                        <HomeIcon />
                      )
                    }
                  >
                    Save Homepage
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={6}>
        <Paper>
          <Box p={1}>
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={7}>
                <Typography variant="subtitle1">
                  Pick a image to use on homepage
                </Typography>
              </Grid>
              <Grid item xs={5}>
                <TextField
                  label="Search"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} className={mediaSelection}>
                {mediaLoading ? (
                  <Loading />
                ) : (
                  <MediaSelector
                    medias={media}
                    selected={selected}
                    onClick={[mediaSelected]}
                  />
                )}
              </Grid>
            </Grid>
          </Box>
        </Paper>
      </Grid>
    </Grid>
  );
}

export default HomeForm;
