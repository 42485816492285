import React, { createContext, useContext, useMemo, useState } from "react";
import { getAuthToken, removeAuthToken } from "utils/helpers";

export const AuthContext = createContext(null);
export const useAuthContext = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [authed, setAuthed] = useState(null);

  const doLogout = () => {
    setAuthed(null)
    removeAuthToken()
  }

  useMemo(() => {
    const authed = getAuthToken();
    setAuthed(authed);
  }, [])

  const state = useMemo(
    () => ({
      authed,
      setAuthed,
      doLogout
    }),
    [authed]
  );

  return <AuthContext.Provider value={state}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
