import { gql } from "@apollo/client";

export const GET_HOMEPAGE = gql`
  query GetHomepage {
    homepage {
      id
      title
      subTitle
      seoTitle
      seoDescription
      videoLink
      showContact
      sections {
        title
        type
        order
        link
        linkText
        colWidth
        createdAt
        boatSection {
          id
          boatsOrder
          boats {
            id
            name
          }
        }
        thumbLinkSection {
          id
          landingPagesOrder
          landingPages {
            id
            name
          }
        }
        codeBlock {
          id
          name
          value
        }
      }
      featuredImage {
        id
        name
        mediaFiles {
          name
          path
          type
        }
      }
      locationImage {
        id
        name
        mediaFiles {
          name
          path
          type
        }
      }
    }
  }
`;

export const UPDATE_HOMEPAGE = gql`
  mutation UpdateHomepage($input: HomepageInput!) {
    updateHomepage(input: $input) {
      id
      title
      subTitle
      seoTitle
      seoDescription
      videoLink
      showContact
      sections {
        title
        type
        order
        link
        linkText
        colWidth
        boatSection {
          id
          boatsOrder
          boats {
            id
            name
          }
        }
        thumbLinkSection {
          id
          landingPagesOrder
          landingPages {
            id
            name
          }
        }
        codeBlock {
          id
          name
          value
        }
      }
      featuredImage {
        id
        name
        mediaFiles {
          name
          path
          type
        }
      }
      locationImage {
        id
        name
        mediaFiles {
          name
          path
          type
        }
      }
    }
  }
`;
