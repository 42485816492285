import LandingPageWizard from "@components/landing/LandingPageWizard";
import Loading from "@components/loading/Loading";
import { Typography } from "@material-ui/core";
import { useGetLandingPage } from "@store/actions/landingPageActions";
import { useLandingPageContext } from "providers/LandingPageProvider";
import React, { useEffect } from "react";
import { Route, Switch, useParams, useRouteMatch } from "react-router-dom";

function LandingPagePage() {
  const { landingPageId } = useParams();
  const { loading, data } = useGetLandingPage(landingPageId);
  const { path } = useRouteMatch();
  const { landingPage, setLandingPage } = useLandingPageContext();

  useEffect(() => {
    if (data) {
      setLandingPage({
        ...data.landingPage,
      });
    }
  }, [data, setLandingPage]);

  if (loading) return <Loading />;

  return (
    <>
      <Switch>
        <Route path="/landing-pages/:landingPageId/edit">
          <LandingPageWizard />
        </Route>
        <Route path={path}>
          <Typography variant="h1">name : {landingPage.name}</Typography>
        </Route>
      </Switch>
    </>
  );
}

export default LandingPagePage;
