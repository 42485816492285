import { capitalize } from "@material-ui/core";

export const splitCamel = (str) => {
  return str.replace(/([a-z])([A-Z])/g, "$1 $2");
};

export const capatalise = (str) => str.replace(/^\w/, (c) => c.toUpperCase());

export const formatEnumVal = (x) => {
  return capitalize(x.toLowerCase().split("_").join(" "));
};

export const camelToText = (s) => {
  const t = s.replace(/([A-Z])/g, " $1");
  return t.charAt(0).toUpperCase() + t.slice(1);
};

export const truncate = (input, len) =>
  (input || "").length > len ? `${input.substring(0, len)}...` : input;
