import Uploader from "@components/media/Uploader";
import { Button, Grid, TextField } from "@material-ui/core";
import CloudUpload from "@material-ui/icons/CloudUpload";
import { useBoatContext } from "@providers/BoatProvider";
import { useCreateMedia } from "@store/actions/mediaActions";
import React from "react";
import { generateUploadedPath } from "utils/helpers";
import AttachementRow from "@components/attachment/AttachementRow";

function BoatAttachments({ updateBoat }) {
  const { boat, setBoat } = useBoatContext();
  const [addMedia] = useCreateMedia();

  const handleChange = (e) => {
    setBoat({
      ...boat,
      [e.target.name]: e.target.value,
    });
  };

  const onFinish = async (result, file) => {
    const source = generateUploadedPath(result.signedUrl.split("?")[0]);
    // save a media
    const { data: res } = await addMedia({
      variables: {
        input: {
          name: file.name,
          type: "PDF",
          source,
        },
      },
    });
    const updatedBoat = {
      ...boat,
      attachments: [...(boat.attachments || []), res.createMedia],
    };
    setBoat(updatedBoat);
    await updateBoat(updatedBoat);
  };

  const removeAttachment = (att) => {
    const idx = boat.attachments.findIndex((a) => a.id === att.id);
    let attachments = [...(boat.attachments || [])];
    attachments.splice(idx, 1);
    const updatedBoat = {
      ...boat,
      attachments,
    };
    setBoat(updatedBoat);
    updateBoat(updatedBoat);
  };

  return (
    <form>
      <Grid container spacing={3}>
        <Grid item xs={7}>
          <TextField
            label="attachment info"
            name="attachmentsInfo"
            variant="outlined"
            fullWidth
            multiline
            minRows={2}
            maxRows={5}
            value={boat.attachmentsInfo || ""}
            onChange={handleChange}
          />
        </Grid>
        <Grid item xs={7}>
          <Button
            color="primary"
            variant="contained"
            component="label"
            startIcon={<CloudUpload />}
          >
            Upload Boat Attachment
            <Uploader
              onFinish={onFinish}
              hidden={true}
              path={boat.id}
              accept="application/pdf"
            />
          </Button>
        </Grid>
        {(boat.attachments || []).map((a) => (
          <Grid key={a.id} item xs={7}>
            <AttachementRow
              attachment={a}
              removeAttachment={removeAttachment}
            />
          </Grid>
        ))}
      </Grid>
    </form>
  );
}

export default BoatAttachments;
