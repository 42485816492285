import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  makeStyles,
  Typography,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  accordianBody: {
    background: theme.palette.action.hover,
    boxShadow: `inset ${theme.shadows[1]}`,
  },
}));

function HomeAccordian({ children, actions, title, onChange }) {
  const [open, setOpen] = useState(false);
  const { accordianBody } = useStyles();

  return (
    <Accordion onChange={onChange}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography>{title}</Typography>
      </AccordionSummary>

      <AccordionDetails className={accordianBody}>{children}</AccordionDetails>
      {actions && <AccordionActions>{actions}</AccordionActions>}
    </Accordion>
  );
}

export default HomeAccordian;
