export const isHtmlEmpty = (htmlString) => {
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlString;

  const allEmpty = Array.from(tempDiv.children).every((child) => {
    const element = child;
    return !element.innerText.trim();
  });

  return allEmpty;
};
