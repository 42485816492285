import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import {
  CATERING_ENUM,
  CHECK_AVAILABILITY,
  CREATE_BOOKING,
  CREATE_BOOKING_ITEM,
  CREATE_BOOKING_OPTION,
  DELETE_BOOKING,
  DELETE_BOOKING_ITEM,
  DELETE_BOOKING_OPTION,
  EVENT_ENUM,
  GET_ALL_BOOKINGS,
  GET_BOOKING,
  GET_BOOKING_BY_REF,
  GET_BOOKING_ITEM,
  GET_BOOKING_OPTION,
  MERGE_BOOKINGS,
  QUOTE_ITEM_TYPE_ENUM,
  SEND_BOOKING_OPTION,
  SEND_CHANGED_ITEM,
  SEND_CHECK_AVAILABILITY,
  UPDATE_BOOKING,
  UPDATE_BOOKING_ITEM,
  UPDATE_BOOKING_OPTION,
} from "@operations/bookingOperations";

/* -------------------------------------------------------------------------- */
/*                                    BOOKING                                 */
/* -------------------------------------------------------------------------- */

export const useGetBooking = (id) =>
  useQuery(GET_BOOKING, { variables: { id } });

export const useGetBookingByRef = (ref) =>
  useQuery(GET_BOOKING_BY_REF, { variables: { ref } });

export const useLazyGetBooking = () => useLazyQuery(GET_BOOKING);

export const useGetAllBookings = (pollInterval) =>
  useQuery(GET_ALL_BOOKINGS, { pollInterval });

export const useCreateBooking = () =>
  useMutation(CREATE_BOOKING, {
    update: (cache, { data: { createBooking } }) => {
      const data = cache.readQuery({
        query: GET_ALL_BOOKINGS,
      });
      if (!data) {
        return;
      }
      cache.writeQuery({
        query: GET_ALL_BOOKINGS,
        data: {
          bookings: [...(data.bookings || []), createBooking],
        },
      });
    },
  });

export const useUpdateBooking = () => useMutation(UPDATE_BOOKING);

export const useDeleteBooking = () =>
  useMutation(DELETE_BOOKING, {
    update: (cache, { data: { deleteBooking } }) => {
      const data = cache.readQuery({
        query: GET_ALL_BOOKINGS,
      });
      const bookings = data ? data.bookings : [];
      cache.writeQuery({
        query: GET_ALL_BOOKINGS,
        data: {
          bookings: bookings.filter((w) => w.id !== deleteBooking.id),
        },
      });
    },
  });

export const useMergeBookings = () =>
  useMutation(MERGE_BOOKINGS, {
    refetchQueries: [{ query: GET_ALL_BOOKINGS }],
  });

export const useCateringEnum = () => useQuery(CATERING_ENUM);
export const useEventEnum = () => useQuery(EVENT_ENUM);
export const useQuoteItemType = () => useQuery(QUOTE_ITEM_TYPE_ENUM);

export const useSendAvailabilityCheck = () =>
  useMutation(SEND_CHECK_AVAILABILITY);

export const useCheckAvailability = () => useMutation(CHECK_AVAILABILITY);

export const useSendChangeToItem = () => useMutation(SEND_CHANGED_ITEM);

/* -------------------------------------------------------------------------- */
/*                                booking item                                */
/* -------------------------------------------------------------------------- */

export const useGetBookingItem = (id) =>
  useQuery(GET_BOOKING_ITEM, { variables: { id } });

export const useCreateBookingItem = (booking) =>
  useMutation(CREATE_BOOKING_ITEM, {
    update: (cache, { data: { createBookingItem } }) => {
      if (!booking) {
        return;
      }
      cache.writeQuery({
        query: GET_BOOKING,
        variables: { id: booking.id },
        data: {
          booking: {
            ...booking,
            items: [...booking.items, createBookingItem],
          },
        },
      });
    },
  });

export const useUpdateBookingItem = (booking) =>
  useMutation(UPDATE_BOOKING_ITEM, {
    update: (cache, { data: { updateBookingItem } }) => {
      if (!booking) {
        return;
      }
      // amend the item
      const newBookingItems = booking.items.map((bi) => {
        return bi.id === updateBookingItem.id ? updateBookingItem : bi;
      });

      cache.writeQuery({
        query: GET_BOOKING,
        variables: { id: booking.id },
        data: {
          booking: {
            ...booking,
            items: [...newBookingItems],
          },
        },
      });
    },
  });

export const useDeleteBookingItem = (booking) =>
  useMutation(DELETE_BOOKING_ITEM, {
    update: (cache, { data: { deleteBookingItem } }) => {
      if (!booking) {
        return;
      }
      cache.writeQuery({
        query: GET_BOOKING,
        variables: { id: booking.id },
        data: {
          booking: {
            ...booking,
            items: [
              ...booking.items.filter((bi) => bi.id !== deleteBookingItem.id),
            ],
          },
        },
      });
    },
  });

/* -------------------------------------------------------------------------- */
/*                               booking options                              */
/* -------------------------------------------------------------------------- */
export const useCreateBookingOption = (booking) =>
  useMutation(CREATE_BOOKING_OPTION, {
    update: (cache, { data: { createBookingOption } }) => {
      if (!booking) {
        return;
      }
      cache.writeQuery({
        query: GET_BOOKING,
        variables: { id: booking.id },
        data: {
          booking: {
            ...booking,
            options: [...booking.options, createBookingOption],
          },
        },
      });
    },
  });

export const useUpdateBookingOption = () => useMutation(UPDATE_BOOKING_OPTION);

export const useGetBookingOption = (id) =>
  useQuery(GET_BOOKING_OPTION, { variables: { id } });

export const useDeleteBookingOption = (booking) =>
  useMutation(DELETE_BOOKING_OPTION, {
    update: (cache, { data: { deleteBookingOption } }) => {
      if (!booking) {
        return;
      }
      cache.writeQuery({
        query: GET_BOOKING,
        variables: { id: booking.id },
        data: {
          booking: {
            ...booking,
            options: [
              ...booking.options.filter(
                (bo) => bo.id !== deleteBookingOption.id
              ),
            ],
          },
        },
      });
    },
  });

export const useSendBookingOption = (bookingOptionID) =>
  useMutation(SEND_BOOKING_OPTION, {
    update: (cache, { data: { sendBookingOption } }) => {
      if (!bookingOptionID) {
        return;
      }
      cache.writeQuery({
        query: GET_BOOKING_OPTION,
        variables: { id: bookingOptionID },
        data: {
          bookingOption: sendBookingOption,
        },
      });
    },
  });
