import {
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  makeStyles,
  Button,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import classNames from "classnames";

const useStyles = makeStyles(({ palette }) => ({
  defaultRow: {
    background: palette.action.selected,
  },
}));

const BoatPriceTable = ({ boat, setDefault, onEdit, onDelete, readOnly }) => {
  const { defaultRow } = useStyles();

  const isDefault = (bp) => {
    return boat.boatPrice && bp.id === boat.boatPrice.id;
  };

  const deleteRate = ({ id }) => {
    onDelete({
      variables: {
        id,
      },
    });
  };

  return (
    <TableContainer component={Paper} variant="outlined">
      <Table size="small">
        <TableHead>
          <TableRow>
            {!readOnly && <TableCell>Default</TableCell>}

            <TableCell>Name</TableCell>
            <TableCell>Base Rate</TableCell>
            {!readOnly && <TableCell></TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {(boat.boatPrices || []).map((bp) => (
            <TableRow
              key={bp.id}
              className={classNames({
                [defaultRow]: isDefault(bp),
              })}
            >
              {!readOnly && (
                <TableCell>
                  {isDefault(bp) ? (
                    <CheckIcon fontSize="small" />
                  ) : (
                    <Button
                      color="secondary"
                      size="small"
                      onClick={() => setDefault(bp)}
                    >
                      Set default
                    </Button>
                  )}
                </TableCell>
              )}
              <TableCell>{bp.name}</TableCell>
              <TableCell>${bp.baseHourlyRate}</TableCell>
              {!readOnly && (
                <TableCell>
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => onEdit(bp)}
                  >
                    <EditIcon />
                  </IconButton>

                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => deleteRate(bp)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default BoatPriceTable;
