import {
  Button,
  Divider,
  Grid,
  InputAdornment,
  makeStyles,
  TextField,
  Typography,
} from "@material-ui/core";
import { BoatCard, useBoatLowestPrice } from "components-library";
import { dummyLink } from "pages/Home";
import { useDrop } from "react-dnd";
import AccessTimeIcon from "@material-ui/icons/AccessTime";
import PriceInfoCard from "@components/boat/PriceInfoCard";
import { useEffect, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: "300px",
    border: `2px dashed ${theme.palette.divider}`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(2),
    margin: 0,
  },
  info: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
}));

const BookingOptionItemPreview = ({ item, setItem, onRemove, booking }) => {
  const { getRate } = useBoatLowestPrice();
  const [recomended, setRecomended] = useState(0);
  const handleChange = (e) => {
    setItem({
      ...item,
      [e.target.name]: e.target.value,
    });
  };

  useEffect(() => {
    if (booking.cruiseDate && item.hours && item.bookingItem?.boat) {
      const baseCost = getRate(
        booking.cruiseDate,
        item.bookingItem.boat,
        item.hours
      );
      setRecomended(baseCost);
    }
  }, [item]);

  const { info } = useStyles();
  return (
    <Grid container spacing={5}>
      <Grid item xs={6}>
        {item?.bookingItem?.boat && (
          <BoatCard
            useNoneGatsbyImage
            boat={item?.bookingItem?.boat}
            LinkComponent={dummyLink}
            item={item}
          />
        )}
      </Grid>
      <Grid item xs={6} className={info}>
        <div>
          <Typography variant="h6" gutterBottom>
            {item.bookingItem?.boat?.name}
          </Typography>
          <TextField
            name="price"
            onChange={handleChange}
            value={item.price || ""}
            fullWidth
            label="Total price"
            variant="outlined"
            helperText={`Recomended $${recomended}`}
            margin="normal"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">$</InputAdornment>
              ),
            }}
          />

          <TextField
            name="hours"
            onChange={handleChange}
            value={item.hours || ""}
            fullWidth
            label="Number of hours"
            margin="normal"
            variant="outlined"
            type="number"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AccessTimeIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Button variant="outlined" color="primary" onClick={onRemove}>
          Remove
        </Button>
      </Grid>
    </Grid>
  );
};

function BookingOptionDrop({ option, setOption, booking }) {
  const { getRate } = useBoatLowestPrice();
  const { root } = useStyles();
  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "bookingItem",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: ({ item }) => {
      onDrop(item);
    },
  });

  const onDrop = (bookingItem) => {
    // check item is not first dummy item
    const baseCost = getRate(
      booking.cruiseDate,
      bookingItem?.boat,
      booking.hours
    );
    const items = [
      ...(option?.items || []),
      {
        hours: booking.hours || null,
        price: baseCost > 0 ? baseCost : null,
        bookingItem,
      },
    ];

    setOption({
      ...option,
      items,
    });
  };

  const setItem = (i) => (v) => {
    let items = [...option.items];
    items[i] = v;
    setOption({
      ...option,
      items,
    });
  };

  const removeItem = (idx) => () => {
    const items = [...option.items.filter((_, i) => i !== idx)];
    setOption({
      ...option,
      items,
    });
  };

  const isActive = canDrop && isOver;
  return (
    <Grid
      container
      ref={drop}
      spacing={3}
      className={root}
      style={{ opacity: isActive ? 0.5 : 1 }}
    >
      {option?.items?.length > 0 ? (
        option?.items?.map((item, i) => (
          <Grid item xs={12} key={i} container spacing={1}>
            <Grid item xs={6}>
              <BookingOptionItemPreview
                booking={booking}
                item={item}
                setItem={setItem(i)}
                onRemove={removeItem(i)}
              />
            </Grid>
            <Grid item xs={1} container justify="center">
              <Divider orientation="vertical" variant="middle" />
            </Grid>
            <Grid item xs={5}>
              {item?.bookingItem?.boat && (
                <PriceInfoCard boat={item?.bookingItem?.boat} />
              )}
            </Grid>
            {i + 1 < option?.items?.length && (
              <Grid item xs={12}>
                <br />
                <br />
                <Divider />
                <br />
              </Grid>
            )}
          </Grid>
        ))
      ) : (
        <>drop in here</>
      )}
    </Grid>
  );
}

export default BookingOptionDrop;
