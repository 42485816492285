import { makeVar } from "@apollo/client"

const VisibilityFilters = {
  SHOW_ALL: {
    id: "show_all",
    displayName: "All"
  },
  SHOW_COMPLETED: {
    id: "show_completed",
    displayName: "Completed"
  },
  SHOW_ACTIVE: {
    id: "show_active",
    displayName: "Active"
  }
}


export const visibilityFilterVar = makeVar(
  VisibilityFilters.SHOW_ALL
)