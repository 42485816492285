/* -------------------------------------------------------------------------- */
/*                                   quotes                                   */
/* -------------------------------------------------------------------------- */

import { useMutation, useQuery } from "@apollo/client";
import { GET_BOOKING } from "@store/operations/bookingOperations";
import {
  CREATE_BOOKING_ITEM_COST,
  CREATE_QUOTE,
  CREATE_QUOTE_ITEM,
  DELETE_BOOKING_ITEM_COST,
  DELETE_QUOTE,
  DELETE_QUOTE_ITEM,
  GET_ALL_PAYOUTS,
  GET_QUOTE,
  GET_QUOTE_BY_REF,
  GET_QUOTE_USER_STATS,
  MARK_PAYOUT_AS_PAID,
  QUOTE_TYPE_ENUM,
  SEND_QUOTE,
  SEND_QUOTE_HOLD,
  SEND_QUOTE_RELEASE,
  UPDATE_BOOKING_ITEM_COST,
  UPDATE_QUOTE,
  UPDATE_QUOTE_ITEM,
} from "@store/operations/quoteOperations";

export const useCreateQuote = (booking) =>
  useMutation(CREATE_QUOTE, {
    update: (cache, { data: { createQuote } }) => {
      if (!booking) {
        return;
      }
      cache.writeQuery({
        query: GET_BOOKING,
        variables: { id: booking.id },
        data: {
          booking: {
            ...booking,
            quotes: [...booking.quotes, createQuote],
          },
        },
      });
    },
  });

export const useUpdateQuote = () => useMutation(UPDATE_QUOTE);

export const useDeleteQuote = (booking) =>
  useMutation(DELETE_QUOTE, {
    update: (cache, { data: { deleteQuote } }) => {
      if (!booking) {
        return;
      }
      cache.writeQuery({
        query: GET_BOOKING,
        variables: { id: booking.id },
        data: {
          booking: {
            ...booking,
            options: [...booking.quotes.filter((q) => q.id !== deleteQuote.id)],
          },
        },
      });
    },
  });

export const useGetQuote = (id) => useQuery(GET_QUOTE, { variables: { id } });
export const useGetQuoteByRef = (ref) =>
  useQuery(GET_QUOTE_BY_REF, { variables: { ref } });

export const useSendQuote = (quoteID) =>
  useMutation(SEND_QUOTE, {
    update: (cache, { data: { sendQuote } }) => {
      if (!quoteID) {
        return;
      }
      cache.writeQuery({
        query: GET_QUOTE,
        variables: { id: quoteID },
        data: {
          quote: sendQuote,
        },
      });
    },
  });
export const useSendOnHold = () => useMutation(SEND_QUOTE_HOLD);
export const useSendHoldRelease = () => useMutation(SEND_QUOTE_RELEASE);

export const useQuoteTypeEnum = () => useQuery(QUOTE_TYPE_ENUM);

export const useGetQuoteUserStats = (userId) =>
  useQuery(GET_QUOTE_USER_STATS, { variables: { userId } });

/* -------------------------------------------------------------------------- */
/*                                 quote items                                */
/* -------------------------------------------------------------------------- */

export const useCreateQuoteItem = (quote) =>
  useMutation(CREATE_QUOTE_ITEM, {
    update: (cache, { data: { createQuoteItem } }) => {
      if (!quote) {
        return;
      }
      cache.writeQuery({
        query: GET_QUOTE,
        variables: { id: quote.id },
        data: {
          quote: {
            ...quote,
            items: [...quote.items, createQuoteItem],
          },
        },
      });
    },
  });

export const useUpdateQuoteItem = (quote) =>
  useMutation(UPDATE_QUOTE_ITEM, {
    update: (cache, { data: { updateQuoteItem } }) => {
      if (!quote) {
        return;
      }
      // amend the item
      const newQuoteItems = quote.items.map((bi) => {
        return bi.id === updateQuoteItem.id ? updateQuoteItem : bi;
      });

      cache.writeQuery({
        query: GET_QUOTE,
        variables: { id: quote.id },
        data: {
          quote: {
            ...quote,
            items: [...newQuoteItems],
          },
        },
      });
    },
  });

export const useDeleteQuoteItem = (quote) =>
  useMutation(DELETE_QUOTE_ITEM, {
    update: (cache, { data: { deleteQuoteItem } }) => {
      if (!quote) {
        return;
      }
      cache.writeQuery({
        query: GET_QUOTE,
        variables: { id: quote.id },
        data: {
          quote: {
            ...quote,
            items: [...quote.items.filter((i) => i.id !== deleteQuoteItem.id)],
          },
        },
      });
    },
  });

/* -------------------------------------------------------------------------- */
/*                              booking item cost                             */
/* -------------------------------------------------------------------------- */

export const useCreateBookingItemCost = (quote, quoteItem) =>
  useMutation(CREATE_BOOKING_ITEM_COST, {
    update: (cache, { data: { createBookingItemCost } }) => {
      if (!quote || !quoteItem) {
        return;
      }

      cache.writeQuery({
        query: GET_QUOTE,
        variables: { id: quote.id },
        data: {
          quote: {
            ...quote,
            items: quote.items.map((item) => ({
              ...item,
              costs:
                item.id === quoteItem.id
                  ? [...(item.costs || []), createBookingItemCost]
                  : item.costs,
            })),
          },
        },
      });
    },
  });

export const useUpdateBookingItemCost = () =>
  useMutation(UPDATE_BOOKING_ITEM_COST);

export const useDeleteBookingItemCost = (quote, quoteItem) =>
  useMutation(DELETE_BOOKING_ITEM_COST, {
    update: (cache, { data: { deleteBookingItemCost } }) => {
      if (!quote || !quoteItem) {
        return;
      }
      cache.writeQuery({
        query: GET_QUOTE,
        variables: { id: quote.id },
        data: {
          quote: {
            ...quote,
            items: quote.items.map((item) => ({
              ...item,
              costs:
                item.id === quoteItem.id
                  ? item.costs.filter((c) => c.id !== deleteBookingItemCost.id)
                  : item.costs,
            })),
          },
        },
      });
    },
  });

/* -------------------------------------------------------------------------- */
/*                              Payouts                                       */
/* -------------------------------------------------------------------------- */

export const useGetAllPayouts = () => useQuery(GET_ALL_PAYOUTS);

export const useMarkPayoutAsPaid = () => useMutation(MARK_PAYOUT_AS_PAID);
