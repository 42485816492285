import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useCreateBoatType, useUpdateBoatType } from "@actions/boatActions";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import useIsPathValidValidation from "hooks/useIsPathValidValidation";

function BoatTypeModal({ open, setOpen, onSave, boatType, setBoatType }) {
  const [valid, setValid] = useState(false);
  const [addBoatType] = useCreateBoatType();
  const [updateBoatType] = useUpdateBoatType();
  const isPathValid = useIsPathValidValidation();
  const formRef = useRef();

  useEffect(() => {
    if (!open) {
      return;
    }
    let found = !!formRef.current;
    if (found) {
      setValidity();
      return;
    }
    const t = setInterval(() => {
      found = !!formRef.current;
      if (found) {
        setValidity();
        clearInterval(t);
      }
    }, 10);
  }, [formRef, boatType, open]);

  const setValidity = async () => {
    const valid = await formRef.current.isFormValid();
    setValid(valid);
  };

  const close = () => {
    setOpen(false);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    const input = {
      name: boatType.name,
      path: boatType.path,
    };

    const fn = boatType.id
      ? updateBoatType({
          variables: {
            id: boatType.id,
            input,
          },
        })
      : addBoatType({
          variables: {
            input,
          },
        });
    fn.then(({ data }) => {
      setBoatType({});
      onSave(boatType.id ? data.updateBoatType : data.createBoatType);
    });
  };

  return (
    <Dialog
      fullWidth
      maxWidth="xs"
      open={open}
      onClose={close}
      disableBackdropClick={true}
    >
      <ValidatorForm onSubmit={onSubmit} ref={formRef}>
        <DialogTitle>New Boat Type</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the boat boat name and path.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            label="Boat type"
            fullWidth
            value={boatType.name || ""}
            onChange={(e) =>
              setBoatType({
                ...boatType,
                name: e.target.value,
              })
            }
          />
          <TextValidator
            margin="dense"
            label="Path"
            fullWidth
            value={boatType.path || ""}
            validators={[
              isPathValid,
              "minStringLength:3",
              "maxStringLength:255",
            ]}
            errorMessages={[
              "Path is not valid, it must start with `/` and be url friendy",
              "Path must be more than 3 characters long",
              "Path must be less than 255 characters long",
            ]}
            onChange={(e) =>
              setBoatType({
                ...boatType,
                path: e.target.value,
              })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={close} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary" disabled={!valid}>
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default BoatTypeModal;
