import { useDropStyles } from "@components/feature/FeatureSideDropZone";
import {
  Box,
  List,
  ListItem,
  Paper,
  Grid,
  Typography,
  Button,
  IconButton,
  TextField,
  makeStyles,
  Fab,
  ButtonGroup,
} from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useMemo, useRef } from "react";
import { useDrop } from "react-dnd";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import RemoveIcon from "@material-ui/icons/Remove";
import DirectionsBoat from "@material-ui/icons/DirectionsBoat";
import {
  ArrowDropDown,
  ArrowDropUp,
  Code,
  CropOriginal,
} from "@material-ui/icons";
import { truncate } from "utils/strings";

const useBlockDropStyles = makeStyles((theme) => ({
  remove: {
    position: "absolute",
    top: theme.spacing(-1.5),
    right: theme.spacing(-1.5),
  },
}));

const BlockItem = ({ item, setItem, onRemove, moveUp, moveDown }) => {
  const classes = useBlockDropStyles();
  return (
    <ListItem
      component={Paper}
      variant="outlined"
      style={{ display: "block" }}
      disableGutters
    >
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} container spacing={1} alignItems="center">
            <Grid item xs={3}>
              <TextValidator
                autoFocus
                variant="outlined"
                name="title"
                label="Title"
                fullWidth
                value={item.title || ""}
                onChange={(e) =>
                  setItem({
                    ...item,
                    title: e.target.value,
                  })
                }
                size="small"
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="Link"
                size="small"
                variant="outlined"
                value={item.link || ""}
                onChange={(e) =>
                  setItem({
                    ...item,
                    link: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={3}>
              <TextField
                label="Link Text"
                size="small"
                variant="outlined"
                value={item.linkText || ""}
                onChange={(e) =>
                  setItem({
                    ...item,
                    linkText: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={2}>
              <TextField
                label="width"
                size="small"
                variant="outlined"
                type="number"
                value={item.colWidth || ""}
                onChange={(e) =>
                  setItem({
                    ...item,
                    colWidth: e.target.value,
                  })
                }
              />
            </Grid>
            <Grid item xs={1}>
              <ButtonGroup size="small">
                <IconButton onClick={moveUp}>
                  <ArrowDropUp />
                </IconButton>
                <IconButton onClick={moveDown}>
                  <ArrowDropDown />
                </IconButton>
              </ButtonGroup>
            </Grid>
          </Grid>

          <Grid item xs={12} container spacing={1} alignItems="center">
            <Grid item xs={1}>
              {item.type === "boatSection" ? (
                <DirectionsBoat />
              ) : item.type === "codeBlock" ? (
                <Code />
              ) : (
                <CropOriginal />
              )}
            </Grid>
            {item.type === "boatSection" ? (
              item.boatSection.boats.map((b) => (
                <Grid item key={b.id}>
                  <Paper>
                    <Box p={1}>{b.name}</Box>
                  </Paper>
                </Grid>
              ))
            ) : item.type === "codeBlock" ? (
              <Paper>
                <Box p={1}>
                  <strong>{item.codeBlock?.name}</strong> -{" "}
                  <Typography variant="caption">
                    {truncate(item.codeBlock?.value, 30)}
                  </Typography>
                </Box>
              </Paper>
            ) : (
              item.thumbLinkSection.landingPages.map((l) => (
                <Grid item key={l.id}>
                  <Paper>
                    <Box p={1}>{l.name}</Box>
                  </Paper>
                </Grid>
              ))
            )}
          </Grid>
          <Fab
            className={classes.remove}
            variant="round"
            color="secondary"
            size="small"
            onClick={onRemove}
          >
            <RemoveIcon />
          </Fab>
        </Grid>
      </Box>
    </ListItem>
  );
};

function BlockDropzone({ onSubmit, items, setItems, showSave }) {
  const form = useRef();
  const { dropzone, hovered, inner } = useDropStyles();

  const orderedItems = useMemo(() => {
    return (items || [])
      .map((o) => o)
      .sort((a, b) =>
        a.order > b.order
          ? 1
          : a.order < b.order
          ? -1
          : a.createdAt > b.createdAt
      );
  }, [items]);

  useEffect(() => {
    // validate when no button
    if (!form || showSave) {
      return;
    }

    form?.current?.submit();
  }, [items]);

  const [{ canDrop, isOver }, drop] = useDrop({
    accept: "block-item",
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    drop: ({ item }) => {
      onDrop(item);
    },
  });

  const onDrop = (item) => {
    setItems([
      ...items,
      {
        title: "",
        type:
          item.__typename === "BoatSection"
            ? "boatSection"
            : item.__typename === "CodeBlock"
            ? "codeBlock"
            : "thumbLinkSection",
        boatSection: item,
        thumbLinkSection: item,
        codeBlock: item,
      },
    ]);
  };

  const onRemove = (i) => () => {
    const newItems = [...orderedItems];
    newItems.splice(i, 1);
    setItems(newItems);
  };

  const moveUp = (idx) => () => {
    const newOrderd = orderedItems.map((o, i) => {
      const order =
        i < idx - 1 ? i : i === idx - 1 ? i + 1 : i === idx ? i - 1 : i;
      return {
        ...o,
        order: order + 1,
      };
    });
    setItems(newOrderd);
  };

  const moveDown = (idx) => () => {
    const newOrderd = orderedItems.map((o, i) => {
      const order = i < idx || i > idx + 1 ? i : i === idx ? i + 1 : i - 1;
      return {
        ...o,
        order: order + 1,
      };
    });
    setItems(newOrderd);
  };

  const isActive = canDrop && isOver;
  return (
    <ValidatorForm
      ref={form}
      onSubmit={(e) => {
        e.preventDefault();
        onSubmit(items);
      }}
    >
      <Box
        p={2}
        className={classNames(dropzone, {
          [hovered]: isActive,
        })}
      >
        <List className={inner} ref={drop} dense={true}>
          {orderedItems.map((item, i) => (
            <div key={i}>
              <BlockItem
                item={item}
                setItem={(v) =>
                  setItems(
                    orderedItems.map((item, idx) => {
                      if (idx === i) {
                        return {
                          ...v,
                        };
                      }
                      return item;
                    })
                  )
                }
                onRemove={onRemove(i)}
                moveUp={moveUp(i)}
                moveDown={moveDown(i)}
              >
                {item.name}
              </BlockItem>
              <br />
            </div>
          ))}
        </List>
      </Box>
      {showSave && (
        <>
          <br />
          <Button variant="contained" color="primary" type="submit">
            Save
          </Button>
        </>
      )}
    </ValidatorForm>
  );
}
BlockDropzone.defaultProps = {
  showSave: true,
};
export default BlockDropzone;
