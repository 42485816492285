import { Button, Grid, TextField, Typography } from "@material-ui/core";
import { usePackagePageContext } from "providers/PackagePageProvider";
import CloudUpload from "@material-ui/icons/CloudUpload";
import React, { useEffect, useRef, useState } from "react";
import Uploader from "@components/media/Uploader";
import { useCreateMedia, useGetAllMedia } from "@store/actions/mediaActions";
import Image from "material-ui-image";
import { generateUploadedPath } from "utils/helpers";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import useIsPathValidValidation from "hooks/useIsPathValidValidation";
import MediaSelectModal from "@components/media/MediaSelectModal";

function PackagePageForm({ handleUpdate, setValid }) {
  const { packagePage, setPackagePage } = usePackagePageContext();
  const [addMedia] = useCreateMedia();
  const pathRef = useRef();
  const isPathValid = useIsPathValidValidation();
  const [open, setOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState([]);
  const { data: mediaData } = useGetAllMedia();

  useEffect(() => {
    setValid(pathRef.current.isValid());
    setSelectedMedia([packagePage?.media]);
    return () => {
      setValid(true);
    };
  }, []);

  const handleChange = (e) => {
    setPackagePage({
      ...packagePage,
      [e.target.name]:
        e.target.type === "checkbox"
          ? !packagePage[e.target.name]
          : e.target.value,
    });
    if (e.target.name === "path") {
      pathRef.current.validate(e.target.value);
      setTimeout(() => {
        setValid(e.target.value && pathRef.current.isValid());
      }, 0);
    }
  };

  const onSelect = (sel) => {
    const media = sel.length ? sel[sel.length - 1] : {};
    setSelectedMedia([media]);
    setOpen(false);

    // add to package page
    const updatedPackage = {
      ...packagePage,
      media: media,
    };
    setPackagePage(updatedPackage);
    handleUpdate(updatedPackage);
  };

  const onFinish = async (result, file) => {
    const source = generateUploadedPath(result.signedUrl.split("?")[0]);
    // save a media
    const { data: res } = await addMedia({
      variables: {
        input: {
          name: file.name,
          source,
        },
      },
    });
    onSelect([res.createMedia]);
  };

  return (
    <ValidatorForm noValidate autoComplete="off" onSubmit={() => {}}>
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={10}>
              <TextField
                name="name"
                onChange={handleChange}
                value={packagePage.name || ""}
                fullWidth
                size="small"
                label="Name"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="title"
                onChange={handleChange}
                value={packagePage.title || ""}
                fullWidth
                size="medium"
                label="Title"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="oneLiner"
                onChange={handleChange}
                value={packagePage.oneLiner || ""}
                fullWidth
                size="small"
                label="One Liner"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                name="description"
                onChange={handleChange}
                value={packagePage.description || ""}
                fullWidth
                size="small"
                multiline
                minRows={4}
                label="Hero Description"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <TextValidator
                name="path"
                onChange={handleChange}
                value={packagePage.path || ""}
                fullWidth
                size="small"
                label="Path"
                variant="outlined"
                ref={pathRef}
                validators={[
                  isPathValid,
                  "minStringLength:3",
                  "maxStringLength:255",
                ]}
                errorMessages={[
                  "Path is not valid, it must start with `/` and be url friendy",
                  "Path must be more than 3 characters long",
                  "Path must be less than 255 characters long",
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="body1">
                Select a featured image to show in the top section of the page
              </Typography>
            </Grid>

            <Grid item xs={8}>
              <Button
                color="primary"
                variant="contained"
                component="label"
                startIcon={<CloudUpload />}
              >
                {packagePage.media ? "Replace" : "Add"} Featured Image
                <Uploader
                  onFinish={onFinish}
                  hidden={true}
                  path={packagePage.id}
                  accept="image/*"
                />
              </Button>
            </Grid>
            <Grid item xs={4}>
              <Button
                color="primary"
                variant="contained"
                component="label"
                onClick={() => setOpen(true)}
              >
                Select media
              </Button>
            </Grid>
            {packagePage.media && packagePage.media.mediaFiles && (
              <Grid item xs={12}>
                <Image
                  aspectRatio={1.9}
                  style={{ overflow: "hidden" }}
                  imageStyle={{
                    width: "auto",
                    left: "50%",
                    transform: "translateX(-50%)",
                    overflow: "hidden",
                  }}
                  src={packagePage.media.mediaFiles[0].path}
                />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
      <MediaSelectModal
        open={open}
        setOpen={setOpen}
        media={mediaData?.medias || []}
        selected={selectedMedia}
        setSelected={onSelect}
        min={1}
        max={2} // 2 to allow swapping
      />
    </ValidatorForm>
  );
}

export default PackagePageForm;
