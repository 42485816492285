import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  makeStyles,
  MenuItem,
  TextField,
  Typography,
  FormControlLabel,
  Switch,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import AddIcon from "@material-ui/icons/Add";
import RemoveIcon from "@material-ui/icons/Remove";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useMemo } from "react";
import { useState } from "react";
import { useUpdateEffect } from "react-use";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      marginBottom: theme.spacing(2),
    },
  },
  btn: {
    marginTop: theme.spacing(1),
  },
}));

function BoatPriceItem({ item, setItem, addItem, removeItem }) {
  const { root, btn } = useStyles();
  const [open, setOpen] = useState(true);
  const days = [
    "monday",
    "tuesday",
    "wednesday",
    "thursday",
    "friday",
    "saturday",
    "sunday",
  ];

  const units = ["hours", "days"];

  const handleChange = (e) => {
    const name = e.target.name;
    const type = e.target.type;
    const value = type === "checkbox" ? e.target.checked : e.target.value;

    setItem({
      ...item,
      days: name === "unit" && value === "days" ? [] : item.days,
      [name]: value,
    });
  };

  const formClicked = (e) => {
    e.stopPropagation();
  };

  const fillEveryDay = () => {
    setItem({
      ...item,
      days,
    });
  };

  useUpdateEffect(() => {
    if (item.fixed) {
      setItem({
        ...item,
        to: undefined,
      });
    }
  }, [item.fixed]);

  const isDays = useMemo(() => item.unit === "days", [item]);

  return (
    <Accordion expanded={!isDays && open} onChange={() => setOpen(!open)}>
      <AccordionSummary expandIcon={!isDays ? <ExpandMoreIcon /> : null}>
        <div className={root} onClick={formClicked}>
          <Grid container alignItems="center">
            <Grid item xs={12} container spacing={1}>
              <Grid item xs={3}>
                <TextField
                  name="unit"
                  label="Unit"
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin="normal"
                  value={item.unit || ""}
                  onChange={handleChange}
                  select
                >
                  {units.map((u) => (
                    <MenuItem key={u} value={u}>
                      {u}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  name="from"
                  label={item.fixed ? item.unit : "From"}
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin="normal"
                  value={item.from || ""}
                  onChange={handleChange}
                  type="number"
                />
              </Grid>
              {!item.fixed && (
                <Grid item xs={2}>
                  <TextField
                    name="to"
                    label="To"
                    fullWidth
                    variant="outlined"
                    size="small"
                    margin="normal"
                    value={item.to || ""}
                    onChange={handleChange}
                    type="number"
                  />
                </Grid>
              )}

              <Grid item xs={4}>
                <TextField
                  name="rate"
                  label="Rate"
                  fullWidth
                  variant="outlined"
                  size="small"
                  margin="normal"
                  type="number"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                  }}
                  value={item.rate || ""}
                  onChange={handleChange}
                />
              </Grid>
            </Grid>
            <Grid item xs={10}>
              <FormControlLabel
                control={
                  <Switch
                    checked={item.fixed}
                    onChange={handleChange}
                    color="primary"
                  />
                }
                label="Rate is Fixed?"
                name="fixed"
              />
            </Grid>
            <Grid item xs={2} container justify="flex-end">
              <IconButton
                variant="contained"
                color="default"
                size="small"
                onClick={removeItem}
              >
                <RemoveIcon />
              </IconButton>
              <IconButton
                variant="contained"
                color="primary"
                size="small"
                onClick={addItem}
              >
                <AddIcon />
              </IconButton>
            </Grid>
          </Grid>
        </div>
      </AccordionSummary>
      {!isDays && (
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography>
                You can select which days of the week this hourly rate applies
                for
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Autocomplete
                options={days || []}
                getOptionLabel={(option) => option.slice(0, 3)}
                onChange={(_, v) => {
                  setItem({
                    ...item,
                    days: v,
                  });
                }}
                value={item.days || []}
                fullWidth
                size="small"
                multiple
                margin="dense"
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    label="Days"
                    variant="outlined"
                    size="small"
                    margin="dense"
                  />
                )}
              />
            </Grid>
            <Grid item xs={4}>
              <Button
                className={btn}
                fullWidth
                variant="outlined"
                color="primary"
                onClick={fillEveryDay}
              >
                Every day
              </Button>
            </Grid>
          </Grid>
        </AccordionDetails>
      )}
    </Accordion>
  );
}

export default BoatPriceItem;
