import { InMemoryCache } from "@apollo/client";
import routesVar from "./routes";
import { visibilityFilterVar } from "./VisibilityFilter";

export const cache = new InMemoryCache({
  // addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        boats: {
          // merge: true,
        },
        routes: {
          read() {
            return routesVar();
          },
        },
        visibilityFilter: {
          read() {
            return visibilityFilterVar();
          },
        },
      },
    },
  },
});
