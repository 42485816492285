import {
  Box,
  Button,
  FormControlLabel,
  Grid,
  IconButton,
  MenuItem,
  Paper,
  Switch,
  TextField,
} from "@material-ui/core";
import { Clear } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import { useUpdateEffect } from "react-use";

function SiteMetaForm({
  onSave,
  loading,
  siteMeta,
  setSiteMeta,
  contentPages,
}) {
  const [siteName, setSiteName] = useState(siteMeta.siteName || "");
  const [noIndex, setNoIndex] = useState(siteMeta.noIndex || false);
  const [googleConsole, setGoogleConsole] = useState(
    siteMeta.googleConsole || ""
  );
  const [termsPageId, setTermsPageId] = useState(
    siteMeta.termsContentPage?.id || undefined
  );

  const onSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setSiteMeta({
        ...siteMeta,
        siteName,
        googleConsole,
        noIndex,
        termsContentPage: {
          id: termsPageId,
        },
      });
    },
    [siteMeta, siteName, googleConsole, noIndex, termsPageId]
  );

  useUpdateEffect(() => {
    onSave();
  }, [siteMeta]);

  if (loading) {
    return <CircularProgress />;
  }

  return (
    <ValidatorForm onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper style={{ width: 400 }}>
            <Box p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    label="Site Name"
                    value={siteName}
                    name="siteName"
                    validators={["required"]}
                    errorMessages={["Site name is required"]}
                    onChange={(e) => setSiteName(e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    label="Google Console"
                    value={googleConsole}
                    name="googleConsole"
                    onChange={(e) => setGoogleConsole(e.target.value)}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    select
                    label="Select a Terms and Conditions Page"
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <IconButton
                          onClick={() => {
                            setTermsPageId(undefined);
                          }}
                          style={{ order: 1, marginRight: 10 }}
                        >
                          <Clear color="disabled" fontSize="small" />
                        </IconButton>
                      ),
                    }}
                    fullWidth
                    variant="outlined"
                    value={termsPageId}
                    onChange={(e) => setTermsPageId(e.target.value)}
                  >
                    {(contentPages || []).map((l) => (
                      <MenuItem key={l.id} value={l.id}>
                        {l.name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Grid>
                <Grid item xs={12}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={noIndex}
                        onChange={() => setNoIndex(!noIndex)}
                        color="primary"
                      />
                    }
                    label="Stop Indexing"
                  />
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="primary" type="submit">
            Save Site Meta
          </Button>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

export default SiteMetaForm;
