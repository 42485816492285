import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
} from "@apollo/client";
import { cache } from "@store/cache";
import AuthProvider from "@providers/AuthProvider";
import { getAuthToken, removeAuthToken, rootDomain } from "utils/helpers";
import { onError } from "@apollo/client/link/error";
import NotificationProvider, {
  useNotificationContext,
} from "@providers/NotificationProvider";

const cleanTypeName = new ApolloLink((operation, forward) => {
  if (operation.variables) {
    const omitTypename = (key, value) =>
      key === "__typename" ? undefined : value;
    operation.variables = JSON.parse(
      JSON.stringify(operation.variables),
      omitTypename
    );
  }
  return forward(operation).map((data) => {
    return data;
  });
});

const addAuthHeader = new ApolloLink((operation, forward) => {
  const auth = getAuthToken();
  if (auth) {
    operation.setContext({
      headers: {
        Authorization: `Bearer ${auth.user}`,
      },
    });
  }
  return forward(operation);
});

export const baseURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8000"
    : `${window.location.protocol}//${rootDomain(window.location.host)}`;

export const APIURL =
  process.env.NODE_ENV === "development"
    ? "http://localhost:8080" //  "https://api.ucruisesydney.com.au/query"
    : `${window.location.protocol}//api.${rootDomain(window.location.host)}`;

const httpLink = new HttpLink({
  uri: `${APIURL}/query`,
});

const Root = () => {
  return (
    <NotificationProvider>
      <Store />
    </NotificationProvider>
  );
};

const Store = () => {
  const { showNotification } = useNotificationContext();
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    let unAuthed = false;
    if (networkError && networkError.statusCode === 401) {
      unAuthed = true;
    } else if (graphQLErrors) {
      showNotification("There was an Internal error, Please try again.");
      for (let i = 0; i < graphQLErrors.length; i++) {
        if (
          ["JWT Auth Token is expired", "Access denied"].includes(
            graphQLErrors[i].message
          )
        ) {
          unAuthed = true;
          break;
        }
      }
    }
    if (unAuthed) {
      removeAuthToken();
      location.reload();
    }
  });
  const client = new ApolloClient({
    link: ApolloLink.from([cleanTypeName, errorLink, addAuthHeader, httpLink]),
    cache,
    connectToDevTools: true,
  });
  return (
    <ApolloProvider client={client}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </ApolloProvider>
  );
};

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<Root />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
