import { useMutation, useQuery } from "@apollo/client";
import {
  GET_ALL_PENDING_RATINGS,
  VALIDATE_RATING,
} from "@store/operations/ratingOperations";
import { produce } from "immer";

export const useGetPendingRatings = () => useQuery(GET_ALL_PENDING_RATINGS);

export const useValidateRating = () =>
  useMutation(VALIDATE_RATING, {
    update: (cache, { data: { validateRating } }) => {
      // update all
      const data = cache.readQuery({
        query: GET_ALL_PENDING_RATINGS,
      });
      if (data) {
        cache.writeQuery({
          query: GET_ALL_PENDING_RATINGS,
          data: {
            pendingRatings: produce(data.pendingRatings, (draft) => {
              const idx = draft.findIndex((d) => d.id === validateRating.id);
              if (idx >= 0) {
                draft.splice(idx, 1);
              }
            }),
          },
        });
      }
    },
  });
