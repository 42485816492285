import CardTable from "@components/cardTable/CardTable";
import Delete from "@material-ui/icons/Delete";
import Edit from "@material-ui/icons/Edit";

function BoatTypesList({ boatTypes, onEdit, onDelete }) {
  return (
    <CardTable
      tableHead={["name"]}
      tableData={boatTypes}
      actions={[
        {
          icon: <Edit color="primary" />,
          fn: onEdit,
        },
        {
          icon: <Delete color="secondary" />,
          fn: onDelete,
        },
      ]}
    />
  );
}

export default BoatTypesList;
