import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Typography,
} from "@material-ui/core";
import React from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";
import Alert from "@material-ui/lab/Alert";

function BoatSEOTemplate({ siteMeta, setSiteMeta, onSave, loading }) {
  if (loading) {
    return <CircularProgress />;
  }

  const handleChange = (e) => {
    setSiteMeta({
      ...siteMeta,
      [e.target.name]: e.target.value,
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onSave();
  };

  return (
    <ValidatorForm onSubmit={onSubmit}>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper>
            <Box p={3}>
              <Grid container spacing={3}>
                <Grid item xs={12}>
                  <Alert severity="info">
                    <Typography>
                      When setting up you can use the follwing variables
                    </Typography>
                    <Typography variant="body2">
                      [boat_name] = the name of the boat
                    </Typography>
                    <Typography variant="body2">
                      [one_liner] = the boats one liner
                    </Typography>
                    <Typography variant="body2">
                      [locations] = comma seperated list of the locations to
                      boat operates in
                    </Typography>
                    <Typography variant="body2">
                      [boat_types] = comma seperated list of the boat types the
                      boat is
                    </Typography>
                  </Alert>
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    label="Title"
                    value={siteMeta.boatSEOTitleTemplate || ""}
                    name="boatSEOTitleTemplate"
                    validators={["required"]}
                    errorMessages={["Title is required"]}
                    onChange={handleChange}
                    variant="outlined"
                    size="small"
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextValidator
                    fullWidth
                    label="Description"
                    value={siteMeta.boatSEODescriptionTemplate || ""}
                    name="boatSEODescriptionTemplate"
                    validators={["required"]}
                    errorMessages={["Description is required"]}
                    onChange={handleChange}
                    variant="outlined"
                    multiline
                    minRows={2}
                    maxRows={4}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button variant="contained" color="primary" type="submit">
                    Save Boat SEO Meta
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    </ValidatorForm>
  );
}

export default BoatSEOTemplate;
