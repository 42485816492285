import moment from "moment";

export const getQuotesItemsTotal = (quotes) =>
  quotes.reduce((a, b) => {
    const t = b.items.reduce((ia, ib) => {
      const c = ib.costs.reduce((ca, cb) => ca + cb.cost, 0);
      return ia + ib.baseCost + c;
    }, 0);
    return a + t;
  }, 0);

export const getQuotesTransactionsTotal = (quotes, from) =>
  quotes.reduce((a, b) => {
    const transTotal = b.transactions
      .filter((t) => (from ? moment(from).isBefore(t.createdAt) : true))
      .reduce((arg, v) => arg + v.total / 100, 0);
    return a + transTotal;
  }, 0);
export const getQuotesTransactionsOwnerTotal = (quotes, from) =>
  quotes.reduce((a, b) => {
    const transTotal = b.transactions
      .filter((t) => (from ? moment(from).isBefore(t.createdAt) : true))
      .reduce((arg, v) => arg + v.ownerCut / 100, 0);
    return a + transTotal;
  }, 0);

export const getStandardQuotes = (quotes) =>
  quotes.filter((q) => q.type === "standard");

export const getNonAdditionalQuotes = (quotes) =>
  quotes.filter((q) => q.type !== "additional");
