import { useDrag } from "react-dnd";
import DragIndicatorIcon from "@material-ui/icons/DragIndicator";
import {
  Avatar,
  Checkbox,
  FormControlLabel,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  makeStyles,
  Paper,
  TextField,
} from "@material-ui/core";
import SaveIcon from "@material-ui/icons/Save";
import DeleteIcon from "@material-ui/icons/Delete";
import { useState } from "react";

const useStyles = makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(1),
    cursor: "move",
    padding: theme.spacing(1, 2),
  },
  icon: {
    width: theme.spacing(4),
    height: theme.spacing(4),
  },
  checkControl: {
    width: "100%",
  },
  check: {
    padding: theme.spacing(0.5, 1),
  },
}));

const FeatureDragCard = ({ feature, onDelete, onUpdate, checkbox }) => {
  const { card, icon, checkControl, check } = useStyles();
  const [localName, setLocalName] = useState(feature.name);
  const [{ isDragging }, drag] = useDrag({
    item: { type: "FetaureDragCard", feature },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0.4 : 1;
  if (checkbox) {
    return (
      <FormControlLabel
        className={checkControl}
        ref={drag}
        control={<Checkbox color="primary" className={check} />}
        label={feature.name}
      />
    );
  }
  return (
    <ListItem
      ref={drag}
      component={Paper}
      variant="outlined"
      style={{ opacity }}
      className={card}
    >
      <ListItemAvatar>
        <Avatar variant="rounded" className={icon}>
          <DragIndicatorIcon />
        </Avatar>
      </ListItemAvatar>
      {typeof onUpdate === "function" ? (
        <div>
          <TextField
            margin="dense"
            label="Feature Name"
            value={localName || ""}
            onChange={(e) => setLocalName(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    size="small"
                    color="secondary"
                    onClick={() => {
                      onUpdate({ ...feature, name: localName });
                    }}
                  >
                    <SaveIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </div>
      ) : (
        <ListItemText
          primary={feature.name}
          primaryTypographyProps={{ variant: "body2" }}
        />
      )}

      {typeof onDelete === "function" ? (
        <ListItemSecondaryAction>
          <IconButton edge="end" onClick={() => onDelete(feature)}>
            <DeleteIcon />
          </IconButton>
        </ListItemSecondaryAction>
      ) : null}
    </ListItem>
  );
};

export default FeatureDragCard;
