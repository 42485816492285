import Editor from "@components/editor/Editor";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  useTheme,
} from "@material-ui/core";
import { useNotificationContext } from "@providers/NotificationProvider";
import { useCreateWaiver, useUpdateWaiver } from "@store/actions/waiverActions";
import React, { useCallback, useRef, useState } from "react";
import { TextValidator, ValidatorForm } from "react-material-ui-form-validator";

function BoatWaiverDialog({ open, setOpen, waiver, boatId }) {
  const formRef = useRef();
  const [name, setName] = useState(waiver?.name || "");
  const [msg, setMsg] = useState(
    waiver?.slateJson ? JSON.parse(waiver.slateJson) : null
  );
  const [html, setHtml] = useState(waiver?.content);
  const [add] = useCreateWaiver();
  const [update] = useUpdateWaiver();
  const theme = useTheme();
  const { showNotification } = useNotificationContext();

  const saveWaiver = useCallback(
    async (e) => {
      e.preventDefault();
      let input = {
        name,
        content: html,
        slateJson: JSON.stringify(msg),
        boatId,
      };

      const fn = waiver?.id ? update : add;
      await fn({
        variables: {
          id: waiver?.id || null,
          input,
        },
      });

      if (waiver?.id) {
        showNotification(`${name} Updated`);
      } else {
        showNotification(`${name} Created`);
      }

      setOpen(false);
    },
    [waiver, name, html, msg, boatId]
  );

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      disableBackdropClick={true}
      PaperProps={{
        style: {
          background: theme.palette.background.default,
        },
      }}
    >
      <ValidatorForm onSubmit={saveWaiver} ref={formRef}>
        <DialogTitle>New Waiver</DialogTitle>
        <DialogContent>
          <TextValidator
            margin="none"
            label="Name"
            variant="outlined"
            fullWidth
            size="small"
            style={{
              maxWidth: 300,
            }}
            validators={["required"]}
            errorMessages={["Name is required"]}
            value={name}
            onChange={(e) => setName(e.target.value)}
          />

          <Box mt={2}>
            <Editor
              basic
              msg={msg}
              setMsg={setMsg}
              html={html}
              setHtml={setHtml}
              minHeight={300}
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button color="primary" type="submit">
            Continue
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default BoatWaiverDialog;
