import {
  Button,
  ButtonGroup,
  Grid,
  Link,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import AddIcon from "@material-ui/icons/Add";
import CardTable from "@components/cardTable/CardTable";
import {
  useCreateStripeAccount,
  useDeleteOwner,
  useGetAllOwners,
  useRemoveStripeAccount,
} from "@store/actions/boatActions";
import Loading from "@components/loading/Loading";
import OwnerModal from "@components/owner/OwnerModal";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { Link as RouterLink } from "react-router-dom";
import { baseURL } from "index";
import { Assignment, Check } from "@material-ui/icons";

const nameColumn = (col, row) => {
  return (
    <>
      <Link
        variant="body1"
        color="primary"
        component={RouterLink}
        to={`/owners/${row.id}`}
      >
        {row.firstName} {row.lastName}
      </Link>
      <Typography variant="body2">{row.email}</Typography>
      <Typography variant="body2">{row.phone}</Typography>
    </>
  );
};

const stripeColumn = (onCreate, onDeleteStripeAccount) => (_, row) => {
  if (row.isManual) {
    return "";
  }
  if (row.stripeAccounts.length) {
    return (
      <ButtonGroup color="primary" size="small">
        <Button style={{ minWidth: 100 }} variant="outlined">
          <a href={`${baseURL}/account-setup/?id=${row.id}`} target="_blank">
            Setup Link
          </a>
        </Button>

        <Button onClick={() => onDeleteStripeAccount(row)}>
          <DeleteIcon />
        </Button>
      </ButtonGroup>
    );
  }
  return (
    <Button size="small" variant="outlined" onClick={() => onCreate(row)}>
      Create stripe account
    </Button>
  );
};

const stripeCopyColumn = (_, row) => {
  if (row.isManual) {
    return "";
  }
  if (row.stripeAccounts.length) {
    return (
      <Button
        size="small"
        variant="outlined"
        color="primary"
        onClick={() => {
          navigator.clipboard.writeText(
            `${baseURL}/account-setup/?id=${row.id}`
          );
        }}
        endIcon={<Assignment />}
      >
        Copy To Clipboard
      </Button>
    );
  }
};

const stripeStatusColumn = (_, row) => {
  if (row.isManual || !row.stripeAccounts.length) {
    return "";
  }
  return (
    <Typography variant="body2">{row.stripeAccounts[0].status}</Typography>
  );
};
const isManualColumn = (_, row) => {
  return row.isManual && <Check />;
};

function OwnersPage() {
  const { loading, error, data } = useGetAllOwners(5000);
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const [orderedOwners, setOrderedOwners] = useState([]);
  const [owner, setOwner] = useState({});
  const [deleteOwner] = useDeleteOwner();
  const [removeStripe] = useRemoveStripeAccount();
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showNotification } = useNotificationContext();
  const [createStripeAccount] = useCreateStripeAccount();

  useEffect(() => {
    if (!data) {
      return;
    }
    handleOwnerChange(data?.owners);
  }, [data]);

  useEffect(() => {
    if (search.length > 1) {
      handleOwnerChange(
        data?.owners.filter(
          (o) =>
            (o.email && o.email.toLowerCase().includes(search.toLowerCase())) ||
            (o.firstName &&
              o.firstName.toLowerCase().includes(search.toLowerCase())) ||
            (o.lastName &&
              o.lastName.toLowerCase().includes(search.toLowerCase()))
        )
      );
      return;
    }
    handleOwnerChange(data?.owners || []);
  }, [search]);

  const handleOwnerChange = (data) => {
    const owners = data
      .map((o) => o)
      .sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    setOrderedOwners(owners);
  };

  const onEdit = (owner) => {
    setOwner(owner);
    setOpen(true);
  };

  const onDelete = ({ id, firstName }) => {
    openConfirmation({
      title: `Delete ${firstName}`,
      body: `Deleting ${firstName} can not be undone, are you sure you want to continue?`,
      callback: () =>
        deleteOwner({
          variables: {
            id,
          },
        }).then(() => {
          showNotification(`${firstName} Deleted!`);
          closeConfirmation();
        }),
    });
  };

  const onCreateStripeAccount = (owner) => {
    openConfirmation({
      title: `Create Stripe Account for ${owner.firstName}`,
      body: `Once created you will be able to provide the boat owner with a special URL which will allow them to fully onboard.`,
      callback: async () => {
        try {
          await createStripeAccount({
            variables: {
              id: owner.id,
            },
          });
          showNotification(`Account created`);
        } catch (error) {
          showNotification(`There was an internal error, ${error.message}`);
        }

        closeConfirmation();
      },
    });
  };

  const onDeleteStripeAccount = (owner) => {
    openConfirmation({
      title: `Delete ${owner.firstName}'s Stripe Account Link`,
      body: `Deleting this will remove the owners account, this could mean they do not get paid, are you sure?`,
      callback: async () => {
        // todo, api call
        removeStripe({
          variables: {
            id: owner.id,
          },
        });
        closeConfirmation();
        showNotification(`Stripe Account Removed created`);
      },
    });
  };

  if (loading) return <Loading />;
  if (error) return `Error! ${error.message}`;
  return (
    <Grid
      container
      item
      xs={12}
      spacing={2}
      justify="space-between"
      alignItems="center"
    >
      <Grid item xs={5}>
        <Grid container item xs={12}>
          <Grid item xs={6}>
            <Typography variant="h5" component="span" display="inline">
              Our boat owners
            </Typography>
          </Grid>
          <TextField
            label="Search"
            variant="outlined"
            size="small"
            onChange={(e) => setSearch(e.target.value)}
          />
        </Grid>
      </Grid>

      <Grid item>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => {
            setOwner({});
            setOpen(true);
          }}
        >
          New boat owner
        </Button>
      </Grid>
      <Grid item xs={12}>
        <CardTable
          tableHead={[
            "info",
            "defaultCommission",
            "stripe",
            "copyStripeUrl",
            "stripeStatus",
            "isManual",
          ]}
          tableData={orderedOwners}
          customColumns={[
            {
              name: "info",
              component: nameColumn,
            },
            {
              name: "stripe",
              component: stripeColumn(
                onCreateStripeAccount,
                onDeleteStripeAccount
              ),
            },
            {
              name: "copyStripeUrl",
              component: stripeCopyColumn,
            },
            {
              name: "stripeStatus",
              component: stripeStatusColumn,
            },
            {
              name: "isManual",
              component: isManualColumn,
            },
          ]}
          actions={[
            {
              icon: <EditIcon color="primary" />,
              fn: onEdit,
            },
            {
              icon: <DeleteIcon color="error" />,
              fn: onDelete,
            },
          ]}
        />
      </Grid>
      <OwnerModal
        open={open}
        setOpen={setOpen}
        owner={owner}
        setOwner={setOwner}
      />
    </Grid>
  );
}

export default OwnersPage;
