import CardTable from "@components/cardTable/CardTable";
import { Button, ButtonGroup, Grid, Typography, Link } from "@material-ui/core";
import {
  useDeleteMessage,
  useGetAllMessages,
} from "@store/actions/messageActions";
import React, { useCallback, useEffect, useState } from "react";
import SortBy from "components-library/lib/components/SortBy/SortBy";
import ConvertMessageToBookingModal from "@components/booking/ConvertMessageToBookingModal";
import { useUpdateEffect } from "react-use";
import Loading from "@components/loading/Loading";
import DeleteIcon from "@material-ui/icons/Delete";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useNotificationContext } from "@providers/NotificationProvider";
import { Link as RouterLink } from "react-router-dom";
import moment from "moment";

export const LeadCol = (col) => {
  return col ? (
    <>
      <Link
        variant="body1"
        color="primary"
        component={RouterLink}
        to={`/leads/${col.id}`}
      >
        {col.email}
      </Link>
      <Typography variant="body2">
        {col.firstName} {col.lastName}
      </Typography>
    </>
  ) : (
    <>-</>
  );
};
export const CreatedAtCol = (col) => {
  return col ? (
    <Typography variant="body1">{moment(col).format("ll")}</Typography>
  ) : (
    <>-</>
  );
};

const ContentCol = (onClick) => (col) => {
  return (
    <>
      {col.length > 180 ? `${col.slice(0, 180)}...` : col}
      <Button size="small" color="primary" onClick={() => onClick(col)}>
        View all
      </Button>
    </>
  );
};

const ActionsCol = (convertFn) => (_, row) => {
  return (
    <div style={{ maxWidth: "250px" }}>
      <ButtonGroup orientation="vertical" color="primary">
        <Button
          variant="outlined"
          size="small"
          color="primary"
          onClick={() => convertFn(row)}
        >
          Convert to Booking
        </Button>
      </ButtonGroup>
    </div>
  );
};

function EnquiriesPage() {
  const { data, loading, refetch } = useGetAllMessages(
    JSON.stringify({
      booking_id: null,
      owner_id: null,
      to_owner_id: null,
      enquiries_only: true,
    })
  );
  const [sortOptions] = useState(["Newist First", "Oldist First", "By Lead"]);
  const [sort, setSort] = useState("");
  const [convertOpen, setConvertOpen] = useState(false);
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState(null);
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const { showNotification } = useNotificationContext();
  const [deleteMessage] = useDeleteMessage();

  const sortFn = useCallback(
    (a, b) => {
      switch (sort) {
        case sortOptions[0]:
          return new Date(b.createdAt) - new Date(a.createdAt);
        case sortOptions[1]:
          return new Date(a.createdAt) - new Date(b.createdAt);
        case sortOptions[2]:
          if (a.lead.id < b.lead.id) {
            return -1;
          }
          if (a.lead.id > b.lead.id) {
            return 1;
          }
          return new Date(b.createdAt) - new Date(a.createdAt);
      }
    },
    [sortOptions]
  );

  useEffect(() => {
    setSort(sortOptions[0]);
    return () => {
      setSort(sortOptions[0]);
    };
  }, [sortOptions]);

  useEffect(() => {
    if (!data) {
      return;
    }
    setMessages([...(data?.messages || [])].sort(sortFn));
  }, [data, sort]);

  useUpdateEffect(() => {
    if (!convertOpen) {
      refetch();
    }
  }, [convertOpen]);

  const convertFn = (message) => {
    setMessage(message);
    setConvertOpen(true);
  };

  const onDelete = (message) => {
    openConfirmation({
      title: `Delete message from ${message.lead?.firstName}`,
      body: `Deleting this message can not be undone, are you sure you want to continue?`,
      callback: async () => {
        await deleteMessage({
          variables: {
            id: message.id,
          },
        });
        closeConfirmation();
        showNotification(`Message from ${message.lead?.firstName} Deleted!`);
      },
    });
  };

  const viewMsg = (msg) => {
    openConfirmation({
      title: "Enquiry",
      body: msg,
      callback: closeConfirmation,
    });
  };

  return (
    <Grid container spacing={3}>
      <Grid item xs={12}>
        <Typography variant="h6">Enquiries</Typography>
        <Typography variant="body1">
          These are new enquiries that are not bookings yet, you can convert to
          bookings or remove
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <SortBy value={sort} setValue={setSort} options={sortOptions} />
      </Grid>
      <Grid item xs={10}>
        {loading ? (
          <Loading />
        ) : (
          <CardTable
            tableHead={["content", "lead", "createdAt", "actions"]}
            tableData={messages}
            customColumns={[
              {
                name: "content",
                component: ContentCol(viewMsg),
              },
              {
                name: "lead",
                component: LeadCol,
              },
              {
                name: "createdAt",
                component: CreatedAtCol,
              },
              {
                name: "actions",
                component: ActionsCol(convertFn),
              },
            ]}
            actions={[
              {
                icon: <DeleteIcon color="secondary" />,
                fn: onDelete,
              },
            ]}
          />
        )}
      </Grid>
      <ConvertMessageToBookingModal
        open={convertOpen}
        setOpen={setConvertOpen}
        message={message}
      />
    </Grid>
  );
}

export default EnquiriesPage;
