import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  TextField,
  Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import BookingFormContent from "@components/booking/BookingFormContent";
import { ValidatorForm } from "react-material-ui-form-validator";
import { useBookingContext } from "@providers/BookingProvider";
import { useUpdateEffect } from "react-use";
import { useUpdateMessage } from "@actions/messageActions";
import moment from "moment";

function ConvertMessageToBookingModal({ open, setOpen, message }) {
  const {
    booking,
    setBooking,
    itemInput,
    setItemInput,
    saveBooking,
  } = useBookingContext();
  const [doAttach, setDoAttach] = useState(false);
  const [updateMessage] = useUpdateMessage();

  useEffect(() => {
    if (!message?.id) {
      return;
    }
    setBooking({
      ...booking,
      lead: { ...message.lead },
    });
  }, [message]);

  useEffect(() => {
    return () => {
      if (open) {
        setBooking({});
        setItemInput({});
        setDoAttach(false);
      }
    };
  }, [open]);

  useUpdateEffect(async () => {
    if (doAttach) {
      const input = {
        content: message.content,
        lead: { id: message.lead.id },
        booking: { id: booking.id },
        status: message.status,
      };
      await updateMessage({
        variables: {
          id: message.id,
          input,
        },
      });
      setOpen(false);
    }
  }, [doAttach]);

  const handleClose = () => {
    setOpen(false);
  };

  const save = async (e) => {
    e.preventDefault();
    if (!booking.id) {
      const { data } = await saveBooking();
      setBooking({ ...data.createBooking });
    }
    setDoAttach(true);
  };

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      <ValidatorForm onSubmit={save}>
        <DialogTitle>
          Convert enquiry from {message?.lead?.firstName} to Booking
          <Typography gutterBottom variant="body2">
            Original Message - {message?.content}
          </Typography>
          <Typography variant="body1">
            You can convert this enquiry to a booking by
          </Typography>
        </DialogTitle>

        <Divider />
        <DialogContent>
          <Typography variant="h6" gutterBottom>
            Adding enquiry to an existing booking of same lead
          </Typography>
          <Autocomplete
            options={message?.lead?.bookings || []}
            fullWidth
            size="small"
            value={booking}
            getOptionLabel={(option) => {
              let s = option.reference || "";
              if (option.cruiseDate) {
                s = s + ` - (${moment(option.CruiseDate).format("lll")})`;
              }
              return s;
            }}
            onChange={(_, v) => {
              setBooking(v || {});
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                margin="normal"
                label="Select existing booking"
                variant="outlined"
              />
            )}
          ></Autocomplete>
        </DialogContent>
        {!booking?.id && (
          <>
            <Divider />
            <br></br>
            <DialogContent>
              <Typography variant="h6" gutterBottom>
                or create a new booking
              </Typography>
            </DialogContent>
            <DialogContent>
              <BookingFormContent
                isNew
                booking={booking}
                setBooking={setBooking}
                itemInput={itemInput}
                setItemInput={setItemInput}
                showLead={false}
              />
            </DialogContent>
          </>
        )}

        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
    </Dialog>
  );
}

export default ConvertMessageToBookingModal;
