import { useState } from "react";

// enum BoatPageLayoutContent {
//   about
//   rates
//   packages
//   additional
//   featureGroup
// }

export const useBoatLayoutContents = () => {
  const data = [
    {
      label: "About Section",
      value: "about",
    },
    {
      label: "Boat Rates Section",
      value: "rates",
    },
    {
      label: "Boat Packages",
      value: "packages",
    },
    {
      label: "Boat Additional information section",
      value: "additional",
    },
    {
      label: "Feature section (choose feature group)",
      value: "featureGroup",
    },
    {
      label: "Wharfs section",
      value: "wharfs",
    },
    {
      label: "Attachments section",
      value: "attachments",
    },
    {
      label: "Media (only for boat pages with video)",
      value: "media",
    },
  ];

  const [options] = useState(data);
  return options;
};
