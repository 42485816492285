import { gql } from "@apollo/client";

export const STAFF_ROLE_ENUM = gql`
  query {
    __type(name: "StaffRole") {
      name
      enumValues {
        name
      }
    }
  }
`;

export const GET_ALL_STAFF = gql`
  query GetAllStaffs {
    staffList {
      id
      firstName
      lastName
      email
      role
    }
  }
`;

export const CREATE_STAFF = gql`
  mutation CreateStaff($input: StaffInput!) {
    createStaff(input: $input) {
      id
      firstName
      lastName
      email
      role
    }
  }
`;
export const UPDATE_STAFF = gql`
  mutation UpdateStaff($id: UUID!, $input: StaffInput!) {
    updateStaff(id: $id, input: $input) {
      id
      firstName
      lastName
      email
      role
    }
  }
`;

export const DELETE_STAFF = gql`
  mutation DeleteStaff($id: UUID!) {
    deleteStaff(id: $id) {
      id
    }
  }
`;
