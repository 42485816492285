import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@material-ui/core";
import { useBookingContext } from "@providers/BookingProvider";
import { ValidatorForm } from "react-material-ui-form-validator";
import BookingFormContent from "./BookingFormContent";
import Alert from "@material-ui/lab/Alert";
import { useEffect, useState } from "react";
import BookingItemModal from "./BookingItemModal";
import { useLazyGetBooking } from "@store/actions/bookingActions";

function BookingModal({ open, setOpen, onSave, description }) {
  const {
    booking,
    setBooking,
    saveBooking,
    setBookingItem,
    itemInput,
    setItemInput,
  } = useBookingContext();

  const [getBooking, { data }] = useLazyGetBooking();

  useEffect(() => {
    if (open && booking.id) {
      getBooking({ variables: { id: booking.id } });
    }
  }, [open]);

  useEffect(() => {
    if (data) {
      setBooking(data.booking);
    }
  }, [data]);

  const [itemOpen, setItemOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };

  const handleSave = (e) => {
    e.preventDefault();
    saveBooking().then((res) =>
      onSave(booking.id ? res.data.updateBooking : res.data.createBooking)
    );
  };
  const onEditItem = (item) => {
    setBookingItem(item);
    setItemOpen(true);
  };

  const onItemSave = () => {};

  return (
    <Dialog
      fullWidth
      maxWidth="md"
      open={open}
      onClose={handleClose}
      disableBackdropClick={true}
    >
      {description && <Alert severity="error">{description}</Alert>}

      <ValidatorForm onSubmit={handleSave}>
        <DialogTitle>Booking</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the bookings details below.
          </DialogContentText>
          <BookingFormContent
            onEditItem={onEditItem}
            booking={booking}
            setBooking={setBooking}
            isNew={!booking.id}
            itemInput={itemInput}
            setItemInput={setItemInput}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button type="submit" color="primary">
            Save
          </Button>
        </DialogActions>
      </ValidatorForm>
      <BookingItemModal
        open={itemOpen}
        setOpen={setItemOpen}
        onSave={onItemSave}
      />
    </Dialog>
  );
}

export default BookingModal;
