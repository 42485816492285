import {
  GridList,
  GridListTile,
  GridListTileBar,
  IconButton,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import React, { useRef } from "react";
import { useDrag, useDrop } from "react-dnd";

const ItemTypes = {
  CARD: "card",
};

const DragTile = ({ tile, onDeleteAsset, index, setOrder }) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: ItemTypes.CARD,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }
      setOrder(dragIndex, hoverIndex);
      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });
  const [{ isDragging }, drag] = useDrag({
    item: { type: ItemTypes.CARD, id: tile.id, index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));
  return (
    <GridListTile
      key={tile.id}
      ref={ref}
      style={{
        width: "25%",
        height: "134px",
        padding: "2px",
        opacity,
        overflow: "hidden",
        transform: "translate3d(0, 0, 0)",
        cursor: "move",
      }}
      data-handler-id={handlerId}
    >
      <img src={tile.path} alt={tile.name} />
      <GridListTileBar
        title={tile.name}
        actionIcon={
          <IconButton
            style={{ color: "white" }}
            onClick={() => onDeleteAsset(tile)}
          >
            <DeleteIcon />
          </IconButton>
        }
        actionPosition="right"
      />
    </GridListTile>
  );
};

function BoatAssetsDropzone({ assets, onDeleteAsset, setOrder }) {
  return (
    <GridList
      cellHeight={130}
      cols={4}
      children={assets.map((tile, index) => (
        <DragTile
          key={tile.id}
          tile={tile}
          onDeleteAsset={onDeleteAsset}
          index={index}
          setOrder={setOrder}
        />
      ))}
    ></GridList>
  );
}

export default BoatAssetsDropzone;
