import Breadcrumb from "@components/breadcrumb/Breadcrumb";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  Grid,
  Typography,
} from "@material-ui/core";
import { useRoutesContext } from "@providers/RoutesProvider";
import { useGetQuoteUserStats } from "@store/actions/quoteActions";
import { useGetAllUser } from "@store/actions/userActions";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { friendlyDateTime } from "utils/date";
import { formatNumber } from "utils/numbers";

function UserPage() {
  const { userId } = useParams();
  const { data, loading } = useGetQuoteUserStats(userId);
  const { loading: allUserLoading, data: allUserData } = useGetAllUser();
  const { routes } = useRoutesContext();

  const user = useMemo(() => {
    if (!allUserData) {
      return;
    }
    return allUserData.users.find((u) => u.id === userId);
  }, [allUserData, userId]);

  if (loading) {
    return <CircularProgress />;
  }
  return (
    <>
      <Breadcrumb routes={routes} />
      <Divider />
      <br />

      <Grid container spacing={2} style={{ maxWidth: 1100 }}>
        <Grid item xs={12}>
          {allUserLoading ? (
            <CircularProgress />
          ) : (
            <>
              <Typography variant="h6">{user.email}</Typography>
              <Typography variant="body2">
                <strong>name : </strong> {user.firstName} {user.lastName}
              </Typography>
              <Typography variant="body2">
                <strong>user name : </strong> {user.userName}
              </Typography>
              <Typography variant="body2">
                <strong>last login : </strong>{" "}
                {friendlyDateTime(user.lastLogin)}
              </Typography>
            </>
          )}
          <Grid item xs={12}>
            <Box mt={2}>
              <Divider />
            </Box>
          </Grid>
        </Grid>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Grid item xs={12}>
              <Typography variant="h6">Quote stats</Typography>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <Typography>No. of sent quotes.</Typography>
                  <Typography variant="h5">
                    {formatNumber(data.quoteStats.sentQuotes)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <Typography>No. of paid quotes.</Typography>
                  <Typography variant="h5">
                    {formatNumber(data.quoteStats.paidQuotes)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <Typography>Total Revenue.</Typography>
                  <Typography variant="h5">
                    ${formatNumber(data.quoteStats.totalRevenue / 100)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={3}>
              <Card>
                <CardContent>
                  <Typography>Average Revenue per quote.</Typography>
                  <Typography variant="h5">
                    ${formatNumber(data.quoteStats.averageQuoteRevenue / 100)}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          </>
        )}
      </Grid>
    </>
  );
}

export default UserPage;
