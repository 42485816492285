import CardTable from "@components/cardTable/CardTable";
import { Grid, Typography, Button } from "@material-ui/core";
import {
  useDeleteBoatSection,
  useGetAllBoatSections,
} from "@store/actions/blockActions";
import { useGetAllBoats } from "@store/actions/boatActions";
import React, { useState, useEffect } from "react";
import BoatSectionDialog from "./BoatSectionDialog";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { useConfirmationContext } from "@providers/ConfirmationProvider";
import { useBoatTileOrder } from "components-library";

const boatsCol = (getOrderedBoats) => (col, row) => {
  const boats = getOrderedBoats(row);
  return boats.map((b) => b.name).join(" - ");
};

function BoatSectionsTab() {
  const [boatSections, setBoatSections] = useState([]);
  const [boatSection, setBoatSection] = useState({});
  const { data: boatData } = useGetAllBoats();
  const { data } = useGetAllBoatSections();
  const [boats, setBoats] = useState([]);
  const [open, setOpen] = useState(false);
  const { openConfirmation, closeConfirmation } = useConfirmationContext();
  const [deleteBoatSection] = useDeleteBoatSection();
  const { getOrderedBoats } = useBoatTileOrder();

  useEffect(() => {
    if (boatData?.boats) {
      setBoats(boatData?.boats);
    }
  }, [boatData]);

  useEffect(() => {
    if (data?.boatSections) {
      setBoatSections(data?.boatSections);
    }
  }, [data]);

  const newSection = () => {
    setBoatSection({});
    setOpen(true);
  };

  const onEdit = (s) => {
    setBoatSection(s);
    setOpen(true);
  };

  const onDelete = (ref) => {
    openConfirmation({
      title: `Delete ${ref.name}`,
      body: `Deleting ${ref.name} can not be undone, are you sure you want to continue?`,
      callback: () => onDeleteBoatSection(ref.id),
    });
  };

  const onDeleteBoatSection = async (id) => {
    await deleteBoatSection({
      variables: {
        id,
      },
    });
    closeConfirmation();
  };

  return (
    <Grid container style={{ minWidth: 400 }} spacing={3}>
      <Grid item xs={9}>
        <Typography variant="subtitle1">
          Boat Sections are a list of boats to show, these will be show as boat
          cards
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={newSection}
        >
          New Boat Section
        </Button>
      </Grid>
      <Grid item xs={12}>
        <CardTable
          tableHead={["name", "title", "boats"]}
          tableData={boatSections}
          customColumns={[
            {
              name: "boats",
              component: boatsCol(getOrderedBoats),
            },
          ]}
          actions={[
            {
              icon: <EditIcon color="primary" />,
              fn: onEdit,
            },
            {
              icon: <DeleteIcon color="error" />,
              fn: onDelete,
            },
          ]}
        />
      </Grid>
      <BoatSectionDialog
        open={open}
        setOpen={setOpen}
        boatSection={boatSection}
        setBoatSection={setBoatSection}
        boats={boats}
      />
    </Grid>
  );
}

export default BoatSectionsTab;
